import { EDIT_PATH, ERROR_PATH, FILTER_PATH, FORBIDDEN_PATH, NEW_PATH, ROOT_PATH } from "lib/constants/paths"
import { IModule } from "types"

export const isForbidden = (nav: IModule[], location: string): boolean => {
    const navigationMatch = nav.map(n => {
        const viewMatch = n.views.find(v => v.relativePath === location || v.relativePath + NEW_PATH === location || v.relativePath + EDIT_PATH === location || v.relativePath + FILTER_PATH)
        return Boolean(viewMatch) ? location : undefined
    }).concat(nav.map(n => n.relativePath))
    return !navigationMatch.includes(location)
}

export const isUnauthenticatedPath = (path: string): boolean => {
    return [ROOT_PATH, ERROR_PATH + FORBIDDEN_PATH].includes(path)
}