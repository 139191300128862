import { Icon, Button, Divider, Typography, Tooltip, CircularProgress } from '@material-ui/core'
import React from 'react'
import { PurchaseOrderRequestSteps } from 'types'

interface Props {
    onNextStep: () => void
    onPrevStep: () => void
    step: PurchaseOrderRequestSteps
    enableNext: boolean
    loading: boolean
    onSave: () => void
    onCreate: () => void
}

const PurchaseOrderActions = (props: Props) => {
    const { onNextStep, onPrevStep, step, enableNext, onSave: handleSave, loading, onCreate: handleCreate } = props
    return (
        <div className="mt-4 flex justify-between">
            <div className="h-full flex items-center pl-2">
                <Typography variant="caption" color="textSecondary" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                    {"SOLICITUD DE ORDEN DE COMPRA"}
                </Typography>
            </div>
            <div className="flex">
                <div className="flex h-full items-center pr-4">
                    {loading && <CircularProgress color="primary" size={26} className="my-1" thickness={12} />}
                </div>
                {
                    step === "supplier" &&
                    <React.Fragment>
                        <Button
                            disableElevation
                            onClick={onPrevStep}
                            disabled={loading}
                        >
                            <Icon className="ml-1">
                                chevron_left
                            </Icon>
                            {"Información"}
                        </Button>
                        <div className="mx-3">
                            <Divider orientation="vertical" />
                        </div>
                    </React.Fragment>
                }
                {
                    step === "info" &&
                    <React.Fragment>
                        <Tooltip arrow title={!enableNext ? "Agrega detalles para poder continuar" : ""}>
                            <span>
                                <Button
                                    disableElevation
                                    onClick={onNextStep}
                                    disabled={!enableNext || loading}
                                >
                                    {"Proveedores"}
                                    <Icon className="ml-1">
                                        chevron_right
                                    </Icon>
                                </Button>
                            </span>
                        </Tooltip>
                    </React.Fragment>
                }
                <div className="mx-3">
                    <Divider orientation="vertical" />
                </div>
                <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    style={{ marginRight: 15 }}
                    onClick={handleSave}
                    disabled={loading}
                >
                    {"Guardar"}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    type="submit"
                    onClick={handleCreate}
                    disabled={loading}
                >
                    {"Crear"}
                </Button>
            </div>
        </div >
    )
}

export default PurchaseOrderActions