import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DocumentDetail, PurchaseOrderActions, PurchaseOrderHeader, SupplierApproach } from 'components'
import { AuthContext, FileContext, ManagementContext } from 'context'
import { ROOT_TITLE, NEW_PURCHASE_ORDER_TITLE, savePurchaseOrderService, PURCHASE_ORDER_PATH, updatePurchaseOrderService, createPurchaseOrderService, TO_APPROVE_PATH, validatePurchaseOrder, getTotalBySupplierInfo, getApprovalLevel, FIELDS, deleteDocumentFileByIdService } from 'lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router-dom'
import { IPurchaseOrderInitial, PurchaseOrderRequestSteps, IPurchaseOrderHeader, IPurchaseOrderBackUpFile, IMessageConfig, ISupplierApproachRef, IUploadDocumentFile, IDocument, IDocumentFile } from 'types'

const NewPurchaseOrder = () => {
    const location = useLocation()
    const history = useHistory()
    const supplierApproachRef = React.useRef<ISupplierApproachRef>()
    const { suppliers, taxes, taxTypes, refreshSuppliers, refreshTaxes, refreshTaxTypes, approvalLevels } = React.useContext(ManagementContext)
    const { user } = React.useContext(AuthContext)
    const { uploadPurchaseOrderFiles } = React.useContext(FileContext)
    const [step, setStep] = React.useState<PurchaseOrderRequestSteps>("info")
    const [documentId, setDocumentId] = React.useState<number | undefined>(undefined)
    const [purchaseOrder, setPurchaseOrder] = React.useState<IPurchaseOrderInitial>({ detail: [], header: ({} as IPurchaseOrderHeader), suppliersInfo: [] } as IPurchaseOrderInitial)
    const [approvalMessage, setApprovalMessage] = React.useState<File | undefined>()
    const [backUpFiles, setBackUpFiles] = React.useState<IPurchaseOrderBackUpFile[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const handleNext = () => {
        setStep("supplier")
    }
    const handlePrev = () => {
        setStep("info")
    }
    const handleSave = async () => {
        let createdDocumentId = documentId
        try {
            setLoading(true)
            if (createdDocumentId) {
                await updatePurchaseOrderService(createdDocumentId, { ...purchaseOrder, header: { ...purchaseOrder.header, type: PURCHASE_ORDER_PATH.replace("/", "") } }, user?.userId)
            } else {
                const savedPurchaseOrder = await savePurchaseOrderService({ ...purchaseOrder, header: { ...purchaseOrder.header, type: PURCHASE_ORDER_PATH.replace("/", "") } }, user?.userId)
                setDocumentId(savedPurchaseOrder.documentId)
                createdDocumentId = savedPurchaseOrder.documentId
            }
            const filesToUpload: IUploadDocumentFile[] = []
            if (approvalMessage && (await approvalMessage.arrayBuffer()).byteLength !== 0) {
                filesToUpload.push({
                    description: FIELDS.approvalMessage.key,
                    file: approvalMessage
                })
            }
            if (backUpFiles.length > 0) {
                for (const backUpFile of backUpFiles) {
                    if ((await backUpFile.file.arrayBuffer()).byteLength !== 0) {
                        filesToUpload.push({
                            description: `${backUpFile.supplierId}`,
                            file: backUpFile.file
                        })
                    }
                }
            }
            const savedApprovalMessage: IDocumentFile = (location.state as any)?.edit?.files?.find(f => f.tag === FIELDS.approvalMessage.key)
            if (savedApprovalMessage && !approvalMessage) {
                await deleteDocumentFileByIdService(savedApprovalMessage.documentFileId)
            }
            const createdBackupFiles: IDocumentFile[] = (location.state as any)?.edit?.files?.filter(f => !isNaN(parseInt(f.tag))) ?? []
            for (const file of createdBackupFiles) {
                const shouldDelete = !backUpFiles.map(b => b.supplierId).includes(parseInt(file.tag))
                if (shouldDelete) {
                    await deleteDocumentFileByIdService(file.documentFileId)
                }
            }
            if (filesToUpload.length > 0 && createdDocumentId) {
                await uploadPurchaseOrderFiles(filesToUpload, createdDocumentId)
            }
            setLoading(false)
            setMessageConfig({ open: true, message: "Orden de compra guardada exitosamente!", severity: "success" })
        } catch (error) {
            console.log(error)
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo guardar la orden de compra", severity: "error" })
        }
    }
    const handleCreate = async () => {
        try {
            const { valid, message, moveTo, supplierIndex } = validatePurchaseOrder(purchaseOrder)
            if (!valid) {
                if (message) { setMessageConfig({ open: true, message, severity: "error" }) }
                if (moveTo) { setStep(moveTo) }
                if (supplierIndex !== undefined) { supplierApproachRef.current?.onChangeTab(supplierIndex) }
                return
            }
            const totals: number[] = []
            for (const info of purchaseOrder.suppliersInfo) {
                totals.push(getTotalBySupplierInfo(info))
            }
            const approvalLevel = getApprovalLevel(totals, approvalLevels)
            setLoading(true)
            const createdDocument: IDocument = await createPurchaseOrderService(documentId ?? 0, { ...purchaseOrder, header: { ...purchaseOrder.header, type: PURCHASE_ORDER_PATH.replace("/", ""), approvalLevel, flowData: [] } }, user?.userId)
            const filesToUpload: IUploadDocumentFile[] = []
            if (approvalMessage && (await approvalMessage.arrayBuffer()).byteLength !== 0) {
                filesToUpload.push({
                    description: FIELDS.approvalMessage.key,
                    file: approvalMessage
                })
            }
            if (backUpFiles.length > 0) {
                for (const backUpFile of backUpFiles) {
                    if ((await backUpFile.file.arrayBuffer()).byteLength !== 0) {
                        filesToUpload.push({
                            description: `${backUpFile.supplierId}`,
                            file: backUpFile.file
                        })
                    }
                }
            }
            if (filesToUpload.length > 0) {
                await uploadPurchaseOrderFiles(filesToUpload, createdDocument.documentId)
            }
            history.replace({
                pathname: `${PURCHASE_ORDER_PATH}${TO_APPROVE_PATH}`,
                state: { showCreatedMessage: true }
            })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo crear la orden de compra", severity: "error" })
        }
    }
    React.useEffect(() => {
        refreshSuppliers()
        refreshTaxes()
        refreshTaxTypes()
    }, [refreshSuppliers, refreshTaxes, refreshTaxTypes])

    React.useEffect(() => {
        if ((location.state as any)?.edit) {
            setPurchaseOrder((location.state as any).edit?.data)
            setDocumentId((location.state as any).edit?.document?.documentId ?? undefined)
            const savedApprovalMessage: IDocumentFile = (location.state as any).edit?.files?.find(f => f.tag === FIELDS.approvalMessage.key)
            if (savedApprovalMessage) {
                setApprovalMessage(new File([], savedApprovalMessage.fileName))
            }
            const createdBackupFiles: IDocumentFile[] = (location.state as any).edit?.files?.filter(f => !isNaN(parseInt(f.tag)))
            setBackUpFiles(current => current.concat(createdBackupFiles.map(c => ({ file: new File([], c.fileName), supplierId: parseInt(c.tag) }))))
        }
    }, [location])

    return (
        <div className="flex flex-col h-full overflow-y-hidden">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${NEW_PURCHASE_ORDER_TITLE}`}</title>
            </Helmet>
            {
                step === "info" &&
                <div className="flex flex-col h-full overflow-y-hidden">
                    <div className="p-1">
                        <PurchaseOrderHeader
                            header={purchaseOrder.header}
                            onChange={(key, value) => setPurchaseOrder(current => ({ ...current, header: { ...current.header, [key]: value } }))}
                            approvalMessage={approvalMessage}
                            setApprovalMessage={setApprovalMessage}
                        />
                    </div>
                    <div className="p-1 flex-grow overflow-y-hidden">
                        <DocumentDetail
                            detail={purchaseOrder.detail}
                            onAdd={(added) => {
                                const newPurchaseOrder = { ...purchaseOrder }
                                newPurchaseOrder.detail = newPurchaseOrder.detail.concat({ ...added, discount: 0 })
                                if (newPurchaseOrder.suppliersInfo.length > 0) {
                                    newPurchaseOrder.suppliersInfo = newPurchaseOrder.suppliersInfo.map(s => ({ ...s, detailBySupplierInfo: [...s.detailBySupplierInfo, { ...added, discount: 0 }] }))
                                }
                                setPurchaseOrder(newPurchaseOrder)
                            }}
                            hideSummary
                            onEdit={(edited) => setPurchaseOrder(current => ({ ...current, detail: edited }))}
                            onDelete={(deleted) => {
                                const newPurchaseOrder = { ...purchaseOrder }
                                newPurchaseOrder.detail = newPurchaseOrder.detail.filter(d => d !== deleted)
                                if (newPurchaseOrder.suppliersInfo.length > 0) {
                                    newPurchaseOrder.suppliersInfo = newPurchaseOrder.suppliersInfo.map(s => ({ ...s, detailBySupplierInfo: [...s.detailBySupplierInfo].filter(d => d.purchaseCode !== deleted.purchaseCode) }))
                                }
                                setPurchaseOrder(newPurchaseOrder)
                            }}
                            taxes={[]}
                            taxTypes={[]}
                            purchaseOrderDescriptionOnly
                        />
                    </div>
                </div>
            }
            {
                step === "supplier" &&
                <div className="flex flex-col h-full overflow-y-hidden p-1">
                    <SupplierApproach
                        ref={supplierApproachRef}
                        suppliers={suppliers}
                        taxes={taxes}
                        taxTypes={taxTypes}
                        detail={purchaseOrder.detail ?? []}
                        suppliersInfo={purchaseOrder.suppliersInfo ?? []}
                        onChange={(suppliersInfo) => setPurchaseOrder(current => ({ ...current, suppliersInfo }))}
                        backUpFiles={backUpFiles}
                        setBackUpFiles={setBackUpFiles}
                        setMessageConfig={setMessageConfig}
                    />
                </div>
            }
            <PurchaseOrderActions
                onNextStep={handleNext}
                onPrevStep={handlePrev}
                step={step}
                enableNext={Boolean(purchaseOrder.detail.length > 0)}
                onSave={handleSave}
                onCreate={handleCreate}
                loading={loading}
            />
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default NewPurchaseOrder
