import { isEmptyObject, LOCAL_STORAGE_KEY } from 'lib'
import React from 'react'

interface Props {
    saveItem: (key: string, value: any) => void,
    getItem: (key: string) => undefined | any,
    deleteItem: (key: string) => void,
}

const useStorage = (): Props => {
    const saveItem = React.useCallback((key: string, value: any) => {
        localStorage.setItem(LOCAL_STORAGE_KEY + key, JSON.stringify(value))
    }, [])
    const getItem = React.useCallback((key: string) => {
        const result = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY + key) || "{}")
        return isEmptyObject(result) ? undefined : result
    }, [])
    const deleteItem = React.useCallback((key: string) => {
        localStorage.removeItem(LOCAL_STORAGE_KEY + key)
    }, [])

    return {
        deleteItem,
        getItem,
        saveItem
    }
}

export default useStorage
