import { Paper, Typography, Divider } from '@material-ui/core'
import { DocumentContext } from 'context'
import React from 'react'

interface Props {
    identification: string
    name: string
    isManual?: boolean
}

const textStyle = { fontWeight: 600, fontSize: "0.8em" }

const ReceiverData = (props: Props) => {
    const { identification, name, isManual } = props
    const { company } = React.useContext(DocumentContext)
    return (
        <Paper variant="outlined" className="p-4 px-6 mt-3" elevation={1}>
            <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                {"DATOS DEL RECEPTOR"}
            </Typography>
            <div className="mt-2">
                <div className="my-2">
                    <Divider />
                </div>
                <div className="flex">
                    <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                        {`Razón social/Nombres y apellidos:`}
                    </Typography>
                    <Typography style={textStyle} className="pl-2" variant="subtitle2">
                        {isManual ? company?.comercialName : name}
                    </Typography>
                </div>
                <div className="flex items-center">
                    <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                        {`Identificación:`}
                    </Typography>
                    <Typography style={textStyle} className="pl-2" variant="subtitle2">
                        {isManual ? company?.identification : identification}
                    </Typography>
                </div>
            </div>
        </Paper>
    )
}

export default ReceiverData
