import React from "react"
import {
    Button,
    Collapse,
    Icon,
    IconButton,
    LinearProgress,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tooltip,
} from "@material-ui/core"
import {
    DocumentPicker,
    FixedLoadingIndicator,
    LabelDisplayedRows,
    ServerErrorHandler,
    Search,
    DocumentFilters,
    RefundsCell,
    CountDocuments,
} from "components"
import {
    DOCUMENTS_TABLE_COLUMNS,
    MEDIUM_ROWS_PER_PAGINATION_OPTIONS,
    DOCUMENT_PATH,
    ROOT_TITLE,
    DOCUMENT_TITLE,
    FIELDS,
    DOCUMENT_VIEW_PATH,
    getDocumentByIdService,
    DOCUMENT_TYPES,
    NEW_DOCUMENT_PATH,
    DOCUMENT_STATES,
    DOCUMENT_TYPE_CODES,
    FINISHED_PRINTED_INVOICES_COLUMNS,
    getPurchaseOrderByIdService,
    PURCHASE_ORDER_PATH,
    PURCHASE_ORDER_VIEW_PATH,
    SINGLE_COMPANY_DOCUMENTS_TABLE_COLUMNS,
    COUPA_STATES,
    queueDocumentByIdService,
    COUPA_QUEUEABLE_DOCUMENT_STATUS,
    DOCUMENT_TYPE_IDS,
    SRI_AUTHORIZED_ID,
    approveDocumentService,
    RETENTION_PATH,
} from "lib"
import { useHistory } from "react-router"
import { IMessageConfig, IDocument, IDocumentAdditionalInfo } from "types"
import { AuthContext, DocumentContext, ManagementContext } from "context"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Helmet } from "react-helmet"
import moment from 'moment'

interface Props {
    initialCode?: string
    initialState?: number
    initialHistoryType?: number
    typePath?: string
    redirectOnApprove?: string
    redirectOnReject?: string
    hideState?: boolean
    hideType?: boolean
    forEmission?: boolean
    forReception?: boolean
    showCounts?: boolean
    issuingCompany?: boolean
    receivingCompany?: boolean
    countPath?: string
    createRetentionOnly?: boolean
}

const DocumentsDashboard = (props: Props) => {
    const {
        initialCode,
        typePath,
        issuingCompany,
        initialState,
        redirectOnApprove,
        hideState,
        hideType,
        forEmission,
        forReception,
        showCounts,
        redirectOnReject,
        initialHistoryType,
        receivingCompany,
        countPath,
        createRetentionOnly
    } = props
    const {
        documents,
        company,
        conditions,
        documentFilters: filters,
        setFilters,
        documentsModel,
        documentTypes,
        getDocumentsByFilters,
        totalDocuments,
        getNextDocumentsByFilters,
        getDocumentsByInitialFilters,
        historyTypes
    } = React.useContext(DocumentContext)
    const { user } = React.useContext(AuthContext)
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage, } = documentsModel
    const history = useHistory()
    const { refreshIdentificationTypes, refreshTaxes, refreshTaxTypes, identificationTypes, taxTypes, taxes } = React.useContext(ManagementContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info", })
    const [openPicker, setOpenPicker] = React.useState<boolean>(false)
    const [openFilters, setOpenFilters] = React.useState<boolean>(false)
    const handleFilter = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            await getDocumentsByFilters()
            handleChangePage(undefined, 0)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }
    const handleNewPage = async (pageNumber: number, offset: number) => {
        try {
            setLoading(true)
            await getNextDocumentsByFilters(offset, pageNumber)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }
    const handleSelect = async (document: IDocument) => {
        try {
            setLoading(true)
            if (identificationTypes.length === 0) { refreshIdentificationTypes() }
            if (taxTypes.length === 0) { refreshTaxTypes() }
            if (taxes.length === 0) { refreshTaxes() }
            const response = await getDocumentByIdService(document.documentId)
            history.push({
                state: { document: response, redirectOnApprove, redirectOnReject },
                pathname: `${DOCUMENT_PATH}${DOCUMENT_VIEW_PATH}/${document.documentId}`
            })
            setLoading(false)
        } catch (error) {
            console.log(error)
            setMessageConfig({ open: true, message: "No se pudo obtener información del documento selecionado...", severity: "error" })
            setLoading(false)
        }
    }
    const handleClearFilters = async () => {
        try {
            setLoading(true)
            await getDocumentsByInitialFilters(initialCode, initialState, issuingCompany ? company?.identification : undefined, initialHistoryType)
            handleChangePage(undefined, 0)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }
    const getModel = React.useCallback((): any[] => { return (initialLoading ? [] : documents) }, [documents, initialLoading])
    const handleNewDocument = async () => {
        if (typePath) {
            const selected = DOCUMENT_TYPES.find(d => d.type === typePath.replace("/", ""))
            if (selected) {
                history.push({
                    pathname: `${DOCUMENT_PATH}${NEW_DOCUMENT_PATH}/${selected.type}`,
                    state: { type: selected }
                })
                return
            }
        }
        if (createRetentionOnly) {
            history.push({
                pathname: `${RETENTION_PATH}${NEW_DOCUMENT_PATH}`,
            })
            return
        }
        setOpenPicker(true)
    }
    const isFinishedInvoice = React.useCallback(() => {
        return initialState === DOCUMENT_STATES.finished && (initialCode === DOCUMENT_TYPE_CODES.printedInvoice || initialCode === DOCUMENT_TYPE_CODES.electronicInvoice)
    }, [initialState, initialCode])
    const isRetentionReceipt = React.useCallback(() => {
        return initialCode === DOCUMENT_TYPE_CODES.retentionReceipt
    }, [initialCode])
    const isFinishedForeignExpensesLiquidation = React.useCallback(() => {
        return initialCode === DOCUMENT_TYPE_CODES.foreignExpensesLiquidation && initialState === DOCUMENT_STATES.finished
    }, [initialCode, initialState])
    const isReimbursementInvoice = React.useCallback(() => {
        return initialCode === DOCUMENT_TYPE_CODES.reimbursementInvoice
    }, [initialCode])
    const isForeignExpensesLiquidationNotFinished = React.useCallback(() => {
        return initialCode === DOCUMENT_TYPE_CODES.foreignExpensesLiquidation && initialState !== DOCUMENT_STATES.finished
    }, [initialCode, initialState])
    const isPurchaseSettlement = React.useCallback(() => {
        return initialCode === DOCUMENT_TYPE_CODES.purchaseSettlement
    }, [initialCode])
    const applyingFilters = React.useCallback((): boolean => {
        return Boolean((
            filters.conditions.length > 0
            || filters.documentTypes.length > 0
            || Boolean(filters.rucOrName) || Boolean(filters.serialNumber)
            || filters.documentRange.start !== null
            || filters.documentRange.end !== null
            || filters.receptionRange.start !== null
            || filters.receptionRange.end !== null
            || filters.rucOrNameReceptor !== null
        ))
    }, [filters])

    const handlePurchaseOrder = async (id: number) => {
        try {
            setLoading(true)
            const purchaseOrder = await getPurchaseOrderByIdService(id)
            history.push({
                state: { purchaseOrder },
                pathname: `${PURCHASE_ORDER_PATH}${PURCHASE_ORDER_VIEW_PATH}/${purchaseOrder.document.documentId}`
            })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo obtener información del documento selecionado...", severity: "error" })
        }
    }

    const handleQueue = async (id: number, noCoupaMessage?: boolean) => {
        try {
            setLoading(true)
            await queueDocumentByIdService(id)
            setLoading(false)
            setMessageConfig({ open: true, message: noCoupaMessage ? "El documento se encuentra en proceso de ser autorizado" : "El documento selecionado se encuentra en proceso de enviar a COUPA!", severity: "success" })
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo enviar a COUPA el documento seleccionado...", severity: "error" })
        }
    }

    const handleStatus = async (id: number, additionalInfo: any) => {
        try {
            setLoading(true)
            await approveDocumentService({ documentId: id, statusId: SRI_AUTHORIZED_ID, userId: user?.userId }, additionalInfo ? additionalInfo : {})
            setLoading(false)
            setMessageConfig({ open: true, message: "Documento enviado al SRI", severity: "success" })
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo enviar el documento seleccionado...", severity: "error" })
        }
    }

    React.useEffect(() => {
        const initScreen = async () => {
            try {
                if (history.action === "REPLACE" || history.action === "PUSH") {
                    setInitialLoading(true)
                    await getDocumentsByInitialFilters(initialCode, initialState, issuingCompany ? company?.identification : undefined, initialHistoryType, receivingCompany ? company?.identification : undefined)
                    handleChangePage(undefined, 0)
                    setInitialLoading(false)
                }
            } catch (error) {
                setInitialLoading(false)
                setError(true)
            }
        }
        initScreen()
    }, [initialCode, getDocumentsByInitialFilters, history, initialState, handleChangePage, issuingCompany, company, initialHistoryType, receivingCompany])
    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${DOCUMENT_TITLE}`}</title>
            </Helmet>
            <div className="flex items-center">
                <form className="flex flex-col w-full" onSubmit={handleFilter}>
                    {
                        showCounts &&
                        <CountDocuments countPath={countPath} />
                    }
                    <div className="flex items-center justify-between w-full">
                        <div className="flex">
                            <Search
                                onChange={(e) => setFilters({ ...filters, serialNumber: e.target.value })}
                                query={filters.serialNumber ?? ""}
                                onClear={() => setFilters({ ...filters, serialNumber: "" })}
                                placeholer="Buscar por número de serie..."
                                width={250}
                            />
                            {
                                !issuingCompany ?
                                    <div className="ml-2">
                                        <Search
                                            onChange={(e) => setFilters({ ...filters, rucOrName: e.target.value })}
                                            query={filters.rucOrName}
                                            onClear={() => setFilters({ ...filters, rucOrName: "" })}
                                            placeholer="RUC o nombre..."
                                            width={250}
                                        />
                                    </div>
                                    :
                                    <div className="ml-2">
                                        <Search
                                            onChange={(e) => setFilters({ ...filters, rucOrNameReceptor: e.target.value })}
                                            query={filters.rucOrNameReceptor}
                                            onClear={() => setFilters({ ...filters, rucOrNameReceptor: "" })}
                                            placeholer="RUC o nombre de receptor..."
                                            width={250}
                                        />
                                    </div>
                            }
                            <Button
                                size="small"
                                style={{ marginLeft: 20 }}
                                disableElevation
                                color={applyingFilters() ? "primary" : undefined}
                                onClick={() => setOpenFilters(current => !current)}
                            >
                                <Icon fontSize="small" style={{ marginRight: 5 }}>{openFilters ? "expand_less" : "expand_more"}</Icon>
                                <span className="pr-2">{`${openFilters ? "Menos filtros" : "Más filtros"}`}</span>
                            </Button>
                            {
                                applyingFilters() &&
                                <Tooltip
                                    arrow
                                    title="Resetear filtros por defecto"
                                >
                                    <IconButton
                                        size="small"
                                        onClick={handleClearFilters}
                                        disabled={loading || initialLoading}
                                    >
                                        <Icon fontSize="small" className="mx-2">
                                            restart_alt
                                        </Icon>
                                    </IconButton>
                                </Tooltip>
                            }
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginLeft: 20 }}
                                disableElevation
                                type="submit"
                                disabled={loading || initialLoading}
                            >
                                {"Aplicar"}
                            </Button>
                        </div>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 20 }}
                            disableElevation
                            onClick={handleNewDocument}
                        >
                            <Icon fontSize="small" style={{ marginRight: 5 }}>add</Icon>
                            {"Nuevo documento"}
                        </Button>
                    </div>
                    <Collapse in={openFilters}>
                        <DocumentFilters
                            hideState={hideState}
                            hideType={hideType}
                            forEmission={forEmission}
                            forReception={forReception}
                        />
                    </Collapse>
                </form>
            </div>
            <div className="flex h-full flex-col mt-4 overflow-hidden">
                {
                    initialLoading &&
                    <LinearProgress />
                }
                <div className="flex flex-grow overflow-auto">
                    <TableContainer>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {(issuingCompany ? SINGLE_COMPANY_DOCUMENTS_TABLE_COLUMNS : DOCUMENTS_TABLE_COLUMNS).map((headCell) => (
                                        (
                                            (createRetentionOnly && headCell.id !== FIELDS.processStatus.key) || !createRetentionOnly
                                        ) &&
                                        <TableCell
                                            key={headCell.id}
                                            align={"left"}
                                            padding={"default"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : "asc"}
                                                onClick={createSortHandler(headCell.id as any)}
                                                style={{ fontSize: "0.85em", width: (headCell.id === FIELDS.companyName.key || headCell.id === FIELDS.receiverName.key) ? 320 : (headCell.id === FIELDS.flowStatus.key || headCell.id === FIELDS.processStatus.key) ? 250 : "max-content" }}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === "desc"
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    {
                                        isFinishedInvoice() &&
                                        <React.Fragment>
                                            {
                                                FINISHED_PRINTED_INVOICES_COLUMNS.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        align={"left"}
                                                        padding={"default"}
                                                        sortDirection={orderBy === headCell.id ? order : false}
                                                    >
                                                        <TableSortLabel
                                                            active={orderBy === headCell.id}
                                                            direction={orderBy === headCell.id ? order : "asc"}
                                                            onClick={createSortHandler(headCell.id as any)}
                                                            style={{ fontSize: "0.85em", width: (headCell.id === FIELDS.companyName.key || headCell.id === FIELDS.receiverName.key) ? 320 : headCell.id === FIELDS.flowStatus.key ? 200 : "max-content" }}
                                                        >
                                                            {headCell.label}
                                                            {orderBy === headCell.id ? (
                                                                <span className="hidden">
                                                                    {order === "desc"
                                                                        ? "sorted descending"
                                                                        : "sorted ascending"}
                                                                </span>
                                                            ) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))
                                            }
                                        </React.Fragment>
                                    }
                                    {
                                        isRetentionReceipt() &&
                                        <TableCell
                                            key={FIELDS.invoiceNumber.name}
                                            align={"left"}
                                            padding={"default"}
                                            sortDirection={orderBy === FIELDS.invoiceNumber.name ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === FIELDS.invoiceNumber.name}
                                                direction={orderBy === FIELDS.invoiceNumber.name ? order : "asc"}
                                                onClick={createSortHandler(FIELDS.invoiceNumber.name as any)}
                                                style={{ fontSize: "0.85em", width: 200 }}
                                            >
                                                {FIELDS.invoiceNumber.name}
                                                {orderBy === FIELDS.invoiceNumber.name ? (
                                                    <span className="hidden">
                                                        {order === "desc"
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    }
                                    {
                                        isFinishedForeignExpensesLiquidation() &&
                                        <React.Fragment>
                                            <TableCell
                                                key={FIELDS.purchaseSettlement.name}
                                                align={"left"}
                                                padding={"default"}
                                                sortDirection={orderBy === FIELDS.purchaseSettlement.name ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === FIELDS.purchaseSettlement.name}
                                                    direction={orderBy === FIELDS.purchaseSettlement.name ? order : "asc"}
                                                    onClick={createSortHandler(FIELDS.purchaseSettlement.name as any)}
                                                    style={{ fontSize: "0.85em", width: 200 }}
                                                >
                                                    {FIELDS.purchaseSettlement.name}
                                                    {orderBy === FIELDS.purchaseSettlement.name ? (
                                                        <span className="hidden">
                                                            {order === "desc"
                                                                ? "sorted descending"
                                                                : "sorted ascending"}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                key={FIELDS.retention.name}
                                                align={"left"}
                                                padding={"default"}
                                                sortDirection={orderBy === FIELDS.retention.name ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === FIELDS.retention.name}
                                                    direction={orderBy === FIELDS.retention.name ? order : "asc"}
                                                    onClick={createSortHandler(FIELDS.retention.name as any)}
                                                    style={{ fontSize: "0.85em", width: 200 }}
                                                >
                                                    {FIELDS.retention.name}
                                                    {orderBy === FIELDS.retention.name ? (
                                                        <span className="hidden">
                                                            {order === "desc"
                                                                ? "sorted descending"
                                                                : "sorted ascending"}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        </React.Fragment>
                                    }
                                    {
                                        isReimbursementInvoice() &&
                                        <TableCell
                                            key={FIELDS.sustentions.name}
                                            align={"left"}
                                            padding={"default"}
                                            sortDirection={orderBy === FIELDS.sustentions.name ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === FIELDS.sustentions.name}
                                                direction={orderBy === FIELDS.sustentions.name ? order : "asc"}
                                                onClick={createSortHandler(FIELDS.sustentions.name as any)}
                                                style={{ fontSize: "0.85em", width: 200 }}
                                            >
                                                {FIELDS.sustentions.name}
                                                {orderBy === FIELDS.sustentions.name ? (
                                                    <span className="hidden">
                                                        {order === "desc"
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    }
                                    {
                                        isForeignExpensesLiquidationNotFinished() &&
                                        <React.Fragment>
                                            <TableCell
                                                key={FIELDS.retentionReceipt.name}
                                                align={"left"}
                                                padding={"default"}
                                                sortDirection={orderBy === FIELDS.retentionReceipt.name ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === FIELDS.retentionReceipt.name}
                                                    direction={orderBy === FIELDS.retentionReceipt.name ? order : "asc"}
                                                    onClick={createSortHandler(FIELDS.retentionReceipt.name as any)}
                                                    style={{ fontSize: "0.85em", width: 200 }}
                                                >
                                                    {FIELDS.retentionReceipt.name}
                                                    {orderBy === FIELDS.retentionReceipt.name ? (
                                                        <span className="hidden">
                                                            {order === "desc"
                                                                ? "sorted descending"
                                                                : "sorted ascending"}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                key={FIELDS.purchaseSettlement.name}
                                                align={"left"}
                                                padding={"default"}
                                                sortDirection={orderBy === FIELDS.purchaseSettlement.name ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === FIELDS.purchaseSettlement.name}
                                                    direction={orderBy === FIELDS.purchaseSettlement.name ? order : "asc"}
                                                    onClick={createSortHandler(FIELDS.purchaseSettlement.name as any)}
                                                    style={{ fontSize: "0.85em", width: 200 }}
                                                >
                                                    {FIELDS.purchaseSettlement.name}
                                                    {orderBy === FIELDS.purchaseSettlement.name ? (
                                                        <span className="hidden">
                                                            {order === "desc"
                                                                ? "sorted descending"
                                                                : "sorted ascending"}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        </React.Fragment>
                                    }
                                    {
                                        isPurchaseSettlement() &&
                                        <TableCell
                                            key={FIELDS.foreignExpensesLiquidation.name}
                                            align={"left"}
                                            padding={"default"}
                                            sortDirection={orderBy === FIELDS.foreignExpensesLiquidation.name ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === FIELDS.foreignExpensesLiquidation.name}
                                                direction={orderBy === FIELDS.foreignExpensesLiquidation.name ? order : "asc"}
                                                onClick={createSortHandler(FIELDS.foreignExpensesLiquidation.name as any)}
                                                style={{ fontSize: "0.85em", width: 250 }}
                                            >
                                                {FIELDS.foreignExpensesLiquidation.name}
                                                {orderBy === FIELDS.foreignExpensesLiquidation.name ? (
                                                    <span className="hidden">
                                                        {order === "desc"
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    }
                                    <TableCell padding={"default"} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(getModel()) as IDocument[]).map(
                                    (row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.documentId.toString()}
                                                className="cursor-pointer"
                                                onClick={(loading || initialLoading) ? undefined : () => handleSelect(row)}
                                            >
                                                <TableCell className="truncate" component="th" scope="row" padding="none">
                                                    {documentTypes.find(d => d.documentTypeId === row.documentTypeId)?.documentName}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {
                                                    row.serialNumber.length === 15 ?
                                                    `${row.serialNumber.substring(0,3)}-${row.serialNumber.substring(3,6)}-${row.serialNumber.substring(6,15)}`
                                                    : row.serialNumber
                                                    }
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {/* Se añade una hora a la fecha, ya que el servidor de producción tiene una hora adelantada
                                                    y el documentDate en la base tiene como hora 00:00:00 -5, y el servidor tiene +1 */}
                                                    {moment(new Date(row.documentDate)).add(1, "hour").format('DD-MM-YYYY').toString()}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {row.authorizationDate ? moment(new Date(row.authorizationDate).toISOString()).format('DD-MM-YYYY').toString() : "---"}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {row.inserted ? moment(new Date(row.inserted).toISOString()).format('DD-MM-YYYY').toString() : "---"}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {issuingCompany ? row.receiverRuc : row.companyRuc}
                                                </TableCell>
                                                <TableCell className="truncate" padding="default" align="left">
                                                    {issuingCompany ? row.receiverName : row.companyName}
                                                </TableCell>
                                                {
                                                    issuingCompany && !createRetentionOnly &&
                                                    (
                                                    ((COUPA_QUEUEABLE_DOCUMENT_STATUS.includes(row.processStatus)) && row.flowStatus === DOCUMENT_STATES.finished) ||
                                                        ((row.documentTypeId === DOCUMENT_TYPE_IDS.purchaseSettlement || row.documentTypeId === DOCUMENT_TYPE_IDS.retentionReceipt) && row.processStatus !== SRI_AUTHORIZED_ID) ?
                                                        <Tooltip
                                                            enterNextDelay={500}
                                                            classes={{
                                                                arrow: row.processStatus === COUPA_STATES.error ? "warning-arrow-tooltip" : "info-arrow-tooltip",
                                                                tooltip: row.processStatus === COUPA_STATES.error ? "warning-tooltip" : "info-tooltip"
                                                            }}
                                                            title={
                                                                <Alert
                                                                    severity={row.processStatus === COUPA_STATES.error ? "warning" : "info"}
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        e.stopPropagation()
                                                                    }}
                                                                >
                                                                    <AlertTitle style={{ fontWeight: 900, fontSize: "0.8em", letterSpacing: 1, marginBottom: 10 }}>{`${documentTypes.find(d => d.documentTypeId === row.documentTypeId)?.documentName ?? ""} - ${row.serialNumber}`}</AlertTitle>
                                                                    <React.Fragment>
                                                                        {
                                                                            row.additionalInfo && row.processStatus === COUPA_STATES.error &&
                                                                            ((row.additionalInfo as unknown) as IDocumentAdditionalInfo).coupaError?.message
                                                                        }
                                                                    </React.Fragment>
                                                                    {
                                                                        ((row.documentTypeId === DOCUMENT_TYPE_IDS.purchaseSettlement || row.documentTypeId === DOCUMENT_TYPE_IDS.retentionReceipt) && row.processStatus !== SRI_AUTHORIZED_ID) ?
                                                                            <div className="flex items-center px-2">
                                                                                <Button
                                                                                    color={row.processStatus === COUPA_STATES.error ? "secondary" : undefined}
                                                                                    variant="contained"
                                                                                    size="small"
                                                                                    style={{ marginTop: 10 }}
                                                                                    onClick={(e) => {
                                                                                        handleQueue(row.documentId, true)
                                                                                    }}
                                                                                    disabled={loading}
                                                                                    disableElevation
                                                                                >
                                                                                    {"Consultar SRI"}
                                                                                </Button>
                                                                                <Button
                                                                                    color={row.processStatus === COUPA_STATES.error ? "secondary" : undefined}
                                                                                    variant="contained"
                                                                                    size="small"
                                                                                    style={{ marginTop: 10, marginLeft: 10 }}
                                                                                    onClick={(e) => {
                                                                                        handleStatus(row.documentId, row.additionalInfo)
                                                                                    }}
                                                                                    disabled={loading}
                                                                                    disableElevation
                                                                                >
                                                                                    {"Enviar SRI"}
                                                                                </Button>
                                                                            </div>
                                                                            :
                                                                            <Button
                                                                                color={row.processStatus === COUPA_STATES.error ? "secondary" : undefined}
                                                                                variant="contained"
                                                                                size="small"
                                                                                style={{ marginTop: 10 }}
                                                                                onClick={(e) => {
                                                                                    handleQueue(row.documentId)
                                                                                }}
                                                                                disabled={loading}
                                                                                disableElevation
                                                                            >
                                                                                {"Volver a enviar"}
                                                                            </Button>
                                                                    }
                                                                </Alert>
                                                            }
                                                            interactive
                                                            arrow
                                                        >
                                                            <TableCell padding="default">
                                                                <div className="flex items-center">
                                                                    {historyTypes.find(h => h.historyTypeId === row.processStatus)?.name}
                                                                    {
                                                                        row.processStatus === COUPA_STATES.error ?
                                                                            <Icon fontSize="small" color="error">error</Icon>
                                                                            :
                                                                            <Icon fontSize="small" color="action">keyboard_arrow_down</Icon>
                                                                    }
                                                                </div>
                                                            </TableCell>
                                                        </Tooltip>
                                                        :
                                                        <TableCell padding="default">
                                                            {historyTypes.find(h => h.historyTypeId === row.processStatus)?.name}
                                                        </TableCell>
                                                    )
                                                }
                                                { !issuingCompany &&
                                                <TableCell padding="default" align="left">
                                                    {conditions.find(c => c.conditionId === row.flowStatus)?.name}
                                                </TableCell>
                                                }
                                                <TableCell padding="default" align="left" style={{ whiteSpace: 'nowrap' }}>
                                                    {row.error}
                                                </TableCell>
                                                {
                                                    isFinishedInvoice() &&
                                                    <React.Fragment>
                                                        <TableCell
                                                            onClick={(e) => {
                                                                const id = row.associated.find(r => {
                                                                    const type = documentTypes.find(d => d.documentTypeId === r.documentTypeId)
                                                                    return (r.documentTypeId === type?.documentTypeId && type.documentCode === DOCUMENT_TYPE_CODES.purchaseOrder)
                                                                })?.documentId
                                                                if (id) {
                                                                    e.stopPropagation()
                                                                    handlePurchaseOrder(id)
                                                                }
                                                            }}
                                                            className="document-link" padding="default" align="left"
                                                        >
                                                            {row.associated.find(r => {
                                                                const type = documentTypes.find(d => d.documentTypeId === r.documentTypeId)
                                                                return (r.documentTypeId === type?.documentTypeId && type.documentCode === DOCUMENT_TYPE_CODES.purchaseOrder)
                                                            })?.serialNumber}
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={(e) => {
                                                                const document = row.associated.find(r => {
                                                                    const type = documentTypes.find(d => d.documentTypeId === r.documentTypeId)
                                                                    return (r.documentTypeId === type?.documentTypeId && type.documentCode === DOCUMENT_TYPE_CODES.retentionReceipt)
                                                                })
                                                                if (document) {
                                                                    e.stopPropagation()
                                                                    handleSelect(document)
                                                                }
                                                            }}
                                                            className="document-link" padding="default" align="left"
                                                        >
                                                            {row.associated.find(r => {
                                                                const type = documentTypes.find(d => d.documentTypeId === r.documentTypeId)
                                                                return (r.documentTypeId === type?.documentTypeId && type.documentCode === DOCUMENT_TYPE_CODES.retentionReceipt)
                                                            })?.serialNumber}
                                                        </TableCell>
                                                    </React.Fragment>
                                                }
                                                {
                                                    isRetentionReceipt() &&
                                                    <TableCell
                                                        onClick={(e) => {
                                                            const document = row.associated.find(r =>
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.electronicInvoice ||
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.reimbursementInvoice ||
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.foreignExpensesLiquidation ||
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.printedInvoice
                                                            )
                                                            if (document) {
                                                                e.stopPropagation()
                                                                handleSelect(document)
                                                            }
                                                        }}
                                                        className="document-link" padding="default" align="left"
                                                    >
                                                        {
                                                            row.associated.find(r =>
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.electronicInvoice ||
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.reimbursementInvoice ||
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.foreignExpensesLiquidation ||
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.printedInvoice
                                                            )?.serialNumber
                                                        }
                                                    </TableCell>
                                                }
                                                {
                                                    isFinishedForeignExpensesLiquidation() &&
                                                    <React.Fragment>
                                                        <TableCell
                                                            onClick={(e) => {
                                                                const document = row.associated.find(r => {
                                                                    const type = documentTypes.find(d => d.documentTypeId === r.documentTypeId)
                                                                    return (r.documentTypeId === type?.documentTypeId && (type.documentCode === DOCUMENT_TYPE_CODES.purchaseSettlement))
                                                                })
                                                                if (document) {
                                                                    e.stopPropagation()
                                                                    handleSelect(document)
                                                                }
                                                            }}
                                                            className="document-link" padding="default" align="left"
                                                        >
                                                            {
                                                                row.associated.find(r => {
                                                                    const type = documentTypes.find(d => d.documentTypeId === r.documentTypeId)
                                                                    return (r.documentTypeId === type?.documentTypeId && (type.documentCode === DOCUMENT_TYPE_CODES.purchaseSettlement))
                                                                })?.serialNumber
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={(e) => {
                                                                const document = row.associated.find(r => {
                                                                    const type = documentTypes.find(d => d.documentTypeId === r.documentTypeId)
                                                                    return (r.documentTypeId === type?.documentTypeId && (type.documentCode === DOCUMENT_TYPE_CODES.retentionReceipt))
                                                                })
                                                                if (document) {
                                                                    e.stopPropagation()
                                                                    handleSelect(document)
                                                                }
                                                            }}
                                                            className="document-link" padding="default" align="left"
                                                        >
                                                            {
                                                                row.associated.find(r => {
                                                                    const type = documentTypes.find(d => d.documentTypeId === r.documentTypeId)
                                                                    return (r.documentTypeId === type?.documentTypeId && (type.documentCode === DOCUMENT_TYPE_CODES.retentionReceipt))
                                                                })?.serialNumber
                                                            }
                                                        </TableCell>
                                                    </React.Fragment>
                                                }
                                                {
                                                    isReimbursementInvoice() &&
                                                    <RefundsCell
                                                        documentId={row.documentId}
                                                    />
                                                }
                                                {
                                                    isForeignExpensesLiquidationNotFinished() &&
                                                    <React.Fragment>
                                                        <TableCell
                                                            onClick={(e) => {
                                                                const document = row.associated.find(r => {
                                                                    const type = documentTypes.find(d => d.documentTypeId === r.documentTypeId)
                                                                    return (r.documentTypeId === type?.documentTypeId && (type.documentCode === DOCUMENT_TYPE_CODES.retentionReceipt))
                                                                })
                                                                if (document) {
                                                                    e.stopPropagation()
                                                                    handleSelect(document)
                                                                }
                                                            }}
                                                            className="document-link" padding="default" align="left"
                                                        >
                                                            {
                                                                row.associated.find(r => {
                                                                    const type = documentTypes.find(d => d.documentTypeId === r.documentTypeId)
                                                                    return (r.documentTypeId === type?.documentTypeId && (type.documentCode === DOCUMENT_TYPE_CODES.retentionReceipt))
                                                                })?.serialNumber
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={(e) => {
                                                                const document = row.associated.find(r => {
                                                                    const type = documentTypes.find(d => d.documentTypeId === r.documentTypeId)
                                                                    return (r.documentTypeId === type?.documentTypeId && (type.documentCode === DOCUMENT_TYPE_CODES.purchaseSettlement))
                                                                })
                                                                if (document) {
                                                                    e.stopPropagation()
                                                                    handleSelect(document)
                                                                }
                                                            }}
                                                            className="document-link" padding="default" align="left"
                                                        >
                                                            {
                                                                row.associated.find(r => {
                                                                    const type = documentTypes.find(d => d.documentTypeId === r.documentTypeId)
                                                                    return (r.documentTypeId === type?.documentTypeId && (type.documentCode === DOCUMENT_TYPE_CODES.purchaseSettlement))
                                                                })?.serialNumber
                                                            }
                                                        </TableCell>
                                                    </React.Fragment>
                                                }
                                                {
                                                    isPurchaseSettlement() &&
                                                    <TableCell
                                                        onClick={(e) => {
                                                            const document = row.associated.find(r =>
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.foreignExpensesLiquidation ||
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.employeeReimbursement ||
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.cashReimbursement
                                                            )
                                                            if (document) {
                                                                e.stopPropagation()
                                                                handleSelect(document)
                                                            }
                                                        }}
                                                        className="document-link" padding="default" align="left"
                                                    >
                                                        {
                                                            row.associated.find(r =>
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.foreignExpensesLiquidation ||
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.employeeReimbursement ||
                                                                r.documentTypeId === DOCUMENT_TYPE_IDS.cashReimbursement
                                                            )?.serialNumber
                                                        }
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        )
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <TablePagination
                        rowsPerPageOptions={MEDIUM_ROWS_PER_PAGINATION_OPTIONS}
                        component="div"
                        count={totalDocuments}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={async (event, pageNumber) => {
                            if (pageNumber > page && ((pageNumber + 1) * rowsPerPage) > getModel().length && getModel().length < totalDocuments) {
                                await handleNewPage(pageNumber, rowsPerPage)
                            }
                            handleChangePage(event, pageNumber)
                        }}
                        onRowsPerPageChange={async (event) => {
                            if (parseInt(event.target.value, 10) > rowsPerPage && parseInt(event.target.value, 10) > getModel().length && getModel().length < totalDocuments) {
                                await handleNewPage(page, parseInt(event.target.value, 10))
                            }
                            handleChangeRowsPerPage(event as any)
                        }}
                        labelRowsPerPage="Documentos por página"
                        labelDisplayedRows={LabelDisplayedRows}
                    />
                </div>
            </div >
            <Snackbar
                open={messageConfig.open}
                autoHideDuration={6000}
                onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Alert
                    variant="filled"
                    onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                    severity={messageConfig.severity}
                >
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading} />
            <DocumentPicker
                open={openPicker}
                onClose={() => setOpenPicker(false)}
            />
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={getDocumentsByInitialFilters}
            />
        </Paper >
    )
}

export default DocumentsDashboard