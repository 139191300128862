import { Button, CircularProgress, Snackbar, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import React from 'react'

interface Props {
    error: boolean
    tryAgain: (values?: any) => Promise<any>
    onSuccess: () => void
}

const ServerErrorHandler = (props: Props) => {
    const { error, onSuccess, tryAgain } = props
    const [loading, setLoading] = React.useState<boolean>(false)
    const handleTryAgain = async () => {
        try {
            setLoading(true)
            await tryAgain()
            setLoading(false)
            onSuccess()
        } catch (error) {
            setLoading(false)
        }
    }
    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') { return }
        onSuccess()
    }
    return (
        <React.Fragment>
            <Snackbar
                open={error}
                autoHideDuration={null}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    variant="filled"
                    onClose={handleClose}
                    severity="error"
                    action={
                        <Button
                            style={{ marginLeft: 30, marginRight: 30 }}
                            color="inherit"
                            onClick={handleTryAgain}
                            disabled={loading}
                            size="small"
                        >
                            {loading ? <CircularProgress style={{ marginLeft: 25, marginRight: 25 }} thickness={12} size={20} /> : "Reintentar"}
                        </Button>
                    }
                >
                    <AlertTitle style={{ fontSize: "0.95em", letterSpacing: 0.5 }} >{"Error"}</AlertTitle>
                    <Typography style={{ color: "#dedede" }} variant="caption">{"No se ha podido obtener datos del"} <strong>{"servidor"}</strong></Typography>
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default ServerErrorHandler
