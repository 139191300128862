import React from "react"
import {
    Button,
    Icon,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
} from "@material-ui/core"
import {
    ConfirmDialog,
    RejectionCauseForm,
    Search,
    FormDialog,
    FixedLoadingIndicator,
    LabelDisplayedRows,
    ServerErrorHandler,
} from "components"
import { useInputValue, useTableModel } from "hooks"
import {
    REJECTION_CAUSE_PATH,
    NEW_PATH,
    RESOURCES_PATH,
    REJECTIONCAUSE_TABLE_COLUMNS,
    EDIT_PATH,
    deleteRejectionCauseService,
    SMALL_ROWS_PER_PAGINATION_OPTIONS,
    ROOT_TITLE,
    REJECTION_CAUSE_TITLE,
} from "lib"
import { useHistory, useLocation } from "react-router"
import {
    IMessageConfig,
    IRejectionCause,
    RejectionCauseStateFilter
} from "types"
import { ManagementContext } from "context"
import { Alert } from "@material-ui/lab"
import { Helmet } from "react-helmet"

const RejectionCauses = () => {
    const { rejectionCauses, refreshRejectionCauses } =
        React.useContext(ManagementContext)
    const {
        value: query,
        clearValue: handleClear,
        onChange: handleChange,
    } = useInputValue()
    const {
        createSortHandler,
        handleChangePage,
        handleChangeRowsPerPage,
        order,
        orderBy,
        page,
        rows,
        rowsPerPage,
    } = useTableModel()
    const location = useLocation()
    const history = useHistory()
    const [filter, setFilter] =
        React.useState<RejectionCauseStateFilter>("active")
    const [selected, setSelected] = React.useState<any | undefined>(undefined)
    const [actionsAnchorEl, setActionsAnchorEl] =
        React.useState<null | HTMLElement>(null)
    const [deleteId, setDeleteId] = React.useState<number>(-1)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({
        open: false,
        message: "",
        severity: "info",
    })
    const getModel = React.useCallback((): any[] => {
        const src =
            filter === "all"
                ? [...rejectionCauses]
                : [...rejectionCauses].filter((o) =>
                    o.active === (filter === "active") ? true : false
                )
        if (Boolean(query)) {
            return src.filter((s: any) => {
                return (
                    s.name.toUpperCase().includes(query.toUpperCase()) ||
                    s.rejectionCauseCode.toString().includes(query.toUpperCase()) ||
                    s.description.toUpperCase().includes(query.toUpperCase())
                )
            })
        }
        return src
    }, [rejectionCauses, query, filter])
    const handleEdit = () => {
        setActionsAnchorEl(null)
        history.push({
            state: {
                edit: true,
                values: selected,
            },
            pathname: `${RESOURCES_PATH}${REJECTION_CAUSE_PATH}${EDIT_PATH}`,
        })
    }
    const handleConfirmDelete = (id: number) => {
        setActionsAnchorEl(null)
        setDeleteId(id)
    }
    const handleDelete = async () => {
        try {
            setLoading(true)
            await deleteRejectionCauseService(deleteId)
            setMessageConfig({
                open: true,
                message: "Motivo de Rechazo eliminado!",
                severity: "success",
            })
            await refreshRejectionCauses()
            setLoading(false)
            return true
        } catch (error) {
            setMessageConfig({
                open: true,
                message: "No se puede eliminar el Motivo de Rechazo...",
                severity: "error",
            })
            setLoading(false)
            return false
        }
    }
    React.useEffect(() => {
        const initScreen = async () => {
            try {
                setLoading(true)
                await refreshRejectionCauses()
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setError(true)
            }
        }
        initScreen()
    }, [refreshRejectionCauses])
    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${REJECTION_CAUSE_TITLE}`}</title>
            </Helmet>
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <Search
                        onChange={handleChange}
                        query={query}
                        onClear={handleClear}
                        placeholer={"Buscar por nombre,descripción o codigo..."}
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        style={{ width: 140, marginLeft: 15 }}
                        classes={{ root: "filter-text-field" }}
                        select
                        label="Estado"
                        value={filter}
                        onChange={(e) =>
                            setFilter(e.target.value as RejectionCauseStateFilter)
                        }
                    >
                        <MenuItem dense value={"active"}>
                            {"Activos"}
                        </MenuItem>
                        <MenuItem dense value={"inactive"}>
                            {"Inactivos"}
                        </MenuItem>
                        <MenuItem dense value={"all"}>
                            {"Todos"}
                        </MenuItem>
                    </TextField>
                </div>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() =>
                        history.push(`${RESOURCES_PATH}${REJECTION_CAUSE_PATH}${NEW_PATH}`)
                    }
                >
                    <Icon fontSize="small" style={{ marginRight: 5 }}>add</Icon>
                    {"Nueva"}
                </Button>
                <FormDialog
                    open={Boolean(
                        location.pathname ===
                        `${RESOURCES_PATH}${REJECTION_CAUSE_PATH}${NEW_PATH}` ||
                        location.pathname ===
                        `${RESOURCES_PATH}${REJECTION_CAUSE_PATH}${EDIT_PATH}`
                    )}
                    onClose={() => history.push(`${RESOURCES_PATH}${REJECTION_CAUSE_PATH}`)}
                >
                    <RejectionCauseForm
                        onSuccess={() => {
                            setMessageConfig({
                                open: true,
                                message: Boolean(selected)
                                    ? "Motivo de Rechazo actualizado!"
                                    : "Motivo de Rechazo registrado!",
                                severity: "success",
                            })
                            refreshRejectionCauses()
                            if (Boolean(selected)) {
                                setSelected(undefined)
                            }
                        }}
                        onError={() =>
                            setMessageConfig({
                                open: true,
                                message: "No se puede registrar el Motivo de Rechazo",
                                severity: "error",
                            })
                        }
                        onClose={() => history.push(`${RESOURCES_PATH}${REJECTION_CAUSE_PATH}`)}
                    />
                </FormDialog>
            </div>
            <div className="flex h-full flex-col mt-4 overflow-hidden">
                <div className="flex flex-grow overflow-auto">
                    <TableContainer >
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {REJECTIONCAUSE_TABLE_COLUMNS.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={"left"}
                                            padding={"none"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : "asc"}
                                                onClick={createSortHandler(headCell.id as any)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === "desc"
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell padding={"default"} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(getModel()) as IRejectionCause[]).map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.rejectionCauseId.toString()}
                                        >
                                            <TableCell padding="none" align="left">
                                                {row.rejectionCauseCode}
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                {row.name}
                                            </TableCell>
                                            <TableCell padding="none" align="left">
                                                {row.description}
                                            </TableCell>
                                            <TableCell padding="none" align="left">
                                                <span
                                                    className={
                                                        row.active
                                                            ? "active-state-label"
                                                            : "inactive-state-label"
                                                    }
                                                >
                                                    {row.active ? "Activo" : "Inactivo"}
                                                </span>
                                            </TableCell>
                                            <TableCell align="left">
                                                <IconButton
                                                    onClick={(e) => {
                                                        setActionsAnchorEl(e.currentTarget)
                                                        setSelected(row)
                                                    }}
                                                    size="small"
                                                >
                                                    <Icon fontSize="small">more_vert</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                <Menu
                                    anchorEl={actionsAnchorEl}
                                    open={Boolean(actionsAnchorEl)}
                                    onClose={() => setActionsAnchorEl(null)}
                                >
                                    <MenuItem onClick={handleEdit} dense>
                                        {"Editar"}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            handleConfirmDelete(selected?.rejectionCauseId ?? -1)
                                        }
                                        dense
                                    >
                                        {"Eliminar"}
                                    </MenuItem>
                                </Menu>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <TablePagination
                        rowsPerPageOptions={SMALL_ROWS_PER_PAGINATION_OPTIONS}
                        component="div"
                        count={getModel().length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Motivos de Rechazo por página"
                        labelDisplayedRows={LabelDisplayedRows}
                    />
                </div>
            </div>
            <ConfirmDialog
                onCancel={() => setDeleteId(-1)}
                onConfirm={handleDelete}
                open={Boolean(deleteId !== -1)}
                title={"¿Esta seguro?"}
                loading={loading}
            />
            <Snackbar
                open={messageConfig.open}
                autoHideDuration={6000}
                onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Alert
                    variant="filled"
                    onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                    severity={messageConfig.severity}
                >
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading && Boolean(deleteId === -1)} />
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={refreshRejectionCauses}
            />
        </Paper>
    )
}

export default RejectionCauses
