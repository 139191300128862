import { Dialog, Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react'

interface Props {
    open: boolean
    onClose: () => void
    children?: React.ReactNode
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />
})

const FullSizeDialog = (props: Props) => {
    const { onClose, open, children } = props
    return (
        <Dialog fullWidth maxWidth="lg"  open={open} onClose={onClose} TransitionComponent={Transition}>
            {open && children}
        </Dialog>
    )
}

export default FullSizeDialog
