import React from 'react'
import { AuthContext } from 'context'
import { Icon, IconButton, Typography, Popover, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { useHistory } from 'react-router'
import { CHANGE_PASSWORD_PATH, USER_PATH } from 'lib'
import packageJson from '../../../package.json';

const drawerWidth = 260
const mainNavWidth = 60

interface Props {
    compact?: boolean
    onLogout: () => void
}

const UserMenu = (props: Props) => {
    const { user, logout } = React.useContext(AuthContext)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const history = useHistory()
    const handleLogout = () => {
        setAnchorEl(null)
        props.onLogout()
        logout()
    }
    const handleChangePassword = () => {
        setAnchorEl(null)
        history.push(USER_PATH + CHANGE_PASSWORD_PATH)
    }
    return (
        <React.Fragment>
            {
                Boolean(user) &&
                <React.Fragment>
                    <div className="flex items-center">
                        {
                            !props.compact &&
                            <div className="flex items-center justify-center" style={{ width: mainNavWidth, backgroundColor: grey[50], height: mainNavWidth }}>
                                <Icon>account_circle</Icon>
                            </div>
                        }
                        <div className={`${!props.compact ? "pl-3" : ""} flex items-center w-full`}>
                            {
                                !props.compact &&
                                <div className='flex flex-col flex-grow'>
                                    <Typography className="truncate" style={{ fontSize: "0.75em", maxWidth: (drawerWidth - (mainNavWidth * 2)) }}>
                                        {user?.name}
                                    </Typography>
                                    <Typography color="textSecondary" className="truncate" style={{ fontSize: "0.75em", maxWidth: (drawerWidth - (mainNavWidth * 2)) }}>
                                        {user?.email}
                                    </Typography>
                                </div>
                            }
                            <div className="flex items-center justify-center" style={{ width: mainNavWidth * 0.8, height: mainNavWidth }}>
                                <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} size={!props.compact ? "small" : "medium"}>
                                    <Icon fontSize={!props.compact ? "small" : "default"}>{!props.compact ? "chevron_right" : "person"}</Icon>
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <Popover
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        onClose={() => setAnchorEl(null)}
                    >
                        <List>
                            <ListItem onClick={handleChangePassword} dense button>
                                <ListItemAvatar>
                                    <Avatar style={{ width: 35, height: 35, marginLeft: 10, backgroundColor: grey[300] }}>
                                        <Icon fontSize="small">vpn_key</Icon>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Cambiar contraseña" />
                            </ListItem>
                            <ListItem onClick={handleLogout} dense button>
                                <ListItemAvatar>
                                    <Avatar style={{ width: 35, height: 35, marginLeft: 10, backgroundColor: grey[300] }}>
                                        <Icon fontSize="small">exit_to_app</Icon>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Cerrar sesión" />
                            </ListItem>
                        </List>
                        <Divider />
                        <div className="w-full pr-3 flex justify-end py-2">
                            <Typography variant="caption" align="right" color="textSecondary" style={{ fontWeight: 900 }}>
                                V-{packageJson.version}
                            </Typography>
                        </div>
                    </Popover>
                </React.Fragment>
            }
        </React.Fragment >
    )
}

export default UserMenu
