import { Button, CircularProgress, Divider, Icon, IconButton, TextField, Typography } from '@material-ui/core'
import { useValues } from 'hooks'
import { createIdentificationTypeService, editIdentificationTypeService, FIELDS } from 'lib'
import React from 'react'
import { useLocation } from 'react-router'
import { IIdentificationType } from 'types'

interface Props {
    onSuccess: () => void
    onError: () => void
    onClose: () => void
}

const IdentificationTypeForm = (props: Props) => {
    const location = useLocation()
    const { onError, onSuccess, onClose } = props
    const initialInput = React.useRef<any>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const { values: identificationType, updateValue: handleChange, updateValues } = useValues()
    const handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            if (!(location.state as any)?.edit) {
                await createIdentificationTypeService({ ...identificationType })
            } else {
                const edited = { ...identificationType }
                delete edited[FIELDS.identificationTypeId.key]
                await editIdentificationTypeService(identificationType[FIELDS.identificationTypeId.key], { ...edited })
            }
            setLoading(false)
            if (!(location.state as any)?.edit) {
                updateValues({ description: "", identificationTypeCode: "", name: "" } as IIdentificationType)
                initialInput.current?.focus()
            }
            onSuccess()
        } catch (error) {
            setLoading(false)
            onError()
        }
    }
    React.useEffect(() => {
        if ((location.state as any)?.edit) {
            if ((location.state as any)?.values) {
                updateValues((location.state as any)?.values)
            }
        }
    }, [location.state, updateValues])
    return (
        <div className="p-4">
            <div className="flex items-center justify-between">
                <Typography variant="body2" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                    {(location.state as any)?.edit ? "Editar tipo de identificacion" : "Nuevo tipo de identificacion"}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <Icon fontSize="small">close</Icon>
                </IconButton>
            </div>
            <form onSubmit={handleSumbit} className="pt-4 flex flex-col" style={{ width: 300 }}>
                <TextField
                    inputRef={initialInput}
                    autoFocus
                    size="small"
                    label={FIELDS.identificationTypeCode.name}
                    variant="outlined"
                    fullWidth
                    required
                    value={identificationType[FIELDS.identificationTypeCode.key]}
                    onChange={(e) => handleChange(FIELDS.identificationTypeCode.key, e.target.value)}
                />
                <TextField
                    size="small"
                    label={FIELDS.name.name}
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 10 }}
                    multiline
                    onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault() } }}
                    rows={3}
                    required
                    value={identificationType[FIELDS.name.key]}
                    onChange={(e) => handleChange(FIELDS.name.key, e.target.value)}
                />
                <TextField
                    size="small"
                    label={FIELDS.description.name}
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 10 }}
                    multiline
                    onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault() } }}
                    rows={3}
                    required
                    value={identificationType[FIELDS.description.key]}
                    onChange={(e) => handleChange(FIELDS.description.key, e.target.value)}
                />
                <div className="mt-4">
                    <Divider />
                </div>
                <div className="flex justify-end pt-3">
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        disableElevation
                        disabled={loading}
                        type="submit"
                    >
                        {loading ? <CircularProgress style={{ color: "white" }} size={16} className="my-1" thickness={10} /> : (location.state as any)?.edit ? "Guardar" : "Registrar"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default IdentificationTypeForm
