export const REGIMEN_TYPES = [
  {
    value: "01",
    name: "RÉGIMEN GENERAL",
  },
  {
    value: "02",
    name: "PARAISO FISCAL",
  },
  {
    value: "03",
    name: "RÉGIMEN FISCAL PREFERENTE O DE MENOR IMPOSICIÓN",
  },
]
