import axios, { AxiosError } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, MATRIX_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const getMatrixesService = async () => {
    try {
        const result = await intance.get(MATRIX_PATH + LIST_PATH);
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createMatrixesService = async (matrix: any) => {
    try {
        const result = await intance.post(MATRIX_PATH, matrix)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteMatrixService = async (id: any) => {
    try {
        const result = await intance.delete(MATRIX_PATH + `/${id}`)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editMatrixService = async (id: number, matrix: any) => {
    try {
        const result = await intance.put(MATRIX_PATH + `/${id}`, matrix)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}