import axios, { AxiosError } from 'axios'
import { DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { COMPANY_PATH } from 'lib/constants/paths'


const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

export const getCompanyService = async () => {
    try {
        const result = await intance.get(COMPANY_PATH)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}