import { Typography, IconButton, Icon, Checkbox, TextField, Snackbar, CircularProgress, Tooltip, TableCell, TableRow, Table, TableBody, TableHead } from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { ManagementContext } from 'context'
import React from 'react'
import { IMessageConfig, ISupplier, ITaxPlan, ITaxPlanSupplier } from 'types'
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { createTaxPlanSupplier, deleteTaxPlanSupplier, TAXPLAN_TABLE_RETENTION_COLUMNS } from 'lib'

interface Props {
    onClose: () => void
    supplier?: ISupplier
    taxPlanSupplier: ITaxPlanSupplier[]
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const TaxPlanSupplier = (props: Props) => {
    const { onClose, supplier } = props
    const inputRef = React.useRef<any>(null)
    const { taxPlans, taxTypes } = React.useContext(ManagementContext)
    const [taxPlanSupplier, setTaxPlanSupplier] = React.useState(props.taxPlanSupplier ?? [])
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const [loading, setLoading] = React.useState<boolean>(false)
    const getTaxPlanIds = React.useCallback(() => { return taxPlanSupplier.map(t => t.taxPlanId) }, [taxPlanSupplier])
    const handleAdd = async (taxPlan: ITaxPlan) => {
        try {
            setLoading(true)
            const result = await createTaxPlanSupplier({ supplierId: supplier?.supplierId, taxPlanId: taxPlan.taxPlanId })
            setTaxPlanSupplier(current => [...current, result])
            setLoading(false)
            inputRef.current?.focus()
            setMessageConfig({ open: true, message: "Plan de impuestos asignado!", severity: "success" })
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo agregar el plan de impuestos", severity: "error" })
        }
    }
    const handleDelete = async (taxPlan: ITaxPlan) => {
        try {
            setLoading(true)
            const taxPlanSupplierId = taxPlanSupplier.find(t => t.taxPlanId === taxPlan.taxPlanId)?.taxPlanSupplierId
            await deleteTaxPlanSupplier(taxPlanSupplierId)
            setTaxPlanSupplier(current => current.filter(c => c.taxPlanSupplierId !== taxPlanSupplierId))
            setLoading(false)
            inputRef.current?.focus()
            setMessageConfig({ open: true, message: "Plan de impuestos eliminado!", severity: "success" })
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo eliminar el plan de impuestos", severity: "error" })
        }
    }
    return (
        <div className="p-4 overflow-hidden" style={{ width: 550 }}>
            <div className="flex items-center justify-between">
                <div>
                    <Typography variant="body2" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                        {"Administrar plan de impuestos"}
                    </Typography>
                    <Typography color="textSecondary" variant="caption" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                        {supplier?.companyName}
                    </Typography>
                </div>
                <IconButton disabled={loading} onClick={onClose} size="small">
                    <Icon fontSize="small">close</Icon>
                </IconButton>
            </div>
            <form>
                <div className="flex items-center my-1">
                    <Autocomplete
                        multiple
                        options={taxPlans.filter(t => !t.forCustomers)}
                        disableCloseOnSelect
                        disabled={loading}
                        getOptionLabel={(tax) => `${tax.name}`}
                        limitTags={2}
                        fullWidth
                        value={taxPlans.filter(t => getTaxPlanIds().includes(t.taxPlanId))}
                        openOnFocus
                        disableClearable
                        size="small"
                        style={{ marginTop: 20 }}
                        ChipProps={{ size: "small", style: { maxWidth: 90 } }}
                        onChange={(_, _values, reason, value) => {
                            if (reason === "clear" || reason === "remove-option") {
                                if (value?.option) {
                                    handleDelete(value.option)
                                }
                            } else if (value?.option) {
                                handleAdd(value.option)
                            }
                        }}
                        renderOption={(tax, { selected }) => {
                            return (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8, height: 50 }}
                                        checked={selected}
                                    />
                                    {`${tax.name}`}
                                </React.Fragment>
                            )
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputRef={inputRef}
                                variant="outlined"
                                fullWidth
                                label={"Selecciona para agregar/eliminar"}
                            />
                        )}
                    />
                </div>
            </form>
            <div className="w-full overflow-y-auto py-1 mt-4 relative" style={{ height: "50vh", maxHeight: "50vh" }}>
                {
                    taxPlanSupplier.length === 0 ?
                        <div className="w-full h-full flex items-center justify-center">
                            <Typography align="center" color="textSecondary" className="px-6" variant="h6">
                                {"No se han agregado planes de impuestos a este proveedor."}
                            </Typography>
                        </div>
                        :
                        <React.Fragment>
                            <Typography align="center" color="textSecondary" className="pb-4" variant="caption">
                                {"Agregados:"}
                            </Typography>
                            {taxPlans.filter(t => getTaxPlanIds().includes(t.taxPlanId)).map(t => (
                                <div className="my-1 flex items-center flex-col w-full">
                                    <div className="flex items-center justify-between w-full">
                                        <Typography>
                                            {`${t.name}`}
                                        </Typography>
                                        <div className="flex items-center">
                                            <Tooltip arrow title="Eliminar plan de impuestos">
                                                <IconButton disabled={loading} onClick={() => handleDelete(t)} size="small">
                                                    <Icon fontSize="small">close</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="w-full py-2">
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    {TAXPLAN_TABLE_RETENTION_COLUMNS.map(headerCell => (
                                                        <TableCell padding="none">{headerCell.label}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    t.taxCodes
                                                        .map((row) => {
                                                            return (
                                                                <TableRow
                                                                    key={row.taxId}
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    selected
                                                                >
                                                                    <TableCell padding="none" align="left">{taxTypes.find(t => t.taxId.toString() === row.taxTypeId.toString())?.name}</TableCell>
                                                                    <TableCell padding="none" align="left">{row.taxCode}</TableCell>
                                                                    <TableCell padding="none" align="left">{row.description}</TableCell>
                                                                    <TableCell padding="none" align="left">{`${row.percentage.toFixed(2)}%`}</TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            ))}
                        </React.Fragment>
                }
                {
                    loading &&
                    <div className="absolute mb-2 mr-2 flex items-center bottom-0 right-0">
                        <CircularProgress thickness={10} size={22} color="primary" />
                    </div>
                }
            </div>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default TaxPlanSupplier
