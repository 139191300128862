export const EXCEPTION_TYPES = [
    {
        id: 0,
        name: "Proveedores críticos",
        description: "Se ingresa el documento a pesar del cierre, pero se envía a COUPA el siguiente mes.",
    },
    {
        id: 1,
        name: "Compañias relacionadas",
        description: "Se ingresa el documento a pesar del cierre, pero si es envíado a COUPA el mismo día.",
    },
    {
        id: 2,
        name: "Cierre de año",
        description: "Se ingresa el documento a pesar del cierre, pero si es envíado a COUPA el mismo día.",
    },
]