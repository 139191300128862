import React from 'react'
import { IRejectedReportParams } from 'types'

interface Props {

}

interface Hook {
    params: IRejectedReportParams
    handleChangeParams: (key: keyof IRejectedReportParams, value: any) => void
    restartDates: () => void
}

const useRejectedReportParams = (props?: Props): Hook => {
    const [params, setParams] = React.useState<IRejectedReportParams>({
        rejectedEnd: null,
        rejectedStart: null,
        states: [0, 1]
    })
    const handleChangeParams = (key: keyof IRejectedReportParams, value: any) => {
        setParams(current => ({
            ...current,
            [key]: value
        }))
    }
    const restartDates = () => {
        setParams(current => ({ ...current, rejectedEnd: null, rejectedStart: null }))
    }
    return {
        params,
        handleChangeParams,
        restartDates
    }
}

export default useRejectedReportParams
