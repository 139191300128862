export const INVOICE_PAYMENT_TYPES = [
  {
    value: "01",
    name: "01 - SIN UTILIZACION DEL SISTEMA FINANCIERO",
  },
  {
    value: "15",
    name: "15 - COMPENSACIÓN DE DEUDAS",
  },
  {
    value: "16",
    name: "16 - TARJETA DE DÉBITO",
  },
  {
    value: "17",
    name: "17 - DINERO ELECTRÓNICO",
  },
  {
    value: "18",
    name: "18 - TARJETA PREPAGO",
  },
  {
    value: "19",
    name: "19 - TARJETA DE CRÉDITO",
  },
  {
    value: "20",
    name: "20 - OTROS CON UTILIZACION DEL SISTEMA FINANCIERO",
  },
  {
    value: "21",
    name: "21 - ENDOSO DE TÍTULOS",
  }
]
