import { Dialog, DialogActions, Button, CircularProgress, TextField } from '@material-ui/core'
import { FIELDS, updateRejectedReasonService } from 'lib'
import React from 'react'

interface Props {
    open: boolean
    onClose: () => void
    onSuccess: () => void
    onError: () => void
    rejectedId: number
}

const ReasonDialog = (props: Props) => {
    const { open, rejectedId, onClose, onSuccess, onError } = props
    const [loading, setLoading] = React.useState(false)
    const [reason, setReason] = React.useState<string>("")
    const handleConfirm = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            setLoading(true)
            await updateRejectedReasonService(rejectedId, reason)
            setLoading(false)
            setReason("")
            onSuccess()
            onClose()
        } catch (error) {
            onError()
            onClose()
            setLoading(false)
        }
    }
    return (
        <Dialog
            open={open}
            onClose={(_, reason) => {
                if (reason === "backdropClick" && loading) { return }
                onClose()
                setReason("")
            }}
            disableEscapeKeyDown={loading}
        >
            <form onSubmit={handleConfirm}>
                <div className='p-4 mt-2'>
                    <TextField
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        style={{ width: 350 }}
                        label={FIELDS.reason.name}
                        variant="outlined"
                        size="small" 
                    />
                </div>
                <DialogActions>
                    <Button disabled={loading} size="small" onClick={onClose}>
                        {"Cancelar"}
                    </Button>
                    <Button
                        disableElevation
                        disabled={loading}
                        size="small"
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        {loading ? <CircularProgress style={{ color: "white" }} size={15} className="my-1" thickness={8} /> : "Enviar"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default ReasonDialog
