import { Backdrop, Button, Paper, Slide, Typography } from '@material-ui/core'
import React from 'react'

interface Props {
    onSave: () => void
    onDiscard: () => void
}

export interface UnsavedChangesConfirmRef {
    open: () => void
}

const UnsavedChangesConfirm = React.forwardRef<UnsavedChangesConfirmRef, Props>((props: Props, ref?: React.ForwardedRef<UnsavedChangesConfirmRef>) => {
    const { onDiscard, onSave } = props
    const [show, setShow] = React.useState(false)
    const handleClose = () => {
        setShow(false)
    }
    const handleSave = () => {
        onSave()
        handleClose()
    }

    const handleDiscard = () => {
        onDiscard()
        handleClose()
    }


    React.useImperativeHandle(ref, () => ({
        open() {
            setShow(true)
        }
    }))

    return (
        <Backdrop
            open={show}
            style={{ zIndex: 9999 }}
        >
            <Slide in={show}>
                <Paper className='flex flex-col items-center justify-center p-6 fixed top-0 mt-8'>
                    <Typography
                        variant='caption'
                        className='font-bold uppercase'
                    >
                        {"Tienes cambios sin guardar"}
                    </Typography>
                    <Typography
                        variant='subtitle1'
                        color="textSecondary"
                        className='pt-2'
                    >
                        {"Si continuas perderas las modificaciones realizadas"}
                    </Typography>
                    <Typography
                        variant='subtitle2'
                        color="textSecondary"
                        className='pt-2'
                    >
                        {"¿Qué deseas hacer?"}
                    </Typography>
                    <div className='px-2 mt-6 flex'>
                        <Button
                            size='small'
                            disableElevation
                            onClick={handleDiscard}
                        >
                            {"Descartar cambios"}
                        </Button>
                        <Button
                            size='small'
                            color='primary'
                            disableElevation
                            variant='contained'
                            style={{ marginLeft: 15 }}
                            onClick={handleSave}
                        >
                            {"Guardar"}
                        </Button>
                    </div>
                </Paper>
            </Slide>
        </Backdrop>
    )
})

export default UnsavedChangesConfirm
