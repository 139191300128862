export const TAX_SUPPORTS = [
    {
        code: "00",
        name: "Casos especiales cuyo sustento no aplica en las opciones anteriores"
    },
    {
        code: "01",
        name: "Crédito tributario para declaración de IVA (Servicios y bienes distintos de inventarios y activos fijos)"
    },
    {
        code: "02",
        name: "Costo o Gasto para declaración de Impuesto a la Renta (Servicios y bienes distintos de inventarios y activos fijos)"
    },
    {
        code: "03",
        name: "Activo Fijo - Crédito Tributario para declaración de IVA"
    },
    {
        code: "04",
        name: "Activo Fijo - Costo o Gasto para la declaración de Impuesto a la Renta"
    },
    {
        code: "05",
        name: "Liquidación de gastos de viaje, hospedaje y alimentación (a nombre de empleados y no de la empresa)"
    },
    {
        code: "06",
        name: "Inventario - Crédito Tributario para declaración de IVA"
    },
    {
        code: "07",
        name: "Inventario - Costo o Gasto para la declaración de Impuesto a la Renta"
    },
    {
        code: "08",
        name: "Valor pagado para solicitar Reembolso de Gastos (Intermediario)"
    },
    {
        code: "09",
        name: "Reembolso por Siniestros"
    },
    {
        code: "10",
        name: "Distribución de Dividendos, Beneficios o Utilidades"
    },
    {
        code: "11",
        name: "Convenios de débito o recaudación para IFI's"
    },
    {
        code: "12",
        name: "Impuestos y retenciones presuntivos"
    },
    {
        code: "13",
        name: "Valores reconocidos por entidades del sector público a favor de sujetos pasivos"
    },
]