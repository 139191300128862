import React from 'react'
import { approvePurchaseOrderService, DOCUMENT_PATH, FIELDS, FIRST_LEVEL_INDEX, PURCHASE_ORDER_PATH, PURCHASE_ORDER_STATUS, PURCHASE_ORDER_VIEW_TITLE, REJECTED_PATH, rejectPurchaseOrderService, ROOT_TITLE, TO_APPROVE_PATH, updatePurchaseOrderStatusService } from 'lib'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router-dom'
import { IMessageConfig, IPurchaseOrder, ISupplier } from 'types'
import { PurchaseOrderBar, RequestData, ApproachPicker, DetailData, DocumentData, DocumentEvents, EmissionData, ReceiverData, ValuesData, DocumentFiles, ApprovalData } from 'components'
import { Divider, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { AuthContext, DocumentContext, ManagementContext } from 'context'

const PurchaseOrderView = () => {
    const location = useLocation()
    const history = useHistory()
    const [purchaseOrder, setPurchaseOrder] = React.useState<IPurchaseOrder | undefined>(undefined)
    const { user, userApproval } = React.useContext(AuthContext)
    const { approvalLevels } = React.useContext(ManagementContext)
    const { company } = React.useContext(DocumentContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const handleApprove = async (message: string) => {
        if (!(purchaseOrder?.data?.header?.supplier)) {
            setMessageConfig({ open: true, message: "Selecciona un proveedor para continuar", severity: "warning" })
            return
        }
        try {
            if (purchaseOrder) {
                setLoading(true)
                const newPurchaseOrder = { ...purchaseOrder.data, header: { ...purchaseOrder.data.header, flowData: [...purchaseOrder.data.header.flowData, { message, userId: user?.userId ?? -1 }] } }
                const approvalLevel = approvalLevels.find(a => a.approvalLevelId === newPurchaseOrder.header.approvalLevel)
                await approvePurchaseOrderService(purchaseOrder?.document?.documentId, newPurchaseOrder, user?.userId)
                if (approvalLevel && newPurchaseOrder.header.flowData.length === approvalLevel.index) {
                    await handleFinish(true)
                    history.replace({
                        pathname: `${PURCHASE_ORDER_PATH}${TO_APPROVE_PATH}`,
                        state: { showCompletedMessage: true }
                    })
                } else {
                    await handleFinish(false)
                    history.replace({
                        pathname: `${PURCHASE_ORDER_PATH}${TO_APPROVE_PATH}`,
                        state: { showApprovedMessage: true }
                    })
                }
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo aprobar la orden de compra", severity: "error" })
        }
    }
    const handleReject = async (message: string) => {
        try {
            if (purchaseOrder) {
                setLoading(true)
                await rejectPurchaseOrderService(purchaseOrder?.document?.documentId, { ...purchaseOrder.data, header: { ...purchaseOrder.data.header, flowData: [...purchaseOrder.data.header.flowData, { message, userId: user?.userId ?? -1 }] } }, user?.userId)
                history.replace({
                    pathname: `${PURCHASE_ORDER_PATH}${REJECTED_PATH}`,
                    state: { showRejectedMessage: true }
                })
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo aprobar la orden de compra", severity: "error" })
        }
    }
    const handleFinish = async (toFinish: boolean) => {
        try {
            if (purchaseOrder) {
                setLoading(true)
                if (toFinish) {
                    await updatePurchaseOrderStatusService(purchaseOrder?.document?.documentId, PURCHASE_ORDER_STATUS.finished.conditionId, user?.userId)
                    history.replace({
                        pathname: `${PURCHASE_ORDER_PATH}${TO_APPROVE_PATH}`,
                        state: { showCompletedMessage: true }
                    })
                } else {
                    await updatePurchaseOrderStatusService(purchaseOrder?.document?.documentId, PURCHASE_ORDER_STATUS.perApproval.conditionId, user?.userId)
                    history.replace({
                        pathname: `${PURCHASE_ORDER_PATH}${TO_APPROVE_PATH}`,
                        state: { showApprovedMessage: true }
                    })    
                }
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo aprobar la orden de compra", severity: "error" })
        }
    }

    const handleDownloadStarted = () => setMessageConfig({ open: true, message: "Su descarga ha comenzado", severity: "info" })

    React.useEffect(() => {
        const init = async () => {
            if ((location.state as any)?.purchaseOrder) {
                setPurchaseOrder((location.state as any)?.purchaseOrder)
            } else {
                // const id = location.pathname.split(ROOT_PATH).pop()
                try {
                    // const result = await getDocumentByIdService(id)
                    // setPurchaseOrder({ ...result, additionalInfo: JSON.parse(result.additionalInfo) })
                } catch (error) {
                    history.replace(`${DOCUMENT_PATH}`)
                }
            }
        }
        init()
    }, [location, history])
    return (
        <div className="w-full flex flex-col h-full bg-white document-primary-outline">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${PURCHASE_ORDER_VIEW_TITLE}`}</title>
            </Helmet>
            {
                purchaseOrder &&
                <React.Fragment>
                    <PurchaseOrderBar
                        processStatus={purchaseOrder.document.flowStatus}
                        supplierSelected={Boolean(purchaseOrder.data.header.supplier)}
                        onApprove={handleApprove}
                        onReject={handleReject}
                        loading={loading}
                        flowData={purchaseOrder.data.header.flowData}
                        documentId={purchaseOrder.document.documentId}
                        purchaseOrderNumber={purchaseOrder.document.serialNumber}
                    />
                    <Divider style={{ backgroundColor: "#eee", marginLeft: 3 }} />
                    {
                        (purchaseOrder.document.flowStatus !== PURCHASE_ORDER_STATUS.finished.conditionId || purchaseOrder.document.flowStatus !== PURCHASE_ORDER_STATUS.assigned.conditionId) ?
                            <div className="overflow-y-auto">
                                <div className="flex w-full mt-2 py-4 px-8 flex-col">
                                    <RequestData
                                        header={purchaseOrder.data.header}
                                        serialNumber={purchaseOrder.document.serialNumber}
                                    />
                                    <div className="mt-2">
                                        <ApprovalData
                                            approvalLevel={purchaseOrder.data.header.approvalLevel}
                                            flowData={purchaseOrder.data.header.flowData}
                                            rejected={purchaseOrder.document.flowStatus === PURCHASE_ORDER_STATUS.rejected.conditionId}
                                            approvalMessage={purchaseOrder.files.find(f => f.tag === FIELDS.approvalMessage.key)}
                                            onDownloadStarted={() => handleDownloadStarted()}
                                        />
                                    </div>
                                    {
                                        purchaseOrder.document.flowStatus === PURCHASE_ORDER_STATUS.rejected.conditionId &&
                                        <DetailData
                                            detail={purchaseOrder.data.detail}
                                            hideDiscount
                                            hideTotal
                                            hideUnitPrice
                                        />
                                    }
                                    <div className="mt-2">
                                        <ApproachPicker
                                            canSelect={approvalLevels.find(a => a.approvalLevelId === userApproval?.approvalLevelId)?.index === FIRST_LEVEL_INDEX && purchaseOrder.data.header.flowData.length < FIRST_LEVEL_INDEX}
                                            selected={purchaseOrder.data.header.supplier}
                                            readOnly={purchaseOrder.document.flowStatus !== PURCHASE_ORDER_STATUS.perApproval.conditionId}
                                            suppliersInfo={purchaseOrder.data.suppliersInfo}
                                            onSelect={(supplier, detail) => {
                                                setPurchaseOrder({ ...purchaseOrder, data: { ...purchaseOrder.data, header: { ...purchaseOrder.data.header, supplier }, detail } })
                                            }}
                                            backUpFiles={purchaseOrder.files.filter(f => !isNaN(parseInt(f.tag)))}
                                            onDownloadStarted={() => handleDownloadStarted()}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="overflow-y-auto">
                                <div className="flex w-full mt-2 items-start">
                                    <div className="w-2/4 py-4 px-8">
                                        {
                                            purchaseOrder.data &&
                                            <EmissionData
                                                header={{
                                                    supplier: { identification: purchaseOrder.document.companyRuc, companyName: purchaseOrder.document.companyName, address: company?.address, keepAccounting: company?.keepAccounting } as ISupplier,
                                                    createDate: new Date(purchaseOrder.data.header.createDate).toISOString()
                                                }}
                                            />
                                        }
                                        <ReceiverData
                                            identification={purchaseOrder.data.header.supplier?.identification ?? ""}
                                            name={purchaseOrder.data.header.supplier?.companyName ?? ""}
                                        />
                                    </div>
                                    <div className="w-2/4 py-4 pr-8 h-full">
                                        {
                                            purchaseOrder.data &&
                                            <DocumentData
                                                header={{
                                                    emissionDate: purchaseOrder.document.documentDate,
                                                    receptionDate: purchaseOrder.document.authorizationDate
                                                }}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="mt-2 px-8">
                                    <ApprovalData
                                        approvalLevel={purchaseOrder.data.header.approvalLevel}
                                        flowData={purchaseOrder.data.header.flowData}
                                        onDownloadStarted={() => handleDownloadStarted()}
                                    />
                                </div>
                                <div className="flex w-full mt-2">
                                    {
                                        purchaseOrder.data &&
                                        <DetailData
                                            detail={purchaseOrder.data.detail}
                                        />
                                    }
                                </div>
                                <div className="flex w-full mt-2">
                                    <div className="w-2/4 py-4 px-8">
                                        <DocumentFiles
                                            files={purchaseOrder.files.map(f => ({ ...f, fileName: "data.json", description: "Datos de la orden de compra" }))}
                                            documentId={purchaseOrder.document.documentId}
                                            readOnly
                                        />
                                    </div>
                                    <div className="w-2/4 py-4 pr-8">
                                        <ValuesData
                                            data={purchaseOrder.data}
                                            detail={[]}
                                            additionalInfo=""
                                            documentId={purchaseOrder.document.documentId}
                                        />
                                    </div>
                                </div>
                                <div className="flex w-full mt-2">
                                    <DocumentEvents
                                        events={purchaseOrder.historyData}
                                    />
                                </div>
                            </div>
                    }
                </React.Fragment>
            }
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default PurchaseOrderView