import { Button, CircularProgress, Divider, MenuItem, TextField, Typography, IconButton, Icon } from '@material-ui/core'
import { useValues } from 'hooks'
import { FIELDS, createTaxesService, editTaxService } from 'lib'
import React from 'react'
import { useLocation } from 'react-router'
import { ITax } from 'types'
import { ManagementContext } from 'context'
import { PercentageInput } from 'components'

interface Props {
    onSuccess: () => void
    onError: () => void
    onClose: () => void
}

const TaxForm = (props: Props) => {
    const location = useLocation()
    const { taxTypes } = React.useContext(ManagementContext);
    const { onError, onSuccess, onClose } = props
    const initialInput = React.useRef<any>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const { values: tax, updateValue: handleChange, updateValues } = useValues()
    const handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            if (!(location.state as any)?.edit) {
                await createTaxesService({ ...tax })
            }
            else {
                const edited = { ...tax }
                delete edited[FIELDS.taxId.key]
                await editTaxService(tax[FIELDS.taxId.key], { ...edited })
            }
            setLoading(false)
            if (!(location.state as any)?.edit) {
                updateValues({
                    description: "",
                    percentage: 0,
                    jbaCode: "",
                } as ITax)
                initialInput.current?.focus()
            }
            onSuccess()
        } catch (error) {
            setLoading(false)
            onError()
        }
    }
    React.useEffect(() => {
        if ((location.state as any)?.edit) {
            if ((location.state as any)?.values) {
                updateValues((location.state as any)?.values)
            }
        }
    }, [location.state, updateValues])
    return (
        <div className="p-4">
            <div className="flex items-center justify-between">
                <Typography variant="body2" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                    {(location.state as any)?.edit ? "Editar Impuesto" : "Nuevo Impuesto"}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <Icon fontSize="small">close</Icon>
                </IconButton>
            </div>
            <form onSubmit={handleSumbit} className="pt-4 flex flex-col" style={{ width: 300 }}>
                <TextField
                    inputRef={initialInput}
                    autoFocus
                    size="small"
                    label={FIELDS.taxCode.name}
                    variant="outlined"
                    fullWidth
                    required
                    value={tax[FIELDS.taxCode.key]}
                    onChange={(e) => handleChange(FIELDS.taxCode.key, e.target.value)}
                />
                <TextField
                    size="small"
                    label={FIELDS.description.name}
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 10 }}
                    multiline
                    onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault() } }}
                    rows={3}
                    required
                    value={tax[FIELDS.description.key]}
                    onChange={(e) => handleChange(FIELDS.description.key, e.target.value)}
                />
                <TextField
                    size="small"
                    label={FIELDS.percentage.name}
                    variant="outlined"
                    style={{ marginTop: 10 }}
                    fullWidth
                    required
                    InputProps={{
                        inputComponent: PercentageInput as any,
                    }}
                    value={tax[FIELDS.percentage.key]}
                    onChange={(e) => handleChange(FIELDS.percentage.key, e.target.value)}
                />
                <TextField
                    size="small"
                    label={FIELDS.jbaCode.name}
                    variant="outlined"
                    style={{ marginTop: 10 }}
                    fullWidth
                    required
                    value={tax[FIELDS.jbaCode.key]}
                    onChange={(e) => handleChange(FIELDS.jbaCode.key, e.target.value)}
                />
                <TextField
                    value={tax[FIELDS.taxTypeId.key]?.toString() || ''}
                    label={FIELDS.taxTypeId.name}
                    onChange={(e) => { handleChange(FIELDS.taxTypeId.key, e.target.value) }}
                    required
                    style={{ marginTop: 10 }}
                    variant="outlined"
                    size="small"
                    select
                >
                    {taxTypes.map((taxT) => {
                        return <MenuItem key={taxT.taxId} value={taxT.taxId}>{taxT.name}</MenuItem>
                    })}
                </TextField>

                <p>{tax[FIELDS.active.key]}</p>
                <div className="mt-4">
                    <Divider />
                </div>
                <div className="flex justify-end pt-3">
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        disableElevation
                        disabled={loading}
                        type="submit"
                    >
                        {loading ? <CircularProgress style={{ color: "white" }} size={16} className="my-1" thickness={10} /> : (location.state as any)?.edit ? "Guardar" : "Registrar"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default TaxForm
