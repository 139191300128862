import {
  Paper,
  Button,
  Icon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  Snackbar,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import {
  Search,
  FormDialog,
  IdentificationTypeForm,
  ConfirmDialog,
  FixedLoadingIndicator,
  LabelDisplayedRows,
  ServerErrorHandler,
} from "components"
import { ManagementContext } from "context"
import { useInputValue, useTableModel } from "hooks"
import {
  RESOURCES_PATH,
  IDENTIFICATION_TYPE_PATH,
  EDIT_PATH,
  deleteIdentificationTypeService,
  NEW_PATH,
  IDENTIFICATION_TYPE_TABLE_COLUMNS,
  SMALL_ROWS_PER_PAGINATION_OPTIONS,
  IDENTIFICATION_TYPE_TITLE,
  ROOT_TITLE,
} from "lib"
import React from "react"
import { Helmet } from "react-helmet"
import { useHistory, useLocation } from "react-router-dom"
import { IMessageConfig, IIdentificationType } from "types"

const IdentificationTypes = () => {
  const { identificationTypes, refreshIdentificationTypes } =
    React.useContext(ManagementContext)
  const {
    value: query,
    clearValue: handleClear,
    onChange: handleChange,
  } = useInputValue()
  const {
    createSortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    order,
    orderBy,
    page,
    rows,
    rowsPerPage,
  } = useTableModel()
  const location = useLocation()
  const history = useHistory()
  const [selected, setSelected] = React.useState<any | undefined>(undefined)
  const [actionsAnchorEl, setActionsAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [deleteId, setDeleteId] = React.useState<number>(-1)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<boolean>(false)
  const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({
    open: false,
    message: "",
    severity: "info",
  })
  const getModel = React.useCallback((): any[] => {
    const src = [...identificationTypes]
    if (Boolean(query)) {
      return src.filter((s: any) => {
        return (
          s.name.toUpperCase().includes(query.toUpperCase()) ||
          s.identificationTypeCode.toUpperCase().includes(query.toUpperCase()) ||
          s.name.toUpperCase().includes(query.toUpperCase())
        )
      })
    }
    return src
  }, [identificationTypes, query])
  const handleEdit = () => {
    setActionsAnchorEl(null)
    history.push({
      state: {
        edit: true,
        values: selected,
      },
      pathname: `${RESOURCES_PATH}${IDENTIFICATION_TYPE_PATH}${EDIT_PATH}`,
    })
  }
  const handleConfirmDelete = (id: number) => {
    setActionsAnchorEl(null)
    setDeleteId(id)
  }
  const handleDelete = async () => {
    try {
      setLoading(true)
      await deleteIdentificationTypeService(deleteId)
      setMessageConfig({
        open: true,
        message: "Tipo de identificacion eliminada!",
        severity: "success",
      })
      await refreshIdentificationTypes()
      setLoading(false)
      return true
    } catch (error) {
      setMessageConfig({
        open: true,
        message: "No se pudo eliminar el tipo de identificacion...!",
        severity: "error",
      })
      setLoading(false)
      return false
    }
  }
  React.useEffect(() => {
    const initScreen = async () => {
      try {
        setLoading(true)
        await refreshIdentificationTypes()
        setLoading(false)
      } catch (error) {
        setError(true)
        setLoading(false)
      }
    }
    initScreen()
  }, [refreshIdentificationTypes])
  return (
    <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
      <Helmet>
        <title>{`${ROOT_TITLE} - ${IDENTIFICATION_TYPE_TITLE}`}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Search
            onChange={handleChange}
            query={query}
            onClear={handleClear}
            placeholer={"Buscar por código, nombre o descripción..."}
          />
        </div>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() =>
            history.push(`${RESOURCES_PATH}${IDENTIFICATION_TYPE_PATH}${NEW_PATH}`)
          }
        >
          <Icon fontSize="small" style={{ marginRight: 5 }}>add</Icon>
          {"Nuevo"}
        </Button>
        <FormDialog
          open={Boolean(
            location.pathname ===
            `${RESOURCES_PATH}${IDENTIFICATION_TYPE_PATH}${NEW_PATH}` ||
            location.pathname ===
            `${RESOURCES_PATH}${IDENTIFICATION_TYPE_PATH}${EDIT_PATH}`
          )}
          onClose={() => history.push(`${RESOURCES_PATH}${IDENTIFICATION_TYPE_PATH}`)}
        >
          <IdentificationTypeForm
            onSuccess={() => {
              setMessageConfig({
                open: true,
                message: Boolean(selected)
                  ? "Tipo de identificacion actualizada!"
                  : "Tipo de identificacion registrada!",
                severity: "success",
              })
              refreshIdentificationTypes()
              if (Boolean(selected)) {
                setSelected(undefined)
              }
            }}
            onError={() =>
              setMessageConfig({
                open: true,
                message: "No se pude registrar el tipo de identificacion",
                severity: "error",
              })
            }
            onClose={() =>
              history.push(`${RESOURCES_PATH}${IDENTIFICATION_TYPE_PATH}`)
            }
          />
        </FormDialog>
      </div>
      <div className="flex h-full flex-col mt-4 overflow-hidden">
        <div className="flex flex-grow overflow-auto">
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  {IDENTIFICATION_TYPE_TABLE_COLUMNS.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={"left"}
                      padding={"none"}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id as any)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className="hidden">
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell padding={"default"} />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rows(getModel()) as IIdentificationType[]).map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.identificationTypeId.toString()}
                    >
                      <TableCell component="th" scope="row" padding="none">
                        {row.identificationTypeCode}
                      </TableCell>
                      <TableCell padding="none" align="left">
                        {row.name}
                      </TableCell>
                      <TableCell padding="none" align="left">
                        {row.description}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          onClick={(e) => {
                            setActionsAnchorEl(e.currentTarget)
                            setSelected(row)
                          }}
                          size="small"
                        >
                          <Icon fontSize="small">more_vert</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
                <Menu
                  anchorEl={actionsAnchorEl}
                  open={Boolean(actionsAnchorEl)}
                  onClose={() => setActionsAnchorEl(null)}
                >
                  <MenuItem onClick={handleEdit} dense>
                    {"Editar"}
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleConfirmDelete(selected?.identificationTypeId ?? -1)
                    }
                    dense
                  >
                    {"Eliminar"}
                  </MenuItem>
                </Menu>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          <TablePagination
            rowsPerPageOptions={SMALL_ROWS_PER_PAGINATION_OPTIONS}
            component="div"
            count={getModel().length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Tipos de identificacion por página"
            labelDisplayedRows={LabelDisplayedRows}
          />
        </div>
      </div>
      <ConfirmDialog
        onCancel={() => setDeleteId(-1)}
        onConfirm={handleDelete}
        open={Boolean(deleteId !== -1)}
        title={"¿Estás seguro que deseas eliminar?"}
        loading={loading}
      />
      <Snackbar
        open={messageConfig.open}
        autoHideDuration={6000}
        onClose={() => setMessageConfig({ ...messageConfig, open: false })}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert
          variant="filled"
          onClose={() => setMessageConfig({ ...messageConfig, open: false })}
          severity={messageConfig.severity}
        >
          {messageConfig.message}
        </Alert>
      </Snackbar>
      <FixedLoadingIndicator loading={loading && Boolean(deleteId === -1)} />
      <ServerErrorHandler
        error={error}
        onSuccess={() => setError(false)}
        tryAgain={refreshIdentificationTypes}
      />
    </Paper>
  )
}

export default IdentificationTypes
