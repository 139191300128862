import { Paper, Typography, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FixedLoadingIndicator } from 'components'
import { DocumentContext } from 'context'
import { getDocumentByIdService, DOCUMENT_PATH, DOCUMENT_VIEW_PATH, DOCUMENT_TYPE_IDS } from 'lib'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router'
import { IDocument, IMessageConfig } from 'types'

interface Props {
    associated: IDocument[]
}

const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2 }

const AssociatedData = (props: Props) => {
    const { associated } = props
    const history = useHistory()
    const { documentTypes } = React.useContext(DocumentContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info", })
    const handleSelect = async (document: IDocument) => {
        try {
            setLoading(true)
            const response = await getDocumentByIdService(document.documentId)
            history.push({
                state: { document: response },
                pathname: `${DOCUMENT_PATH}${DOCUMENT_VIEW_PATH}/${document.documentId}`
            })
            setLoading(false)
        } catch (error) {
            console.log(error)
            setMessageConfig({ open: true, message: "No se pudo obtener información del documento selecionado...", severity: "error" })
            setLoading(false)
        }
    }
    return (
        <div className="px-8 w-full">
            <Paper variant="outlined" className="mt-3 w-full" elevation={1}>
                <div className="py-3 px-4 flex w-full items-center">
                    <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                        {"DOCUMENTOS ASOCIADOS"}
                    </Typography>
                </div>
                <Divider />
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle} align="left">{"Tipo"}</TableCell>
                                <TableCell style={headerCellStyle} align="right">{"Fecha"}</TableCell>
                                <TableCell style={headerCellStyle} align="right">{"Número"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                associated.map(document => (
                                    <TableRow style={{ cursor: "pointer", borderBottom: document.documentTypeId === DOCUMENT_TYPE_IDS.employeeReimbursement ? "#42a5f5 solid 2px" : undefined}} onClick={() => handleSelect(document)}>
                                        <TableCell style={bodyCellStyle} align="left">{documentTypes.find(d => d.documentTypeId === document.documentTypeId)?.documentName}</TableCell>
                                        <TableCell style={bodyCellStyle} align="right">{moment(new Date(document.documentDate).toISOString())?.format('DD-MM-YYYY')?.toString()}</TableCell>
                                        <TableCell style={bodyCellStyle} align="right">{document.serialNumber}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Snackbar
                open={messageConfig.open}
                autoHideDuration={6000}
                onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Alert
                    variant="filled"
                    onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                    severity={messageConfig.severity}
                >
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading} />
        </div>
    )
}

export default AssociatedData
