import { Button, CircularProgress, TextField, Typography, Icon, IconButton } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ManagementContext } from 'context'
import { createCategoryService, editCategoryService, EDIT_PATH, FIELDS } from 'lib'
import React from 'react'
import { useLocation, useHistory } from 'react-router'
import { ICategory } from 'types'

interface Props {
    onSuccess: () => void
    onError: (message?: string) => void
    onClose: () => void
}

const CategoryForm = (props: Props) => {
    const location = useLocation()
    const history = useHistory()
    const { onError, onSuccess, onClose } = props
    const { taxPlans } = React.useContext(ManagementContext)
    const initialInput = React.useRef<any>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [category, setCategory] = React.useState<ICategory>({} as ICategory)
    const handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            if (!(location.state as any)?.edit) {
                await createCategoryService({ name: category.name, taxPlanId: category.taxPlan.taxPlanId })
            } else {
                const edited = { name: category.name, taxPlanId: category.taxPlan.taxPlanId, categoryId: category.categoryId }
                await editCategoryService(category.categoryId, { ...edited })
            }
            setLoading(false)
            if (!(location.state as any)?.edit) {
                setCategory({ name: "" } as ICategory)
                initialInput.current?.focus()
            }
            onSuccess()
        } catch (error: any) {
            setLoading(false)
            onError(error.message ?? undefined)
        }
    }
    React.useEffect(() => {
        if ((location.state as any)?.edit) {
            if ((location.state as any)?.values) {
                setCategory((location.state as any)?.values)
            }
        }
        else {
            history.replace(location.pathname.replace(EDIT_PATH, ""))
        }
    }, [location.state, history, location.pathname])


    return (
        <div className="p-4">
            <div className="flex items-center justify-between">
                <Typography variant="body2" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                    {(location.state as any)?.edit ? "Editar categoría" : "Nueva categoría"}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <Icon fontSize="small">close</Icon>
                </IconButton>
            </div>
            <form onSubmit={handleSumbit} className="pt-4 flex flex-col" style={{ width: 300 }}>
                <TextField
                    inputRef={initialInput}
                    autoFocus
                    size="small"
                    label={FIELDS.name.name}
                    variant="outlined"
                    fullWidth
                    required
                    value={category.name}
                    onChange={(e) => setCategory(current => ({ ...current, name: e.target.value }))}
                />
                <Autocomplete
                    options={taxPlans.filter(t => t.forCustomers)}
                    getOptionLabel={(taxPlan) => taxPlan.name}
                    fullWidth
                    value={category.taxPlan ? category.taxPlan : null}
                    openOnFocus
                    size="small"
                    style={{ marginTop: 10 }}
                    onChange={(e, selectedValue) => {
                        if (selectedValue) {
                            setCategory(current => ({ ...current, taxPlan: selectedValue }))
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            label={"Plan de impuestos"}
                            required
                        />
                    )}
                />
                <div className="flex justify-end pt-3">
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        disableElevation
                        disabled={loading}
                        type="submit"
                    >
                        {loading ? <CircularProgress style={{ color: "white" }} size={16} className="my-1" thickness={10} /> : (location.state as any)?.edit ? "Guardar" : "Registrar"}
                    </Button>
                </div>
            </form>
        </div >
    )
}

export default CategoryForm
