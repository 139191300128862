import React from 'react'

interface Props {
    handleChangePage: (event: unknown, newPage: number) => void
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
    page: number
    rowsPerPage: number

}

const usePagination = (): Props => {
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const handleChangePage = (event: unknown, newPage: number) => { setPage(newPage) }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }
    return {
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        rowsPerPage
    }
}

export default usePagination
