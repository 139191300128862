import React from 'react'
import Lottie from 'react-lottie'
import forbidden from 'assets/animations/forbidden.json'
import { Typography } from '@material-ui/core'
import { useLocation } from 'react-router'

const Forbidden = () => {
    const location = useLocation()

    return (
        <div className="h-full w-full flex flex-col items-center justify-center">
            <Typography variant="h4" className="py-5 text-center">{"No tienes acceso a la ruta solicitada."}</Typography>
            {
                (location.state as any).requestedPath &&
                <Typography color="error" variant="body2">{(location.state as any).requestedPath}</Typography>
            }
            <Lottie
                options={{
                    loop: false,
                    autoplay: true,
                    animationData: forbidden,
                    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                }}
                height={300}
                width={300}
            />
        </div>
    )
}

export default Forbidden
