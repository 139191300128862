import { FIELDS } from "./fields"

export const CUSTOMER_TABLE_COLUMNS = [
    { id: FIELDS.identification.key, label: FIELDS.identification.name },
    { id: FIELDS.identificationType.key, label: FIELDS.identificationType.name },
    { id: FIELDS.companyName.key, label: FIELDS.companyName.name },
    { id: FIELDS.specialTaxpayer.key, label: FIELDS.specialTaxpayer.name },
    { id: FIELDS.activeAlt.key, label: FIELDS.activeAlt.name },
]

export const CUSTOMER_TYPES = ["Empleado", "Esporádico", "Exportador", "Extranjero" , "Noacional"]