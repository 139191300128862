export const SUSTENTO_CODES = [
  {
    value: "01",
    name: "01 - Crédito Tributario para declaración de IVA",
  },
  {
    value: "02",
    name: "02 - Costo o Gasto para declaración de IR",
  },
  {
    value: "03",
    name: "03 - Activo Fijo - Crédito Tributario para declaración de IVA",
  },
  {
    value: "04",
    name: "04 - Activo Fijo - Costo o Gasto para declaración de IR",
  },
  {
    value: "05",
    name: "05 - Liquidación Gastos de Viaje, hospedaje y alimentación Gastos IR (a nombre de empleados y no de la empresa)",
  },
  {
    value: "06",
    name: "06 - Inventario - Crédito Tributario para declaración de IVA",
  },
  {
    value: "07",
    name: "07 - Inventario - Costo o Gasto para declaración de IR",
  },
  {
    value: "08",
    name: "08 - Valor pagado para solicitar Reembolso de Gasto (intermediario)",
  },
  {
    value: "09",
    name: "09 - Reembolso por Siniestros",
  },
  {
    value: "10",
    name: "10 - Distribución de Dividendos, Beneficios o Utilidades",
  },
  {
    value: "11",
    name: "11 - Convenios de débito o recaudación para IFI´s",
  },
  {
    value: "12",
    name: "12 - Impuestos y retenciones presuntivos",
  },
  {
    value: "13",
    name: "13 - Valores reconocidos por entidades del sector público a favor de sujetos pasivos",
  },
  {
    value: "00",
    name: "00 - Casos especiales cuyo sustento no aplica en las opciones anteriores",
  },
]
