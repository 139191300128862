import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { FIELDS } from 'lib'
import { DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, PAYMENTS_PATH, SEND_PATH, UPLOAD_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

const formDataConfig: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export const uploadPaymentFileService = async (file: any) => {
    const formData = new FormData()
    formData.append(FIELDS.file.key, file)
    try {
        const result = await intance.post(PAYMENTS_PATH + UPLOAD_PATH, formData, formDataConfig)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getPaymentsService = async () => {
    try {
        const result = await intance.get(PAYMENTS_PATH + LIST_PATH);
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const sendPaymentNotifications = async () => {
    try {
        const result = await intance.get(PAYMENTS_PATH + SEND_PATH);
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}