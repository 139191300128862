import axios, { AxiosError } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, TAX_TYPE_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const getTaxTypesService = async () => {
    try {
        const result = await intance.get(TAX_TYPE_PATH + LIST_PATH);
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createTaxTypeService = async (taxType: any) => {
    try {
        const result = await intance.post(TAX_TYPE_PATH, taxType)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteTaxTypeService = async (id: any) => {
    try {
        const result = await intance.delete(TAX_TYPE_PATH + `/${id}`)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editTaxTypeService = async (id: number, taxType: any) => {
    try {
        const result = await intance.put(TAX_TYPE_PATH + `/${id}`, taxType)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}
