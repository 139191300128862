import { Avatar, Button, Checkbox, Divider, List, ListItem, ListItemAvatar, ListItemText, Paper, Snackbar, Tab, Tabs, TextField, Typography, ListItemSecondaryAction } from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { ManagementContext } from 'context'
import { ROOT_TITLE, EXCEPTION_TITLE, EXCEPTION_TYPES, createExceptionService, getExceptionsService, deleteExceptionService } from 'lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { IException, IMessageConfig, ISupplier } from 'types'
import { ConfirmDialog, FixedLoadingIndicator, Search } from 'components'
import { useInputValue } from 'hooks'

interface Props {

}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const Supplier = ({ supplier, exception, setDeleteId }: { supplier: ISupplier | undefined, exception: IException, setDeleteId: React.Dispatch<React.SetStateAction<number>> }) => {
    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    {supplier?.comercialName.charAt(0).toUpperCase()}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${supplier?.comercialName}`} secondary={supplier?.identification} />
            <ListItemSecondaryAction>
                <Button onClick={() => setDeleteId(exception.exceptionId)} variant="contained" disableElevation size="small">
                    <Typography className="px-2" variant="caption">
                        {"Quitar de la lista de excepciones"}
                    </Typography>
                </Button>
            </ListItemSecondaryAction>
        </ListItem>
    )
}

const Exceptions = (props: Props) => {
    const [type, setType] = React.useState(0)
    const { suppliers } = React.useContext(ManagementContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const [deleteId, setDeleteId] = React.useState<number>(-1)
    const [list, setList] = React.useState<IException[]>([])
    const { value: query, clearValue: handleClear, onChange: handleChange } = useInputValue()

    const handleAdd = async (selected: ISupplier) => {
        try {
            setLoading(true)
            await createExceptionService({ supplierId: selected.supplierId, type })
            await getExceptions()
            setLoading(false)
            setMessageConfig({ open: true, message: "Proveedor agregado a lista de excepciones!", severity: "success" })
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo agregar el proveedor selecionado...", severity: "error" })
        }
    }

    const getExceptions = React.useCallback(async () => {
        try {
            setLoading(true)
            const result = await getExceptionsService()
            setList(result)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }, [])

    const getExceptionsByType = React.useCallback(() => {
        return list.filter(item => item.type === type)
    }, [list, type])

    const handleDelete = async () => {
        try {
            setLoading(true)
            await deleteExceptionService(deleteId)
            setLoading(false)
            await getExceptions()
            setMessageConfig({ open: true, message: "Regla de excepción eliminada!", severity: "success" })
            return true
        } catch (error) {
            setMessageConfig({ open: true, message: "No se pudo eliminar la excepción...!", severity: "error" })
            setLoading(false)
            return false
        }
    }

    React.useEffect(() => {
        getExceptions()
    }, [getExceptions])

    return (
        <Paper className="flex flex-col h-full overflow-hidden pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${EXCEPTION_TITLE}`}</title>
            </Helmet>
            <div className="flex w-full items-center">
                <Tabs
                    value={type}
                    onChange={(_, newValue) => {
                        setType(newValue)
                        handleClear()
                    }}
                    textColor='primary'
                    indicatorColor='primary'
                    centered
                    style={{ width: "68%" }}
                    variant="fullWidth"
                >
                    {
                        EXCEPTION_TYPES.map(exception => (
                            <Tab label={exception.name} />
                        ))
                    }
                </Tabs>
                <Autocomplete
                    multiple
                    options={suppliers.filter(s => !list.map(e => e.supplierId).includes(s.supplierId))}
                    disableCloseOnSelect
                    getOptionLabel={(supplier) => `${supplier.companyName}`}
                    limitTags={2}
                    value={suppliers.filter(s => getExceptionsByType().map(e => e.supplierId).includes(s.supplierId))}
                    openOnFocus
                    disableClearable
                    size="small"
                    style={{ width: "28%", marginLeft: "3%" }}
                    ChipProps={{ size: "small", style: { maxWidth: 90 } }}
                    renderTags={() => <React.Fragment />}
                    disabled={loading}
                    onChange={(_, _values, reason, value) => {
                        if (reason === "select-option") {
                            const selected = value?.option
                            if (selected) {
                                handleAdd(selected)
                            }
                        }
                    }}
                    renderOption={(supplier, { selected }) => {
                        return (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8, height: 50 }}
                                    checked={selected}
                                    disabled={loading}
                                />
                                {`${supplier.companyName}`}
                            </React.Fragment>
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            placeholder={"Selecciona para agregar"}
                        />
                    )}
                />
            </div>
            <div className="w-full">
                <Divider />
            </div>
            <div className="w-full px-4 py-4 flex justify-between items-center">
                <Search
                    onChange={handleChange}
                    query={query}
                    onClear={handleClear}
                    placeholer="Nombre o identificación"
                />
                <Typography
                    color='textSecondary'
                    align='right'
                >
                    {EXCEPTION_TYPES[type]?.description}
                </Typography>
            </div>
            <div className="flex h-full flex-col overflow-hidden">
                <div className="flex flex-grow overflow-auto">
                    <List style={{ width: '100%' }}>
                        {
                            getExceptionsByType().map(exception => {
                                const supplier = suppliers.find(s => s.supplierId === exception.supplierId)
                                return (
                                    <React.Fragment>
                                        {
                                            Boolean(query) ?
                                                <React.Fragment>
                                                    {
                                                        (supplier?.identification.includes(query) || supplier?.comercialName?.toUpperCase().includes(query.toUpperCase())) ?
                                                            <Supplier
                                                                supplier={supplier}
                                                                exception={exception}
                                                                setDeleteId={setDeleteId}
                                                            />
                                                            :
                                                            undefined
                                                    }
                                                </React.Fragment>
                                                :
                                                <Supplier
                                                    supplier={supplier}
                                                    exception={exception}
                                                    setDeleteId={setDeleteId}
                                                />
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </List>
                </div>
            </div>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading} />
            <ConfirmDialog
                onCancel={() => setDeleteId(-1)}
                onConfirm={handleDelete}
                open={Boolean(deleteId !== -1)}
                title={"¿Estás seguro que deseas eliminar?"}
                loading={loading}
            />
        </Paper>
    )
}

export default Exceptions
