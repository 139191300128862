import React from 'react'

interface Hook<T> {
    filters: T
    handleChangeFilters: (key: keyof T, value: any) => void
    restartFilters: () => void
}

interface Props<T> {
    initialFilters: T
}

const useFilters = <T,>(props: Props<T>): Hook<T> => {
    const { initialFilters } = props
    const [filters, setFilters] = React.useState<T>(initialFilters)
    const handleChangeFilters = (key: keyof T, value: any) => {
        setFilters(current => ({ ...current, [key]: value }))
    }
    const restartFilters = () => setFilters(initialFilters)
    return {
        filters,
        handleChangeFilters,
        restartFilters
    }
}

export default useFilters