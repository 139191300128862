import { Button, CircularProgress, Divider, FormControlLabel, FormGroup, Switch, TextField, Typography, Icon, IconButton } from '@material-ui/core'
import { useValues } from 'hooks'
import { FIELDS } from 'lib'
import React from 'react'
import { useLocation } from 'react-router'
import { ITaxType } from 'types'
import { createTaxTypeService, editTaxTypeService } from '../../lib/utils/api/taxType';

interface Props {
    onSuccess: () => void
    onError: () => void
    onClose: () => void
}

const TaxTypeForm = (props: Props) => {
    const location = useLocation()
    const { onError, onSuccess, onClose } = props
    const initialInput = React.useRef<any>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const { values: taxType, updateValue: handleChange, updateValues } = useValues()
    const handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            if (!(location.state as any)?.edit) {
                if (!taxType.forRetention) {
                    taxType.forRetention = false;
                }
                await createTaxTypeService({ ...taxType })
            }
            else {
                const edited = { ...taxType }
                await editTaxTypeService(taxType[FIELDS.taxId.key], { ...edited })
            }
            setLoading(false)
            if (!(location.state as any)?.edit) {
                updateValues({
                    description: "",
                    name: "",
                    forRetention: false
                } as ITaxType)
                initialInput.current?.focus()
            }
            onSuccess()
        } catch (error) {
            setLoading(false)
            onError()
        }
    }
    React.useEffect(() => {
        if ((location.state as any)?.edit) {
            if ((location.state as any)?.values) {
                updateValues((location.state as any)?.values)
            }
        }
    }, [location.state, updateValues])
    return (
        <div className="p-4">
            <div className="flex items-center justify-between">
                <Typography variant="body2" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                    {(location.state as any)?.edit ? "Editar Impuesto" : "Nuevo Impuesto"}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <Icon fontSize="small">close</Icon>
                </IconButton>
            </div>
            <form onSubmit={handleSumbit} className="pt-4 flex flex-col" style={{ width: 300 }}>
            <TextField
                    inputRef={initialInput}
                    autoFocus
                    size="small"
                    label={FIELDS.taxCode.name}
                    variant="outlined"
                    disabled={(location.state as any)?.edit ? true : false}
                    fullWidth
                    required
                    value={taxType[FIELDS.taxCode.key]}
                    onChange={(e) => handleChange(FIELDS.taxCode.key, e.target.value)}
                />
                <TextField
                 style={{ marginTop: 10 }}
                    size="small"
                    label={FIELDS.name.name}
                    variant="outlined"
                    fullWidth
                    required
                    value={taxType[FIELDS.name.key]}
                    onChange={(e) => handleChange(FIELDS.name.key, e.target.value)}
                />
                <TextField
                    size="small"
                    label={FIELDS.description.name}
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 10 }}
                    multiline
                    onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault() } }}
                    rows={3}
                    required
                    value={taxType[FIELDS.description.key]}
                    onChange={(e) => handleChange(FIELDS.description.key, e.target.value)}
                />

                <FormGroup className="p-2" row>
                    <FormControlLabel
                        control={<Switch color="primary" size="small" checked={Boolean(!!taxType[FIELDS.forRetention.key])} onChange={(e) => handleChange(FIELDS.forRetention.key, e.target.checked)} name={FIELDS.forRetention.name} />}
                        label={FIELDS.forRetention.name}
                    />
                </FormGroup>
                <p>{taxType[FIELDS.active.key]}</p>
                <div className="mt-4">
                    <Divider />
                </div>
                <div className="flex justify-end pt-3">
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        disableElevation
                        disabled={loading}
                        type="submit"
                    >
                        {loading ? <CircularProgress style={{ color: "white" }} size={16} className="my-1" thickness={10} /> : (location.state as any)?.edit ? "Guardar" : "Registrar"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default TaxTypeForm
