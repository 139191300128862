import { getExceptionsService } from 'lib'
import React from 'react'
import { IException } from 'types'

type Hook = {exceptions: IException[]}

const useGetExceptions = ():Hook => {
  const [exceptions, setExceptions] = React.useState<IException[]>([])

  React.useEffect(() => {
    const init = async () => {
        try {
            const result = await getExceptionsService()
            setExceptions(result)
        } catch (error) {
            console.log(error)
        }
    }
    init()
  }, [])
  
  return {exceptions}
}

export default useGetExceptions