import axios, { AxiosError } from 'axios'
import { AUTHENTICATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, MODULE_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL:  AUTHENTICATION_API_SERVICE_DOMAIN})

export const getModuleService = async () => {
    try {
        const result = await intance.get(MODULE_PATH + LIST_PATH)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createModuleService = async (module: any) => {
    try {
        const result = await intance.post(MODULE_PATH, module)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteModuleService = async (id: any) => {
    try {
        const result = await intance.delete(MODULE_PATH + `/${id}`)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editModuleService = async (id: number, module: any) => {
    try {
        const result = await intance.put(MODULE_PATH + `/${id}`, module)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}