import axios, { AxiosError } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, CATEGORY_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const getCategoriesService = async () => {
    try {
        const result = await intance.get(CATEGORY_PATH + LIST_PATH)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createCategoryService = async (category: any) => {
    try {
        const result = await intance.post(CATEGORY_PATH, category)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteCategoryService = async (id: any) => {
    try {
        const result = await intance.delete(CATEGORY_PATH + `/${id}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editCategoryService = async (id: number, category: any) => {
    try {
        const result = await intance.put(CATEGORY_PATH + `/${id}`, category)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}