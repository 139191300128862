import React from 'react'
import { IDocumentReportParams } from 'types'

interface Props {

}

interface Hook {
    params: IDocumentReportParams
    handleChangeParams: (key: string, value: any) => void
}

const useReportParams = (props?: Props): Hook => {
    const [params, setParams] = React.useState<IDocumentReportParams>({
        ats: { yearMonth: new Date() },
        simple: {
            conditions: [],
            documentEnd: new Date(),
            documentStart: new Date(),
            types: []
        },
        tax: { yearMonth: new Date() }
    })
    const handleChangeParams = (key: string, value: any) => {
        setParams(current => ({
            ...current,
            [key]: value
        }))
    }
    return {
        params,
        handleChangeParams
    }
}

export default useReportParams
