import { CircularProgress, List, ListItem, ListItemText, Typography, Icon, IconButton, ListItemSecondaryAction, Collapse, TableCell, TableRow, ListItemIcon, Checkbox } from '@material-ui/core'
import { DocumentContext, ManagementContext } from 'context'
import { getTaxPlanSupplierBySupplierIdService } from 'lib'
import React from 'react'
import { ITaxPlan, ITaxPlanSupplier } from 'types'

interface Props {
    selected: ITaxPlan | undefined
    setSelected: React.Dispatch<ITaxPlan | undefined>
    supplierId?: number
    withReimbursementTaxPlan: boolean
}

const TaxPlanPicker = (props: Props) => {
    const { supplierId, selected, setSelected, withReimbursementTaxPlan } = props
    const [taxPlanSupplier, setTaxPlanSupplier] = React.useState<ITaxPlanSupplier[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const { taxPlans, taxTypes } = React.useContext(ManagementContext)
    const { reimbursementTaxPlan } = React.useContext(DocumentContext)
    const getTaxPlans = React.useCallback(() => {
        const taxPlanIds = taxPlanSupplier.map(t => t.taxPlanId)
        return taxPlans.filter(t => (taxPlanIds.includes(t.taxPlanId) && !t.forCustomers))
    }, [taxPlanSupplier, taxPlans])

    const TaxPlanOption = ({ t, onSelect: handleSelect, selected }: { t: ITaxPlan, onSelect: (s: ITaxPlan) => void, selected: ITaxPlan | undefined }) => {
        const [open, setOpen] = React.useState(false)
        return (
            <React.Fragment>
                <ListItem
                    button
                    onClick={() => handleSelect(t)}
                >
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={selected === t}
                            tabIndex={-1}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemText primary={`${t.name}`} />
                    <ListItemSecondaryAction>
                        <IconButton onClick={() => setOpen(current => !current)}>
                            <Icon>{open ? "expand_less" : "expand_more"}</Icon>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <div className="pl-16">
                        {
                            t.taxCodes.map((row) => {
                                return (
                                    <TableRow
                                        key={row.taxId}
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        selected
                                        style={{ width: "100%", fontSize: "0.8em" }}
                                    >
                                        <TableCell padding="none" align="left">{`${taxTypes.find(t => t.taxId.toString() === row.taxTypeId.toString())?.name} ${row.taxCode} ${row.description} ${row.percentage.toFixed(2)}%`}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </div>
                </Collapse>
            </React.Fragment>
        )
    }

    React.useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const result = await getTaxPlanSupplierBySupplierIdService(supplierId)
                setTaxPlanSupplier(result)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        init()
    }, [supplierId])

    React.useEffect(() => {
        if (withReimbursementTaxPlan && reimbursementTaxPlan) {
            setSelected(reimbursementTaxPlan)
        }
    }, [withReimbursementTaxPlan, reimbursementTaxPlan, setSelected])

    return (
        <div style={{ width: "40vw", height: "40vh" }} className="w-full h-full flex justify-center items-center">
            {
                loading ?
                    <CircularProgress
                        size={40}
                        thickness={15}
                    />
                    :
                    <React.Fragment>
                        <div className="w-full h-full p-6">
                            <Typography style={{ letterSpacing: 0.7, fontWeight: 600 }} variant="body2">
                                {taxPlanSupplier.length === 0 ? "Plan de impuestos disponibles:" : "Plan de impuestos asignados al proveedor:"}
                            </Typography>
                            <List style={{ maxHeight: "30vh", overflowY: "auto" }}>
                                {
                                    (withReimbursementTaxPlan ? [reimbursementTaxPlan] : taxPlanSupplier.length === 0 ? taxPlans : getTaxPlans()).map(t => {
                                        return (
                                            <TaxPlanOption
                                                t={t}
                                                onSelect={(selectedTaxPlan) => setSelected(selectedTaxPlan)}
                                                selected={selected}
                                            />
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </React.Fragment>
            }
        </div>
    )
}

export default TaxPlanPicker
