import { Paper, Typography, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { ManagementContext } from 'context'
import { formatCurrency } from 'lib'
import React from 'react'
import { IRetention } from 'types'

interface Props {
    retentions: IRetention[]
}

const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2 }

const WithholdingsData = (props: Props) => {
    const { retentions } = props
    const { taxTypes } = React.useContext(ManagementContext)
    return (
        <div className="px-8 w-full">
            <Paper variant="outlined" className="mt-3 w-full" elevation={1}>
                <div className="py-3 px-4 flex w-full items-center">
                    <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                        {"RETENCIONES"}
                    </Typography>
                </div>
                <Divider />
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle} align="left">{"Tipo retención"}</TableCell>
                                <TableCell style={headerCellStyle} align="right">{"Base imponible"}</TableCell>
                                <TableCell style={headerCellStyle} align="right">{"Valor retenido"}</TableCell>
                                <TableCell style={headerCellStyle} align="right">{"Porcentaje"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                retentions.map(retention => (
                                    <TableRow>
                                        <TableCell style={bodyCellStyle} align="left">{taxTypes.find(t => t.taxCode.toString() === retention.code) ? `${taxTypes.find(t => t.taxCode.toString() === retention.code)?.name} - ${retention.retentionCode}` : retention.retentionCode}</TableCell>
                                        <TableCell style={bodyCellStyle} align="right">{formatCurrency(retention.subtotal)}</TableCell>
                                        <TableCell style={bodyCellStyle} align="right">{formatCurrency(retention.amount)}</TableCell>
                                        <TableCell style={bodyCellStyle} align="right">{`${retention.percentage.toFixed(2)}%`}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default WithholdingsData
