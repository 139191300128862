import { Paper, TableContainer, Table, TableRow, TableCell, TableBody, Typography, Divider } from '@material-ui/core'
import React from 'react'
import { IDocumentAditional } from 'types'

interface Props {
    additionals: IDocumentAditional[]
}

const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2, minWidth: 200 }

const AdditionalData = (props: Props) => {
    const { additionals } = props
    return (
        <Paper variant="outlined" className="mt-3" elevation={1}>
            <div className="p-4 px-6">
                <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                    {"INFORMACIÓN ADICIONAL"}
                </Typography>
            </div>
            <Divider />
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        {
                            additionals.map(additional => (
                                <TableRow>
                                    <TableCell style={bodyCellStyle} align="left">{additional.key}</TableCell>
                                    <TableCell style={bodyCellStyle} align="right">{additional.value}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default AdditionalData
