import React from "react"
import { Route } from "react-router-dom"
import { AuthContext } from "context"
import { RETENTION_PATH, REJECTED_PATH, HISTORY_PATH, DOCUMENT_TYPE_IDS, PURCHASE_SETTLEMENT_PATH } from "lib"
import { History, Rejected as RejectedDocuments } from "pages"

const Rejected = () => {
    const { user } = React.useContext(AuthContext)
    return (
        <React.Fragment>
            {
                Boolean(user) &&
                <React.Fragment>
                    <Route strict path={`${REJECTED_PATH}${RETENTION_PATH}`} component={() => <RejectedDocuments initialType={DOCUMENT_TYPE_IDS.retentionReceipt} />} />
                    <Route strict path={`${REJECTED_PATH}${PURCHASE_SETTLEMENT_PATH}`} component={() => <RejectedDocuments initialType={DOCUMENT_TYPE_IDS.purchaseSettlement} />} />
                    <Route strict path={`${REJECTED_PATH}${HISTORY_PATH}`} component={History} />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Rejected
