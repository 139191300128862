import React from 'react'
import { Button, Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText, Paper, Snackbar, TablePagination, Typography } from '@material-ui/core'
import { FixedLoadingIndicator, LabelDisplayedRows, Search, ServerErrorHandler } from 'components'
import { useInputValue, usePagination } from 'hooks'
import { AUXILIAR_MODULE, ROOT_TITLE, USER_MODULE_TITLE, getUserModulesByModuleIdService, createUserModulesMassiveService } from 'lib'
import { Helmet } from 'react-helmet'
import { ManagementContext } from 'context'
import { IMessageConfig, IModule, IUser, IUserModule } from 'types'
import { Alert, Skeleton } from '@material-ui/lab'
import Lottie from 'react-lottie'
import checklist from 'assets/animations/checklist.json'

const UserModule = () => {
    const { modules, refreshModules, refreshUsers, users } = React.useContext(ManagementContext)
    const { value: modulesQuery, clearValue: handleClearModulesSearch, onChange: handleChangeModulesSearch } = useInputValue()
    const { value: usersQuery, clearValue: handleClearUsersSearch, onChange: handleChangeUsersSearch } = useInputValue()
    const [updating, setUpdating] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const [selectedModules, setSelectedModules] = React.useState<IModule[]>([])
    const [userModules, setUserModules] = React.useState<IUserModule[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } = usePagination()
    const toggleModule = async (module: IModule) => {
        const selected = selectedModules.includes(module)
        if (selected) {
            setSelectedModules(current => current?.filter(m => m.moduleId !== module.moduleId))
            setUserModules(current => current.filter(c => c.moduleModuleId !== module.moduleId))
        } else {
            setLoading(true)
            const result = await getUserModulesByModuleIdService(module.moduleId)
            setUserModules(current => current.concat(result))
            setSelectedModules(current => [...current, module])
            setLoading(false)
        }
    }
    const toggleUser = (user: IUser) => {
        const addedIds = userModules.map(u => u.userUserId)
        if (addedIds.includes(user.userId)) {
            setUserModules(current => current.filter(c => c.userUserId !== user.userId))
        } else {
            setUserModules(current => [...current, { moduleModuleId: AUXILIAR_MODULE, userUserId: user.userId }])
        }
    }
    const handleSave = async () => {
        try {
            const modules = selectedModules.map(m => m.moduleId).filter(m => m !== AUXILIAR_MODULE)
            const users = Array.from(new Set(userModules.map(u => u.userUserId)))
            setUpdating(true)
            await createUserModulesMassiveService({ users, modules })
            setMessageConfig({ open: true, message: "Módulos y usuarios actualizados!", severity: "success" })
            setUpdating(false)
        } catch (error) {
            setUpdating(false)
            setMessageConfig({ open: true, message: "No se pudo actualizar módulos y usuarios...", severity: "error" })
        }
    }
    const getModulesModel = React.useCallback(() => {
        const src = [...modules]
        if (Boolean(modulesQuery)) {
            return src.filter((s: any) => {
                return s.name.toUpperCase().includes(modulesQuery.toUpperCase())
            })
        }
        return src
    }, [modulesQuery, modules])
    const getUsersModel = React.useCallback(() => {
        const src = [...users]
        if (Boolean(usersQuery)) {
            return src.filter((s: any) => {
                return s.name.toUpperCase().includes(usersQuery.toUpperCase()) || s.email.toUpperCase().includes(usersQuery.toUpperCase())
            })
        }
        return src
    }, [usersQuery, users])
    React.useEffect(() => {
        const initScreen = async () => {
            try {
                setLoading(true)
                await refreshModules()
                await refreshUsers()
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setError(true)
            }
        }
        initScreen()
    }, [refreshModules, refreshUsers])
    return (
        <div className="flex items-center flex h-full justify-center overflow-y-hidden py-1">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${USER_MODULE_TITLE}`}</title>
            </Helmet>
            <Paper className="flex flex-col h-full w-1/4 overflow-hidden">
                <div className="flex flex-grow flex-col p-4 overflow-y-hidden">
                    <Typography style={{ fontWeight: 600, letterSpacing: 0.75 }} variant="subtitle1" color="textSecondary" className="uppercase pb-2">
                        {"Módulos registrados"}
                    </Typography>
                    <Search
                        onChange={handleChangeModulesSearch}
                        query={modulesQuery}
                        onClear={handleClearModulesSearch}
                        placeholer={"Filtrar módulos por nombre..."}
                        width="100%"
                        disabled={loading || updating}
                    />
                    <div className="mt-4">
                        <Divider />
                    </div>
                    <div className="flex-grow overflow-y-auto">
                        <List className="w-full" dense component="div" role="list">
                            {
                                getModulesModel().map(module => (
                                    <ListItem
                                        disabled={loading || updating}
                                        onClick={() => toggleModule(module)}
                                        key={module.relativePath}
                                        role="listitem" button
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={selectedModules.includes(module)}
                                                tabIndex={-1}
                                                disableRipple
                                                size="small"
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={module.name} primary={`${module.name}`} />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </div>
                    <div>
                        <Divider />
                    </div>
                </div>
            </Paper>
            <Paper className="flex-flex-col h-full p-4 w-2/3 ml-6">
                {
                    (Boolean(selectedModules.length) && !loading) ?
                        <div className="w-full h-full flex flex-col">
                            <Typography style={{ fontWeight: 600, letterSpacing: 0.75, marginBottom: 10 }} variant="subtitle1" color="textSecondary" className="uppercase pb-4 line-clamp overflow-hidden">
                                {`Usuarios agregados a ${selectedModules.map(s => s.name).join(", ")}`}
                            </Typography>
                            <Search
                                onChange={handleChangeUsersSearch}
                                query={usersQuery}
                                onClear={handleClearUsersSearch}
                                placeholer={"Filtrar usuarios por nombre o correo electrónico..."}
                                width="50%"
                                disabled={loading || updating}
                            />
                            <div className="mt-4">
                                <Divider />
                            </div>
                            <div className="flex-grow overflow-y-auto w-full mb-4">
                                <List className="w-full">
                                    {
                                        (rowsPerPage > -1 ? getUsersModel().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : getUsersModel()).map((user) => {
                                            return (
                                                <div
                                                    className="flex w-full my-2"
                                                >
                                                    <ListItem
                                                        classes={{ container: "w-full", button: "button-p-0" }}
                                                        disabled={loading || updating}
                                                        onClick={() => toggleUser(user)}
                                                        key={user.userId}
                                                        role="listitem" button
                                                    >
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                checked={userModules.map(u => u.userUserId).includes(user.userId)}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                size="small"
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText primary={user.name} secondary={user.email} />
                                                    </ListItem>
                                                </div>
                                            )
                                        })
                                    }
                                </List>
                            </div>
                            <div className="px-4 flex items-center justify-between">
                                <div>
                                    <Divider />
                                    <TablePagination
                                        rowsPerPageOptions={[10, 15, 25, { label: 'Todos', value: -1 }]}
                                        colSpan={3}
                                        count={getUsersModel().length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        labelRowsPerPage="Usuarios por página"
                                        labelDisplayedRows={LabelDisplayedRows}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                                <Button
                                    disableElevation
                                    disabled={updating}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSave}
                                >
                                    {"Guardar cambios"}
                                </Button>
                            </div>
                        </div>
                        :
                        <div className="w-full h-full flex flex-col items-center justify-center p-8">
                            {
                                loading ?
                                    <Skeleton width="100%" height="50%" variant="text" animation="wave" />
                                    :
                                    <React.Fragment>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: checklist,
                                                rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                                            }}
                                            height={300}
                                            width={300}
                                        />
                                        <Typography color="textSecondary" variant="h6" align="center">
                                            {"Seleciona un módulo para poder administrar sus usuarios"}
                                        </Typography>
                                    </React.Fragment>
                            }
                        </div>
                }
            </Paper>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading || updating} />
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={async () => { try { await refreshModules(); await refreshUsers() } catch (e) { throw new Error("") } }}
            />
        </div>
    )
}

export default UserModule
