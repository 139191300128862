import axios, { AxiosError } from 'axios'
import { AUTHENTICATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, VIEW_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: AUTHENTICATION_API_SERVICE_DOMAIN })

export const getViewsService = async () => {
    try {
        const result = await intance.get(VIEW_PATH + LIST_PATH)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}