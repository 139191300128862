import { Dialog, DialogTitle, DialogActions, Button, CircularProgress } from '@material-ui/core'
import React from 'react'

interface Props {
    open: boolean
    loading?: boolean
    onCancel: () => void
    onConfirm: () => Promise<boolean>
    title: string
}

const ConfirmDialog = (props: Props) => {
    const { open, onCancel, onConfirm, loading, title } = props
    const handleConfirm = async () => {
        try {
            if (await onConfirm()) {
                onCancel()
            }
        } catch (error) {

        }
    }
    return (
        <Dialog
            open={open}
            onClose={(_, reason) => {
                if (reason === "backdropClick" && loading) { return }
                onCancel()
            }}
            disableEscapeKeyDown={loading}
        >
            <DialogTitle style={{ maxWidth: "80%", wordBreak: "break-word" }}>{title}</DialogTitle>
            <DialogActions>
                <Button disabled={loading} size="small" onClick={onCancel}>
                    {"No"}
                </Button>
                <Button disableElevation disabled={loading} size="small" onClick={handleConfirm} variant="contained" color="primary">
                    {loading ? <CircularProgress style={{ color: "white" }} size={15} className="my-1" thickness={8} /> : "Si"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog
