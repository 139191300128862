import { IReimbursement, IReimbursementSummary, IReimbursementValues } from "types"

export const calculateReimbusementsSummary = (reimbursements: IReimbursement[]): IReimbursementSummary => {
    return {
        nonTaxesSupport: calculateReimbursementsValues(reimbursements.filter(r => !Boolean(r.taxSupport))),
        taxSupport: calculateReimbursementsValues(reimbursements.filter(r => Boolean(r.taxSupport)))
    }
}

const calculateReimbursementValuesReducer = (reimbursements: IReimbursement[], key: Extract<keyof IReimbursement, string>): number => { 
    return reimbursements
        .map(s => parseFloat(Boolean(s[key]) ? s[key] as string : "0"))
        .reduce(
            (a, b) => parseFloat(a?.toString() ?? 0) + parseFloat(b?.toString() ?? 0)
        , 0) 
}

export const calculateReimbursementsValues = (reimbursements: IReimbursement[]): IReimbursementValues => {
    let iva: number = calculateReimbursementValuesReducer(reimbursements, 'iva')
    let iva15: number = calculateReimbursementValuesReducer(reimbursements, 'iva15')
    let subtotalNoTaxesObject: number = calculateReimbursementValuesReducer(reimbursements, 'subtotalNoTaxesObject')
    let subtotalRateZero: number = calculateReimbursementValuesReducer(reimbursements, 'subtotalRateZero')
    let subtotalRateTwelve: number = calculateReimbursementValuesReducer(reimbursements, 'subtotalRateTwelve')
    let subtotalRateQuince: number = calculateReimbursementValuesReducer(reimbursements, 'subtotalRateQuince')
    let total: number = iva + subtotalNoTaxesObject + subtotalRateTwelve + subtotalRateZero
    return {
        iva,
        subtotalNoTaxesObject,
        subtotalRateTwelve,
        subtotalRateZero,
        subtotalRateQuince,
        iva15,
        total
    }
}