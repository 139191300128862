import { AuthContext } from 'context'
import { PAYMENTS_PATH } from 'lib'
import React from 'react'
import { Route } from 'react-router-dom'
import { Payments as PaymentsDashboard } from 'pages'

type Props = {}

const Payments = (props: Props) => {
    const { user } = React.useContext(AuthContext)
    return (
        <React.Fragment>
            {
                Boolean(user) &&
                <React.Fragment>
                    <Route strict path={`${PAYMENTS_PATH}`} component={PaymentsDashboard} />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Payments