import axios, { AxiosError } from 'axios'
import { DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { IPurchaseOrderProcessedFilters } from 'types'
import { CONDITIONS_PARAM, CREATE_PATH, PURCHASE_ORDER_PATH, SEARCH_PARAM, APPROVE_PATH, REJECT_PATH, REPORT_PATH, STATUS_PATH, ASSIGN_PATH, AND_PARAM, PAGE_SIZE_PARAM, OFFSET_PARAM, RECEIVER_RUC_PARAM, SIMPLIFIED_PATH, USER_ID_PARAM_END } from '../../constants/paths'

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

export const getPurchaseOrdersService = async (filters: IPurchaseOrderProcessedFilters, pageSize: number, offset: number, userId: number) => {
    try {
        const result = await intance.get(
            PURCHASE_ORDER_PATH + SEARCH_PARAM
            + CONDITIONS_PARAM + filters.conditions + AND_PARAM
            + PAGE_SIZE_PARAM + pageSize + AND_PARAM
            + OFFSET_PARAM + offset + AND_PARAM
            + USER_ID_PARAM_END + userId
        )
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const savePurchaseOrderService = async (purchaseOrder: any, userId: any) => {
    try {
        const result = await intance.post(PURCHASE_ORDER_PATH, { ...purchaseOrder, userId })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createPurchaseOrderService = async (id: number, purchaseOrder: any, userId: any) => {
    try {
        const result = await intance.post(PURCHASE_ORDER_PATH + CREATE_PATH + `/${id}`, { ...purchaseOrder, userId })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deletePurchaseOrderService = async (id: number) => {
    try {
        const result = await intance.delete(PURCHASE_ORDER_PATH + `/${id}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const approvePurchaseOrderService = async (id: number, purchaseOrder: any, userId: any) => {
    try {
        const result = await intance.put(PURCHASE_ORDER_PATH + APPROVE_PATH + `/${id}`, { ...purchaseOrder, userId })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const assignPurchaseOrderService = async (id: number, userId: any, invoiceId: any) => {
    try {
        const result = await intance.put(PURCHASE_ORDER_PATH + ASSIGN_PATH + `/${id}/${userId}/${invoiceId}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const rejectPurchaseOrderService = async (id: number, purchaseOrder: any, userId: any) => {
    try {
        const result = await intance.put(PURCHASE_ORDER_PATH + REJECT_PATH + `/${id}`, { ...purchaseOrder, userId })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const updatePurchaseOrderService = async (id: number, purchaseOrder: any, userId: any) => {
    try {
        const result = await intance.put(PURCHASE_ORDER_PATH + `/${id}`, { ...purchaseOrder, userId })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getPurchaseOrderByIdService = async (id: any) => {
    try {
        const result = await intance.get(PURCHASE_ORDER_PATH + "/" + id)
        return result.data
    } catch (error: any) {
        console.log(error)
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const downloadPurchaseOrderReport = async () => {
    try {
        const result = await intance.get(PURCHASE_ORDER_PATH + REPORT_PATH, { responseType: "blob" })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const updatePurchaseOrderStatusService = async (documentId: any, statusId: any, userId: any) => {
    try {
        const result = await intance.post(PURCHASE_ORDER_PATH + STATUS_PATH, { documentId, statusId, userId })
        return result.data
    } catch (error: any) {
        console.log(error)
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getSimplifiedPurchaseOrdersServiceByFilters = async (conditions: string[], receiverRuc: any) => {
    try {
        const result = await intance.get(
            PURCHASE_ORDER_PATH + SIMPLIFIED_PATH + SEARCH_PARAM
            + CONDITIONS_PARAM + conditions.join(",") + AND_PARAM
            + RECEIVER_RUC_PARAM + receiverRuc
        )
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}