import { Button, Dialog, DialogActions, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@material-ui/core'
import { useGetLast, useTableModel } from 'hooks'
import { ROOT_TITLE, HISTORY_TITLE, HISTORY_REJECTED_TABLE_COLUMNS, MEDIUM_ROWS_PER_PAGINATION_OPTIONS, getRejectedHistoryService, sendRejectedFileFromEmailService, FIELDS, REJECTED_INITIAL_PAGINATION, uploadRejectedFileService } from 'lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import { IRejectedHistory } from 'types'
import moment from 'moment'
import 'moment/locale/es'
import { FileUploadDialog, FixedLoadingIndicator, LabelDisplayedRows } from 'components'
import Lottie from 'react-lottie'
import emailing from 'assets/animations/emailing.json'

moment.locale("es")

interface Props {

}

const History = (props: Props) => {
    const [loading, setLoading] = React.useState(false)
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage } = useTableModel()
    const [list, setList] = React.useState<IRejectedHistory[]>([])
    const [total, setTotal] = React.useState<number>(0)
    const [open, setOpen] = React.useState(false)
    const [openFile, setOpenFile] = React.useState(false)
    const { getLast, LastAlert } = useGetLast()

    const handleNewPage = async (pageNumber: number, offset: number) => {
        try {
            setLoading(true)
            const result = await getRejectedHistoryService(pageNumber, offset)
            setList(current => current.concat(result.history))
            setTotal(result.total)
            getLast()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleRefresh = async () => {
        try {
            setLoading(true)
            const result = await getRejectedHistoryService(REJECTED_INITIAL_PAGINATION.offset, REJECTED_INITIAL_PAGINATION.page)
            setList(result.history)
            setTotal(result.total)
            getLast()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleSend = async () => {
        try {
            setLoading(true)
            await sendRejectedFileFromEmailService()
            getLast()
            setLoading(false)
            setOpen(true)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleUpload = async (file: File) => {
        try {
            setLoading(true)
            await uploadRejectedFileService(file)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const result = await getRejectedHistoryService(REJECTED_INITIAL_PAGINATION.offset, REJECTED_INITIAL_PAGINATION.page)
                setList(result.history)
                setTotal(result.total)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        init()
    }, [])

    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${HISTORY_TITLE}`}</title>
            </Helmet>
            <div className="w-full p-2 flex items-center justify-end">
                <div className="mr-2 flex flex-grow">
                    <LastAlert />
                </div>
                <div className="flex flex-row">
                    <Button
                        variant="contained"
                        disableElevation
                        size="small"
                        style={{ width: 200, marginLeft: 10 }}
                        onClick={handleRefresh}
                    >
                        {"Recargar"}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        size="small"
                        style={{ width: 200, marginLeft: 10 }}
                        onClick={handleSend}
                    >
                        {"Consultar correo"}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disableElevation
                        style={{ width: 200, marginLeft: 10 }}
                        onClick={() => setOpenFile(true)}
                    >
                        {"Subir archivo"}
                    </Button>
                </div>
            </div>
            <div className="flex items-center flex-grow">
                <div className="flex h-full flex-col mt-4 overflow-hidden w-full">
                    <TableContainer className="h-full">
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {(HISTORY_REJECTED_TABLE_COLUMNS).map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={"left"}
                                            padding={"default"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                            width={headCell.id === FIELDS.inserted.key ? "200" : ""}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : "asc"}
                                                onClick={createSortHandler(headCell.id as any)}
                                                style={{ fontSize: "0.85em" }}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === "desc"
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (rows(list) as IRejectedHistory[]).map((row, index) => (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.rejectedHistoryId + index + "rejected"}
                                        >
                                            <TableCell component="th" scope="row">
                                                {
                                                    moment(new Date(row.inserted).toISOString()).format('DD-MM-YYYY - hh:mm:ss').toString()
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div dangerouslySetInnerHTML={{__html: row.comments}}/>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={MEDIUM_ROWS_PER_PAGINATION_OPTIONS}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={async (event, pageNumber) => {
                        if (pageNumber > page && ((pageNumber + 1) * rowsPerPage) > list.length && list.length < total) {
                            await handleNewPage(pageNumber, rowsPerPage)
                        }
                        handleChangePage(event, pageNumber)
                    }}
                    onRowsPerPageChange={async (event) => {
                        if (parseInt(event.target.value, 10) > rowsPerPage && parseInt(event.target.value, 10) > list.length && list.length < total) {
                            await handleNewPage(page, parseInt(event.target.value, 10))
                        }
                        handleChangeRowsPerPage(event as any)
                    }}
                    labelRowsPerPage="Historial de rechazados por página"
                    labelDisplayedRows={LabelDisplayedRows}
                />
            </div>
            <FixedLoadingIndicator loading={loading} />
            <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogContent>
                    <div className="flex flex-col justify-center items-center p-5">
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: emailing,
                                rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                            }}
                            height={250}
                            width={300}
                        />
                        <Typography>
                            {"Se consultará en el correo electrónico si se ha recibido un reporte de rechazados."}
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" autoFocus>
                        {"Ok"}
                    </Button>
                </DialogActions>
            </Dialog>
            <FileUploadDialog
                open={openFile}
                onClose={() => setOpenFile(false)}
                onAccept={(files) => { handleUpload(files[0].file) }}
                singleFile
                hideDescription
            />
        </Paper>
    )
}

export default History
