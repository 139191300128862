import React from 'react'
import { Paper, Icon, Typography, IconButton, Tooltip, Divider, Drawer, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody } from '@material-ui/core'
import { DetailForm } from 'components'
import { DETAIL_DESCRIPTION_ONLY_TABLE_COLUMNS, DETAIL_DESCRIPTION_PURCHASE_ONLY_TABLE_COLUMNS, DETAIL_TABLE_COLUMNS, FIELDS, formatCurrency, calculateDetailSummary } from 'lib'
import { useTableModel } from 'hooks'
import { IDetail, ITax, ITaxType } from 'types'

interface Props {
    detail: IDetail[]
    onAdd: (newDetail: IDetail) => void
    onEdit: (newDetail: IDetail[]) => void
    onDelete: (newDetail: IDetail) => void
    descriptionOnly?: boolean
    loading?: boolean
    taxes: ITax[]
    taxTypes: ITaxType[]
    purchaseOrderDescriptionOnly?: boolean
    hideSummary?: boolean
    rate12TaxOnly?: boolean
}

const DocumentDetail = (props: Props) => {
    const { taxTypes, loading, taxes, detail, descriptionOnly, purchaseOrderDescriptionOnly, onAdd, onDelete, onEdit, hideSummary, rate12TaxOnly } = props
    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false)
    const [edit, setEdit] = React.useState<IDetail | undefined>(undefined)
    const { createSortHandler, order, orderBy, rows } = useTableModel()
    const getHeaderColumns = React.useCallback(() => {
        if (descriptionOnly) {
            return DETAIL_DESCRIPTION_ONLY_TABLE_COLUMNS
        }
        if (purchaseOrderDescriptionOnly) {
            return DETAIL_DESCRIPTION_PURCHASE_ONLY_TABLE_COLUMNS
        }
        return DETAIL_TABLE_COLUMNS
    }, [descriptionOnly, purchaseOrderDescriptionOnly])
    const handleEdit = (detail: IDetail) => {
        setEdit(detail)
        setOpenDrawer(true)
    }
    const getDetailSummary = React.useCallback(() => {
        return calculateDetailSummary(detail)
    }, [detail])
    return (
        <React.Fragment>
            <Paper className="h-full flex flex-col" style={{ padding: "2px 16px" }}>
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <Icon color="action">subject</Icon>
                        <Typography className="pl-2" style={{ fontWeight: 600, letterSpacing: 0.5 }} color="textSecondary" variant="body2">{"Detalles"}</Typography>
                    </div>
                    <Tooltip arrow title="Nuevo detalle">
                        <IconButton onClick={() => { setOpenDrawer(true); setEdit(undefined) }}>
                            <Icon>add</Icon>
                        </IconButton>
                    </Tooltip>
                </div>
                <Divider />
                <div className="flex flex-grow mb-2 overflow-y-auto">
                    <TableContainer>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {getHeaderColumns().map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={'left'}
                                            padding={"none"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                        >
                                            <TableSortLabel
                                                style={{ fontSize: "0.85em" }}
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id as any)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        padding={"default"}
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(detail) as IDetail[]).map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index.toString()}
                                        >
                                            {
                                                !purchaseOrderDescriptionOnly &&
                                                <TableCell component="th" scope="row" padding="none">{row.description}</TableCell>
                                            }
                                            {
                                                !descriptionOnly && !purchaseOrderDescriptionOnly &&
                                                <React.Fragment>
                                                    <TableCell padding="none" align="left">{row.quantity}</TableCell>
                                                    <TableCell padding="none" align="left">{row.unitPrice}</TableCell>
                                                    <TableCell padding="none" align="left">{(taxTypes.find(taxType => taxType.taxId === row.tax?.taxTypeId)?.name ?? "") + ' ' + (row.tax?.taxCode ?? "")}</TableCell>
                                                    <TableCell padding="none" align="left">{row.discount}</TableCell>
                                                    <TableCell padding="none" align="left">{formatCurrency((row[FIELDS.unitPrice.key] * row[FIELDS.quantity.key]) - row[FIELDS.discount.key])}</TableCell>
                                                    <TableCell padding="none" align="left">{formatCurrency(((row[FIELDS.unitPrice.key] * row[FIELDS.quantity.key]) - row[FIELDS.discount.key]) * ((row[FIELDS.tax.key] as ITax)?.percentage) / 100)}</TableCell>
                                                    <TableCell padding="none" align="left">{formatCurrency((((row[FIELDS.unitPrice.key] * row[FIELDS.quantity.key]) - row[FIELDS.discount.key]) * ((row[FIELDS.tax.key] as ITax)?.percentage) / 100) + (row[FIELDS.unitPrice.key] * row[FIELDS.quantity.key]) - row[FIELDS.discount.key])}</TableCell>
                                                </React.Fragment>
                                            }

                                            {
                                                descriptionOnly &&
                                                <React.Fragment>
                                                    <TableCell padding="none" align="left">{row.subtotalNoTaxes}</TableCell>
                                                    <TableCell padding="none" align="left">{row.tax}</TableCell>
                                                    <TableCell padding="none" align="left">{formatCurrency(parseFloat(row[FIELDS.subtotalNoTaxes.key]) + parseFloat(row[FIELDS.tax.key]))}</TableCell>
                                                </React.Fragment>
                                            }
                                            {
                                                purchaseOrderDescriptionOnly &&
                                                <React.Fragment>
                                                    <TableCell padding="none" align="left">{row.purchaseCode}</TableCell>
                                                    <TableCell padding="none" align="left">{row.description}</TableCell>
                                                    <TableCell padding="none" align="left">{row.quantity}</TableCell>
                                                </React.Fragment>
                                            }

                                            <TableCell padding="none" align="right">
                                                <Tooltip arrow title="Editar detalle">
                                                    <IconButton onClick={() => handleEdit(row)} size="small">
                                                        <Icon color="action" fontSize="small">edit</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip style={{ marginLeft: 10 }} arrow title="Eliminar detalle">
                                                    <IconButton onClick={() => onDelete(row)} size="small">
                                                        <Icon color="primary" fontSize="small">close</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                        <React.Fragment>
                            {
                                !hideSummary &&
                                <React.Fragment>
                                    <div className="flex items-center justify-end mt-6 mr-4">
                                        <Typography style={{ fontWeight: 600, letterSpacing: 0.7, marginRight: "2rem" }} color="textSecondary" variant="caption">{FIELDS.subtotal.name}</Typography>
                                        <Typography style={{ fontWeight: 600, letterSpacing: 0.7 }} variant="caption" align="right">{formatCurrency(getDetailSummary().subtotal)}</Typography>
                                    </div>
                                    <div className="flex items-center justify-end mt-2 mr-4">
                                        <Typography style={{ fontWeight: 600, letterSpacing: 0.7, marginRight: "2rem" }} color="textSecondary" variant="caption">{FIELDS.taxesValue.name}</Typography>
                                        <Typography style={{ fontWeight: 600, letterSpacing: 0.7 }} variant="caption" align="right">{formatCurrency(getDetailSummary().taxesValue)}</Typography>
                                    </div>
                                    {
                                        getDetailSummary().discount !== 0 &&
                                        <div className="flex items-center justify-end mt-2 mr-4">
                                            <Typography style={{ fontWeight: 600, letterSpacing: 0.7, marginRight: "2rem" }} color="textSecondary" variant="caption">{FIELDS.discount.name}</Typography>
                                            <Typography style={{ fontWeight: 600, letterSpacing: 0.7 }} variant="caption" align="right">{`(-) ${formatCurrency(getDetailSummary().discount)}`}</Typography>
                                        </div>
                                    }
                                    <div className="flex items-center justify-end mt-2 mr-4">
                                        <Typography style={{ fontWeight: 600, letterSpacing: 0.7, marginRight: "2rem" }} color="textSecondary" variant="caption">{FIELDS.total.name}</Typography>
                                        <Typography style={{ fontWeight: 600, letterSpacing: 0.7 }} variant="caption" align="right">{formatCurrency(getDetailSummary().total)}</Typography>
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    </TableContainer>
                </div>
            </Paper>
            <Drawer onClose={() => setOpenDrawer(false)} anchor="right" open={openDrawer}>
                <div style={{ width: 400 }} className="h-full flex flex-col">
                    <div className="flex flex-wrap items-center p-4 relative">
                        <IconButton onClick={() => setOpenDrawer(false)} className="absolute">
                            <Icon color="action">
                                {"chevron_left"}
                            </Icon>
                        </IconButton>
                        <Typography className="uppercase pl-2" color="textSecondary" variant="h6" align="center" style={{ fontWeight: 600, letterSpacing: 0.25 }}>
                            {"Nuevo detalle"}
                        </Typography>
                    </div>
                    <Divider variant="middle" />
                    <DetailForm
                        onAdd={(newDetail) => {
                            if (edit) {
                                onEdit(detail.map(d => d === edit ? newDetail : d))
                                setEdit(undefined)
                                setOpenDrawer(false)
                            } else {
                                onAdd(newDetail)
                            }
                        }}
                        onClose={() => { setOpenDrawer(false); setEdit(undefined) }}
                        descriptionOnly={descriptionOnly}
                        purchaseOrderDescriptionOnly={purchaseOrderDescriptionOnly}
                        taxes={taxes}
                        loading={loading}
                        taxTypes={taxTypes}
                        edit={edit}
                        rate12TaxOnly={rate12TaxOnly}
                    />
                </div>
            </Drawer>
        </React.Fragment>
    )
}

export default DocumentDetail

