import { Typography, Paper, Radio, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Icon, Tooltip } from '@material-ui/core'
import { } from '@material-ui/icons'
import React from 'react'
import { IPurchaseDetailSupplierInfo, ISupplier, IDetail, IDocumentFile } from 'types'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from 'moment'
import 'moment/locale/es'
import { downloadDocumentFileByIdService, FIELDS, formatCurrency, getDetailSummary, PURCHASE_ORDER_DETAIL_TABLE_COLUMNS } from 'lib'
import FileSaver from 'file-saver';

moment.locale("es")

interface Props {
    suppliersInfo: IPurchaseDetailSupplierInfo[]
    onSelect: (supplier: ISupplier, detail: IDetail[]) => void
    selected: ISupplier | undefined
    readOnly: boolean
    canSelect: boolean
    backUpFiles: IDocumentFile[]
    onDownloadStarted: () => void
}

const textStyle = { fontWeight: 600, fontSize: "0.885em", letterSpacing: 0.25 }

const ApproachPicker = (props: Props) => {
    const { selected, suppliersInfo, onSelect, readOnly, canSelect, backUpFiles, onDownloadStarted } = props
    const handleSelect = (info: IPurchaseDetailSupplierInfo) => {
        onSelect(info.supplier, info.detailBySupplierInfo)
    }
    const handleDownload = async (documentFile: IDocumentFile) => {
        const result = await downloadDocumentFileByIdService(documentFile.documentFileId)
        FileSaver.saveAs(result, documentFile.fileName ?? documentFile?.documentFileId?.toString())
        onDownloadStarted()
    }
    return (
        <React.Fragment>
            <div className="pr-4 my-2 mb-4 pl-1">
                <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                    {readOnly ? "" : "COTIZACIONES"}
                </Typography>
            </div>
            <div>
                {
                    (readOnly ? suppliersInfo.filter(s => s.supplier.supplierId === selected?.supplierId) : suppliersInfo).map(info => {
                        const backUpFile = backUpFiles.find(b => b.tag === info.supplier.supplierId.toString())
                        const getSummary = getDetailSummary(info.detailBySupplierInfo)

                        return (
                            <React.Fragment key={info.supplier.supplierId}>
                                <Paper
                                    variant={(selected?.supplierId === info.supplier.supplierId && !readOnly) ? "elevation" : "outlined"}
                                    className={`p-4 px-6 mb-5 ${(readOnly || !canSelect) ? "" : "cursor-pointer hover-bg-grey-100"} flex items-center`}
                                    elevation={5}
                                    onClick={readOnly ? undefined : canSelect ? () => handleSelect(info) : undefined}
                                    style={(selected?.supplierId === info.supplier.supplierId && !readOnly) ? { backgroundColor: "#f1f1f1" } : undefined}
                                >
                                    {
                                        !readOnly &&
                                        <Radio
                                            checkedIcon={<CheckCircleIcon />}
                                            checked={selected?.supplierId === info.supplier.supplierId}
                                            color="primary"
                                            disabled={(readOnly || !canSelect)}
                                        />
                                    }
                                    <div className={`${readOnly ? "" : "ml-6"} w-full`}>
                                        <div className="w-full flex items-center justify-between">
                                            <Typography style={textStyle}>
                                                {`${info.supplier.companyName} - ${info.supplier.identification}`}
                                            </Typography>
                                        </div>
                                        <div className="flex w-full">
                                            <div className="flex flex-col">
                                                <Typography style={{ fontWeight: 600 }} color="textSecondary" variant="caption" className="pt-2">
                                                    {`Fecha de entrega: ${moment(new Date(info.receptionDate).toISOString()).format('DD-MM-YYYY').toString()}`}
                                                </Typography>
                                                <Typography style={{ fontWeight: 600 }} color="textSecondary" variant="caption">
                                                    {`Paga en: ${info.paymentTerm} días`}
                                                </Typography>
                                                {
                                                    info.comment &&
                                                    <Typography style={{ fontWeight: 600 }} color="textSecondary" variant="caption">
                                                        {`Comentario: ${info.comment}`}
                                                    </Typography>
                                                }
                                                {
                                                    backUpFile &&
                                                    <Tooltip arrow title={"Descargar archivo de respaldo"}>
                                                        <Typography onClick={(e) => { e.stopPropagation(); handleDownload(backUpFile) }} className='pt-2 pb-6 document-link flex cursor-pointer items-center flex-grow-0' style={{ fontSize: "0.9em", letterSpacing: 0.5, width: 'fit-content' }}>
                                                            <Icon style={{ marginRight: 5 }} fontSize="small">download</Icon>
                                                            {backUpFile.fileName}
                                                        </Typography>
                                                    </Tooltip>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex flex-grow py-6">
                                            <TableContainer>
                                                <Table stickyHeader size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            {PURCHASE_ORDER_DETAIL_TABLE_COLUMNS.map((headCell) => (
                                                                <TableCell
                                                                    key={headCell.id}
                                                                    align={(headCell.id === FIELDS.unitPrice.key || headCell.id === FIELDS.quantity.key) ? 'right' : 'left'}
                                                                    padding={"none"}
                                                                    style={{ backgroundColor: "transparent" }}
                                                                >
                                                                    {headCell.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(info.detailBySupplierInfo).map((row, index) => {
                                                            return (
                                                                <TableRow
                                                                    tabIndex={-1}
                                                                    key={row.purchaseCode ?? "" + index}
                                                                >
                                                                    <TableCell style={{ maxWidth: 120 }} component="th" scope="row" padding="none">{row.purchaseCode}</TableCell>
                                                                    <TableCell padding="none" align="left">{row.description}</TableCell>
                                                                    <TableCell padding="none" align="right">{row.quantity}</TableCell>
                                                                    <TableCell padding="none" align="right">{formatCurrency(row.unitPrice ?? 0)}</TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                                <div className='w-full flex justify-end mt-6'>
                                                    <Table style={{ width: "30%" }} size="small">
                                                        <TableBody>
                                                            {getSummary.map(summary => (
                                                                <React.Fragment>
                                                                    {
                                                                        parseInt(summary.value.toString()) !== 0 &&
                                                                        <TableRow>
                                                                            <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{summary.label}</TableCell>
                                                                            <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${summary.substractsFromTotal ? "(-)" : ""} ${formatCurrency(summary.value)}`}</TableCell>
                                                                        </TableRow>
                                                                    }
                                                                </React.Fragment>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </Paper>
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}

export default ApproachPicker
