export const FIELDS = {
    name: { key: "name", name: "Nombre" },
    email: { key: "email", name: "Correo electrónico" },
    password: { key: "password", name: "Contraseña" },
    newPassword: { key: "password", name: "Nueva contraseña" },
    officeCode: { key: "officeCode", name: "Código" },
    accountCode: { key: "accountCode", name: "Código" },
    description: { key: "description", name: "Descripción" },
    documentSupport: { key: "documentSupport", name: "Sustento del Documento" },
    active: { key: "active", name: "Activa" },
    activeAlt: { key: "active", name: "Activo" },
    officeId: { key: "officeId", name: "Sucursal" },
    accountTypeId: { key: "accountTypeId", name: "Tipo de cuenta" },
    state: { key: "state", name: "Estado" },
    costCenterId: { key: "costCenterId", name: "Centro de costo" },
    costCenter: { key: "costCenter", name: "Centro de costo" },
    taxCode: { key: "taxCode", name: "Código" },
    tax: { key: "tax", name: "Impuestos" },
    taxTypeId: { key: "taxTypeId", name: "Tipo de impuesto" },
    forRetention: { key: "forRetention", name: "Retención" },
    applyRetention: { key: "forRetention", name: "Aplica retención" },
    retention: { key: "retention", name: "Retención" },
    retentionReceipt: { key: "retentionReceipt", name: "Comprobante de retención" },
    percentage: { key: "percentage", name: "Porcentaje" },
    taxId: { key: "taxId", name: "Código" },
    taxPlanId: { key: "taxPlanId", name: "Id de plan de impuesto" },
    taxPlan: { key: "taxPlan", name: "Plan de impuesto" },
    taxCodes: { key: "taxCodes", name: "Códigos de impuesto" },
    rejectionCauseId: { key: "rejectionCauseId", name: "Id motivo de rechazo" },
    supplierId: { key: "supplierId", name: "Id proveedor" },
    supplierIdFile: { key: "supplierFileId", name: "Id archivo proveedor" },
    supplier: { key: "supplier", name: "Proveedor" },
    country: { key: "country", name: "País" },
    companyName: { key: "companyName", name: "Razón social" },
    identification: { key: "identification", name: "Identificación" },
    identificationType: { key: "identificationType", name: "Tipo identificación" },
    comercialName: { key: "comercialName", name: "Nombre comercial" },
    address: { key: "address", name: "Dirección" },
    keepAccounting: { key: "keepAccounting", name: "Tipo de cuenta" },
    keepAccounts: { key: "keepAccounts", name: "Obligado a llevar contabilidad" },
    specialTaxpayer: { key: "specialTaxpayer", name: "Contribuyente especial" },
    userId: { key: "userId", name: "Id usuario" },
    userName: { key: "userName", name: "Usuario" },
    validUntil: { key: "validUntil", name: "Valido hasta" },
    moduleId: { key: "moduleId", name: "Id módulo" },
    relativePath: { key: "relativePath", name: "Directorio" },
    icon: { key: "icon", name: "Icono" },
    martixId: { key: "martixId", name: "Id matriz" },
    matrixCode: { key: "matrixCode", name: "Código matriz" },
    jbaCode: { key: "jbaCode", name: "Código Excel" },
    rejectionCauseCode: { key: "rejectionCauseCode", name: "Código" },
    invoiceNumber: { key: "invoiceNumber", name: "Número de factura" },
    creditNoteNumber: { key: "creditNoteNumber", name: "Número de nota de crédito" },
    invoiceDate: { key: "invoiceDate", name: "Fecha factura" },
    authorizationNumber: { key: "authorizationNumber", name: "Número de autorización" },
    emissionDate: { key: "emissionDate", name: "Fecha de emisión" },
    createDate: { key: "createDate", name: "Fecha de creación" },
    receptionDate: { key: "receptionDate", name: "Fecha de recepción" },
    expiresDate: { key: "expiresDate", name: "Fecha de expiración" },
    tip: { key: "tip", name: "Propina" },
    unitPrice: { key: "unitPrice", name: "Precio unitario" },
    quantity: { key: "quantity", name: "Cantidad" },
    taxes: { key: "taxes", name: "Impuestos" },
    discount: { key: "discount", name: "Descuento" },
    identificationTypeId: { key: "identificationTypeId", name: "Id tipo de identificacion" },
    identificationTypeCode: { key: "identificationTypeCode", name: "Código tipo de identificacion" },
    subtotalNoTaxes: { key: "subtotalNoTaxes", name: "Subtotal sin impuestos" },
    subtotal: { key: "subtotal", name: "Subtotal" },
    subtotalNoObject: { key: "subtotal", name: "Subtotal no objeto" },
    subtotalNoTaxesObject: { key: "subtotalNoTaxesObject", name: "Subtotal no objeto de impuestos" },
    taxesValue: { key: "taxesValue", name: "Valor de impuestos" },
    total: { key: "total", name: "Total" },
    employeeOrCustodian: { key: "employeeOrCustodian", name: "Empleado/Custodio" },
    documentNumber: { key: "documentNumber", name: "Número de documento" },
    registeredSupplier: { key: "registeredSupplier", name: "Proveedor registrado" },
    fiscalDocument: { key: "fiscalDocument", name: "Documento fiscal" },
    billOfLadingNumber: { key: "billOfLadingNumber", name: "Número de BL" },
    retentionType: { key: "retentionType", name: "Tipo" },
    retentionCode: { key: "retentionCode", name: "Código" },
    retentionPercentage: { key: "retentionPercentage", name: "Porcentaje" },
    retentionDescription: { key: "retentionDescription", name: "Descripción" },
    documentId: { key: "documentId", name: "ID documento" },
    companyRuc: { key: "companyRuc", name: "RUC compañia" },
    serialNumber: { key: "serialNumber", name: "Numero de serie" },
    documentAuthorization: { key: "documentAuthorization", name: "Autorizacion" },
    documentKeyAccess: { key: "documentKeyAccess", name: "LLave de acceso" },
    processStatus: { key: "processStatus", name: "Estado proceso" },
    process: { key: "processStatus", name: "Proceso" },
    flowStatus: { key: "flowStatus", name: "Estado Flujo" },
    documentDate: { key: "documentDate", name: "Fecha documento" },
    rejectedDate: { key: "rejectedDate", name: "Fecha de rechazado" },
    authorizationDate: { key: "authorizationDate", name: "Fecha autorización" },
    receiverName: { key: "receiverName", name: "Nombre receptor" },
    receiverRuc: { key: "receiverRuc", name: "RUC receptor" },
    receiverIdentification: { key: "receiverIdentification", name: "Identificación del receptor" },
    isManual: { key: "isManual", name: "Manual" },
    additionalInfo: { key: "additionalInfo", name: "Informacion adicional" },
    sriReportId: { key: "sriReportId", name: "Reporte SRI" },
    documentTypeId: { key: "documentTypeId", name: "Tipo documento" },
    ruc: { key: "ruc", name: "RUC" },
    startDocumentDate: { key: "startDocumentDate", name: "Fecha inicio" },
    endDocumentDate: { key: "endDocumentDate", name: "Fecha fin" },
    startDocumentReception: { key: "startDocumentReception", name: "Fecha recepcion inicio" },
    endDocumentReception: { key: "endDocumentReception", name: "Fecha recepcion fin" },
    purchaseSettlementNumber: { key: "purchaseSettlement", name: "Número de liquidación de compra" },
    purchaseSettlement: { key: "purchaseSettlement", name: "Liquidación de compra" },
    foreignExpensesLiquidation: { key: "foreignExpensesLiquidation", name: "Liquidación de gastos exterior" },
    voucher: { key: "voucher", name: "Comprobante" },
    subject: { key: "subject", name: "Asunto" },
    approvalMessage: { key: "approvalMessage", name: "Mensaje de aprobación" },
    deliveryAddress: { key: "deliveryAddress", name: "Dirección de entrega" },
    specialInstruction: { key: "specialInstruction", name: "Instrucción especial de compra" },
    purchaseStatus: { key: "purchaseStatus", name: "Estado de la compra" },
    purchaseCode: { key: "purchaseCode", name: "Código" },
    trackingNumber: { key: "trackingNumber", name: "Número de GUÍA" },
    shipment: { key: "shipment", name: "Shipment" },
    consolidated: { key: "consolidated", name: "Consolidado" },
    taxSupport: { key: "taxSupport", name: "Sustento tributario" },
    rejectionCause: { key: "rejectionCause", name: "Motivo de rechazo" },
    purchaseOrder: { key: "purchaseOrder", name: "Orden de compra" },
    internalCode: { key: "internalCode", name: "Código interno" },
    matrix: { key: "matrix", name: "Matriz" },
    branch: { key: "branch", name: "Sucursal" },
    purchaseType: { key: "purchase", name: "Tipo de compra" },
    accountInfo: { key: "accountInfo", name: "Información de contabilización" },
    paymentTerm: { key: "paymentTerm", name: "Término de pago" },
    creationDate: { key: "createDate", name: "Fecha de creación" },
    detailCount: { key: "detailCount", name: "# Detalles" },
    suppliersCount: { key: "suppliersCount", name: "# Proveedores" },
    activity: { key: "activity", name: "Actividad económica" },
    phoneNumber: { key: "phoneNumber", name: "Teléfono" },
    city: { key: "city", name: "Ciudad" },
    paymentTerms: { key: "paymentTerms", name: "Término de Pago" },
    contact: { key: "contact", name: "Persona de contacto" },
    createdBy: { key: "createdBy", name: "Usuario que lo creo" },
    // TODO: se intercambia temporalmente el campo representante legal por Código CW Proveedor
    legalRepresentative: { key: "legalRepresentative", name: "Código CW proveedor" },
    accountExecutiveName: { key: "accountExecutiveName", name: "Nombre del ejecutivo" },
    accountExecutiveRole: { key: "accountExecutiveRole", name: "Cargo del ejecutivo" },
    accountExecutiveEmail: { key: "accountExecutiveEmail", name: "Email del ejecutivo" },
    accountExecutiveSupervisor: { key: "accountExecutiveSupervisor", name: "Jefe del ejecutivo" },
    supervisorRole: { key: "supervisorRole", name: "Cargo del jefe" },
    supervisorEmail: { key: "supervisorEmail", name: "Email del jefe" },
    documentsEmail: { key: "documentsEmail", name: "Email para documentos" },
    bankName: { key: "bankName", name: "Nombre del banco" },
    bankAccountType: { key: "bankAccountType", name: "Tipo de cuenta bancaria" },
    bankAccountNumber: { key: "bankAccountNumber", name: "Número de cuenta" },
    swiftCode: { key: "swiftCode", name: "Código swift" },
    codeOfConductAgreement: { key: "codeOfConductAgreement", name: "Acepta código de conducta" },
    antiCorruptionAgreement: { key: "antiCorruptionAgreement", name: "Acepta documento anticorrupción" },
    dueDilligenceAgreement: { key: "dueDilligenceAgreement", name: "Acepta la debida diligencia" },
    supplierGroup: { key: "supplierGroup", name: "Grupo del proveedor" },
    ivaType: { key: "ivaType", name: "Tipo de IVA" },
    purchaseLiquidation: { key: "purchaseLiquidation", name: "Liquidación de compra" },
    serviceType: { key: "serviceType", name: "Tipo de servicio" },
    supplierType: { key: "supplierType", name: "Tipo de proveedor" },
    taxpayerType: { key: "taxpayerType", name: "Tipo de contribuyente" },
    sourceTaxRetention: { key: "sourceTaxRetention", name: "Ret. fuente del proveedor" },
    sourceTaxRetentionJba: { key: "sourceTaxRetentionJba", name: "Cod. JBA Rt. Fte" },
    ivaTaxRetention: { key: "ivaTaxRetention", name: "Ret. Iva del proveedor" },
    ivaTaxRetentionJba: { key: "ivaTaxRetentionJba", name: "Cod. JBA Rt. Iva" },
    supplierJba: { key: "supplierJba", name: "Código JBA proveedor" },
    supplierModule: { key: "supplierModule", name: "Tipo proveedor" },
    documentType: { key: "documentType", name: "Tipo de documento" },
    startDate: { key: "startDate", name: "Inicio" },
    yearMonth: { key: "yearMonth", name: "Mes y año" },
    endDate: { key: "endDate", name: "Fin" },
    tagRuc: { key: "rucFile", name: "Etiqueta Ruc" },
    tagCodeConduct: { key: "codeOfConductAgreement", name: "Etiqueta Codigo Conducta" },
    tagAntiCorruption: { key: "antiCorruptionAgreement", name: "Etiqueta Anticorrupción" },
    tagDilligence: { key: "dueDilligenceAgreement", name: "Etiqueta Diligencia" },
    tagAppointment: { key: "appointment", name: "Etiqueta Nombramiento" },
    bankCertificateFile: { key: "bankCertificateFile", name: "Archivo" },
    tagBankCertificate: { key: "bankCertificate", name: "Etiqueta Certificado Bancario" },
    emptyDate: { key: "emptyDate", name: "__/__/____" },
    invalidDate: { key: "invalidDate", name: "Fecha no válida" },
    generalInfo: { key: "generalInfo", name: "Información General" },
    sriInfo: { key: "sriInfo", name: "SRI" },
    // TODO: Se cambia temporalmente el tab Pagos, por tab Contactos
    paymentInfo: { key: "paymentInfo", name: "Contactos" },
    bankInfo: { key: "bankInfo", name: "Bancaria" },
    taxesInfo: { key: "taxesInfo", name: "Impuestos" },
    filesInfo: { key: "filesInfo", name: "Documentos" },
    file: { key: "file", name: "Archivo" },
    tag: { key: "tag", name: "Etiqueta" },
    comment: { key: "comment", name: "Comentario" },
    approvalLevel: { key: "approvalLevel", name: "Nivel de aprobación" },
    maxAmount: { key: "maxAmount", name: "Monto máximo" },
    message: { key: "message", name: "Mensaje" },
    requirePo: { key: "requirePo", name: "Requiere orden de compra" },
    operationalReference: { key: "shipment", name: "Referencia operativa" },
    invoiceId: { key: "documentId", name: "Número de factura" },
    assignedPurchaseOrder: { key: "purchaseOrder", name: "Orden de compra asignada" },
    taxBase: { key: "taxBase", name: "Base imponible" },
    subtotalRateZero: { key: "subtotalRateZero", name: "Subtotal IVA 0%" },
    subtotalRateTwelve: { key: "subtotalRateTwelve", name: "Subtotal IVA 12%" },
    retainedPercentage: { key: "retainedPercentage", name: "Porcentaje de retención" },
    retainedValue: { key: "retainedValue", name: "Valor retenido" },
    fiscalExercise: { key: "fiscalExercise", name: "Ejercicio fiscal" },
    taxPlansCount: { key: "taxPlansCount", name: "Planes de impuestos" },
    sustentions: { key: "sustentions", name: "Sustentos" },
    code: { key: "code", name: "Código" },
    authorizationDoc: { key: "authorizationDoc", name: "Número de documento de autorización" },
    sequential: { key: "sequential", name: "Secuencial" },
    document: { key: "document", name: "Documento" },
    rate: { key: "rate", name: "Tarifa" },
    taxRefund: { key: "taxRefund", name: "Impuesto" },
    totalOtherDocuments: { key: "totalOtherDocuments", name: "Total" },
    totalInvoices: { key: "totalInvoices", name: "Total" },
    subtotalInvoices: { key: "subtotalInvoices", name: "Subtotal" },
    iva: { key: "iva", name: "IVA" },
    issuer: { key: "issuerName", name: "Emisor" },
    issuerName: { key: "issuerName", name: "Nombre del emisor" },
    issuerIdentification: { key: "issuerIdentification", name: "Identificación del emisor" },
    accounting: { key: "accounting", name: "Cuenta contable" },
    detail: { key: "detail", name: "Detalle" },
    canApprove: { key: "canApprove", name: "Aprobar documentos" },
    canReject: { key: "canReject", name: "Rechazar documentos" },
    documentActions: { key: "documentActions", name: "Acciones sobre documentos" },
    rejectedActions: { key: "rejectedActions", name: "Acciones sobre rechazados" },
    expense: { key: "expense", name: "Gasto" },
    pickExpense: { key: "expense", name: "Selecciona un gasto" },
    expenses: { key: "expenses", name: "Gastos" },
    relatedPart: { key: "relatedPart", name: "Parte relacionada" },
    retentionReceiptToBeCanceled: { key: "retentionReceiptToBeCanceled", name: "Retención por anular" },
    purchaseSettlementToBeCanceled: { key: "purchaseSettlementToBeCanceled", name: "Liq. compra por anular" },
    appliedDocument: { key: "appliedDocument", name: "Documento al que aplica" },
    value: { key: "value", name: "Valor" },
    supplierNotification: { key: "supplierNotification", name: "Notificación" },
    reminder: { key: "reminder", name: "Recordatorio" },
    sriRequest: { key: "sriRequest", name: "Solicitud SRI" },
    annulled: { key: "annulled", name: "Anulado" },
    canRequest: { key: "canRequest", name: "Solicitar SRI" },
    canCancel: { key: "canCancel", name: "Anular" },
    inserted: { key: "inserted", name: "Ingresado" },
    updateTaxBase: { key: "updateTaxBase", name: "Modificar base imponible" },
    updateTotal: { key: "updateTotal", name: "Modificar total" },
    fixedAssetsSupplier: { key: "fixedAssetsSupplier", name: "Proveedor de Activos Fijos" },
    paymentType: {key: "paymentType", name: "Tipo de Pago" },
    regimenType: {key: "regimenType", name: "Tipo Régimen" },
    countryPayment: {key: "countryPayment", name: "País al que se efectua el pago" },
    doubleTribCon: {key: "doubleTribCon", name: "Aplica Convenio Doble Tributación" },
    legalNormPayment: {key: "legalNormPayment", name: "Pago sujeto a retención por norma legal" },
    fiscalRegimen: {key: "fiscalRegimen", name: "Regimen fiscal preferente o de menor imposición" },
    invoicePaymentType: {key: "invoicePaymentType", name: "Forma de Pago" },
    sustentoCode: {key: "sustentoCode", name: "Código de Sustento" },
    sujetoRetenidoType: {key: "sujetoRetenidoType", name: "Tipo Sujeto Retenido" },
    retDate: { key: "retDate", name: "Fecha retención" },
    purchaseSettlementDate: { key: "purchaseSettlementDate", name: "Fecha liq." },
    notificationEmail: { key: "notificationEmail", name: "Correo electrónico" },
    reason: { key: "reason", name: "Motivo" },
    recipients: { key: "recipients", name: "Destinatarios" },
    type: { key: "type", name: "Tipo" },
    customer: { key: "customer", name: "Cliente" },
    associatedDocument: { key: "associatedDocument", name: "Documento asociado" },
    documentEmissionDate: { key: "documentEmissionDate", name: "Fecha de emisión del documento" },
    nit: { key: "nit", name: "NIT" },
    accountingEntry: { key: "accountingEntry", name: "Asiento" },
    reference: { key: "reference", name: "Ref. Documento" },
    effectiveDate: { key: "effectiveDate", name: "Fecha Efec." },
    expiringDate: { key: "expiringDate", name: "Fecha Venc." },
    timePeriod: { key: "timePeriod", name: "Per." },
    actualAmount: { key: "actualAmount", name: "Actual" },
    blNumber: { key: "blNumber", name: "BL / Guía" },
    status: { key: "status", name: "Estado" },
    contacts: {key: "contacts", name: "Lista de Contactos"},
    contactName: {key: "contactName", name: "Nombre"},
    contactEmail: {key: "contactEmail", name: "Correo Electrónico"},
    contactPhone: {key: "contactPhone", name: "Teléfono"},
    fileMetaData: {key: "fileMetaData", name: "Metadata Archivos"},
    poRequestNumber: { key: "poRequestNumber", name: "Num. Solicitud" },
    advance: { key: "advance", name: "Anticipo"},
    na: { key: "na", name: "No Aplica"},
    poUserName: {key: 'poUserName', name: 'Solicitante'},
    // NUEVOS EXPRESS
    physical: { key: "physical", name: "¿Emite Facturas FÍsicas?" },
    sriAutomatic: { key: "sriAutomatic", name: "Automatico SRI" },
    manualTaxes: { key: "manualTaxes", name: "Asignacion Manual Impuestos" },
    especificText: { key: "especificText", name: "Texto Especifico" },
    posting: { key: "postingDate", name: "Posting Date" },
}