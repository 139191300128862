import { getSimplifiedPurchaseOrdersServiceByFilters } from 'lib'
import React from 'react'
import { IPurchaseOrderAccoutingInfo } from 'types'

type Props = {
    conditions?: string[]
    receiverRuc?: string
}

interface Hook {
    accountingInfoPurchaseOrders: IPurchaseOrderAccoutingInfo[]
    loading: boolean
}

const useSimplifiedPurchaseOrders = (props: Props): Hook => {

    const { conditions, receiverRuc } = props
    const [accountingInfoPurchaseOrders, setAccountingInfoPurchaseOrders] = React.useState<IPurchaseOrderAccoutingInfo[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)

    React.useEffect(() => {

        const init = async () => {
            if (receiverRuc && conditions && conditions?.length > 0) {
                setLoading(true)
                const response = await getSimplifiedPurchaseOrdersServiceByFilters(conditions, receiverRuc)
                setAccountingInfoPurchaseOrders(response)
                setLoading(false)
            }
        }
        init()

    }, [conditions, receiverRuc])

    return {
        accountingInfoPurchaseOrders,
        loading
    }
}

export default useSimplifiedPurchaseOrders
