import { Badge, Icon, IconButton, Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import moment from 'moment'
import { IClosingDate } from 'types'

interface Props {
    closingDate: IClosingDate
    selectedMonth: number
    selectedDate: number
    disabledPrev: boolean
    onMonthChange: (selected: number) => void
    onNext: () => void
    onPrev: () => void
    disabled: boolean
}

const YearMonthPicker = (props: Props) => {

    const { closingDate, onMonthChange: handleChange, selectedMonth, disabledPrev, onNext, onPrev, disabled } = props


    return (
        <div className='h-full p-1 flex flex-col' style={{ width: 200 }}>
            <div className='flex items-center justify-center'>
                <IconButton onClick={onPrev} disabled={disabledPrev || disabled}>
                    <Icon>
                        chevron_left
                    </Icon>
                </IconButton>
                <Typography
                    className='px-2 flex flex-grow justify-center'
                    variant='button'
                >
                    {closingDate.year}
                </Typography>
                <IconButton disabled={disabled} onClick={onNext}>
                    <Icon>
                        chevron_right
                    </Icon>
                </IconButton>
            </div>
            <div className='flex flex-grow w-full flex-col justify-center overflow-hidden pb-2'>
                <Tabs
                    orientation='vertical'
                    variant='scrollable'
                    value={selectedMonth}
                    onChange={(_, newValue) => handleChange(newValue)}
                    indicatorColor='primary'
                    textColor='primary'
                >
                    {
                        moment.months().map((month, index) => (
                            <Tab
                                disabled={disabled}
                                label={
                                    <div className='flex items-center'>
                                        {month}
                                        {
                                            closingDate.closingMonths.filter(m => Boolean(m.openingDate || m.closingDate)).map(m => m.month).includes(index) &&
                                            <Badge color="primary" variant='dot'>
                                                <Icon className='ml-2' fontSize='small'>event</Icon>
                                            </Badge>
                                        }
                                    </div>
                                }
                            />
                        ))
                    }
                </Tabs>
            </div>
        </div>
    )
}

export default YearMonthPicker
