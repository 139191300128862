import { FIELDS } from "./fields"

export const PURCHASE_ORDER_TABLE_COLUMNS = [
    { id: FIELDS.poUserName.key, label: FIELDS.poUserName.name},
    { id: FIELDS.poRequestNumber.key, label: FIELDS.poRequestNumber.name },
    { id: FIELDS.subject.key, label: FIELDS.subject.name },
    { id: FIELDS.creationDate.key, label: FIELDS.creationDate.name },
    { id: FIELDS.deliveryAddress.key, label: FIELDS.deliveryAddress.name },
]

export const PURCHASE_ORDER_STATUS = {
    inserted: {
        conditionId: 12,
        name: 'Ingresada',
        icon: 'transit_enterexit',
        action: ""
    },
    updated: {
        conditionId: 13,
        name: 'Actualizada',
        icon: 'transit_enterexit',
        action: ""
    },
    perApproval: {
        conditionId: 14,
        name: 'Por aprobar',
        icon: 'beenhere',
        action: "Aprobar"
    },
    approved: {
        conditionId: 15,
        name: 'Aprobada',
        icon: 'beenhere',
        action: "Finalizar"
    },
    finished: {
        conditionId: 16,
        name: 'Finalizada',
        icon: 'flag',
        action: "Descargar Excel"
    },
    rejected: {
        conditionId: 17,
        name: 'Rechazada',
        icon: 'thumb_down',
        action: ""
    },
    assigned: {
        conditionId: 18,
        name: 'Asignada',
        icon: 'assignment_turned_in',
        action: ""
    }
}

export const INSERTED_UPDATED_OR_PER_APPROVAL_PO_COLUMNS = [{ id: FIELDS.detailCount.key, label: FIELDS.detailCount.name }, { id: FIELDS.suppliersCount.key, label: FIELDS.suppliersCount.name }]
export const SUPPLIER_PO_COLUMN = [{ id: FIELDS.supplier.key, label: FIELDS.supplier.name }]
export const ASSIGNED_PO_COLUMNS = [{ id: FIELDS.purchaseOrder.key, label: FIELDS.purchaseOrder.name }, { id: FIELDS.invoiceId.key, label: FIELDS.invoiceId.name }]
export const FINISHED_PO_COLUMNS = [{ id: FIELDS.purchaseOrder.key, label: FIELDS.purchaseOrder.name }]