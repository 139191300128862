import axios, { AxiosError } from 'axios'
import { AUTHENTICATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { INFO_PATH, LIST_PATH, MODULE_PATH, USER_APPROVAL_PATH, USER_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: AUTHENTICATION_API_SERVICE_DOMAIN })

export const getUserApprovalsService = async () => {
    try {
        const result = await intance.get(USER_APPROVAL_PATH + LIST_PATH)
        return result.data
    } catch (error) {
        const e: AxiosError = error as any
        throw new Error(e.response?.data)
    }
}

export const getUserApprovalsByUserService = async (id: any) => {
    try {
        const result = await intance.get(USER_APPROVAL_PATH + USER_PATH + `/${id}`)
        return result.data
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export const getUserInfoByUserIdService = async (id: any) => {
    try {
        const result = await intance.get(USER_PATH + MODULE_PATH + INFO_PATH + `/${id}`)
        return result.data
    } catch (error: any) {
        throw new Error(JSON.stringify(error))
    }
}

export const createUserApprovalService = async (userApproval: any) => {
    try {
        const result = await intance.post(USER_APPROVAL_PATH, userApproval)
        return result.data
    } catch (error) {
        const e: AxiosError = error as any
        throw new Error(e.response?.data)
    }
}

export const deleteUserApprovalByUserIdService = async (id: any) => {
    try {
        const result = await intance.delete(USER_APPROVAL_PATH + USER_PATH + `/${id}`)
        return result.data
    } catch (error) {
        const e: AxiosError = error as any
        throw new Error(e.response?.data)
    }
}