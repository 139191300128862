import React from 'react'
import clsx from 'clsx'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { CssBaseline, Divider, Drawer, IconButton, Icon, ListItem, Tooltip, Typography, Popover } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { useHistory, useLocation } from 'react-router'
import logo from 'assets/img/logo-bg-transparent.svg'
import { AuthContext, ManagementContext } from 'context'
import { UserMenu } from 'components'
import { Alert } from '@material-ui/lab'

const drawerWidth = 260
const mainNavWidth = 60

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        menuButton: {

        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
            backgroundColor: grey[50],
            height: "100vh",
            width: "100%",
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        mainNav: {
            width: mainNavWidth,
            backgroundColor: grey[50],
            height: "100%",
        },
        mainNavIcon: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(1) / 2
        },
        subNav: {
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
        },
        alertIcon: {
            width: mainNavWidth,
            paddingRight: theme.spacing(2),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: theme.spacing(1)
        },
        alertIconCompact: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 0
        },
        alertRoot: {
            padding: 0
        },
        alertMessage: {
            fontSize: "0.75em",
            height: "auto",
            display: "flex",
            alignItems: "center",
            letterSpacing: 0.5
        }
    }),
)

const Nav: React.FC = ({ children }) => {
    const { user, navigation, searchingUser, selectedNav, setSelectedNav, userApproval } = React.useContext(AuthContext)
    const { approvalLevels } = React.useContext(ManagementContext)
    const classes = useStyles()
    const [open, setOpen] = React.useState(true)
    const handleDrawerOpen = () => setOpen(true)
    const handleDrawerClose = () => setOpen(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
    const history = useHistory()
    const location = useLocation()
    const validAuth = React.useCallback(() => { return Boolean(user) && !Boolean(searchingUser) }, [user, searchingUser])
    return (
        <div className={classes.root}>
            {
                validAuth() &&
                <React.Fragment>
                    <CssBaseline />
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                    >
                        {
                            selectedNav &&
                            <div className="p-2" onMouseLeave={() => setAnchorEl(null)}>
                                <Typography variant="button" style={{ fontWeight: 900, letterSpacing: "0.5px", fontSize: "0.75em" }} color="primary" className="flex-grow pl-4">
                                    {selectedNav.name.toUpperCase()}
                                </Typography>
                                <div className="mt-2">
                                    {
                                        selectedNav.views.map(nav => (
                                            <ListItem selected={location.pathname === nav.relativePath} onClick={() => { history.push(nav.relativePath); setAnchorEl(null) }} key={nav.relativePath} button>
                                                {nav.name}
                                            </ListItem>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    </Popover>
                    {
                        !open &&
                        <div style={{ width: mainNavWidth, backgroundColor: grey[50] }} className={`h-screen flex flex-col items-center py-2 ml-5`}>
                            <IconButton
                                color="inherit"
                                onClick={handleDrawerOpen}
                                className={clsx(classes.menuButton, open && classes.hide)}
                            >
                                <Icon>menu</Icon>
                            </IconButton>
                            <div className="py-1 px-2 flex-grow">
                                {
                                    navigation.map(nav => (
                                        <ListItem
                                            selected={selectedNav?.moduleId === nav.moduleId}
                                            className={classes.mainNavIcon}
                                            button
                                            onClick={(e) => { setAnchorEl(e.currentTarget); setSelectedNav(nav) }}
                                        >
                                            <Icon style={{ color: selectedNav?.moduleId === nav.moduleId ? "white" : grey[500] }}>
                                                {nav.icon}
                                            </Icon>
                                        </ListItem>
                                    ))
                                }
                            </div>
                            {
                                userApproval &&
                                <div className="cursor-pointer">
                                    <Tooltip arrow title={`Nivel de aprobación ${approvalLevels.find(a => a.approvalLevelId === userApproval.approvalLevelId)?.index ?? ""}`}>
                                        <Alert
                                            color="warning"
                                            severity="success"
                                            classes={{
                                                icon: classes.alertIconCompact,
                                            }}
                                        >
                                        </Alert>
                                    </Tooltip>
                                </div>
                            }
                            <UserMenu onLogout={() => setSelectedNav(undefined)} compact />
                        </div>
                    }
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{ paper: classes.drawerPaper, }}
                    >
                        <div className="w-full h-full flex overflow-x-hidden">
                            <div className={classes.mainNav}>
                                <div className={classes.drawerHeader}>
                                    <img src={logo} alt="Logo" />
                                </div>
                                <div className="py-1 px-2">
                                    {
                                        navigation.map(nav => (
                                            <Tooltip arrow key={nav.relativePath} title={nav.name} placement="right" >
                                                <ListItem selected={selectedNav?.moduleId === nav.moduleId} onClick={() => setSelectedNav(nav)} className={classes.mainNavIcon} button>
                                                    <Icon style={{ color: selectedNav?.moduleId === nav.moduleId ? "white" : grey[500] }}>
                                                        {nav.icon}
                                                    </Icon>
                                                </ListItem>
                                            </Tooltip>
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                selectedNav &&
                                <div className={classes.subNav}>
                                    <div className={classes.drawerHeader}>
                                        <Typography variant="button" style={{ fontWeight: 900, letterSpacing: "0.5px", fontSize: "0.75em" }} color="primary" className="flex-grow pl-4">
                                            {selectedNav.name.toUpperCase()}
                                        </Typography>
                                        <IconButton onClick={handleDrawerClose}>
                                            <Icon fontSize="small" >chevron_left</Icon>
                                        </IconButton>
                                    </div>
                                    <Divider />
                                    <div className="py-2 px-3 flex-grow overflow-y-auto">
                                        {
                                            selectedNav.views.map(nav => (
                                                <ListItem selected={location.pathname === nav.relativePath} onClick={() => history.push(nav.relativePath)} key={nav.relativePath} button>
                                                    {nav.name}
                                                </ListItem>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            userApproval &&
                            <div className="px-2 cursor-pointer mt-2">
                                <Tooltip arrow title="Nivel de aprobación para solicitudes de ordenes de compra">
                                    <Alert
                                        color="warning"
                                        severity="success"
                                        classes={{
                                            icon: classes.alertIcon,
                                            root: classes.alertRoot,
                                            message: classes.alertMessage
                                        }}
                                    >
                                        {`Nivel de aprobación ${approvalLevels.find(a => a.approvalLevelId === userApproval.approvalLevelId)?.index ?? ""}`}
                                    </Alert>
                                </Tooltip>
                            </div>
                        }
                        <UserMenu onLogout={() => setSelectedNav(undefined)} />
                    </Drawer>
                </React.Fragment>
            }
            <main className={`${validAuth() ? clsx(classes.content, { [classes.contentShift]: open, }) : "w-screen h-screen overflow-y-hidden"} overflow-x-hidden`}>
                {children}
            </main>
        </div>
    )
}

export default Nav