import { FIELDS } from "./fields"

export const PAYMENT_LIST_TABLE_COLUMNS = [
    { id: FIELDS.supplier.key, label: FIELDS.supplier.name },
    { id: FIELDS.nit.key, label: FIELDS.nit.name },
    { id: FIELDS.comercialName.key, label: FIELDS.comercialName.name },
    { id: FIELDS.type.key, label: FIELDS.type.name },
    { id: FIELDS.accountingEntry.key, label: FIELDS.accountingEntry.name },
    { id: FIELDS.reference.key, label: FIELDS.reference.name },
    { id: FIELDS.effectiveDate.key, label: FIELDS.effectiveDate.name },
    { id: FIELDS.expiringDate.key, label: FIELDS.expiringDate.name },
    { id: FIELDS.timePeriod.key, label: FIELDS.timePeriod.name },
    { id: FIELDS.actualAmount.key, label: FIELDS.actualAmount.name },
    { id: FIELDS.blNumber.key, label: FIELDS.blNumber.name },
    { id: FIELDS.status.key, label: FIELDS.status.name }
]