import { TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useSimplifiedPurchaseOrders } from 'hooks'
import { FIELDS, PURCHASE_ORDER_STATUS } from 'lib'
import React from 'react'
import { IPurchaseOrderAccoutingInfo } from 'types'

interface Props {
    selected: IPurchaseOrderAccoutingInfo | undefined
    setSelected: React.Dispatch<IPurchaseOrderAccoutingInfo | undefined>
    receiverRuc?: string
}

const INITIAL_CONDITIONS = [PURCHASE_ORDER_STATUS.finished.conditionId.toString()]

const inputStyle = { width: "100%", marginBottom: 15 }

const PurchaseOrderPicker = (props: Props) => {
    const { selected, setSelected, receiverRuc } = props
    const { accountingInfoPurchaseOrders, loading } = useSimplifiedPurchaseOrders({ conditions: INITIAL_CONDITIONS, receiverRuc })

    return (
        <div style={{ width: "40vw", height: "40vh" }} className="w-full h-full flex justify-center">
            <div className="w-full h-full p-6">
                <Typography className="pb-4" style={{ letterSpacing: 0.7, fontWeight: 600 }} variant="body2">
                    {"Ordenes de compra finalizadas:"}
                </Typography>
                <Autocomplete
                    style={inputStyle}
                    options={accountingInfoPurchaseOrders}
                    getOptionLabel={option => option.serialNumber}
                    size="small"
                    fullWidth
                    loading={loading}
                    loadingText="Cargando..."
                    freeSolo
                    noOptionsText="No existen ordenes de compra registradas..."
                    value={accountingInfoPurchaseOrders.find(p => p.serialNumber === selected?.serialNumber)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder={FIELDS.serialNumber.name}
                            required
                            onChange={(e) => {
                                setSelected({ serialNumber: e.target.value })
                            }}
                            fullWidth
                        />
                    )}
                    onChange={(_, selectedValue) => {
                        if (selectedValue && typeof selectedValue !== "string") {
                            setSelected(selectedValue)
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default PurchaseOrderPicker
