import axios, { AxiosError } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, OFFICE_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const getOfficesService = async () => {
    try {
        const result = await intance.get(OFFICE_PATH + LIST_PATH)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createOfficeService = async (office: any) => {
    try {
        const result = await intance.post(OFFICE_PATH, office)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data?.message)
    }
}

export const deleteOfficeService = async (id: any) => {
    try {
        const result = await intance.delete(OFFICE_PATH + `/${id}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editOfficeService = async (id: number, office: any) => {
    try {
        const result = await intance.put(OFFICE_PATH + `/${id}`, office)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data?.message)
    }
}