import { LAST_LEVEL_INDEX } from "lib/constants/approvalLevel"
import { PURCHASE_ORDER_STATUS } from "lib/constants/purchaseOrder"
import { VALIDATION_MESSAGES } from "lib/constants/validation"
import { IApprovalLevel, IPurchaseDetailSupplierInfo, IPurchaseOrderInitial, IPurchaseOrderStatus, IPurchaseOrderValidation } from "types"

export const getPurchaseOrderStatusIds = (): IPurchaseOrderStatus[] => {
    const statusIds: IPurchaseOrderStatus[] = []
    for (const key in PURCHASE_ORDER_STATUS) {
        if (Object.prototype.hasOwnProperty.call(PURCHASE_ORDER_STATUS, key)) {
            const purchaseOrder = PURCHASE_ORDER_STATUS[key]
            statusIds.push(purchaseOrder)
        }
    }
    return statusIds
}

export const validatePurchaseOrder = (purchaseOrder: IPurchaseOrderInitial): IPurchaseOrderValidation => {
    const validation: IPurchaseOrderValidation = { valid: true }
    if (!purchaseOrder.header.subject || !purchaseOrder.header.createDate || !purchaseOrder.header.deliveryAddress || !purchaseOrder.header.costCenterId || !purchaseOrder.header.accountTypeId) {
        validation.valid = false
        validation.message = VALIDATION_MESSAGES.requiredField
        validation.moveTo = "info"
        return validation
    }
    if (purchaseOrder.detail.length === 0) {
        validation.valid = false
        validation.message = VALIDATION_MESSAGES.emptyDetail
        validation.moveTo = "info"
        return validation
    }
    if (purchaseOrder.suppliersInfo.length < 1) {
        validation.valid = false
        validation.message = VALIDATION_MESSAGES.oneSuppliersAtLeast
        validation.moveTo = "supplier"
        return validation
    }
    for (let i = 0; i < purchaseOrder.suppliersInfo.length; i++) {
        if (!purchaseOrder.suppliersInfo[i].paymentTerm || !purchaseOrder.suppliersInfo[i].receptionDate) {
            validation.valid = false
            validation.message = VALIDATION_MESSAGES.requiredField
            validation.moveTo = "supplier"
            validation.supplierIndex = i
            return validation
        }
        for (const detail of purchaseOrder.suppliersInfo[i].detailBySupplierInfo) {
            if (!detail.unitPrice?.toString() || !detail.discount?.toString() || !detail.tax) {
                validation.valid = false
                validation.message = VALIDATION_MESSAGES.requiredField
                validation.moveTo = "supplier"
                validation.supplierIndex = i
                return validation
            }
        }
    }
    return validation
}

export const getSubtotalBySupplierInfo = (supplierInfo: IPurchaseDetailSupplierInfo): number => {
    if (!supplierInfo) return 0
    let subtotal = 0
    for (const detail of supplierInfo.detailBySupplierInfo) {
        let detailTotal: number = 0
        const totalNoTaxes = ((detail?.unitPrice ?? 0) * (detail?.quantity ?? 0))
        detailTotal = totalNoTaxes
        subtotal = subtotal + detailTotal
    }
    return subtotal
}

export const getTotalBySupplierInfo = (supplierInfo: IPurchaseDetailSupplierInfo): number => {
    if (!supplierInfo) return 0
    let total = 0
    for (const detail of supplierInfo.detailBySupplierInfo) {
        let detailTotal: number = 0
        const totalNoTaxes = ((detail?.unitPrice ?? 0) * (detail?.quantity ?? 0))
        detailTotal = totalNoTaxes
        if (detail.tax) {
            const taxesValue = totalNoTaxes * (detail.tax.percentage / 100)
            detailTotal = detailTotal + taxesValue
        }
        total = total + detailTotal
    }
    return total
}

export const getApprovalLevel = (totals: number[], approvalLevels: IApprovalLevel[]) => {
    const max = Math.max(...totals)
    let approvalLevel: number | undefined = undefined
    for (const level of approvalLevels) {
        if (max < level.maxAmount) {
            return level.approvalLevelId
        }
    }
    if(!approvalLevel) {
        approvalLevel = approvalLevels.pop()?.approvalLevelId ?? LAST_LEVEL_INDEX
    }
    return approvalLevel
}