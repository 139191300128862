import { Typography, Paper, Icon, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core'
import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@material-ui/lab'
import React from 'react'
import { IClosingDate } from 'types'
import 'moment/locale/es'
import moment from 'moment'

moment.locale("es")

interface Props {
    closingDates: IClosingDate[]
    selectedDate: number
}

export interface ClosingYearSummaryRef {
    close: () => void
    open: () => void
}

const months = moment.months()

const ClosingYearSummary = React.forwardRef<ClosingYearSummaryRef, Props>((props: Props, ref?: React.ForwardedRef<ClosingYearSummaryRef>) => {

    const { closingDates, selectedDate } = props
    const [open, setOpen] = React.useState(false)
    const [index, setIndex] = React.useState(selectedDate)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    React.useImperativeHandle(ref, () => ({
        close() { handleClose() },
        open() { handleOpen() }
    }))

    React.useEffect(() => {
        setIndex(selectedDate)
    }, [selectedDate])

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    <div className='flex items-center'>
                        <Tooltip arrow title={"Anterior"}>
                            <IconButton onClick={() => setIndex(current => current - 1)} disabled={index === 0}>
                                <Icon>
                                    chevron_left
                                </Icon>
                            </IconButton>
                        </Tooltip>
                        <Typography
                            className='px-2 flex flex-grow justify-center'
                        >
                            {`Resumen ${closingDates[index].year}`}
                        </Typography>
                        <Tooltip arrow title={"Siguiente"}>
                            <IconButton onClick={() => setIndex(current => current + 1)} disabled={closingDates.length === index + 1}>
                                <Icon>
                                    chevron_right
                                </Icon>
                            </IconButton>
                        </Tooltip>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className='h-full p-1 flex flex-col overflow-auto'>
                        <Timeline>
                            {
                                closingDates[index].closingMonths.map((month, index) => (
                                    <TimelineItem style={{ width: 500 }}>
                                        <TimelineOppositeContent>
                                            <Paper className="p-2" variant='outlined'>
                                                <div className='flex flex-col'>
                                                    <Typography style={{ fontWeight: 900, letterSpacing: 0.5, textTransform: 'capitalize' }} color="textSecondary" variant="caption">
                                                        {months[index]}
                                                    </Typography>
                                                    <div>
                                                        <Typography variant="h5" style={{ textTransform: "capitalize" }}>
                                                            {
                                                                month.openingDate &&  moment(new Date(month.openingDate)).isValid() ?
                                                                    moment(new Date(month.openingDate))?.format('dddd D')?.toString()
                                                                    : "Sin fecha"
                                                            }
                                                        </Typography>
                                                        <Typography style={{ fontWeight: 900, letterSpacing: 0.5 }} color="textSecondary" variant="caption">
                                                            {"Apertura"}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Paper>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot>
                                                <Tooltip title={(months[index] ?? "").toLocaleUpperCase()} arrow>
                                                    <Icon>event</Icon>
                                                </Tooltip>
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Paper className="p-2 mt-2" variant='outlined'>
                                                <Typography variant="h5" style={{ textTransform: "capitalize" }}>
                                                    {
                                                        month.closingDate && moment(new Date(month.closingDate)).isValid() ?
                                                            moment(new Date(month.closingDate))?.format('dddd D')?.toString()
                                                            : "Sin fecha"
                                                    }
                                                </Typography>
                                                <Typography style={{ fontWeight: 900, letterSpacing: 0.5 }} color="textSecondary" variant="caption">
                                                    {"Cierre"}
                                                </Typography>
                                            </Paper>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))
                            }

                        </Timeline>
                    </div>
                </DialogContent>
                <DialogActions className='m-1'>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        variant="contained"
                        disableElevation
                        size='small'
                    >
                        {"Cerrar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
})

export default ClosingYearSummary
