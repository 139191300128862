import { updateDocumentAdditionalInfoService } from 'lib'
import React from 'react'

interface Props {
    additionalInfo: string
    documentId: number
}

interface Hook {
    total: number
    taxBase: number
    handleChange: (key: "total" | "taxBase", value: number) => void
    handleSubmit: (e: React.FormEvent) => Promise<boolean>
    loading: boolean
}

const useUpdateValues = (props: Props): Hook => {
    const { additionalInfo, documentId } = props
    const [loading, setLoading] = React.useState(false)
    const [total, setTotal] = React.useState(JSON.parse(additionalInfo).total ?? 0)
    const [taxBase, setTaxBase] = React.useState(JSON.parse(additionalInfo).taxBase ?? 0)
    const handleChange = (key: "total" | "taxBase", value: number) => {
        if (key === "total") { setTotal(value) }
        if (key === "taxBase") { setTaxBase(value) }
    }
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setLoading(true)
        try {
            const newAdditionalInfo = JSON.parse(additionalInfo)
            await updateDocumentAdditionalInfoService(documentId, JSON.stringify({ ...newAdditionalInfo, total, taxBase }))
            setLoading(false)
            return true
        } catch (error) {
            console.log(error)
            setLoading(false)
            return false
        }
    }
    return {
        loading,
        handleChange,
        handleSubmit,
        taxBase,
        total
    }
}

export default useUpdateValues
