import {
    Button, CircularProgress, Divider, FormControlLabel, FormGroup, Switch, TextField,
    MenuItem, Typography, Icon, IconButton
} from '@material-ui/core'
import { useValues } from 'hooks'
import { FIELDS, createMatrixesService, editMatrixService } from 'lib'
import React from 'react'
import { useLocation } from 'react-router'
import { IMatrix } from 'types'
import { ManagementContext } from 'context'

interface Props {
    onSuccess: () => void
    onError: () => void
    onClose: () => void
}

const MatrixForm = (props: Props) => {
    const location = useLocation()
    const { accountTypes } = React.useContext(ManagementContext)
    const { offices } = React.useContext(ManagementContext)
    const { onError, onSuccess, onClose } = props
    const initialInput = React.useRef<any>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const { values: matrix, updateValue: handleChange, updateValues } = useValues()
    const handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            if (!(location.state as any)?.edit) {
                await createMatrixesService({ ...matrix, active: true })
            }
            else {
                const edited = { ...matrix }
                delete edited[FIELDS.martixId.key]
                await editMatrixService(matrix[FIELDS.martixId.key], { ...edited })
            }
            setLoading(false)
            if (!(location.state as any)?.edit) {
                updateValues({
                    description: "",
                    matrixCode: "",
                    active: true
                } as IMatrix)
                initialInput.current?.focus()
            }
            onSuccess()
        } catch (error) {
            setLoading(false)
            onError()
        }
    }
    React.useEffect(() => {
        if ((location.state as any)?.edit) {
            if ((location.state as any)?.values) {
                updateValues((location.state as any)?.values)
            }
        }
    }, [location.state, updateValues])
    return (
        <div className="p-4">
            <div className="flex items-center justify-between">
                <Typography variant="body2" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                    {(location.state as any)?.edit ? "Editar Matriz" : "Nueva Matriz"}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <Icon fontSize="small">close</Icon>
                </IconButton>
            </div>
            <form onSubmit={handleSumbit} className="pt-4 flex flex-col" style={{ width: 300 }}>
                <TextField
                    inputRef={initialInput}
                    autoFocus
                    size="small"
                    label={FIELDS.matrixCode.name}
                    variant="outlined"
                    fullWidth
                    required
                    value={matrix[FIELDS.matrixCode.key]}
                    onChange={(e) => handleChange(FIELDS.matrixCode.key, e.target.value)}
                />
                <TextField
                    size="small"
                    label={FIELDS.description.name}
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 10 }}
                    multiline
                    onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault() } }}
                    rows={3}
                    required
                    value={matrix[FIELDS.description.key]}
                    onChange={(e) => handleChange(FIELDS.description.key, e.target.value)}
                />
                <TextField
                    value={matrix[FIELDS.accountTypeId.key]?.toString() || ''}
                    label={FIELDS.accountTypeId.name}
                    onChange={(e) => { handleChange(FIELDS.accountTypeId.key, e.target.value) }}
                    required
                    style={{ marginTop: 10 }}
                    variant="outlined"
                    size="small"
                    select
                >
                    {accountTypes.map((accountT) => {
                        return <MenuItem key={accountT.accountTypeId} value={accountT.accountTypeId}>{accountT.description}</MenuItem>
                    })}
                </TextField>
                <TextField
                    value={matrix[FIELDS.officeId.key]?.toString() || ''}
                    label={FIELDS.officeId.name}
                    onChange={(e) => { handleChange(FIELDS.officeId.key, e.target.value) }}
                    required
                    style={{ marginTop: 10 }}
                    variant="outlined"
                    size="small"
                    select
                >
                    {offices.map((officeT) => {
                        return <MenuItem key={officeT.officeId} value={officeT.officeId}>{officeT.description}</MenuItem>
                    })}
                </TextField>
                {
                    (location.state as any)?.edit &&
                    <FormGroup className="p-2" row>
                        <FormControlLabel
                            control={<Switch color="primary" size="small" checked={Boolean(matrix[FIELDS.active.key])} onChange={(e) => handleChange(FIELDS.active.key, e.target.checked)} name={FIELDS.active.name} />}
                            label={FIELDS.active.name}
                        />
                    </FormGroup>
                }
                <div className="mt-4">
                    <Divider />
                </div>
                <div className="flex justify-end pt-3">
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        disableElevation
                        disabled={loading}
                        type="submit"
                    >
                        {loading ? <CircularProgress style={{ color: "white" }} size={16} className="my-1" thickness={10} /> : (location.state as any)?.edit ? "Guardar" : "Registrar"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default MatrixForm
