import axios, { AxiosError } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, TAX_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const getTaxesService = async () => {
    try {
        const result = await intance.get(TAX_PATH + LIST_PATH);
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createTaxesService = async (tax: any) => {
    console.log(tax);
    try {
        const result = await intance.post(TAX_PATH, tax)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteTaxService = async (id: any) => {
    try {
        const result = await intance.delete(TAX_PATH + `/${id}`)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editTaxService = async (id: number, office: any) => {
    try {
        const result = await intance.put(TAX_PATH + `/${id}`, office)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}