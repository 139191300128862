import React from "react"
import { Route } from "react-router-dom"
import { AuthContext } from "context"
import { CLOSING_PATH, CONFIGURATION_PATH, EXCEPTION_PATH } from "lib"
import { Configuration, Exceptions } from "pages"

const Closing = () => {
    const { user } = React.useContext(AuthContext)
    return (
        <React.Fragment>
            {
                Boolean(user) &&
                <React.Fragment>
                    <Route strict path={`${CLOSING_PATH}${CONFIGURATION_PATH}`} component={Configuration} />
                    <Route strict path={`${CLOSING_PATH}${EXCEPTION_PATH}`} component={Exceptions} />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Closing
