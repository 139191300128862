import { Icon, Typography, Box, Button, Divider, IconButton, Tooltip, Dialog, DialogActions, DialogContent, TextField, DialogTitle, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import FixedLoadingIndicator from 'components/FixedLoadingIndicator/FixedLoadingIndicator'
import { AuthContext, ManagementContext } from 'context'
import FileSaver from 'file-saver'
import { DASHBOARD_PATH, PURCHASE_ORDER_PATH, getPurchaseOrderStatusIds, PURCHASE_ORDER_STATUS, FIELDS, downloadPdfByDocumentFileId } from 'lib'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { IFlowData, IMessageConfig } from 'types'

interface Props {
    processStatus: number
    supplierSelected?: boolean
    onApprove: (message: string) => void
    onReject: (message: string) => void
    loading: boolean
    flowData: IFlowData[]
    documentId: number
    purchaseOrderNumber: string
}

const PurchaseOrderBar = (props: Props) => {
    const history = useHistory()
    const { userApproval } = React.useContext(AuthContext)
    const { approvalLevels } = React.useContext(ManagementContext)
    const { processStatus, supplierSelected, onApprove: handleApprove, loading, onReject: handleReject, flowData, documentId, purchaseOrderNumber } = props
    const [action, setAction] = React.useState<number | undefined>()
    const [message, setMessage] = React.useState<string>("")
    const [downloading, setDownloading] = React.useState(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (PURCHASE_ORDER_STATUS.rejected.conditionId === action) {
            handleReject(message)
        } else {
            handleApprove(message)
        }
        setMessage("")
        setAction(undefined)
    }
    const canApprove = React.useCallback(() => {
        const userApprovalLevel = approvalLevels.find(a => a.approvalLevelId === userApproval?.approvalLevelId)
        if (userApprovalLevel) {
            return flowData.length + 1 === userApprovalLevel.index
        }
        return false
    }, [flowData, approvalLevels, userApproval])
    const handleDownload = async () => {
        try {
            setDownloading(true)
            const result = await downloadPdfByDocumentFileId(documentId)
            FileSaver.saveAs(result, `ORDEN DE COMPRA - ${purchaseOrderNumber}.pdf`)
            setMessageConfig({ open: true, message: "Su descarga ha comenzado", severity: "info" })
            setDownloading(false)
        } catch (error) {
            setDownloading(false)
        }
    }
    return (
        <div className="flex py-3 px-8 items-center">
            <div className="flex flex-grow items-center">
                <Tooltip arrow title="Regresar">
                    <IconButton
                        onClick={() => history.action !== "POP" ? history.goBack() : history.push(`${PURCHASE_ORDER_PATH}${DASHBOARD_PATH}`)}
                    >
                        <Icon fontSize="small">
                            chevron_left
                        </Icon>
                    </IconButton>
                </Tooltip>
                <div className="ml-3 mr-6" style={{ height: 20 }}>
                    <Divider orientation="vertical" />
                </div>
                <Icon fontSize="small" color="action">
                    {getPurchaseOrderStatusIds().find(d => d.conditionId === processStatus)?.icon}
                </Icon>
                <div className="flex flex-col pl-4">
                    <Typography variant="caption" color="textSecondary" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                        {PURCHASE_ORDER_STATUS.perApproval.conditionId === processStatus ? "SOLICITUD DE ORDEN DE COMPRA" : "ORDEN DE COMPRA"}
                    </Typography>
                    <Box color="info.main">
                        <Typography variant="caption" style={{ fontWeight: 600, letterSpacing: 0.5, fontSize: "0.7em" }}>
                            {getPurchaseOrderStatusIds().find(d => d.conditionId === processStatus)?.name}
                        </Typography>
                    </Box>
                </div>
            </div>
            <div className="flex">
                {
                    processStatus === PURCHASE_ORDER_STATUS.perApproval.conditionId &&
                    <React.Fragment>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ width: 120, marginRight: 10 }}
                            disableElevation
                            size="small"
                            disabled={loading}
                            onClick={() => setAction(PURCHASE_ORDER_STATUS.rejected.conditionId)}
                        >
                            {"Rechazar"}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ width: 120 }}
                            disableElevation
                            size="small"
                            disabled={!supplierSelected || loading || !canApprove()}
                            onClick={() => setAction(PURCHASE_ORDER_STATUS.approved.conditionId)}
                        >
                            {getPurchaseOrderStatusIds().find(p => p.conditionId === processStatus)?.action}
                        </Button>
                    </React.Fragment>
                }
                {
                    (processStatus === PURCHASE_ORDER_STATUS.finished.conditionId || processStatus === PURCHASE_ORDER_STATUS.assigned.conditionId) &&
                    <Button
                        disabled={downloading}
                        size="small"
                        onClick={handleDownload}
                        style={{ marginRight: 15 }}
                        color="primary"
                        disableElevation
                        variant="contained"
                    >
                        {"Descargar pdf"}
                    </Button>
                }
            </div>
            {
                Boolean(action) &&
                <Dialog
                    open={Boolean(action)}
                    onClose={() => setAction(undefined)}
                    maxWidth="xl"
                >
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>
                            {PURCHASE_ORDER_STATUS.rejected.conditionId === action ? "Rechazar solicitud" : "Aprobar solicitud"}
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                variant="outlined"
                                multiline
                                onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault() } }}
                                rows={3}
                                style={{ width: 300, marginBottom: 20 }}
                                size="small"
                                label={FIELDS.message.name}
                                autoFocus
                                required
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setAction(undefined)}
                                size="small"
                                disableElevation
                            >
                                {"Cancelar"}
                            </Button>
                            <Button
                                disableElevation
                                color="primary"
                                size="small"
                                variant="contained"
                                type="submit"
                            >
                                {"Enviar"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            }
            <FixedLoadingIndicator
                loading={loading}
            />
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default PurchaseOrderBar
