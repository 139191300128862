import { getDocumentByIdService } from 'lib'
import React from 'react'
import { IDocumentView } from 'types'

interface Props {

}

interface Hook {
    getDocument: (documentId: number) => Promise<IDocumentView | undefined>
    loading: boolean
}

const useViewDocument = (props?: Props): Hook => {

    const [loading, setLoading] = React.useState(false)

    const getDocument = async (documentId: number): Promise<IDocumentView | undefined> => {
        try {
            setLoading(true)
            const response = await getDocumentByIdService(documentId)
            return response
        } catch (error) {
            setLoading(false)
            return undefined
        }
    }


    return {
        getDocument,
        loading
    }
}

export default useViewDocument
