import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Alert, Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  AuthorizationNumberInput,
  CurrencyInput,
  InvoiceInput,
} from "components";
import { DocumentContext, ManagementContext } from "context";
import { useValues } from "hooks";
import {
  AIR_TICKET_AUTH_NUMBER,
  AUTHORIZATION_NUMBER_SIZES,
  ELECTRONIC_AUTHORIZATION_NUMBER_LIMIT,
  EXPENSE_ITEMS,
  FIELDS,
  ID_CARD_LENGTH,
  INITIAL_REIMBURSEMENT,
  RUC_LENGTH,
  formatCurrency,
  getReimbursementInfoByElectronicInvoice,
  getSupportDocumentByAuthorizationNumberService,
  validateRUC,
} from "lib";
import { SUPPORT_DOCUMENTS_OPTIONS } from "mocks";
import moment from "moment";
import "moment/locale/es";
import React from "react";
import { IExpense, IMessageConfig, IRefund, IReimbursement } from "types";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

moment.locale("es");

interface Props {
  onAdd: (reimbursement: IReimbursement) => void;
  onClose: () => void;
  loading?: boolean;
  edit?: IReimbursement;
  withDetails?: boolean;
  initialValue?: Partial<IRefund>;
  validation?: (reimbursement: IReimbursement) => boolean;
  withExpenses?: boolean;
  supplierIdentification: string;
  supplierName?: string;
  withTaxSupport?: boolean;
  skipAuthorizationValidation?: boolean;
  defaultCloseOnAdd?: boolean;
}

const ReimbursementForm = (props: Props) => {
  const {
    edit,
    onAdd,
    onClose,
    withDetails,
    initialValue,
    validation,
    withExpenses,
    supplierIdentification,
    supplierName,
    withTaxSupport,
    skipAuthorizationValidation,
    defaultCloseOnAdd,
  } = props;
  const { costCenters } = React.useContext(DocumentContext);
  const { offices } = React.useContext(ManagementContext);
  const {
    updateValue: handleChange,
    values: reimbursement,
    updateValues,
  } = useValues(edit ? {} : INITIAL_REIMBURSEMENT);
  const [closeOnAdd, setCloseOnAdd] = React.useState<boolean>(
    defaultCloseOnAdd ?? false
  );
  const [searching, setSearching] = React.useState<boolean>(false);
  const descriptionInput = React.useRef<any>(null);
  const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({
    open: false,
    message: "",
    severity: "info",
  });
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validation && !validation({ ...reimbursement })) {
      return;
    }

    if (
      Boolean(reimbursement[FIELDS.taxSupport.key]) &&
      !validateRUC(reimbursement[FIELDS.issuerIdentification.key] ?? "")
    ) {
      setMessageConfig({
        message: `Ingresa un RUC válido`,
        severity: "error",
        open: true,
      });
      return;
    }
    if (
      Boolean(reimbursement[FIELDS.taxSupport.key]) &&
      reimbursement[FIELDS.authorizationNumber.key] &&
      !AUTHORIZATION_NUMBER_SIZES.includes(
        reimbursement[FIELDS.authorizationNumber.key].length ?? -1
      ) &&
      !skipAuthorizationValidation
    ) {
      setMessageConfig({
        message: `El número de autorización debe constar entre ${AUTHORIZATION_NUMBER_SIZES.join(
          `, `
        )} dígitos`,
        severity: "error",
        open: true,
      });
      return;
    }
    if (
      reimbursement[FIELDS.expenses.key] &&
      getTotal().toFixed(2) !== getTotalExpenses().toFixed(2)
    ) {
      setMessageConfig({
        message: `El total y el total de gastos no coinciden`,
        severity: "error",
        open: true,
      });
      return;
    }
    if (
      Boolean(reimbursement[FIELDS.taxSupport.key]) &&
      withExpenses &&
      reimbursement[FIELDS.receiverIdentification.key] &&
      (reimbursement[FIELDS.receiverIdentification.key].length !== RUC_LENGTH ||
        reimbursement[FIELDS.receiverIdentification.key].length !==
        ID_CARD_LENGTH) &&
      !supplierIdentification?.includes(
        reimbursement[FIELDS.receiverIdentification.key]
      ) &&
      reimbursement[FIELDS.authorizationNumber.key] &&
      reimbursement[FIELDS.authorizationNumber.key] !== AIR_TICKET_AUTH_NUMBER
    ) {
      setMessageConfig({
        message: `La identificación del receptor no coincide con la identificación del proveedor seleccionado ${supplierIdentification}`,
        severity: "error",
        open: true,
      });
      return;
    }
    if (
      withExpenses &&
      (!Boolean(reimbursement.expenses) || reimbursement.expenses?.length === 0)
    ) {
      setMessageConfig({
        message: `Agrega como mínimo un gasto`,
        severity: "error",
        open: true,
      });
      return;
    }
    if (
      Boolean(reimbursement[FIELDS.taxSupport.key]) &&
      reimbursement[FIELDS.documentNumber.key].includes("_")
    ) {
      setMessageConfig({
        message: `El formato de número de documento es incorrecto`,
        severity: "error",
        open: true,
      });
      return;
    }
    if (!Boolean(reimbursement[FIELDS.emissionDate.key])) {
      setMessageConfig({
        message: `Agregar fecha de emisión`,
        severity: "error",
        open: true,
      });
      return;
    }
    if (
      !Boolean(reimbursement[FIELDS.taxSupport.key]) &&
      reimbursement[FIELDS.authorizationNumber.key] === undefined
    ) {
      handleChange(reimbursement[FIELDS.authorizationNumber.key], "");
    }
    if (
      !Boolean(reimbursement[FIELDS.taxSupport.key]) &&
      reimbursement[FIELDS.issuerIdentification.key] === undefined
    ) {
      handleChange(reimbursement[FIELDS.issuerIdentification.key], "");
    }
    if (
      !Boolean(reimbursement[FIELDS.taxSupport.key]) &&
      reimbursement[FIELDS.issuerName.key] === undefined
    ) {
      handleChange(reimbursement[FIELDS.issuerName.key], "");
    }
    onAdd({
      ...reimbursement,
      iva: Boolean(reimbursement.iva) ? reimbursement.iva : 0,
      iva15: Boolean(reimbursement.iva15) ? reimbursement.iva15 : 0,
      subtotalNoTaxesObject: Boolean(reimbursement.subtotalNoTaxesObject)
        ? reimbursement.subtotalNoTaxesObject
        : 0,
      subtotalRateTwelve: Boolean(reimbursement.subtotalRateTwelve)
        ? reimbursement.subtotalRateTwelve
        : 0,
      subtotalRateQuince: Boolean(reimbursement.subtotalRateQuince) ? reimbursement.subtotalRateQuince : 0,
      subtotalRateZero: Boolean(reimbursement.subtotalRateZero)
        ? reimbursement.subtotalRateZero
        : 0,
    });
    updateValues(INITIAL_REIMBURSEMENT);

    if (supplierIdentification && supplierName) {
      updateValues({
        [FIELDS.receiverIdentification.key]: supplierIdentification,
        [FIELDS.receiverName.key]: supplierName,
      });
    }

    if (closeOnAdd) {
      onClose();
    }
  };
  const handleSearchDocument = async () => {
    if (
      reimbursement[FIELDS.authorizationNumber.key]?.length <
      ELECTRONIC_AUTHORIZATION_NUMBER_LIMIT
    ) {
      setMessageConfig({
        message: `El número de autorización debe tener ${ELECTRONIC_AUTHORIZATION_NUMBER_LIMIT} dígitos`,
        severity: "warning",
        open: true,
      });
      return;
    }
    try {
      setSearching(true);
      const result = await getSupportDocumentByAuthorizationNumberService(
        reimbursement[FIELDS.authorizationNumber.key]
      );
      const electronicReimbursement = getReimbursementInfoByElectronicInvoice(
        result,
        result.electronicData
      );
      updateValues({
        ...electronicReimbursement,
        taxSupport: Boolean(reimbursement[FIELDS.taxSupport.key]),
      });
      setSearching(false);
      setMessageConfig({
        message: "Documento encontrado",
        severity: "success",
        open: true,
      });
    } catch (error) {
      setMessageConfig({
        message: error.message,
        severity: "error",
        open: true,
      });
      setSearching(false);
    }
  };

  const getTotal = React.useCallback(() => {
    const subtotalNoTaxesObject = parseFloat(
      reimbursement[FIELDS.subtotalNoTaxesObject.key] ?? "0"
    );
    const subtotalRateTwelve = parseFloat(
      reimbursement[FIELDS.subtotalRateTwelve.key] ?? "0"
    );
    const subtotalRateQuince = parseFloat(reimbursement['subtotalRateQuince'] ?? "0")
    const subtotalRateZero = parseFloat(
      reimbursement[FIELDS.subtotalRateZero.key] ?? "0"
    );
    const iva15 = parseFloat(reimbursement['iva15'] ?? "0")
    const iva = parseFloat(reimbursement[FIELDS.iva.key] ?? "0");
    return subtotalNoTaxesObject + subtotalRateTwelve + subtotalRateZero + iva + iva15 + subtotalRateQuince
  }, [reimbursement]);
  const getTotalExpenses = React.useCallback((): number => {
    const values = ((reimbursement[FIELDS.expenses.key] ??
      []) as IExpense[]).map((e) => e.value);
    const reducer = (previousValue, currentValue) =>
      previousValue + currentValue;
    return values.reduce(reducer, 0);
  }, [reimbursement]);
  React.useEffect(() => {
    if (edit) {
      updateValues(edit);
    }
  }, [edit, updateValues]);

  React.useEffect(() => {
    const init = async () => {
      updateValues({
        authorizationNumber: initialValue?.authorizationDoc,
        emissionDate: moment(initialValue?.emitionDate, "DD/MM/YYYY").toDate(),
        issuerIdentification: initialValue?.identification,
        documentNumber: initialValue?.sequential,
        taxSupport: Boolean(initialValue?.taxSupport),
      });
      if (
        (initialValue?.authorizationDoc?.length ?? 0) <
        ELECTRONIC_AUTHORIZATION_NUMBER_LIMIT
      ) {
        setSearching(false);
        return;
      }
      try {
        setSearching(true);
        const result = await getSupportDocumentByAuthorizationNumberService(
          initialValue?.authorizationDoc
        );

        const electronicReimbursement = getReimbursementInfoByElectronicInvoice(
          result,
          result.electronicData
        );
        updateValues({
          ...electronicReimbursement,
          authorizationNumber: initialValue?.authorizationDoc,
          taxSupport: true,
        });
        setSearching(false);
        setMessageConfig({
          message: "Documento encontrado",
          severity: "success",
          open: true,
        });
      } catch (error) {
        setMessageConfig({
          message: error.message,
          severity: "error",
          open: true,
        });
        setSearching(false);
      }
    };
    if (initialValue?.authorizationDoc) {
      init();
    }
  }, [updateValues, initialValue]);
  React.useEffect(() => {
    if (supplierIdentification && supplierName) {
      updateValues({
        [FIELDS.receiverIdentification.key]: supplierIdentification,
        [FIELDS.receiverName.key]: supplierName,
      });
    }
  }, [supplierIdentification, supplierName, updateValues]);

  return (
    <form
      onSubmit={handleSubmit}
      className="h-full flex flex-col overflow-y-hidden"
    >
      <div className="flex flex-col flex-grow overflow-y-hidden">
        <div className="flex flex-grow overflow-y-auto px-8 py-6">
          <div className="flex flex-grow flex-col">
            {withTaxSupport && (
              <FormControlLabel
                className="w-full mb-4 pl-2"
                control={
                  <Switch
                    color="primary"
                    size="small"
                    checked={Boolean(reimbursement[FIELDS.taxSupport.key])}
                    onChange={(e) => {
                      updateValues({
                        ...reimbursement,
                        taxSupport: e.target.checked,
                        documentNumber: "",
                        issuerIdentification:
                          e.target.checked && reimbursement.issuerIdentification
                            ? ""
                            : reimbursement.issuerIdentification,
                        receiverIdentification:
                          e.target.checked &&
                            reimbursement.receiverIdentification
                            ? supplierIdentification
                            : reimbursement.receiverIdentification,
                      });
                    }}
                    name="taxSupport"
                  />
                }
                label={
                  <Typography
                    color={
                      Boolean(reimbursement[FIELDS.taxSupport.key])
                        ? "textPrimary"
                        : "textSecondary"
                    }
                  >
                    {FIELDS.taxSupport.name}
                  </Typography>
                }
              />
            )}
            {reimbursement[FIELDS.taxSupport.key] && (
              <React.Fragment>
                <div
                  style={{
                    backgroundColor: "#fff8c9",
                    padding: "10px",
                    borderRadius: "10px",
                    width: "40vw",
                    marginBottom: "20px",
                  }}
                >
                  <Typography variant="caption" style={{ color: "#5c593e" }}>
                    {
                      "Se intentará buscar el comprobante electrónico autorizado en el SRI. Si la autorización del comprobante fue hace más de 30 días, o si el SRI no entrega la información del comprobante después de intentar más de dos veces, se puede intentar a cargar el archivo XML de la autorización, o llenar manualmente la información. "
                    }
                  </Typography>
                </div>
                <div className="w-full flex items-start">
                  <TextField
                    inputRef={descriptionInput}
                    variant="outlined"
                    fullWidth
                    size="small"
                    label={FIELDS.authorizationNumber.name}
                    required
                    value={
                      reimbursement[FIELDS.authorizationNumber.key]
                        ? reimbursement[
                          FIELDS.authorizationNumber.key
                        ].toString()
                        : ""
                    }
                    onChange={(e) =>
                      handleChange(
                        FIELDS.authorizationNumber.key,
                        e.target.value
                      )
                    }
                    autoFocus
                    helperText={
                      <div className="flex w-full justify-between">
                        <p>
                          {searching
                            ? "Buscando..."
                            : "Presiona enter para buscar en el SRI"}
                        </p>
                        <p className="pr-1">{`${reimbursement[FIELDS.authorizationNumber.key]
                          ?.length ?? 0
                          }/${ELECTRONIC_AUTHORIZATION_NUMBER_LIMIT}`}</p>
                      </div>
                    }
                    FormHelperTextProps={{
                      style: { margin: 0, marginLeft: 8 },
                    }}
                    InputProps={{
                      endAdornment: searching ? (
                        <InputAdornment position="end">
                          <CircularProgress
                            color="primary"
                            thickness={10}
                            size={16}
                          />
                        </InputAdornment>
                      ) : Boolean(reimbursement[FIELDS.taxSupport.key]) &&
                        reimbursement[FIELDS.authorizationNumber.key] &&
                        AUTHORIZATION_NUMBER_SIZES.includes(
                          reimbursement[FIELDS.authorizationNumber.key]
                            .length ?? -1
                        ) ? (
                        <InputAdornment position="end">
                          <Icon color="secondary" fontSize="small">
                            check_circle
                          </Icon>
                        </InputAdornment>
                      ) : undefined,
                      inputComponent: AuthorizationNumberInput as any,
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSearchDocument();
                      }
                    }}
                  />
                  <Button
                    className="mt-1"
                    color="primary"
                    style={{ marginLeft: 10, minWidth: 0 }}
                    variant="contained"
                    disableElevation={true}
                    onClick={handleSearchDocument}
                    disabled={
                      reimbursement[FIELDS.authorizationNumber.key]?.length <
                      ELECTRONIC_AUTHORIZATION_NUMBER_LIMIT || searching
                    }
                  >
                    <Icon>search</Icon>
                  </Button>
                </div>
                <div className="w-full flex justify-between mt-4">
                  <TextField
                    variant="outlined"
                    style={{ width: "49%" }}
                    size="small"
                    label={FIELDS.issuerIdentification.name}
                    required
                    value={
                      reimbursement[FIELDS.issuerIdentification.key]
                        ? reimbursement[FIELDS.issuerIdentification.key]
                        : ""
                    }
                    onChange={(e) => {
                      console.log(e.target.value);
                      handleChange(
                        FIELDS.issuerIdentification.key,
                        e.target.value
                      );
                    }}
                  />
                  <TextField
                    variant="outlined"
                    style={{ width: "49%" }}
                    size="small"
                    label={FIELDS.issuerName.name}
                    required
                    value={
                      reimbursement[FIELDS.issuerName.key]
                        ? reimbursement[FIELDS.issuerName.key]
                        : ""
                    }
                    onChange={(e) =>
                      handleChange(FIELDS.issuerName.key, e.target.value)
                    }
                  />
                </div>
              </React.Fragment>
            )}
            {!reimbursement[FIELDS.taxSupport.key] && (
              <React.Fragment>
                <div className="w-full flex justify-between mt-4">
                  <TextField
                    variant="outlined"
                    style={{ width: "49%" }}
                    size="small"
                    label={FIELDS.issuerName.name}
                    required
                    value={
                      reimbursement[FIELDS.issuerName.key]
                        ? reimbursement[FIELDS.issuerName.key]
                        : ""
                    }
                    onChange={(e) =>
                      handleChange(FIELDS.issuerName.key, e.target.value)
                    }
                  />
                </div>
              </React.Fragment>
            )}
            <div className="w-full flex justify-between mt-4">
              <TextField
                variant="outlined"
                style={{ width: "49%" }}
                size="small"
                label={FIELDS.documentNumber.name}
                required
                InputProps={{
                  inputComponent: Boolean(reimbursement[FIELDS.taxSupport.key])
                    ? (InvoiceInput as any)
                    : undefined,
                }}
                value={
                  reimbursement[FIELDS.documentNumber.key]
                    ? reimbursement[FIELDS.documentNumber.key]
                    : ""
                }
                onChange={(e) =>
                  handleChange(FIELDS.documentNumber.key, e.target.value)
                }
              />
              <KeyboardDatePicker
                style={{ width: "49%" }}
                className="date-input"
                autoOk
                invalidDateMessage={FIELDS.invalidDate.name}
                InputAdornmentProps={{
                  position: "start",
                  style: { margin: 0 },
                }}
                value={reimbursement[FIELDS.emissionDate.key] ?? null}
                onChange={(date) => handleChange(FIELDS.emissionDate.key, date)}
                inputVariant="outlined"
                fullWidth
                emptyLabel={FIELDS.emptyDate.name}
                required
                size="small"
                label={FIELDS.emissionDate.name}
                variant="inline"
                format="dd/MM/yyyy"
              />
            </div>
            {withExpenses && (
              <div className="w-full flex justify-between mt-4">
                <TextField
                  variant="outlined"
                  style={{ width: "49%" }}
                  size="small"
                  label={FIELDS.receiverIdentification.name}
                  required
                  value={
                    reimbursement[FIELDS.receiverIdentification.key]
                      ? reimbursement[FIELDS.receiverIdentification.key]
                      : ""
                  }
                  onChange={(e) =>
                    handleChange(
                      FIELDS.receiverIdentification.key,
                      e.target.value
                    )
                  }
                />
                <TextField
                  variant="outlined"
                  style={{ width: "49%" }}
                  size="small"
                  label={FIELDS.receiverName.name}
                  required
                  value={
                    reimbursement[FIELDS.receiverName.key]
                      ? reimbursement[FIELDS.receiverName.key]
                      : ""
                  }
                  onChange={(e) =>
                    handleChange(FIELDS.receiverName.key, e.target.value)
                  }
                />
              </div>
            )}

            <div className="w-full flex justify-between mt-4">
              {!withDetails && (
                <TextField
                  variant="outlined"
                  style={{ width: "49%" }}
                  size="small"
                  label={FIELDS.description.name}
                  required
                  value={
                    reimbursement[FIELDS.description.key]
                      ? reimbursement[FIELDS.description.key]
                      : ""
                  }
                  onChange={(e) =>
                    handleChange(FIELDS.description.key, e.target.value)
                  }
                />
              )}
              <Autocomplete
                options={SUPPORT_DOCUMENTS_OPTIONS}
                style={{
                  width: "49%",
                }}
                getOptionLabel={(option) => option.name}
                size="small"
                openOnFocus
                noOptionsText="No se encontrarion los documnetos sustentos..."
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={FIELDS.documentSupport.name}
                    variant="outlined"
                    required
                  />
                )}
                value={
                  SUPPORT_DOCUMENTS_OPTIONS.find(
                    (v) => v.code === reimbursement[FIELDS.documentSupport.key]
                  ) ?? null
                }
                onChange={(_, value) => {
                  handleChange(
                    FIELDS.documentSupport.key,
                    value ? value.code : null
                  );
                }}
              />
            </div>

            <div className="w-full pt-4 pl-2">
              <Typography
                style={{ fontWeight: 900 }}
                color="textSecondary"
                variant="caption"
              >
                {"Valores"}
              </Typography>
            </div>

            <div className="w-full flex justify-between mt-4">
              <TextField
                variant="outlined"
                style={{ width: "49%" }}
                size="small"
                label={FIELDS.subtotalNoTaxesObject.name}
                required
                onFocus={(event) => {
                  event.target.select();
                }}
                value={
                  reimbursement[FIELDS.subtotalNoTaxesObject.key]
                    ? reimbursement[FIELDS.subtotalNoTaxesObject.key]
                    : "0"
                }
                onChange={(e) =>
                  handleChange(FIELDS.subtotalNoTaxesObject.key, e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon fontSize="small" color="action">
                        attach_money
                      </Icon>
                    </InputAdornment>
                  ),
                  inputComponent: CurrencyInput as any,
                }}
              />
              <TextField
                variant="outlined"
                style={{ width: "49%" }}
                size="small"
                label={FIELDS.subtotalRateZero.name}
                required
                onFocus={(event) => {
                  event.target.select();
                }}
                value={
                  reimbursement[FIELDS.subtotalRateZero.key]
                    ? reimbursement[FIELDS.subtotalRateZero.key]
                    : "0"
                }
                onChange={(e) =>
                  handleChange(FIELDS.subtotalRateZero.key, e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon fontSize="small" color="action">
                        attach_money
                      </Icon>
                    </InputAdornment>
                  ),
                  inputComponent: CurrencyInput as any,
                }}
              />
            </div>
            <div className="w-full flex justify-between mt-4">
              <TextField
                variant="outlined"
                style={{ width: "49%" }}
                size="small"
                onFocus={(event) => {
                  event.target.select();
                }}
                label={FIELDS.subtotalRateTwelve.name}
                required
                value={
                  reimbursement[FIELDS.subtotalRateTwelve.key]
                    ? reimbursement[FIELDS.subtotalRateTwelve.key]
                    : "0"
                }
                onChange={(e) => {
                  updateValues({
                    ...reimbursement,
                    [FIELDS.subtotalRateTwelve.key]: e.target.value,
                    [FIELDS.iva.key]: (parseFloat(e.target.value) * 0.12)
                      .toFixed(2)
                      .toString(),
                  });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon fontSize="small" color="action">
                        attach_money
                      </Icon>
                    </InputAdornment>
                  ),
                  inputComponent: CurrencyInput as any,
                }}
              />
              <TextField
                variant="outlined"
                style={{ width: "49%" }}
                size="small"
                label={'IVA 12%'}
                required
                onFocus={(event) => {
                  event.target.select();
                }}
                value={
                  reimbursement[FIELDS.iva.key]
                    ? reimbursement[FIELDS.iva.key]
                    : "0"
                }
                onChange={(e) => handleChange(FIELDS.iva.key, e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon fontSize="small" color="action">
                        attach_money
                      </Icon>
                    </InputAdornment>
                  ),
                  inputComponent: CurrencyInput as any,
                }}
              />
              <div className="w-full flex justify-between mt-4">
                <TextField
                  variant="outlined"
                  style={{ width: "49%" }}
                  size="small"
                  onFocus={event => { event.target.select() }}
                  label={'Subtotal IVA 15%'}
                  required
                  value={reimbursement['subtotalRateQuince'] ? reimbursement['subtotalRateQuince'] : "0"}
                  onChange={(e) => {
                    updateValues({
                      ...reimbursement,
                      ['subtotalRateQuince']: e.target.value,
                      ['iva15']: (parseFloat(e.target.value) * 0.15).toFixed(2).toString()
                    })
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon fontSize="small" color="action">attach_money</Icon>
                      </InputAdornment>
                    ),
                    inputComponent: CurrencyInput as any,
                  }}
                />
                <TextField
                  variant="outlined"
                  style={{ width: "49%" }}
                  size="small"
                  label={"IVA 15%"}
                  required
                  onFocus={event => { event.target.select() }}
                  value={reimbursement['iva15'] ? reimbursement['iva15'] : "0"}
                  onChange={(e) => handleChange('iva15', e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon fontSize="small" color="action">attach_money</Icon>
                      </InputAdornment>
                    ),
                    inputComponent: CurrencyInput as any,
                  }}
                />
              </div>
            </div>
            <Paper
              className="px-4 py-2 mt-4 flex flex-col w-full"
              variant="outlined"
            >
              <Typography
                align="right"
                style={{ fontWeight: 600, letterSpacing: 0.85 }}
                variant="button"
              >
                {`Total: ${formatCurrency(getTotal())}`}
              </Typography>
            </Paper>
            {withDetails && (
              <React.Fragment>
                <div className="w-full pt-4 pl-2">
                  <Typography
                    style={{ fontWeight: 900 }}
                    color="textSecondary"
                    variant="caption"
                  >
                    {"Detalle"}
                  </Typography>
                </div>
                <div className="w-full flex justify-between mt-4">
                  <TextField
                    variant="outlined"
                    style={{ width: "49%" }}
                    size="small"
                    label={FIELDS.description.name}
                    required
                    value={
                      reimbursement[FIELDS.description.key]
                        ? reimbursement[FIELDS.description.key]
                        : ""
                    }
                    onChange={(e) =>
                      handleChange(FIELDS.description.key, e.target.value)
                    }
                  />
                  <TextField
                    variant="outlined"
                    style={{ width: "49%" }}
                    size="small"
                    label={FIELDS.accounting.name}
                    required
                    value={
                      reimbursement[FIELDS.accounting.key]
                        ? reimbursement[FIELDS.accounting.key]
                        : ""
                    }
                    onChange={(e) =>
                      handleChange(FIELDS.accounting.key, e.target.value)
                    }
                  />
                </div>
                <div className="w-full flex justify-between mt-4">
                  <Autocomplete
                    options={costCenters}
                    style={{ width: "49%" }}
                    getOptionLabel={(option) =>
                      `${option.costCenterCode} - ${option.description}`
                    }
                    size="small"
                    openOnFocus
                    noOptionsText="No se encontraron centros de costo registrados..."
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={FIELDS.costCenter.name}
                        variant="outlined"
                        required
                      />
                    )}
                    value={
                      reimbursement[FIELDS.costCenter.key]
                        ? reimbursement[FIELDS.costCenter.key]
                        : null
                    }
                    onChange={(_, value) =>
                      handleChange(FIELDS.costCenter.key, value)
                    }
                  />
                  <Autocomplete
                    options={offices}
                    style={{ width: "49%" }}
                    getOptionLabel={(option) =>
                      `${option.officeCode} - ${option.description}`
                    }
                    size="small"
                    openOnFocus
                    noOptionsText="No se encontraron sucursales registradas..."
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={FIELDS.branch.name}
                        variant="outlined"
                        required
                      />
                    )}
                    value={
                      reimbursement[FIELDS.branch.key]
                        ? reimbursement[FIELDS.branch.key]
                        : null
                    }
                    onChange={(_, value) =>
                      handleChange(FIELDS.branch.key, value)
                    }
                  />
                </div>
              </React.Fragment>
            )}
          </div>

          {withExpenses && (
            <div className="ml-8" style={{ width: "45%" }}>
              <Autocomplete
                options={EXPENSE_ITEMS}
                getOptionLabel={(option) => option.name}
                size="small"
                openOnFocus
                fullWidth
                noOptionsText="No se encontraron gastos registrados..."
                multiple
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={FIELDS.expenses.name}
                    variant="outlined"
                    helperText={
                      <div className="flex w-full justify-between">
                        <p>{"Selecciona para agregar uno nuevo"}</p>
                      </div>
                    }
                    FormHelperTextProps={{
                      style: { margin: 0, marginLeft: 8 },
                    }}
                  />
                )}
                value={((reimbursement[FIELDS.expenses.key] ??
                  []) as IExpense[]).map((e) => e.type)}
                onChange={(_, __, reason, details) => {
                  const value = details?.option;
                  if (reason === "select-option" && value) {
                    handleChange(
                      FIELDS.expenses.key,
                      reimbursement[FIELDS.expenses.key]
                        ? [
                          ...reimbursement[FIELDS.expenses.key],
                          { type: value, value: 0 },
                        ]
                        : [{ type: value, value: 0 }]
                    );
                  }
                  if (reason === "remove-option" && value) {
                    handleChange(
                      FIELDS.expenses.key,
                      reimbursement[FIELDS.expenses.key]
                        ? (reimbursement[
                          FIELDS.expenses.key
                        ] as IExpense[]).filter(
                          (e) => e.type.key !== value.key
                        )
                        : []
                    );
                  }
                  if (reason === "clear") {
                    handleChange(FIELDS.expenses.key, []);
                  }
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      size="small"
                    />
                    <Typography variant="subtitle2">{option.name}</Typography>
                  </React.Fragment>
                )}
                renderTags={() => <React.Fragment />}
                disableCloseOnSelect
              />

              <div className="w-full justify-between flex flex-wrap">
                {((reimbursement[FIELDS.expenses.key] ?? []) as IExpense[]).map(
                  (expense) => (
                    <TextField
                      variant="outlined"
                      style={{ width: "49%", marginTop: "0.85rem" }}
                      size="small"
                      label={expense?.type?.name}
                      required
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      value={expense.value}
                      onChange={(e) => {
                        const expenses: IExpense[] =
                          reimbursement[FIELDS.expenses.key];
                        handleChange(
                          FIELDS.expenses.key,
                          expenses.map((ex) =>
                            ex.type.key === expense.type.key
                              ? {
                                ...expense,
                                value: parseFloat(e.target.value),
                              }
                              : ex
                          )
                        );
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon fontSize="small" color="action">
                              attach_money
                            </Icon>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip arrow title="Eliminar gasto">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  handleChange(
                                    FIELDS.expenses.key,
                                    reimbursement[FIELDS.expenses.key]
                                      ? (reimbursement[
                                        FIELDS.expenses.key
                                      ] as IExpense[]).filter(
                                        (e) => e.type.key !== expense.type.key
                                      )
                                      : []
                                  );
                                }}
                              >
                                <Icon fontSize="small" color="action">
                                  close
                                </Icon>
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                        inputComponent: CurrencyInput as any,
                      }}
                    />
                  )
                )}
              </div>

              <Paper
                className="px-4 py-2 mt-4 flex flex-col"
                variant="outlined"
              >
                {Boolean(
                  reimbursement[FIELDS.expenses.key] &&
                  reimbursement[FIELDS.expenses.key].length
                ) && (
                    <Typography
                      align="right"
                      style={{
                        fontWeight: 600,
                        letterSpacing: 0.85,
                        marginTop: 5,
                      }}
                      variant="button"
                    >
                      {`Total gastos: ${formatCurrency(getTotalExpenses())}`}
                    </Typography>
                  )}
                {Boolean(
                  reimbursement[FIELDS.expenses.key] &&
                  reimbursement[FIELDS.expenses.key].length
                ) && (
                    <div className="flex w-full items-center justify-end mt-4">
                      <Icon
                        color={
                          getTotal().toFixed(2) !== getTotalExpenses().toFixed(2)
                            ? "error"
                            : "secondary"
                        }
                        className="mr-2"
                        fontSize="small"
                      >
                        {getTotal().toFixed(2) !== getTotalExpenses().toFixed(2)
                          ? "error"
                          : "check_circle"}
                      </Icon>
                      <Typography
                        align="right"
                        style={{ letterSpacing: 0.85 }}
                        variant="caption"
                        color="error"
                      >
                        {getTotal().toFixed(2) !== getTotalExpenses().toFixed(2)
                          ? "Los totales no coinciden"
                          : ""}
                      </Typography>
                    </div>
                  )}
              </Paper>
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex justify-end pr-8 pb-6">
        {!edit && (
          <FormGroup className="p-2" row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={closeOnAdd}
                  onChange={(e) => setCloseOnAdd(e.target.checked)}
                />
              }
              label={"Cerrar al agregar"}
            />
          </FormGroup>
        )}
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
        >
          {edit ? "Editar" : "Agregar"}
        </Button>
      </div>
      <Snackbar
        open={messageConfig.open}
        autoHideDuration={6000}
        onClose={() => setMessageConfig({ ...messageConfig, open: false })}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert
          variant="filled"
          onClose={() => setMessageConfig({ ...messageConfig, open: false })}
          severity={messageConfig.severity}
        >
          {messageConfig.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default ReimbursementForm;
