import React from "react"
import { Routes } from "routes"

function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  )
}

export default App
