import { Button, CircularProgress, Paper, Snackbar, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { AuthContext } from 'context'
import { changePasswordService, FIELDS } from 'lib'
import React from 'react'
import { IMessageConfig } from 'types'

const ChangePassword = () => {
    const { user, logout } = React.useContext(AuthContext)
    const [password, setPassword] = React.useState<string>("")
    const [loading, setLoading] = React.useState<boolean>(false)
    const initialInput = React.useRef<any>(null)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            if (user?.userId) {
                await changePasswordService(user?.userId, { ...user, password })
            }
            setLoading(false)
            setMessageConfig({ open: true, message: "Contraseña actualizada, se procederá a cerrar sesión", severity: "success" })
            setTimeout(() => { logout() }, 4200)
        } catch (error: any) {
            setLoading(false)
            setMessageConfig({ open: true, message: error.message, severity: "error" })
        }
    }
    React.useEffect(() => { setTimeout(() => initialInput.current?.focus(), 500) }, [])
    return (
        <form onSubmit={handleSubmit} className="w-full h-full flex items-center justify-center">
            <Paper className="p-4">
                <TextField
                    autoFocus
                    inputRef={initialInput}
                    size="small"
                    label={FIELDS.newPassword.name}
                    variant="outlined"
                    fullWidth
                    required
                    value={password}
                    style={{ width: 350 }}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    helperText="No puedes ingresar ninguna contraseña antigua"
                />
                <div className="flex items-center justify-end mt-6">
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        disableElevation
                        disabled={loading || !Boolean(password)}
                        type="submit"
                    >
                        {loading ? <CircularProgress style={{ color: "white" }} size={16} className="my-1" thickness={10} /> : "Cambiar"}
                    </Button>
                </div>
            </Paper>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </form>
    )
}

export default ChangePassword
