import { Paper, Icon, Typography, Tooltip, IconButton, Divider, TableContainer, Table, TableHead, TableRow, Drawer, TableCell, TableSortLabel, TableBody, Collapse, Snackbar } from '@material-ui/core'
import { ReimbursementForm } from 'components'
import { useTableModel } from 'hooks'
import { EXPENSE_ITEMS, FIELDS, formatCurrency, REIMBURSEMENT_TABLE_COLUMNS } from 'lib'
import React from 'react'
import { IDocumentHeader, IMessageConfig, IReimbursement, ISupplier } from 'types'
import moment from 'moment'
import 'moment/locale/es'
import { Alert } from '@material-ui/lab'

interface Props {
    reimbursements: IReimbursement[]
    onAdd: (newDetail: IReimbursement) => void
    onEdit: (newDetail: IReimbursement[]) => void
    onDelete: (newDetail: IReimbursement) => void
    loading?: boolean
    withDetails?: boolean
    withExpenses?: boolean
    supplier?: ISupplier
    withTaxSupport?: boolean
    header?: IDocumentHeader
    type?: string
}

const Row = ({ row, index, handleEdit, onDelete, withDetails }: { row: IReimbursement, index: number, handleEdit: (r: IReimbursement) => void, onDelete: (newDetail: IReimbursement) => void, withDetails: boolean | undefined }) => {
    const [open, setOpen] = React.useState(false)
    const getExpenseValue = (key: string) => {
        return row.expenses?.find(e => e.type.key === key)?.value ?? 0
    }
    return (
        <React.Fragment>
            <TableRow
                hover
                tabIndex={-1}
                key={index.toString()}
            >
                <Tooltip title="Ver detalles/gastos" arrow>
                    <TableCell style={{ width: 1 }}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            <Icon fontSize="small">{open ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                        </IconButton>
                    </TableCell>
                </Tooltip>
                <Tooltip arrow title={row.authorizationNumber}>
                    <TableCell
                        className="truncate"
                        style={{ maxWidth: 180, paddingRight: 5 }}
                        component="th"
                        scope="row"
                        padding="none"
                    >
                        {row.authorizationNumber}
                    </TableCell>
                </Tooltip>
                <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    className="truncate"
                    style={{ maxWidth: 180, paddingRight: 5 }}
                >
                    <div className="flex flex-col">
                        {row.issuerName !== undefined ? `${row.issuerName}` : ""}
                    </div>
                    <Typography variant="caption">
                        {row.issuerIdentification !== undefined ? `${row.issuerIdentification}` : ""}
                    </Typography>
                </TableCell>
                <TableCell style={{ maxWidth: 180, paddingRight: 5 }} component="th" scope="row" padding="none">
                    <div>
                        {`${row.receiverName}`}
                        <br />
                        {`${row.receiverIdentification}`}
                    </div>
                </TableCell>
                <TableCell component="th" scope="row" padding="none">{row.documentNumber}</TableCell>
                <TableCell component="th" scope="row" padding="none" align="right">{moment(new Date(row.emissionDate).toISOString()).format('DD-MM-YYYY').toString()}</TableCell>
                <TableCell component="th" scope="row" padding="none" align="right">{formatCurrency(row.subtotalNoTaxesObject)}</TableCell>
                <TableCell component="th" scope="row" padding="none" align="right">{formatCurrency(row.subtotalRateZero)}</TableCell>
                <TableCell component="th" scope="row" padding="none" align="right">{formatCurrency(row.subtotalRateTwelve)}</TableCell>
                <TableCell component="th" scope="row" padding="none" align="right">{formatCurrency(row.subtotalRateQuince)}</TableCell>
                <TableCell component="th" scope="row" padding="none" align="right">{formatCurrency(row.iva)}</TableCell>
                <TableCell component="th" scope="row" padding="none" align="right">{formatCurrency(row.iva15)}</TableCell>
                <TableCell component="th" scope="row" padding="none" align="right">
                    <span>
                        {row.taxSupport ? "S.T." : ""}
                    </span>
                </TableCell>
                <TableCell padding="none" align="right" style={{ minWidth: 100 }}>
                    <Tooltip arrow title="Editar detalle">
                        <IconButton onClick={() => handleEdit(row)} size="small">
                            <Icon color="action" fontSize="small">edit</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip style={{ marginLeft: 10 }} arrow title="Eliminar detalle">
                        <IconButton onClick={() => onDelete(row)} size="small">
                            <Icon color="primary" fontSize="small">close</Icon>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={6} style={{ padding: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div id={`slip-table-${index}`} className="w-full pl-8 my-2">
                            {
                                withDetails &&
                                <React.Fragment>
                                    <div className="w-full flex">
                                        <Typography color="textSecondary" style={{ fontSize: "0.7em", fontWeight: 600 }} variant="button">{"Detalle"}</Typography>
                                        <div style={{ width: "100%", marginLeft: 50, display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                            <Paper variant="outlined" className="px-2 py-1 flex flex-col" style={{ minWidth: "23%", flex: 1, marginRight: 8, marginBottom: 4 }}>
                                                <Typography style={{ fontWeight: 900 }} variant="caption">
                                                    {FIELDS.description.name}
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    {row.description}
                                                </Typography>
                                            </Paper>
                                            <Paper variant="outlined" className="px-2 py-1 flex flex-col" style={{ minWidth: "23%", flex: 1, marginRight: 8, marginBottom: 4 }}>
                                                <Typography style={{ fontWeight: 900 }} variant="caption">
                                                    {FIELDS.accounting.name}
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    {row.accounting}
                                                </Typography>
                                            </Paper>
                                            <Paper variant="outlined" className="px-2 py-1 flex flex-col" style={{ minWidth: "23%", flex: 1, marginRight: 8, marginBottom: 4 }}>
                                                <Typography style={{ fontWeight: 900 }} variant="caption">
                                                    {FIELDS.costCenter.name}
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    {row.costCenter?.description}
                                                </Typography>
                                            </Paper>
                                            <Paper variant="outlined" className="px-2 py-1 flex flex-col" style={{ minWidth: "23%", flex: 1, marginRight: 8, marginBottom: 4 }}>
                                                <Typography style={{ fontWeight: 900 }} variant="caption">
                                                    {FIELDS.branch.name}
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    {row.branch?.description}
                                                </Typography>
                                            </Paper>
                                        </div>
                                    </div>
                                    <div className="my-2">
                                        <Divider />
                                    </div>
                                </React.Fragment>
                            }
                            <div className="w-full flex mt-4">
                                <Typography color="textSecondary" style={{ fontSize: "0.7em", fontWeight: 600 }} variant="button">{"Gastos"}</Typography>
                                <div style={{ width: "100%", marginLeft: 50, display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
                                    {EXPENSE_ITEMS.map(item => (
                                        <Paper variant="outlined" className="px-2 py-1 flex flex-col" style={{ minWidth: "24%", maxWidth: "24%", flex: 1, marginRight: "1%", marginBottom: 4 }}>
                                            <Typography style={{ fontWeight: 900 }} variant="caption">
                                                {item.name}
                                            </Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {formatCurrency(getExpenseValue(item.key))}
                                            </Typography>
                                        </Paper>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

const DocumentReimbursement = (props: Props) => {
    const { withDetails, onAdd, onDelete, onEdit, reimbursements, withExpenses, supplier, withTaxSupport, header, type } = props
    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false)
    const { createSortHandler, order, orderBy, rows } = useTableModel()
    const [edit, setEdit] = React.useState<IReimbursement | undefined>(undefined)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const handleEdit = (reimbursement: IReimbursement) => {
        setEdit(reimbursement)
        setOpenDrawer(true)
    }
    const handleNew = () => {
        if (!Boolean(supplier)) {
            setMessageConfig({ open: true, message: "Selecciona un empleado para agregar reembolsos", severity: "warning" })
            return
        }
        setOpenDrawer(true)
        setEdit(undefined)
    }
    const isReimbursement = React.useCallback(() => {
        return type === "employee-reimbursement" || type === "cash-reimbursement"
    }, [type])
    return (
        <React.Fragment>
            <Paper className="h-full flex flex-col" style={{ padding: "2px 16px" }}>
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <Icon color="action">subject</Icon>
                        <Typography className="pl-2" style={{ fontWeight: 600, letterSpacing: 0.5 }} color="textSecondary" variant="body2">{"Reembolsos"}</Typography>
                    </div>
                    <Tooltip arrow title="Nuevo detalle">
                        <IconButton onClick={handleNew}>
                            <Icon>add</Icon>
                        </IconButton>
                    </Tooltip>
                </div>
                <Divider />
                <div className="flex flex-grow mb-2 overflow-y-auto">
                    <TableContainer style={{ display: "grid" }}>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {
                                        withDetails &&
                                        <TableCell padding={"none"} />
                                    }
                                    {
                                        (REIMBURSEMENT_TABLE_COLUMNS).map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align={
                                                    headCell.id === FIELDS.emissionDate.key ||
                                                        headCell.id === FIELDS.subtotal.key ||
                                                        headCell.id === FIELDS.subtotalRateTwelve.key ||
                                                        headCell.id === FIELDS.subtotalRateZero.key ||
                                                        headCell.id === FIELDS.iva.key ||
                                                        headCell.id === FIELDS.detail.key
                                                        ?
                                                        'right' : 'left'
                                                }
                                                padding={"none"}
                                                sortDirection={orderBy === headCell.id ? order : false}
                                                style={{ width: withDetails ? 200 : undefined }}
                                                className="truncate"
                                            >
                                                <TableSortLabel
                                                    style={{ fontSize: "0.85em" }}
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={createSortHandler(headCell.id as any)}
                                                >
                                                    {headCell.label}
                                                    {orderBy === headCell.id ? (
                                                        <span className="hidden">
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    <TableCell
                                        padding={"default"}
                                    />
                                    <TableCell
                                        padding={"default"}
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(reimbursements) as IReimbursement[]).map((row, index) => {
                                    return (
                                        <Row
                                            row={row}
                                            index={index}
                                            handleEdit={handleEdit}
                                            onDelete={onDelete}
                                            withDetails={withDetails}
                                        />
                                    )
                                })

                                }
                            </TableBody>
                        </Table>
                        <React.Fragment>
                            {
                                isReimbursement() &&
                                <React.Fragment>
                                    <div className="mx-4">
                                        <Divider orientation="vertical" />
                                    </div>
                                    <div className="w-full flex justify-end">
                                        <div className="flex flex-wrap py-4 mr-2" style={{ maxWidth: "40%", height: "min-content", width: "40%" }}>
                                            <div className="w-full">
                                                <Typography
                                                    style={{ fontSize: "0.8em", fontWeight: 800, width: "100%" }}
                                                    align="right"
                                                >
                                                    {"Sustentos tributarios"}
                                                </Typography>
                                                <div className="w-full flex justify-between py-3">
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            {FIELDS.subtotalNoTaxesObject.name}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                        >
                                                            {formatCurrency(header?.reimbursementSummary?.taxSupport.subtotalNoTaxesObject ?? 0)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            {FIELDS.subtotalRateZero.name}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                        >
                                                            {formatCurrency(header?.reimbursementSummary?.taxSupport.subtotalRateZero ?? 0)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            {FIELDS.subtotalRateTwelve.name}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                        >
                                                            {formatCurrency(header?.reimbursementSummary?.taxSupport.subtotalRateTwelve ?? 0)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            {FIELDS.iva.name}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                        >
                                                            {formatCurrency(header?.reimbursementSummary?.taxSupport.iva ?? 0)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            {'Subtotal IVA 15%'}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                        >
                                                            {formatCurrency(header?.reimbursementSummary?.taxSupport.subtotalRateQuince ?? 0)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            {FIELDS.iva.name}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                        >
                                                            {formatCurrency(header?.reimbursementSummary?.taxSupport.iva15 ?? 0)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            {FIELDS.total.name}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                        >
                                                            {formatCurrency(header?.reimbursementSummary?.taxSupport.total ?? 0)}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <Typography
                                                    style={{ fontSize: "0.8em", fontWeight: 800, width: "100%" }}
                                                    align="right"
                                                >
                                                    {"Sustentos no tributarios"}
                                                </Typography>
                                                <div className="w-full flex justify-between py-3">
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            {FIELDS.subtotalNoTaxesObject.name}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                        >
                                                            {formatCurrency(header?.reimbursementSummary?.nonTaxesSupport.subtotalNoTaxesObject ?? 0)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            {FIELDS.subtotalRateZero.name}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                        >
                                                            {formatCurrency(header?.reimbursementSummary?.nonTaxesSupport.subtotalRateZero ?? 0)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            {FIELDS.subtotalRateTwelve.name}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                        >
                                                            {formatCurrency(header?.reimbursementSummary?.nonTaxesSupport.subtotalRateTwelve ?? 0)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            {FIELDS.iva.name}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                        >
                                                            {formatCurrency(header?.reimbursementSummary?.nonTaxesSupport.iva ?? 0)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            {FIELDS.total.name}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                        >
                                                            {formatCurrency(header?.reimbursementSummary?.nonTaxesSupport.total ?? 0)}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    </TableContainer>
                </div>
            </Paper>
            <Drawer onClose={() => setOpenDrawer(false)} anchor="right" open={openDrawer}>
                <div style={{ width: withExpenses ? "80vw" : 600 }} className="h-full flex flex-col overflow-y-hidden">
                    <div className="flex flex-wrap items-center p-4 relative">
                        <IconButton onClick={() => setOpenDrawer(false)} className="absolute">
                            <Icon color="action">
                                {"chevron_left"}
                            </Icon>
                        </IconButton>
                        <Typography className="uppercase pl-2" color="textSecondary" variant="h6" align="center" style={{ fontWeight: 600, letterSpacing: 0.25 }}>
                            {"Nuevo reembolso"}
                        </Typography>
                    </div>
                    <Divider variant="middle" />
                    <ReimbursementForm
                        withDetails={withDetails}
                        onAdd={(newReimbursement) => {
                            if (edit) {
                                onEdit(reimbursements.map(r => r === edit ? newReimbursement : r))
                                setEdit(undefined)
                                setOpenDrawer(false)
                            } else {
                                onAdd(newReimbursement)
                            }
                        }}
                        onClose={() => { setOpenDrawer(false); setEdit(undefined) }}
                        edit={edit}
                        withExpenses={withExpenses}
                        supplierIdentification={supplier?.identification ?? ""}
                        supplierName={supplier?.companyName ?? ""}
                        withTaxSupport={withTaxSupport}
                    />
                </div>
            </Drawer>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default DocumentReimbursement
