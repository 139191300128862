export const COUPA_STATES = {
    unsend: 0,
    error: 104,
    sent: 105,
    rejectedCoupa: 108,
    rejectedSat: 109,
    internalRejected: 112
}

export const REJECTED_STATES_VIEW = [{ id: COUPA_STATES.internalRejected, name: "Rechazo Interno" }, { id: COUPA_STATES.rejectedSat, name: "Rechazado SAT" }]

export const COUPA_QUEUEABLE_DOCUMENT_STATUS = [COUPA_STATES.error, COUPA_STATES.sent, COUPA_STATES.unsend]