import { Icon, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { getLastRejectedHistoryService } from 'lib'
import moment from 'moment'
import React from 'react'
import 'moment/locale/es'

moment.locale("es")

interface Props {

}

interface Hook {
    getLast: () => Promise<void>
    last: Date | undefined
    LastAlert: () => JSX.Element
}

const useGetLast = (props?: Props): Hook => {
    const [last, setLast] = React.useState<Date | undefined>()

    const getLast = React.useCallback(async () => {
        try {
            const result = await getLastRejectedHistoryService()
            setLast(result)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const LastAlert = () => {
        return (
            <Alert
                severity="warning"
                icon={<Icon>event</Icon>}
                style={{ width: '50%' }}
            >
                <AlertTitle style={{ fontSize: '0.8em', height: 12 }}>Actualizado a:</AlertTitle>
                {

                    last ?
                        <div className='flex items-center'>
                            <Typography style={{ fontWeight: 700 }} variant="caption">
                                {moment(new Date(last).toISOString()).format('DD-MM-YYYY - LTS').toString()}
                            </Typography>
                            <Typography style={{ fontWeight: 700, fontSize: "0.8em", marginLeft: 5 }} >
                                {moment(new Date(last).toISOString()).fromNow()}
                            </Typography>
                        </div>
                        :
                        "--"
                }
            </Alert>
        )
    }

    React.useEffect(() => {
        getLast()
    }, [getLast])

    return {
        getLast,
        last,
        LastAlert
    }
}

export default useGetLast
