import { Avatar, Button, Icon, IconButton, LinearProgress, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ConfirmDialog, DetailData, FixedLoadingIndicator, FormDialog, FullSizeDialog, LabelDisplayedRows, ServerErrorHandler } from 'components'
import { AuthContext, DocumentContext, ManagementContext } from 'context'
import { useTableModel } from 'hooks'
import { ROOT_TITLE, PURCHASE_ORDER_TITLE, PURCHASE_ORDER_PATH, NEW_PURCHASE_ORDER_PATH, SMALL_ROWS_PER_PAGINATION_OPTIONS, FIELDS, PURCHASE_ORDER_TABLE_COLUMNS, PURCHASE_ORDER_STATUS, EDIT_PURCHASE_ORDER_PATH, PURCHASE_ORDER_VIEW_PATH, deletePurchaseOrderService, downloadPurchaseOrderReport, INSERTED_UPDATED_OR_PER_APPROVAL_PO_COLUMNS, SUPPLIER_PO_COLUMN, FINISHED_PO_COLUMNS, ASSIGNED_PO_COLUMNS, getDocumentByIdService, DOCUMENT_PATH, DOCUMENT_VIEW_PATH } from 'lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router-dom'
import { IDetail, IDocument, IMessageConfig, IPurchaseDetailSupplierInfo, IPurchaseOrder } from 'types'
import moment from 'moment'
import 'moment/locale/es'
import FileSaver from 'file-saver'

moment.locale("es")

interface Props {
    processStatus: string
}

const PurchaseOrderDashboard = (props: Props) => {
    const { processStatus } = props
    const history = useHistory()
    const location = useLocation()
    const { refreshTaxTypes, refreshTaxes, refreshIdentificationTypes, taxes, taxTypes, identificationTypes } = React.useContext(ManagementContext)
    const { purchaseOrders, getPurchaseOrdersByInitialCondition, totalPurchaseOrders, getNextPurchaseOrderByFilters } = React.useContext(DocumentContext)
    const { user, userApproval } = React.useContext(AuthContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false)
    const [deleteId, setDeleteId] = React.useState<number>(-1)
    const [error, setError] = React.useState<boolean>(false)
    const [selectedDetails, setSelectedDetails] = React.useState<IDetail[] | undefined>(undefined)
    const [selectedPurchaseDetail, setSelectedPurchaseDetail] = React.useState<IPurchaseDetailSupplierInfo[] | undefined>(undefined)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info", })
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage, } = useTableModel()
    const isInsertedRejectedUpdatedOrPerApproval = React.useCallback((): boolean => {
        return (
            processStatus.includes(PURCHASE_ORDER_STATUS.inserted.conditionId.toString()) ||
            processStatus.includes(PURCHASE_ORDER_STATUS.updated.conditionId.toString()) ||
            processStatus.includes(PURCHASE_ORDER_STATUS.perApproval.conditionId.toString()) ||
            processStatus.includes(PURCHASE_ORDER_STATUS.rejected.conditionId.toString())
        )
    }, [processStatus])
    const isInsertedOrUpdated = React.useCallback((): boolean => {
        return (
            processStatus.includes(PURCHASE_ORDER_STATUS.inserted.conditionId.toString()) ||
            processStatus.includes(PURCHASE_ORDER_STATUS.updated.conditionId.toString())
        )
    }, [processStatus])
    const isFinishedOrAssigned = React.useCallback((): boolean => {
        return (
            processStatus.includes(PURCHASE_ORDER_STATUS.finished.conditionId.toString()) ||
            processStatus.includes(PURCHASE_ORDER_STATUS.assigned.conditionId.toString())
        )
    }, [processStatus])
    const isPerApproval = React.useCallback((): boolean => {
        return (processStatus.includes(PURCHASE_ORDER_STATUS.perApproval.conditionId.toString()))
    }, [processStatus])
    const isFinished = React.useCallback((): boolean => {
        return (processStatus.includes(PURCHASE_ORDER_STATUS.finished.conditionId.toString()))
    }, [processStatus])
    const isAssigned = React.useCallback((): boolean => {
        return (processStatus.includes(PURCHASE_ORDER_STATUS.assigned.conditionId.toString()))
    }, [processStatus])
    const isRejected = React.useCallback((): boolean => {
        return (processStatus.includes(PURCHASE_ORDER_STATUS.rejected.conditionId.toString()))
    }, [processStatus])
    const getModel = React.useCallback((): any[] => {
        return initialLoading ? [] : purchaseOrders
    }, [purchaseOrders, initialLoading])
    const handleSelect = (purchaseOrder: IPurchaseOrder) => {
        if ((!userApproval || userApproval.approvalLevelId < 1) && purchaseOrder.document.flowStatus === PURCHASE_ORDER_STATUS.perApproval.conditionId) {
            setMessageConfig({ open: true, message: "Tu usuario no cuenta con un nivel de aprobación necesario", severity: "warning" })
            return
        }
        history.push(isInsertedOrUpdated() ?
            {
                state: { edit: purchaseOrder },
                pathname: `${PURCHASE_ORDER_PATH}${EDIT_PURCHASE_ORDER_PATH}/${purchaseOrder.document.documentId}`
            } :
            {
                state: { purchaseOrder },
                pathname: `${PURCHASE_ORDER_PATH}${PURCHASE_ORDER_VIEW_PATH}/${purchaseOrder.document.documentId}`
            }
        )
    }
    const handleDelete = async () => {
        try {
            setLoading(true)
            await deletePurchaseOrderService(deleteId)
            await getPurchaseOrdersByInitialCondition(user?.userId ?? 0, processStatus)
            setMessageConfig({ open: true, message: "Orden de compra cancelada!", severity: "success" })
            setDeleteId(-1)
            setLoading(false)
            return true
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo cancelar la orden de compra!", severity: "success" })
            return false
        }
    }

    const handleReport = async () => {
        try {
            setLoading(true)
            const result = await downloadPurchaseOrderReport()
            FileSaver.saveAs(result, `Reporte_${new Date().toLocaleDateString()}.xlsx`)
            setMessageConfig({ open: true, message: "Su descarga ha comenzado", severity: "info" })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo descargar el archivo!", severity: "error" })
        }
    }

    const handleDocument = async (document: IDocument | undefined) => {
        if (!document) { return }
        try {
            setLoading(true)
            if (identificationTypes.length === 0) { refreshIdentificationTypes() }
            if (taxTypes.length === 0) { refreshTaxTypes() }
            if (taxes.length === 0) { refreshTaxes() }
            const response = await getDocumentByIdService(document?.documentId)
            history.push({
                state: { document: response },
                pathname: `${DOCUMENT_PATH}${DOCUMENT_VIEW_PATH}/${document.documentId}`
            })
            setLoading(false)
        } catch (error) {
            console.log(error)
            setMessageConfig({ open: true, message: "No se pudo obtener información del documento selecionado...", severity: "error" })
            setLoading(false)
        }
    }

    const handleNewPage = async (pageNumber: number, offset: number) => {
        try {
            setLoading(true)
            await getNextPurchaseOrderByFilters(user?.userId ?? 0, offset, pageNumber, processStatus)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }

    React.useEffect(() => {
        const initScreen = async () => {
            try {
                if (history.action === "REPLACE" || history.action === "PUSH") {
                    setInitialLoading(true)
                    await getPurchaseOrdersByInitialCondition(isPerApproval() || userApproval?.approvalLevelId === 0 ? 0 : user?.userId ?? 0, processStatus)
                    setInitialLoading(false)
                }
            } catch (error) {
                setInitialLoading(false)
                setError(true)
            }
        }
        initScreen()
    }, [history, getPurchaseOrdersByInitialCondition, processStatus, isPerApproval, user, userApproval])

    React.useEffect(() => {
        if (history.action === "REPLACE" || history.action === "PUSH") {
            if ((location.state as any)?.showCreatedMessage) {
                setMessageConfig({ open: true, message: "Orden de compra creada exitosamente!", severity: "success" })
            }
            if ((location.state as any)?.showCompletedMessage) {
                setMessageConfig({ open: true, message: "Orden de compra finalizada!", severity: "success" })
            }
            if ((location.state as any)?.showRejectedMessage) {
                setMessageConfig({ open: true, message: "Orden de compra rechazada!", severity: "success" })
            }
        }
    }, [location, history.action])
    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${PURCHASE_ORDER_TITLE}`}</title>
            </Helmet>
            <div className="flex items-center">
                <div className="flex items-center justify-end w-full">
                    {
                        (processStatus === PURCHASE_ORDER_STATUS.finished.conditionId.toString() || processStatus === PURCHASE_ORDER_STATUS.assigned.conditionId.toString()) &&
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handleReport}
                        >
                            <Icon fontSize="small" style={{ marginRight: 5 }}>get_app</Icon>
                            {"Descargar excel"}
                        </Button>
                    }
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: 20 }}
                        disableElevation
                        onClick={() => history.push(`${PURCHASE_ORDER_PATH}${NEW_PURCHASE_ORDER_PATH}`)}
                    >
                        <Icon fontSize="small" style={{ marginRight: 5 }}>add</Icon>
                        {"Nueva solicitud de orden de compra"}
                    </Button>
                </div>
            </div>
            <div className="flex h-full flex-col mt-4 overflow-hidden">
                {
                    initialLoading &&
                    <LinearProgress />
                }
                <div className="flex flex-grow overflow-auto">
                    <TableContainer>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {PURCHASE_ORDER_TABLE_COLUMNS.concat((isInsertedRejectedUpdatedOrPerApproval()) ? INSERTED_UPDATED_OR_PER_APPROVAL_PO_COLUMNS : SUPPLIER_PO_COLUMN.concat(isRejected() ? [] : !isFinished() ? ASSIGNED_PO_COLUMNS : FINISHED_PO_COLUMNS)).map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={(headCell.id === FIELDS.detailCount.key || headCell.id === FIELDS.suppliersCount.key) ? "right" : "left"}
                                            padding={"default"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : "asc"}
                                                onClick={createSortHandler(headCell.id as any)}
                                                style={{ fontSize: "0.85em", width: headCell.id === FIELDS.userName.key ? 160 : ((headCell.id === FIELDS.companyName.key || headCell.id === FIELDS.receiverName.key) ? 320 : "max-content") }}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === "desc"
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    {
                                        isInsertedOrUpdated() &&
                                        <TableCell
                                            padding={"default"}
                                        ></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(getModel()) as (IPurchaseOrder)[]).map(
                                    (row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={index.toString()}
                                                className="cursor-pointer"
                                                onClick={(loading || initialLoading) ? undefined : () => handleSelect(row)}
                                            >
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row.historyData[0]?.userName}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row.data.header?.poRequestNumber ?? ""}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row.data.header?.subject}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {moment(new Date(row.data.header?.createDate).toISOString()).format('DD-MM-YYYY').toString()}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {row.data.header?.deliveryAddress}
                                                </TableCell>
                                                {
                                                    (isInsertedRejectedUpdatedOrPerApproval()) ?
                                                        <React.Fragment>
                                                            <TableCell padding="default" align="right">
                                                                <div className="flex items-center justify-end">
                                                                    {row.data.detail?.length}
                                                                    <Tooltip
                                                                        arrow
                                                                        title="Ver detalles requeridos"
                                                                    >
                                                                        {
                                                                            !loading ?
                                                                                <IconButton
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation()
                                                                                        if (row.data.detail.length > 0) {
                                                                                            setSelectedDetails(row.data.detail)
                                                                                        } else {
                                                                                            setMessageConfig({ open: true, message: "No cuenta con detalles agregados", severity: "error" })
                                                                                        }
                                                                                    }}
                                                                                    style={{ marginLeft: 12 }}
                                                                                    size="small"
                                                                                >
                                                                                    <Icon color="action" fontSize="small">
                                                                                        more_vert
                                                                                    </Icon>
                                                                                </IconButton>
                                                                                :
                                                                                <React.Fragment />
                                                                        }
                                                                    </Tooltip>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell padding="default" align="right">
                                                                <div className="flex items-center justify-end">
                                                                    {row.data.suppliersInfo?.length ?? 0}
                                                                    <Tooltip
                                                                        arrow
                                                                        title="Ver proveedores seleccionados"
                                                                    >
                                                                        {
                                                                            !loading ?
                                                                                <IconButton
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation()
                                                                                        if (row.data.suppliersInfo?.length > 0) {
                                                                                            setSelectedPurchaseDetail(row.data.suppliersInfo)
                                                                                        } else {
                                                                                            setMessageConfig({ open: true, message: "No cuenta con proveedores seleccionados", severity: "error" })
                                                                                        }
                                                                                    }}
                                                                                    style={{ marginLeft: 12 }}
                                                                                    size="small"
                                                                                >
                                                                                    <Icon color="action" fontSize="small">
                                                                                        more_vert
                                                                                    </Icon>
                                                                                </IconButton>
                                                                                :
                                                                                <React.Fragment />
                                                                        }
                                                                    </Tooltip>
                                                                </div>
                                                            </TableCell>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <TableCell padding="default" align="left">
                                                                <div className="flex items-center">
                                                                    {row.data.header.supplier?.companyName}
                                                                    <Tooltip
                                                                        arrow
                                                                        interactive
                                                                        title={
                                                                            <div className="p-4 flex flex-col">
                                                                                <Typography variant="caption">
                                                                                    {row.data.header.supplier?.companyName}
                                                                                </Typography>
                                                                                <Typography variant="caption">
                                                                                    {row.data.header.supplier?.identification}
                                                                                </Typography>
                                                                                <Typography variant="caption">
                                                                                    {row.data.header.supplier?.address}
                                                                                </Typography>
                                                                                <Typography variant="caption">
                                                                                    {row.data.header.supplier?.keepAccounting}
                                                                                </Typography>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <Icon color="action" style={{ marginLeft: 12 }} fontSize="small">
                                                                            info
                                                                        </Icon>
                                                                    </Tooltip>
                                                                </div>
                                                            </TableCell>
                                                            {
                                                                isFinishedOrAssigned() &&
                                                                <TableCell padding="default" align="left">
                                                                    {row.document.serialNumber}
                                                                </TableCell>
                                                            }
                                                            {
                                                                isAssigned() &&
                                                                <TableCell
                                                                    className="document-link"
                                                                    onClick={(e) => { e.stopPropagation(); handleDocument(row?.associated[0]) }}
                                                                    padding="default" align="left"
                                                                >
                                                                    {row?.associated[0]?.serialNumber}
                                                                </TableCell>
                                                            }
                                                        </React.Fragment>
                                                }
                                                {
                                                    isInsertedOrUpdated() &&
                                                    <TableCell
                                                        padding={"default"}
                                                    >
                                                        <Tooltip title="Cancelar orden de compra">
                                                            <IconButton
                                                                onClick={(e) => {
                                                                    setDeleteId(row.document.documentId)
                                                                    e.stopPropagation()
                                                                }}
                                                                size="small"
                                                            >
                                                                <Icon color="action" fontSize="small">close</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        )
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <TablePagination
                        rowsPerPageOptions={SMALL_ROWS_PER_PAGINATION_OPTIONS}
                        component="div"
                        count={totalPurchaseOrders}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={async (event, pageNumber) => {
                            if (pageNumber > page && ((pageNumber + 1) * rowsPerPage) > getModel().length && getModel().length < totalPurchaseOrders) {
                                await handleNewPage(pageNumber, rowsPerPage)
                            }
                            handleChangePage(event, pageNumber)
                        }}
                        onRowsPerPageChange={async (event) => {
                            if (parseInt(event.target.value, 10) > rowsPerPage && parseInt(event.target.value, 10) > getModel().length && getModel().length < totalPurchaseOrders) {
                                await handleNewPage(page, parseInt(event.target.value, 10))
                            }
                            handleChangeRowsPerPage(event as any)
                        }}
                        labelRowsPerPage="Documentos por página"
                        labelDisplayedRows={LabelDisplayedRows}
                    />
                </div>
            </div>
            <Snackbar
                open={messageConfig.open}
                autoHideDuration={6000}
                onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Alert
                    variant="filled"
                    onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                    severity={messageConfig.severity}
                >
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading} />
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={getPurchaseOrdersByInitialCondition}
            />
            <FullSizeDialog
                open={Boolean(selectedDetails)}
                onClose={() => setSelectedDetails(undefined)}
            >
                <div className="w-full pb-6 pt-2 px-5">
                    <div className="flex w-full justify-between items-center pl-2">
                        <Typography style={{ fontWeight: 800 }} color="textSecondary">
                            {"Detalles solicitados"}
                        </Typography>
                        <IconButton onClick={() => setSelectedDetails(undefined)}>
                            <Icon fontSize="small">close</Icon>
                        </IconButton>
                    </div>
                    <div className="overflow-y-auto" style={{ maxHeight: "50vh" }}>
                        <DetailData
                            detail={selectedDetails ?? []}
                            hideDiscount
                            hideTotal
                            hideUnitPrice
                        />
                    </div>
                </div>
            </FullSizeDialog>
            <FormDialog
                open={Boolean(selectedPurchaseDetail)}
                onClose={() => setSelectedPurchaseDetail(undefined)}
            >
                <div className="w-full pb-6 pt-2 px-5">
                    <div className="flex w-full justify-between items-center pl-2 pb-1">
                        <Typography style={{ fontWeight: 800 }} color="textSecondary">
                            {"Proveedores seleccionados"}
                        </Typography>
                        <IconButton onClick={() => setSelectedPurchaseDetail(undefined)}>
                            <Icon fontSize="small">close</Icon>
                        </IconButton>
                    </div>
                    <div className="overflow-y-auto" style={{ maxHeight: "70vh" }}>
                        {(selectedPurchaseDetail ?? []).map(info => (
                            <Paper className="pl-6 my-4 mx-2 flex items-center p-4">
                                <Avatar>
                                    {info.supplier.companyName.charAt(0).toUpperCase()}
                                </Avatar>
                                <div className="flex flex-col mx-6">
                                    <Typography style={{ fontWeight: 800 }} variant="subtitle1">
                                        {info.supplier.companyName}
                                    </Typography>
                                    <Typography style={{ fontWeight: 800 }} variant="subtitle2">
                                        {info.supplier.identification}
                                    </Typography>
                                    <Typography style={{ fontWeight: 800 }} variant="subtitle2">
                                        {info.supplier.address}
                                    </Typography>
                                    <Typography className="pt-2" style={{ fontWeight: 800 }} color="textSecondary" variant="caption">
                                        {`Término de pago: ${info.paymentTerm} días`}
                                    </Typography>
                                    <Typography style={{ fontWeight: 800 }} color="textSecondary" variant="caption">
                                        {`Fecha de entrega: ${moment(new Date(info.receptionDate).toISOString()).format('DD-MM-YYYY').toString()}`}
                                    </Typography>
                                    {
                                        info.comment &&
                                        <Typography style={{ fontWeight: 800 }} color="textSecondary" variant="caption">
                                            {`Comentario: ${info.comment}`}
                                        </Typography>
                                    }
                                </div>
                            </Paper>
                        ))}
                    </div>
                </div>
            </FormDialog>
            <ConfirmDialog
                onCancel={() => setDeleteId(-1)}
                onConfirm={handleDelete}
                open={Boolean(deleteId !== -1)}
                title={"¿Estás seguro que desea eliminar?"}
                loading={loading}
            />
        </Paper>
    )
}

export default PurchaseOrderDashboard
