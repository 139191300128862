import NumberFormat from "react-number-format"

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
    prefix?: boolean
}

export default function BLInput(props: NumberFormatCustomProps) {
    const { prefix, inputRef, onChange, ...other } = props

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }}
            isNumericString
            decimalScale={0}
            allowNegative={false}
            allowLeadingZeros={true}
        />
    )
}