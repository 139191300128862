import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import { theme } from 'styles'
import { Nav } from 'components'
import { AuthProvider, DocumentProvider, FileProvider, ManagementProvider } from 'context'
import Resources from './Resources/Resources'
import Rejected from './Rejected/Rejected'
import Public from './Public/Public'
import Authentication from './Authentication/Authentication'
import Documents from './Documents/Documents'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { es } from 'date-fns/locale'
import PurchaseOrders from './PurchaseOrders/PurchaseOrders'
import Reports from './Reports/Reports'
import Closing from './Closing/Closing'
import moment from 'moment'
import 'moment/locale/es'
import Received from './Received/Received'
import Retentions from './Retentions/Retentions'
import Payments from './Payments/Payments'

moment.locale("es")

const Routes = () => {
    return (
        <Router>
            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <ManagementProvider>
                        <DocumentProvider>
                            <FileProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                                    <Nav>
                                        <Switch>
                                            <Public />
                                        </Switch>
                                        <Switch>
                                            <Resources />
                                        </Switch>
                                        <Switch>
                                            <Authentication />
                                        </Switch>
                                        <Switch>
                                            <Documents />
                                        </Switch>
                                        <Switch>
                                            <PurchaseOrders />
                                        </Switch>
                                        <Switch>
                                            <Reports />
                                        </Switch>
                                        <Switch>
                                            <Rejected />
                                        </Switch>
                                        <Switch>
                                            <Closing />
                                        </Switch>
                                        <Switch>
                                            <Received />
                                        </Switch>
                                        <Switch>
                                            <Retentions />
                                        </Switch>
                                        <Switch>
                                            <Payments />
                                        </Switch>
                                    </Nav>
                                </MuiPickersUtilsProvider>
                            </FileProvider>
                        </DocumentProvider>
                    </ManagementProvider>
                </AuthProvider>
            </ThemeProvider>
        </Router>
    )
}

export default Routes
