import { Divider, Paper, Tabs, TextField, Typography, Tab, Checkbox, Button, Icon, InputAdornment, IconButton, Tooltip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import { IDetail, IPurchaseDetailSupplierInfo, ISupplier, ITax, ITaxType, IPurchaseOrderBackUpFile, IMessageConfig } from 'types'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { DatePicker } from '@material-ui/pickers'
import { FIELDS, formatCurrency, getSubtotalBySupplierInfo, getTotalBySupplierInfo } from 'lib'
import { CurrencyInput, FullSizeDialog, PercentageInput, SupplierForm } from 'components'
import { ManagementContext } from 'context'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const inputStyle = { width: "32%" }
const detailInputStyle = { width: "25%", marginBottom: 7.5, marginTop: 7.5 }

interface Props {
    suppliers: ISupplier[]
    detail: IDetail[]
    taxes: ITax[]
    taxTypes: ITaxType[]
    suppliersInfo: IPurchaseDetailSupplierInfo[]
    onChange: (suppliersInfo: IPurchaseDetailSupplierInfo[]) => void
    backUpFiles: IPurchaseOrderBackUpFile[]
    setBackUpFiles: React.Dispatch<IPurchaseOrderBackUpFile[]>
    setMessageConfig: React.Dispatch<IMessageConfig>
}

const SupplierApproach = React.forwardRef((props: Props, ref?: any) => {
    const { suppliers, detail, suppliersInfo, onChange, taxTypes, taxes, backUpFiles, setBackUpFiles, setMessageConfig } = props
    const { refreshSuppliers } = React.useContext(ManagementContext)
    const [selected, setSelected] = React.useState<ISupplier[]>(props.suppliersInfo.map(s => s.supplier) ?? [])
    const [tabValue, setTabValue] = React.useState<number>(0)
    const [openNewSupplier, setOpenNewSupplier] = React.useState<boolean>(false)
    const handleSuccess = async () => {
        await refreshSuppliers()
        setMessageConfig({ open: true, message: "Proveedor registrado!", severity: "success" })
    }
    const getNoRetentionTaxes = React.useCallback((): ITax[] => {
        const noRetentionTaxTypes = taxTypes.filter(t => !t.forRetention).map(t => t.taxId)
        return taxes.filter(t => noRetentionTaxTypes.includes(t.taxTypeId))
    }, [taxes, taxTypes])
    const getTotal = React.useCallback(() => {
        return getTotalBySupplierInfo(suppliersInfo[tabValue])
    }, [tabValue, suppliersInfo])
    const getSubtotal = React.useCallback(() => {
        return getSubtotalBySupplierInfo(suppliersInfo[tabValue])
    }, [tabValue, suppliersInfo])
    React.useImperativeHandle(ref, () => ({
        onChangeTab(newTabValue: number) { setTabValue(newTabValue) }
    }))
    return (
        <Paper className="h-full flex flex-col p-2 overflow-hidden">
            <div className="flex p-2 flex-col mb-2">
                <div className="flex w-full items-center">
                    <Autocomplete
                        multiple
                        loadingText="Cargando..."
                        getOptionLabel={option => option.companyName}
                        noOptionsText="No se encontraron proveedores registrados"
                        options={suppliers}
                        disableCloseOnSelect
                        fullWidth
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.companyName}
                            </React.Fragment>
                        )}
                        openOnFocus
                        value={selected}
                        size="small"
                        onChange={(_, selectedValue, reason, d) => {
                            const option = d?.option
                            if (reason === "clear" || reason === "remove-option") {
                                setTabValue(0)
                                setTimeout(() => {
                                    setSelected(selectedValue);
                                    onChange(suppliersInfo.filter(c => c.supplier !== option))
                                }, 350)
                            } else {
                                setSelected(selectedValue)
                                if (option) {
                                    onChange(suppliersInfo.concat({ paymentTerm: "", receptionDate: new Date(), comment: "", supplier: option, detailBySupplierInfo: detail.map(d => ({ ...d })) }))
                                }
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={"Busca y selecciona el proovedor que desees agregar..."}
                                required
                            />
                        )}
                    />
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        disableElevation
                        style={{ marginLeft: 15 }}
                        onClick={() => setOpenNewSupplier(true)}
                    >
                        <Icon fontSize="small" style={{ marginRight: 5 }}>add</Icon>
                        {"Nuevo"}
                    </Button>
                </div>
            </div>
            <Divider />
            <div className="flex flex-grow overflow-y-hidden">
                <div className="flex flex-col" style={{ minWidth: 220 }}>
                    {
                        selected.length > 0 &&
                        <React.Fragment>
                            <Typography
                                className="py-3"
                                style={{ fontWeight: 600, letterSpacing: 0.2 }}
                                variant="caption"
                                align="center"
                                color="textSecondary"
                            >
                                {"Seleccionados"}
                            </Typography>
                            <Divider />
                        </React.Fragment>
                    }
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        textColor="primary"
                        indicatorColor="primary"
                        className="flex flex-grow"
                        value={tabValue}
                        onChange={(_, newValue) => {
                            setTabValue(newValue)
                        }}
                    >
                        {
                            selected.map(supplier => (
                                <Tab style={{ fontSize: "0.8em", fontWeight: 600, letterSpacing: 0.2 }} label={supplier.companyName} />
                            ))
                        }
                    </Tabs>
                    <div className="px-6 py-2 w-full flex justify-end">
                        <Typography style={{ letterSpacing: 0.5, fontWeight: 600, textTransform: "uppercase" }} variant="caption" color="textSecondary">
                            {`Subtotal ${formatCurrency(getSubtotal())}`}
                        </Typography>
                    </div>
                    <div className="px-6 py-2 w-full flex justify-end">
                        <Typography style={{ letterSpacing: 0.5, fontWeight: 600, textTransform: "uppercase" }} variant="caption" color="textSecondary">
                            {`Total ${formatCurrency(getTotal())}`}
                        </Typography>
                    </div>
                </div>
                <Divider orientation="vertical" />
                {
                    (selected.length > 0) &&
                    <div className="flex px-8 w-full pb-4 flex-col">
                        <div className="flex w-full flex-col py-4">
                            <Typography
                                style={{ fontWeight: 600, letterSpacing: 0.2 }}
                                variant="caption"
                            >
                                {"Información por proveedor"}
                            </Typography>
                            <div className="flex flex-wrap w-full mt-3 justify-between">
                                <DatePicker
                                    required
                                    style={inputStyle}
                                    value={suppliersInfo[tabValue].receptionDate}
                                    onChange={(date) => {
                                        if (date) {
                                            suppliersInfo[tabValue].receptionDate = date
                                            onChange(suppliersInfo)
                                        }
                                    }}
                                    inputVariant="outlined"
                                    fullWidth
                                    size="small"
                                    label={FIELDS.receptionDate.name}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon fontSize="small" color="action">event</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    style={{ ...inputStyle, marginLeft: "2%" }}
                                    variant="outlined"
                                    size="small"
                                    required
                                    label={FIELDS.paymentTerm.name}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {"Días"}
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={suppliersInfo[tabValue].paymentTerm}
                                    onChange={(e) => {
                                        suppliersInfo[tabValue].paymentTerm = e.target.value
                                        onChange(suppliersInfo)
                                    }}
                                />
                                <div className="flex items-center" style={{ ...inputStyle, marginLeft: "2%", height: "100%" }}>
                                    <label htmlFor={`approval-button-file-${suppliersInfo[tabValue].supplier.supplierId}`} className="w-full h-full">
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            component="span"
                                            style={{ height: "100%" }}
                                            className="truncate"
                                            fullWidth
                                            disabled={Boolean(backUpFiles.find(b => b.supplierId === suppliersInfo[tabValue].supplier.supplierId))}
                                        >
                                            <Icon color={Boolean(backUpFiles.find(b => b.supplierId === suppliersInfo[tabValue].supplier.supplierId)) ? "disabled" : "action"} className="pr-6" fontSize="small">
                                                publish
                                            </Icon>
                                            <p style={{ maxWidth: Boolean(backUpFiles.find(b => b.supplierId === suppliersInfo[tabValue].supplier.supplierId)) ? 100 : "none" }} className="truncate">
                                                {Boolean(backUpFiles.find(b => b.supplierId === suppliersInfo[tabValue].supplier.supplierId)) ? backUpFiles.find(b => b.supplierId === suppliersInfo[tabValue].supplier.supplierId)?.file.name : "Archivos de respaldo"}
                                            </p>
                                        </Button>
                                    </label>
                                    {
                                        Boolean(backUpFiles.find(b => b.supplierId === suppliersInfo[tabValue].supplier.supplierId)) &&
                                        <Tooltip arrow title="Eliminar archivo">
                                            <IconButton
                                                style={{ marginLeft: 8 }}
                                                onClick={() => {
                                                    setBackUpFiles(backUpFiles.filter(b => b.supplierId !== suppliersInfo[tabValue].supplier.supplierId))
                                                }}
                                                size="small"
                                            >
                                                <Icon fontSize="small" color="action">
                                                    close
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                                <input
                                    accept="image/*,application/pdf"
                                    className="hidden"
                                    id={`approval-button-file-${suppliersInfo[tabValue].supplier.supplierId}`}
                                    type="file"
                                    multiple={false}
                                    onChange={(e) => {
                                        if (e.target.files !== null && e.target.files[0]) {
                                            setBackUpFiles(backUpFiles.concat({ file: e.target.files[0], supplierId: suppliersInfo[tabValue].supplier.supplierId }))
                                        }
                                    }}
                                />
                            </div>
                            <TextField
                                style={{ ...inputStyle, marginTop: 15, width: "100%" }}
                                variant="outlined"
                                size="small"
                                label={FIELDS.comment.name}
                                value={suppliersInfo[tabValue].comment}
                                onChange={(e) => {
                                    suppliersInfo[tabValue].comment = e.target.value
                                    onChange(suppliersInfo)
                                }}
                            />
                        </div>
                        <div className="flex flex-grow flex-col overflow-y-hidden">
                            <div className="mt-1">
                                <Typography
                                    style={{ fontWeight: 600, letterSpacing: 0.2 }}
                                    variant="caption"
                                >
                                    {"Información por detalle"}
                                </Typography>
                            </div>
                            <div className="flex flex-grow overflow-y-auto flex-col">
                                {
                                    suppliersInfo[tabValue].detailBySupplierInfo.map((d, i) => {
                                        return (
                                            <Paper key={"detail" + i + suppliersInfo[tabValue].supplier.supplierId} className="flex p-4 my-1 items-center" variant="outlined">
                                                <div className="flex flex-col mr-3" style={{ maxWidth: 200 }}>
                                                    <Typography variant="caption" style={{ fontWeight: 600, fontSize: "0.75em", letterSpacing: 0.2 }}>
                                                        {`${FIELDS.purchaseCode.name}: ${d.purchaseCode}`}
                                                    </Typography>
                                                    <Typography color="textSecondary" variant="caption" style={{ fontWeight: 600, fontSize: "0.8em", letterSpacing: 0.2 }}>
                                                        {`${FIELDS.description.name} solicitada: ${d.description}`}
                                                    </Typography>
                                                    <Typography color="textSecondary" variant="caption" style={{ fontWeight: 600, fontSize: "0.8em", letterSpacing: 0.2 }}>
                                                        {`${FIELDS.quantity.name} solicitada: ${d.quantity}`}
                                                    </Typography>
                                                </div>
                                                <TextField
                                                    key={"textField" + i + suppliersInfo[tabValue].supplier.supplierId}
                                                    style={{ ...detailInputStyle, marginLeft: "2%" }}
                                                    size="small"
                                                    required
                                                    label={FIELDS.unitPrice.name}
                                                    value={d[FIELDS.unitPrice.key] ?? "0"}
                                                    onChange={(e) => {
                                                        suppliersInfo[tabValue].detailBySupplierInfo[i].unitPrice = parseFloat(e.target.value)
                                                        onChange(suppliersInfo)
                                                    }}
                                                    onFocus={(e) => e.target.select()}
                                                    InputProps={{
                                                        inputComponent: CurrencyInput as any,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Icon fontSize="small" color="action">attach_money</Icon>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <TextField
                                                    style={{ ...detailInputStyle, marginLeft: "2%" }}
                                                    size="small"
                                                    required
                                                    label={FIELDS.discount.name}
                                                    value={d[FIELDS.discount.key] ?? "0"}
                                                    onChange={(e) => {
                                                        suppliersInfo[tabValue].detailBySupplierInfo[i].discount = parseFloat(e.target.value)
                                                        onChange(suppliersInfo)
                                                    }}
                                                    onFocus={(e) => e.target.select()}
                                                    InputProps={{
                                                        inputComponent: PercentageInput as any,
                                                    }}
                                                />
                                                <Autocomplete
                                                    loadingText="Cargando..."
                                                    noOptionsText="No se encontraron impuestos registrados"
                                                    getOptionLabel={(tax) => tax.description}
                                                    options={getNoRetentionTaxes()}
                                                    style={{ ...detailInputStyle, marginLeft: "2%" }}
                                                    size="small"
                                                    value={d[FIELDS.tax.key]}
                                                    onChange={(_, value) => {
                                                        suppliersInfo[tabValue].detailBySupplierInfo[i].tax = value ? value : undefined
                                                        onChange(suppliersInfo)
                                                    }}
                                                    openOnFocus
                                                    renderInput={
                                                        (params) =>
                                                            <TextField
                                                                {...params}
                                                                label={FIELDS.tax.name}
                                                                required
                                                            />
                                                    }
                                                />
                                            </Paper>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
            <FullSizeDialog
                open={openNewSupplier}
                onClose={() => setOpenNewSupplier(false)}
            >
                <SupplierForm
                    onSuccess={handleSuccess}
                    onError={() => setMessageConfig({ open: true, message: "No se pudo registrar el Proveedor", severity: "error" })}
                    onClose={() => setOpenNewSupplier(false)}
                />
            </FullSizeDialog>
        </Paper>
    )
})

export default SupplierApproach
