import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Icon, TextField, Typography, IconButton, Tooltip, Snackbar } from '@material-ui/core'
import { FIELDS, MAX_FILE_SIZE } from 'lib'
import React from 'react'
import moment from 'moment'
import 'moment/locale/es'
import { IMessageConfig, IPurchaseOrderHeader } from 'types'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { DocumentContext, ManagementContext } from 'context'
import { Alert, Autocomplete } from '@material-ui/lab'

moment.locale("es")

interface Props {
    onChange: (key: string, value: any) => void
    header: IPurchaseOrderHeader
    approvalMessage: File | undefined
    setApprovalMessage: React.Dispatch<File | undefined>
}

const inputStyle = { width: "49%", marginBottom: 0 }

const PurchaseOrderHeader = (props: Props) => {
    const { accountTypes } = React.useContext(ManagementContext)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const { costCenters } = React.useContext(DocumentContext)
    const { header, onChange: handleChange, approvalMessage, setApprovalMessage } = props
    const [expanded, setExpanded] = React.useState<boolean>(true)
    return (
        <React.Fragment>
            <Accordion expanded={expanded} onChange={() => setExpanded(current => !current)}>
                <AccordionSummary
                    style={{ marginRight: 10 }}
                    expandIcon={<Icon>expand_more</Icon>}
                >
                    <div className="flex items-center">
                        <Icon color="action">short_text</Icon>
                        <Typography className="pl-2" style={{ fontWeight: 600, letterSpacing: 0.5 }} color="textSecondary" variant="body2">{"Datos de solicitud"}</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="w-full flex flex-wrap justify-start">
                        <div style={{ width: "54%", height: "min-content" }} className="flex flex-wrap justify-between">
                            <TextField
                                size="small"
                                label={FIELDS.subject.name}
                                style={inputStyle}
                                variant="outlined"
                                required
                                autoFocus
                                value={header[FIELDS.subject.key] ? header[FIELDS.subject.key] : ""}
                                onChange={(e) => handleChange(FIELDS.subject.key, e.target.value)}
                            />
                            <KeyboardDatePicker
                                required
                                autoOk
                                style={{ ...inputStyle }}
                                value={header[FIELDS.createDate.key] ?? null}
                                emptyLabel={FIELDS.emptyDate.name}
                                onChange={(e) => handleChange(FIELDS.createDate.key, e)}
                                inputVariant="outlined"
                                fullWidth
                                size="small"
                                label={FIELDS.createDate.name}
                                variant="inline"
                                format="dd/MM/yyyy"
                                InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                                className="date-input"
                            />
                            <Autocomplete
                                options={accountTypes}
                                getOptionLabel={(option) => `${option.accountCode} - ${option.description}`}
                                style={{ ...inputStyle, marginTop: 15 }}
                                size="small"
                                value={accountTypes.find(a => a.accountTypeId === header[FIELDS.accountTypeId.key]) ?? null}
                                onChange={(_, value) => { handleChange(FIELDS.accountTypeId.key, value?.accountTypeId) }}
                                renderInput={(params) => <TextField required {...params} label={FIELDS.accountTypeId.name} variant="outlined" />}
                            />
                            <Autocomplete
                                options={costCenters}
                                getOptionLabel={(option) => `${option.costCenterCode} - ${option.description}`}
                                style={{ ...inputStyle, marginTop: 15 }}
                                size="small"
                                value={costCenters.find(c => c.costCenterId === header[FIELDS.costCenterId.key]) ?? null}
                                onChange={(_, value) => { handleChange(FIELDS.costCenterId.key, value?.costCenterId) }}
                                renderInput={(params) => <TextField required {...params} label={FIELDS.costCenterId.name} variant="outlined" />}
                            />
                            <TextField
                                size="small"
                                label={FIELDS.deliveryAddress.name}
                                style={{ ...inputStyle, width: "100%", marginTop: 15 }}
                                variant="outlined"
                                required
                                value={header[FIELDS.deliveryAddress.key] ? header[FIELDS.deliveryAddress.key] : ""}
                                onChange={(e) => handleChange(FIELDS.deliveryAddress.key, e.target.value)}
                            />
                        </div>
                        <div className="mx-4">
                            <Divider orientation="vertical" />
                        </div>
                        <div className="flex justify-between flex-col" style={{ width: "42%", height: "min-content" }}>
                            <TextField
                                size="small"
                                label={FIELDS.specialInstruction.name}
                                style={{ ...inputStyle, width: "100%" }}
                                variant="outlined"
                                value={header[FIELDS.specialInstruction.key] ? header[FIELDS.specialInstruction.key] : ""}
                                onChange={(e) => handleChange(FIELDS.specialInstruction.key, e.target.value)}
                            />
                            <TextField
                                size="small"
                                label={FIELDS.comment.name}
                                style={{ ...inputStyle, width: "100%", marginTop: 15 }}
                                variant="outlined"
                                value={header[FIELDS.comment.key] ? header[FIELDS.comment.key] : ""}
                                onChange={(e) => handleChange(FIELDS.comment.key, e.target.value)}
                            />
                            <div style={{ marginTop: 15 }} className="flex flex-row items-center">
                                <label htmlFor="approval-button-file" className="w-full">
                                    <Button
                                        style={{ height: 40, justifyContent: "flex-start" }}
                                        variant="outlined"
                                        disableElevation
                                        fullWidth
                                        size="small"
                                        component="span"
                                        disabled={Boolean(approvalMessage)}
                                    >
                                        <div className="flex-grow flex pl-1 truncate" style={{ textTransform: "none", fontSize: "0.9rem" }}>
                                            <p className="truncate" style={{ maxWidth: 200 }}>
                                                {approvalMessage ? approvalMessage.name : "Mensaje de aprobación"}
                                            </p>
                                        </div>
                                        <Divider orientation="vertical" />
                                        {approvalMessage ? "" : "Subir archivo"}
                                        <Icon fontSize="small" color={Boolean(approvalMessage) ? "disabled" : "action"} className="mx-2">publish</Icon>
                                    </Button>
                                </label>
                                {
                                    approvalMessage &&
                                    <Tooltip arrow title="Eliminar archivo">
                                        <IconButton onClick={() => setApprovalMessage(undefined)} style={{ marginLeft: 8 }}>
                                            <Icon fontSize="small" color="action">
                                                close
                                            </Icon>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>
                            <input
                                accept="image/*,application/pdf"
                                className="hidden"
                                id="approval-button-file"
                                type="file"
                                multiple={false}
                                onChange={(e) => {
                                    if (e.target.files !== null && e.target.files[0]) {
                                        if (e.target.files[0].size > MAX_FILE_SIZE) {
                                            setMessageConfig({ message: "El tamaño del archivo excede el máximo permitido", open: true, severity: "error" })
                                            return
                                        }
                                        setApprovalMessage(e.target.files[0])
                                    }
                                }}
                            />
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default PurchaseOrderHeader
