import { FIELDS } from "lib"

export const RETENTION_REJECTED_TABLE_COLUMNS = [
    { id: FIELDS.retentionReceiptToBeCanceled.key, label: FIELDS.retentionReceiptToBeCanceled.name },
    { id: FIELDS.appliedDocument.key, label: FIELDS.appliedDocument.name },
    { id: FIELDS.authorizationNumber.key, label: FIELDS.authorizationNumber.name },
    { id: FIELDS.retDate.key, label: FIELDS.retDate.name },
    { id: FIELDS.identification.key, label: FIELDS.identification.name },
    { id: FIELDS.supplier.key, label: FIELDS.supplier.name },
    { id: FIELDS.value.key, label: FIELDS.value.name },
    { id: FIELDS.processStatus.key, label: FIELDS.processStatus.name },
    { id: FIELDS.supplierNotification.key, label: FIELDS.supplierNotification.name },
    { id: FIELDS.reminder.key, label: FIELDS.reminder.name },
    { id: FIELDS.sriRequest.key, label: FIELDS.sriRequest.name },
    { id: FIELDS.annulled.key, label: FIELDS.annulled.name },
    { id: FIELDS.na.key, label: FIELDS.na.name },
    { id: FIELDS.reason.key, label: FIELDS.reason.name },
    { id: FIELDS.notificationEmail.key, label: FIELDS.notificationEmail.name },
]

export const PURCHASE_SETTLEMENT_REJECTED_TABLE_COLUMNS = [
    { id: FIELDS.purchaseSettlementToBeCanceled.key, label: FIELDS.purchaseSettlementToBeCanceled.name },
    { id: FIELDS.appliedDocument.key, label: FIELDS.appliedDocument.name },
    { id: FIELDS.authorizationNumber.key, label: FIELDS.authorizationNumber.name },
    { id: FIELDS.purchaseSettlementDate.key, label: FIELDS.purchaseSettlementDate.name },
    { id: FIELDS.identification.key, label: FIELDS.identification.name },
    { id: FIELDS.supplier.key, label: FIELDS.supplier.name },
    { id: FIELDS.value.key, label: FIELDS.value.name },
    { id: FIELDS.processStatus.key, label: FIELDS.processStatus.name },
    { id: FIELDS.sriRequest.key, label: FIELDS.sriRequest.name },
    { id: FIELDS.annulled.key, label: FIELDS.annulled.name },
    { id: FIELDS.na.key, label: FIELDS.na.name },
    { id: FIELDS.reason.key, label: FIELDS.reason.name },
]

export const HISTORY_REJECTED_TABLE_COLUMNS = [
    { id: FIELDS.inserted.key, label: FIELDS.inserted.name },
    { id: FIELDS.comment.key, label: FIELDS.comment.name },
]

export const REJECTED_STATES = [
    {
        id: -1,
        name: "Sin motivo de rechazo",
    },
    {
        id: 0,
        name: "Por anular",
    },
    {
        id: 1,
        name: "Por confirmar anulación",
    },
    {
        id: 2,
        name: "Anulado",
    },
    {
        id: 3,
        name: "No Aplica",
    },
]

export const REJECTED_STATE_IDS = {
    withoutReason: -1,
    toBeCanceled: 0,
    requested: 1,
    canceled: 2,
    na: 3
}

export const INITIAL_REJECTED_FILTERS = {
    associatedDocumentNumber: "",
    processStatus: [109, 108, 112],
    retentionDocumentNumber: "",
    rucOrName: "",
    states: [-1, 0, 1],
    type: 0
}

export const REJECTED_INITIAL_PAGINATION = {
    page: 0,
    offset: 25
}