import axios, { AxiosError } from 'axios'
import { AUTHENTICATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, USER_PATH, PASSWORD_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: AUTHENTICATION_API_SERVICE_DOMAIN })

export const getUsersService = async () => {
    try {
        const result = await intance.get(USER_PATH + LIST_PATH)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createUserService = async (User: any) => {
    try {
        const result = await intance.post(USER_PATH, User)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteUserService = async (id: any) => {
    try {
        const result = await intance.delete(USER_PATH + `/${id}`)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editUserService = async (id: number, User: any) => {
    try {
        const result = await intance.put(USER_PATH + `/${id}`, User)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const changePasswordService = async (id: number, User: any) => {
    try {
        const result = await intance.put(USER_PATH + `/${id}` + PASSWORD_PATH, User)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data.message)
    }
}