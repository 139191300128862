import { getCustomerByIdentificationService } from 'lib'
import React from 'react'
import { ICustomer } from 'types'

interface Props {
    identification: string
}

interface Hook {
    loading: boolean
    customer: ICustomer | undefined
}

const useGetSupplier = (props: Props): Hook => {
    const { identification } = props
    const [loading, setLoading] = React.useState<boolean>(false)
    const [customer, setCustomer] = React.useState<ICustomer | undefined>(undefined)

    React.useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const result = await getCustomerByIdentificationService(identification)
                setLoading(false)
                setCustomer(result)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        init()
    }, [identification])

    return {
        customer,
        loading
    }
}

export default useGetSupplier