import { Button, LinearProgress, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FileUploadDialog, FixedLoadingIndicator, LabelDisplayedRows } from 'components'
import { useTableModel } from 'hooks'
import { FIELDS, MEDIUM_ROWS_PER_PAGINATION_OPTIONS, PAYMENTS_TITLE, PAYMENT_LIST_TABLE_COLUMNS, ROOT_TITLE, uploadPaymentFileService, getPaymentsService, sendPaymentNotifications } from 'lib'
import moment from 'moment'
import React from 'react'
import { Helmet } from 'react-helmet'
import { IMessageConfig, IPayment } from 'types'

type Props = {}

const Payments = (props: Props) => {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage } = useTableModel()
    const [list, setList] = React.useState<IPayment[]>([])
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })

    const handleUpload = async (file: File) => {
        try {
            setLoading(true)
            await uploadPaymentFileService(file)
            const result = await getPaymentsService()
            setList(result)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleSendNotifications = async () => {
        try {
            setLoading(true)
            await sendPaymentNotifications()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const result = await getPaymentsService()
                setList(result)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        init()
    }, [])

    return (
    <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${PAYMENTS_TITLE}`}</title>
            </Helmet>
            <div className="flex items-center">
                <form className="flex w-full">
                    <div className="flex-grow">
                        <div className="flex items-center justify-between w-full flex-wrap">
                           
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="ml-8 flex items-start justify-end">
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                disableElevation
                                onClick={handleSendNotifications}
                            >
                                {"Enviar Notificaciones"}
                            </Button>
                            <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    style={{ width: 120, marginLeft: '0.5rem' }}
                                    onClick={() => setOpen(true)}
                                >
                                {"Subir archivo"}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
            {
                loading &&
                <LinearProgress />
            }
            <div className="flex h-full flex-col mt-4 overflow-hidden">
                <TableContainer className="h-full">
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                {PAYMENT_LIST_TABLE_COLUMNS.map((headCell, index) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={"left"}
                                        padding={"default"}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : "asc"}
                                            onClick={createSortHandler(headCell.id as any)}
                                            style={{ fontSize: "0.85em", width: (headCell.id === FIELDS.reference.key || headCell.id === FIELDS.blNumber.key) ? 180 : (headCell.id === FIELDS.comercialName.key ? 300 : 100) }}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <span className="hidden">
                                                    {order === "desc"
                                                        ? "sorted descending"
                                                        : "sorted ascending"}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell padding={"default"} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (rows(list) as IPayment[]).map((row, index) => (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.paymentId + index + "payment"}
                                    >
                                        <TableCell component="th" scope="row">{row.supplierJbaCode}</TableCell>
                                        <TableCell component="th" scope="row">{row.identification}</TableCell>
                                        <TableCell component="th" scope="row">{row.supplierName}</TableCell>
                                        <TableCell component="th" scope="row">{row.documentType}</TableCell>
                                        <TableCell component="th" scope="row">{row.accountingEntry}</TableCell>
                                        <TableCell component="th" scope="row">{row.documentRef}</TableCell>
                                        <TableCell component="th" scope="row">{row.documentDate !== null ? moment(row.documentDate).format('DD-MM-YYYY').toString() : ""}</TableCell>
                                        <TableCell component="th" scope="row">{row.expiringDate !== null ? moment(row.expiringDate).format('DD-MM-YYYY').toString() : ""}</TableCell>
                                        <TableCell component="th" scope="row">{row.timePeriod}</TableCell>
                                        <TableCell component="th" scope="row">{row.amount}</TableCell>
                                        <TableCell component="th" scope="row">{row.blNumber}</TableCell>
                                        <TableCell component="th" scope="row">{row.status === 0 ? "Pendiente Envío" : "Enviado"}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={MEDIUM_ROWS_PER_PAGINATION_OPTIONS}
                    component="div"
                    count={list.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Pagos por página"
                    labelDisplayedRows={LabelDisplayedRows}
                />
            </div>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading} />
            <FileUploadDialog
                open={open}
                onClose={() => setOpen(false)}
                onAccept={(files) => {handleUpload(files[0].file)}}
                singleFile
                hideDescription
            />
        </Paper>
  )
}

export default Payments