import axios, { AxiosError } from 'axios'
import { AUTHENTICATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, APPROVAL_LEVEL_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: AUTHENTICATION_API_SERVICE_DOMAIN })

export const getApprovalLevelsService = async () => {
    try {
        const result = await intance.get(APPROVAL_LEVEL_PATH + LIST_PATH)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editApprovalLevelsService = async (approvalLevel: any, id: any) => {
    try {
        const result = await intance.put(APPROVAL_LEVEL_PATH + `/${id}`, approvalLevel)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}