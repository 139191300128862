import { Button, Dialog, DialogActions, DialogTitle, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ManagementContext } from 'context'
import { FIELDS, TAX_SUPPORTS } from 'lib'
import React from 'react'
import { IAccountInfo } from 'types'

interface Props {
    open: boolean
    infoOnly?: boolean
    onClose: () => void
    onConfirm?: (accountInfo: IAccountInfo) => void
    accountInfo?: IAccountInfo
    defaultOffice?: string
    defaultTaxSupport?: string
}

const inputStyle = { width: "30%", marginBottom: 15 }

const AccountInfoDialog = (props: Props) => {
    const { open, infoOnly, onClose, onConfirm, defaultOffice, defaultTaxSupport } = props
    const { offices, refreshOffices } = React.useContext(ManagementContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [accountInfo, setAccountInfo] = React.useState(props.accountInfo ?? {} as IAccountInfo)
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (onConfirm) {
            onConfirm(accountInfo)
        }
        onClose()
    }
    React.useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                await refreshOffices()
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        init()
    }, [refreshOffices])
   
    React.useEffect(() => {
        const newAccountInfo = props.accountInfo ?? {} as IAccountInfo
        if (defaultOffice) {
            const office = offices.find(o => o.officeCode === defaultOffice)
            if (office) {
                newAccountInfo.branch = office
            }
        }
        if (defaultTaxSupport) {
            const taxSupport = TAX_SUPPORTS.find(t => t.code === defaultTaxSupport)
            if (taxSupport) {
                newAccountInfo.taxSupport = taxSupport
            }
        }
        setAccountInfo(newAccountInfo)
    }, [defaultTaxSupport, props.accountInfo, defaultOffice, offices])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
        >
            <DialogTitle color="textSecondary">{infoOnly ? "Información de contabilización" : "Aprobar documento"}</DialogTitle>
            <form onSubmit={handleSubmit} className="px-4" style={{ width: "50vw" }}>
                <div className="flex flex-wrap w-full justify-between">
                    <Autocomplete
                        loadingText="Cargando..."
                        noOptionsText="No se encontraron sustentos tributarios"
                        options={TAX_SUPPORTS}
                        getOptionLabel={(option) => `${option.code} - ${option.name}`}
                        value={accountInfo.taxSupport}
                        style={{ ...inputStyle, width: "68%" }}
                        openOnFocus={!defaultTaxSupport}
                        size="small"
                        onChange={(_, selectedValue) => setAccountInfo(current => ({ ...current, taxSupport: selectedValue }))}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={FIELDS.taxSupport.name}
                                autoFocus
                                required
                            />
                        )}
                    />
                    <Autocomplete
                        loadingText="Cargando..."
                        noOptionsText="No se encontraron sucursales registradas"
                        options={offices}
                        getOptionLabel={(option) => `${option.officeCode} - ${option.description}`}
                        value={accountInfo.branch}
                        style={inputStyle}
                        loading={loading}
                        openOnFocus
                        size="small"
                        onChange={(_, selectedValue) => setAccountInfo(current => ({ ...current, branch: selectedValue }))}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={FIELDS.branch.name}
                            />
                        )}
                    />
                </div>
                <DialogActions className="mt-5">
                    <Button disabled={loading} onClick={onClose}>
                        {"Cancelar"}
                    </Button>
                    <Button disabled={loading} type="submit" disableElevation variant="contained" color="primary">
                        {infoOnly ? "Aceptar" : "Aprobar"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AccountInfoDialog
