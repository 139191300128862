import React from "react"
import { APPROVAL_LEVEL_PATH, AUTHENTICATION_PATH, CHANGE_PASSWORD_PATH, MODULE_PATH, MODULE_VIEW_PATH, USER_MODULE_PATH, USER_PATH, VIEW_PATH } from "lib"
import { ApprovalLevel, ChangePassword, Modules, ModuleView, UserModule, Users, Views } from "pages"
import { Route } from "react-router-dom"
import { AuthContext } from "context"

const Authentication = () => {
    const { user } = React.useContext(AuthContext)
    return (
        <React.Fragment>
            {
                Boolean(user) &&
                <React.Fragment>
                    <Route strict path={`${AUTHENTICATION_PATH}${MODULE_PATH}`} component={Modules} />
                    <Route strict path={`${AUTHENTICATION_PATH}${USER_PATH}`} component={Users} />
                    <Route strict path={`${AUTHENTICATION_PATH}${MODULE_VIEW_PATH}`} component={ModuleView} />
                    <Route strict path={`${AUTHENTICATION_PATH}${USER_MODULE_PATH}`} component={UserModule} />
                    <Route strict path={`${USER_PATH}${CHANGE_PASSWORD_PATH}`} component={ChangePassword} />
                    <Route strict path={`${AUTHENTICATION_PATH}${VIEW_PATH}`} component={Views} />
                    <Route strict path={`${AUTHENTICATION_PATH}${APPROVAL_LEVEL_PATH}`} component={ApprovalLevel} />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Authentication
