import React from "react"
import {
    AUXILIAR_PATH,
    CATEGORY_PATH,
    CUSTOMER_PATH,
    RECEIVED_PATH, TAX_PLAN_PATH,
} from "lib"
import {
    Auxiliar,
    Categories,
    Customers,
    TaxPlans,
} from "pages"
import { Route } from "react-router-dom"
import { AuthContext } from "context"

const ReceivedTaxPlans = () => <TaxPlans forCustomers />

const Received = () => {
    const { user } = React.useContext(AuthContext)
    return (
        <React.Fragment>
            {
                Boolean(user) &&
                <React.Fragment>
                    <Route strict path={`${RECEIVED_PATH}${TAX_PLAN_PATH}`} component={ReceivedTaxPlans} />
                    <Route strict path={`${RECEIVED_PATH}${CATEGORY_PATH}`} component={Categories} />
                    <Route strict path={`${RECEIVED_PATH}${CUSTOMER_PATH}`} component={Customers} />
                    <Route strict path={`${RECEIVED_PATH}${AUXILIAR_PATH}`} component={Auxiliar} />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Received
