import axios, { AxiosError } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, CUSTOMER_PATH, SEARCH_PARAM, FILTER_PARAM, AND_PARAM, PAGE_SIZE_PARAM, OFFSET_PARAM } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const getCustomersService = async () => {
    try {
        const result = await intance.get(CUSTOMER_PATH + LIST_PATH)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getCustomerByIdentificationService = async (identification: string) => {
    try {
        const result = await intance.get(CUSTOMER_PATH + `/${identification}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getCustomersServicePaginated = async (filter: string, pageSize: number, offset: number) => {
    try {
        const result = await intance.get(CUSTOMER_PATH + SEARCH_PARAM
            + FILTER_PARAM + filter + AND_PARAM
            + PAGE_SIZE_PARAM + pageSize + AND_PARAM
            + OFFSET_PARAM + offset)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createCustomerService = async (category: any) => {
    try {
        const result = await intance.post(CUSTOMER_PATH, category)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteCustomerService = async (id: any) => {
    try {
        const result = await intance.delete(CUSTOMER_PATH + `/${id}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        console.log(JSON.stringify(e))
        throw new Error(e.response?.data)
    }
}

export const editCustomerService = async (id: number, category: any) => {
    try {
        const result = await intance.put(CUSTOMER_PATH + `/${id}`, category)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}