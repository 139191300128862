import { Button, CircularProgress, Dialog, Divider, Icon, IconButton, Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import { INotificationHistory, NotificationType } from 'types'
import moment from 'moment'
import 'moment/locale/es'
import { resendEmailNotificationService } from 'lib'

moment.locale("es")

interface Props {
    open: boolean
    onClose: () => void
    notifications: INotificationHistory[]
    type?: NotificationType
    onSuccess: () => void
    onError: () => void
}

const NotificationMessageDialog = (props: Props) => {
    const { onClose: handleClose, open, notifications, type, onError, onSuccess } = props
    const [selected, setSelected] = React.useState(0)
    const [loading, setLoading] = React.useState(false)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelected(newValue)
    }

    const resendEmail = async () => {
        try {
            setLoading(true)
            await resendEmailNotificationService(notifications[selected].rejectedId, type === "notification" ? 1 : 2)
            setLoading(false)
            onSuccess()
            handleClose()
        } catch (error) {
            setLoading(false)
            onError()
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
        >
            <div style={{ height: '75vh', maxHeight: '75vh', minWidth: 700, backgroundColor: "#ddd" }} className="w-full h-full flex overflow-hidden">
                <div className="h-full" style={{ width: 250 }}>
                    <Typography
                        style={{ height: '30%' }}
                        className="w-full flex justify-center items-center px-10"
                        variant="body1"
                        align="center"
                    >
                        {"Mensajes enviados"}
                    </Typography>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={selected}
                        indicatorColor="primary"
                        onChange={handleChange}
                        style={{ maxHeight: '70%' }}
                    >
                        {
                            notifications.map((_, index) => (
                                <Tab label={`Mensaje ${index + 1}`} />
                            ))
                        }

                    </Tabs>
                </div>
                <div className="flex flex-grow flex-col pr-3 pt-3 pl-8 bg-white">
                    <div className="flex items-start flex-grow">
                        <div className="flex flex-col flex-grow pl-4 pr-8 py-1">
                            <div className="flex pt-3">
                                <Icon color="action" className="mr-4">
                                    supervisor_account
                                </Icon>
                                <Typography
                                    variant="body2"
                                    className="font-bold pr-4"
                                    color="textSecondary"
                                    style={{ width: 120 }}
                                >
                                    {`Destinatarios:`}
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    {`${(notifications[selected].emails ?? "").split(";").join(`, `)}`}
                                </Typography>
                            </div>
                            <div className="flex mt-2 items-center">
                                <Icon color="action" className="mr-4">
                                    event
                                </Icon>
                                <Typography
                                    variant="body2"
                                    className="font-bold pr-4"
                                    color="textSecondary"
                                    style={{ width: 120 }}
                                >
                                    {`Enviado:`}
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    {moment(new Date(notifications[selected].inserted).toISOString())?.format('DD-MM-YYYY - hh:mm:ss')?.toString()}
                                </Typography>
                            </div>
                            <div className="mt-2 w-full">
                                <Divider />
                            </div>
                            <div className="flex mt-4 items-center">
                                <Icon color="action" className="mr-4">
                                    mail
                                </Icon>
                                <Typography
                                    variant="body2"
                                    className="font-bold pr-4"
                                    color="textSecondary"
                                    style={{ width: 120 }}
                                >
                                    {`Mensaje:`}
                                </Typography>
                            </div>
                            <div className="mt-6">
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{ textAlign: 'justify' }}
                                >
                                    <div dangerouslySetInnerHTML={{ __html:notifications[selected].message }} />
                                </Typography>
                            </div>
                        </div>
                        <IconButton onClick={handleClose} size="small">
                            <Icon>
                                close
                            </Icon>
                        </IconButton>
                    </div>
                    <div className="flex py-3 justify-end">
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disableElevation
                            disabled={loading}
                            onClick={resendEmail}
                        >
                            {loading ? <CircularProgress color="primary" size={16} className="my-1" thickness={10} /> : "Volver a enviar"}
                        </Button>
                    </div>
                </div>
            </div>

        </Dialog>
    )
}

export default NotificationMessageDialog
