import { Paper, Typography, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, Icon } from '@material-ui/core'
import React from 'react'
import { IAccountInfo } from 'types'
import { AccountInfoDialog as AccountingDataDialog } from 'components'

interface Props {
    accountInfo: IAccountInfo | undefined
    readOnly?: boolean
}

const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2 }

const AccountingData = (props: Props) => {
    const { accountInfo, readOnly } = props
    const [openDialog, setOpenDialog] = React.useState<boolean>(false)
    return (
        <React.Fragment>
            {
                accountInfo &&
                <div className="px-8 w-full">
                    <Paper variant="outlined" className="mt-3 w-full" elevation={1}>
                        <div className="py-3 px-4 flex w-full items-center">
                            <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                                {"INFORMACIÓN DE CONTABILIZACIÓN"}
                            </Typography>
                        </div>
                        <Divider />
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {
                                            accountInfo?.taxSupport &&
                                            <TableCell style={headerCellStyle} align="left">{"Sustento tributario"}</TableCell>
                                        }
                                        {
                                            accountInfo?.branch &&
                                            <TableCell style={headerCellStyle} align="left">{"Sucursal"}</TableCell>
                                        }
                                        {
                                            accountInfo?.purchaseOrder &&
                                            <TableCell style={headerCellStyle} align="left">{"Orden de compra"}</TableCell>
                                        }
                                        {
                                            accountInfo?.operationalReference &&
                                            <TableCell style={headerCellStyle} align="left">{"Referencia operativa"}</TableCell>
                                        }
                                        {
                                            !readOnly &&
                                            <TableCell style={headerCellStyle} align="left" />
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {
                                            accountInfo?.taxSupport &&
                                            <TableCell style={bodyCellStyle} align="left">{`${accountInfo?.taxSupport?.code} - ${accountInfo?.taxSupport?.name}`}</TableCell>
                                        }
                                        {
                                            accountInfo?.branch &&
                                            <TableCell style={bodyCellStyle} align="left">{`${accountInfo?.branch?.officeCode} - ${accountInfo?.branch?.description}`}</TableCell>
                                        }
                                        {
                                            accountInfo?.purchaseOrder &&
                                            <TableCell style={bodyCellStyle} align="left">{accountInfo?.purchaseOrder?.serialNumber}</TableCell>
                                        }
                                        {
                                            accountInfo?.operationalReference &&
                                            <TableCell style={headerCellStyle} align="left">{accountInfo?.operationalReference}</TableCell>
                                        }
                                        {
                                            !readOnly &&
                                            <TableCell style={bodyCellStyle} align="right">
                                                <Tooltip title="Editar" arrow>
                                                    <IconButton onClick={() => setOpenDialog(true)} size="small"><Icon fontSize="small">edit</Icon></IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <AccountingDataDialog
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                        accountInfo={accountInfo}
                    />
                </div>
            }
        </React.Fragment>
    )
}

export default AccountingData
