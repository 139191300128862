import { PURCHASE_ORDER_PATH, NEW_PURCHASE_ORDER_PATH, ENTERED_PATH, PURCHASE_ORDER_VIEW_PATH, PURCHASE_ORDER_STATUS, REJECTED_PATH, COMPLETED_PATH, TO_APPROVE_PATH, EDIT_PURCHASE_ORDER_PATH, ASSIGNED_PATH } from 'lib'
import { PurchaseOrderDashboard, NewPurchaseOrder, PurchaseOrderView } from 'pages'
import React from 'react'
import { Route } from 'react-router-dom'

const PurchaseOrder = () => {
    return (
        <React.Fragment>
            <Route strict path={`${PURCHASE_ORDER_PATH}${ENTERED_PATH}`} component={() => <PurchaseOrderDashboard processStatus={`${PURCHASE_ORDER_STATUS.inserted.conditionId},${PURCHASE_ORDER_STATUS.updated.conditionId}`} />} />
            <Route strict path={`${PURCHASE_ORDER_PATH}${TO_APPROVE_PATH}`} component={() => <PurchaseOrderDashboard processStatus={PURCHASE_ORDER_STATUS.perApproval.conditionId.toString()} />} />
            <Route strict path={`${PURCHASE_ORDER_PATH}${REJECTED_PATH}`} component={() => <PurchaseOrderDashboard processStatus={PURCHASE_ORDER_STATUS.rejected.conditionId.toString()} />} />
            <Route strict path={`${PURCHASE_ORDER_PATH}${COMPLETED_PATH}`} component={() => <PurchaseOrderDashboard processStatus={PURCHASE_ORDER_STATUS.finished.conditionId.toString()} />} />
            <Route strict path={`${PURCHASE_ORDER_PATH}${ASSIGNED_PATH}`} component={() => <PurchaseOrderDashboard processStatus={PURCHASE_ORDER_STATUS.assigned.conditionId.toString()} />} />
            <Route strict path={`${PURCHASE_ORDER_PATH}${NEW_PURCHASE_ORDER_PATH}`} component={NewPurchaseOrder} />
            <Route strict path={`${PURCHASE_ORDER_PATH}${EDIT_PURCHASE_ORDER_PATH}`} component={NewPurchaseOrder} />
            <Route strict path={`${PURCHASE_ORDER_PATH}${PURCHASE_ORDER_VIEW_PATH}`} component={PurchaseOrderView} />
        </React.Fragment>
    )
}

export default PurchaseOrder