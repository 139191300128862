import { Fab, Divider, Icon, Tooltip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Popover, Button, Snackbar, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FileUploadDialog } from 'components'
import { FileContext } from 'context'
import React from 'react'
import { IDocumentFile, IMenu, IMessageConfig, IUploadDocumentFile } from 'types'
import { deleteDocumentFileByIdService, downloadDocumentFileByIdService } from 'lib'
import FileSaver from 'file-saver'

interface Props {
    files: IDocumentFile[]
    documentId: number
    onAddDocumentFiles?: (newDocuments: IDocumentFile[]) => void
    onDeleteDocumentFile?: (deletedDocument: IDocumentFile) => void
    readOnly?: boolean
}

const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2 }

const DocumentFiles = (props: Props) => {
    const { files, onAddDocumentFiles, documentId, onDeleteDocumentFile, readOnly } = props
    const { uploadDocumentFiles } = React.useContext(FileContext)
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const [menu, setMenu] = React.useState<IMenu>({ anchorEl: null, selected: undefined })
    const handleUpload = async (files: IUploadDocumentFile[]) => {
        const documentFiles = await uploadDocumentFiles(files, documentId)
        if (onAddDocumentFiles) {
            onAddDocumentFiles(documentFiles)
        }
    }
    const handleCloseMenu = () => setMenu({ anchorEl: null, selected: undefined })
    const handleDelete = async (file: IDocumentFile) => {
        if (file.isMain) {
            setMessageConfig({ open: true, message: "No se puede eliminar el archivo principal de un documento!", severity: "error" })
        } else {
            try {
                setLoading(true)
                handleCloseMenu()
                await deleteDocumentFileByIdService(file.documentFileId)
                if (onDeleteDocumentFile) {
                    onDeleteDocumentFile(file)
                }
                setMessageConfig({ open: true, message: "Archivo eliminado!", severity: "success" })
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setMessageConfig({ open: true, message: "No se pudo eliminar el archivo!", severity: "error" })
            }
        }
    }
    const handleDownload = async (file: IDocumentFile) => {
        try {
            setLoading(true)
            handleCloseMenu()
            const result = await downloadDocumentFileByIdService(file.documentFileId)
            FileSaver.saveAs(result, file.fileName ?? file?.documentFileId?.toString())
            setMessageConfig({ open: true, message: "Su descarga ha comenzado", severity: "info" })
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo descargar el archivo!", severity: "error" })
        }
    }
    return (
        <React.Fragment>
            <Paper variant="outlined" className="mt-3" elevation={1}>
                <div className="py-3 px-4 flex w-full items-center">
                    <Typography className="flex-grow" style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                        {"ARCHIVOS"}
                    </Typography>
                    {
                        loading ?
                            <CircularProgress color="primary" size={27} thickness={12} />
                            :
                            <React.Fragment>
                                {
                                    !readOnly &&
                                    <Tooltip arrow title="Nuevo archivo">
                                        <Fab disabled={loading} onClick={() => setOpen(true)} style={{ width: 27, height: 27, minHeight: 0 }} color="primary" size="small">
                                            <Icon fontSize="small">add</Icon>
                                        </Fab>
                                    </Tooltip>
                                }
                            </React.Fragment>
                    }
                </div>
                <Divider />
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle} align="left">{"Nombre"}</TableCell>
                                <TableCell style={headerCellStyle} align="left">{"Descripción"}</TableCell>
                                <TableCell style={headerCellStyle} align="left" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                files.map(file => (
                                    <TableRow>
                                        <TableCell style={bodyCellStyle} align="left">{file.fileName ?? file.documentFileId}</TableCell>
                                        <TableCell style={bodyCellStyle} align="left">{file.description ?? "Sin descripción"}</TableCell>
                                        <TableCell style={bodyCellStyle} align="right">
                                            <React.Fragment>
                                                <Tooltip title="Descargar archivo" arrow>
                                                    <IconButton style={{ marginRight: 10 }} disabled={loading} onClick={() => handleDownload(file)} size="small"><Icon fontSize="small">get_app</Icon></IconButton>
                                                </Tooltip>
                                                {
                                                    !readOnly &&
                                                    <Tooltip title="Eliminar archivo" arrow>
                                                        <IconButton disabled={loading} onClick={(e) => setMenu({ anchorEl: e.currentTarget, selected: file })} size="small"><Icon fontSize="small">close</Icon></IconButton>
                                                    </Tooltip>
                                                }
                                            </React.Fragment>
                                        </TableCell>
                                        <Popover
                                            id={file?.documentFileId?.toString()}
                                            open={menu.selected === file && Boolean(menu.anchorEl)}
                                            anchorEl={menu.anchorEl}
                                            onClose={handleCloseMenu}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <div className="flex flex-col p-4 items-center justify-center">
                                                <Typography style={headerCellStyle}>
                                                    {"¿Estás seguro de eliminar este archivo?"}
                                                </Typography>
                                                <div className="mt-3">
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        variant="contained"
                                                        style={{ marginRight: 5 }}
                                                        onClick={() => handleDelete(file)}
                                                    >
                                                        {"Si"}
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={handleCloseMenu}
                                                    >
                                                        {"No"}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Popover>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <FileUploadDialog
                open={open}
                onClose={() => setOpen(false)}
                onAccept={(files) => handleUpload(files)}
            />
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default DocumentFiles
