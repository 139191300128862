import { Typography, Checkbox, Icon, IconButton, TextField, Tooltip } from '@material-ui/core'
import { REJECTED_STATES, REJECTED_STATES_VIEW, FIELDS } from 'lib'
import React from 'react'
import { IRejectedFilters } from 'types'
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { Autocomplete } from '@material-ui/lab'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface Props {
    filters: IRejectedFilters
    handleChangeFilters: (key: keyof IRejectedFilters, value: any) => void
    widget?: React.ReactNode
}

const RejectedFilters = (props: Props) => {
    const { filters, handleChangeFilters, widget } = props

    const allStatesSelected = React.useCallback(() => { return REJECTED_STATES.length === filters.states.length }, [filters.states])
    const allProcessSelected = React.useCallback(() => { return REJECTED_STATES_VIEW.length === filters.processStatus.length }, [filters.processStatus])

    return (
        <div className="mt-2 mb-1 w-full flex items-center">
            <div style={{ width: 'calc(33% + 44px)' }} className="flex items-center">
                <Autocomplete
                    multiple
                    options={REJECTED_STATES}
                    disableCloseOnSelect
                    getOptionLabel={(option) => (option.name)}
                    fullWidth
                    value={REJECTED_STATES.filter(r => filters.states.includes(r.id))}
                    openOnFocus
                    classes={{ input: "autocomplete-p-s" }}
                    size="small"
                    limitTags={1}
                    ChipProps={{ size: "small", style: { maxWidth: 140 } }}
                    onChange={(_, selectedValue) => {
                        handleChangeFilters("states", selectedValue.map(s => s.id))
                    }}
                    renderOption={(option) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, height: 35 }}
                                checked={Boolean(filters.states.includes(option.id))}
                                size="small"
                            />
                            <Typography variant="body2">
                                {option.name}
                            </Typography>
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            label={FIELDS.state.name}
                        />
                    )}
                />
                <Tooltip arrow title={allStatesSelected() ? "Quitar todos" : "Selecionar todos"}>
                    <IconButton
                        onClick={() => {
                            handleChangeFilters(
                                'states',
                                allStatesSelected() ? [] : REJECTED_STATES.map(r => r.id)
                            )
                        }}
                    >
                        <Icon fontSize="small">{allStatesSelected() ? "indeterminate_check_box" : "check_box_outline_blank"}</Icon>
                    </IconButton>
                </Tooltip>
            </div>
            <div style={{ width: 'calc(33% + 44px)' }} className="flex items-center">
                <Autocomplete
                    multiple
                    options={REJECTED_STATES_VIEW}
                    disableCloseOnSelect
                    getOptionLabel={(option) => (option.name)}
                    fullWidth
                    value={REJECTED_STATES_VIEW.filter(r => filters.processStatus.includes(r.id))}
                    openOnFocus
                    classes={{ input: "autocomplete-p-s" }}
                    size="small"
                    limitTags={1}
                    ChipProps={{ size: "small", style: { maxWidth: 140 } }}
                    onChange={(_, selectedValue) => {
                        handleChangeFilters("processStatus", selectedValue.map(s => s.id))
                    }}
                    renderOption={(option) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, height: 35 }}
                                checked={Boolean(filters.processStatus.includes(option.id))}
                                size="small"
                            />
                            <Typography variant="body2">
                                {option.name}
                            </Typography>
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            label={FIELDS.process.name}
                        />
                    )}
                />
                <Tooltip arrow title={allProcessSelected() ? "Quitar todos" : "Selecionar todos"}>
                    <IconButton
                        onClick={() => {
                            handleChangeFilters(
                                'processStatus',
                                allProcessSelected() ? [] : REJECTED_STATES_VIEW.map(r => r.id)
                            )
                        }}
                    >
                        <Icon fontSize="small">{allProcessSelected() ? "indeterminate_check_box" : "check_box_outline_blank"}</Icon>
                    </IconButton>
                </Tooltip>
            </div>
            {widget}
        </div>
    )
}

export default RejectedFilters
