import { Box, Button, Dialog, DialogActions, Tab, Tabs, Typography } from '@material-ui/core'
import TaxPlanPicker from 'components/TaxPlanPicker/TaxPlanPicker'
import PurchaseOrderPicker from 'components/PurchaseOrderPicker/PurchaseOrderPicker'
import { FIELDS, getSupplierByIdService, NEW_PATH, RESOURCES_PATH, SUPPLIER_PATH } from 'lib'
import React from 'react'
import { IPurchaseOrderAccoutingInfo, ITaxPlan } from 'types'
import { useHistory } from 'react-router'

interface Props {
    open: boolean
    onClose: () => void
    supplierId?: number
    onApprove: (taxPlan?: ITaxPlan, purchaseOrder?: IPurchaseOrderAccoutingInfo) => void
    requirePo: boolean
    requireTaxPlan: boolean
    identification?: string
    purchaseOrder?: IPurchaseOrderAccoutingInfo
    withReimbursementTaxPlan: boolean
}


function TabPanel(props: any) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    )
}

const ApproveDocumentDialog = (props: Props) => {
    const { onClose, open, onApprove, requireTaxPlan, identification, withReimbursementTaxPlan } = props
    const [requirePo, setRequirePo] = React.useState<boolean>(props.requirePo)
    const [value, setValue] = React.useState(requirePo && requireTaxPlan ? 0 : requirePo ? 1 : 0)
    const [selectedTaxPlan, setSelectedTaxPlan] = React.useState<ITaxPlan | undefined>(undefined)
    const [supplierId, setSupplierId] = React.useState<number | undefined>(props.supplierId)
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = React.useState<IPurchaseOrderAccoutingInfo | undefined>(props.purchaseOrder ?? undefined)
    const history = useHistory()

    const handleChange = (_: any, newValue: number) => setValue(newValue)

    React.useEffect(() => {
        const init = async () => {
            if (identification && !supplierId) {
                try {
                    const result = await getSupplierByIdService(identification)
                    setSupplierId(result.supplierId)
                    setRequirePo(result.requirePo)
                } catch (error) {

                }
            }
        }
        init()
    }, [supplierId, identification])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
        >
            <React.Fragment>
                {
                    identification && !supplierId ?
                        <div style={{ width: "40vw", height: "40vh" }} className="w-full h-full flex flex-col px-16 justify-center items-center">
                            <Typography className="pb-8" variant="h5" color="textSecondary" align="center">
                                {"No se encontro el proveedor"}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => history.push(`${RESOURCES_PATH}${SUPPLIER_PATH}${NEW_PATH}`)}
                            >
                                {"Registrar"}
                            </Button>
                        </div>
                        : <React.Fragment>
                            {
                                requirePo && requireTaxPlan &&
                                <Tabs indicatorColor="primary" value={value} onChange={handleChange} variant="fullWidth">
                                    <Tab label={FIELDS.taxPlan.name} />
                                    <Tab label={FIELDS.purchaseOrder.name} />
                                </Tabs>
                            }
                            {
                                requireTaxPlan &&
                                <TabPanel value={value} index={0}>
                                    <TaxPlanPicker
                                        selected={selectedTaxPlan}
                                        setSelected={setSelectedTaxPlan}
                                        supplierId={supplierId}
                                        withReimbursementTaxPlan={withReimbursementTaxPlan}
                                    />
                                </TabPanel>
                            }
                            {
                                requirePo &&
                                <TabPanel value={value} index={1}>
                                    <PurchaseOrderPicker
                                        selected={selectedPurchaseOrder}
                                        setSelected={setSelectedPurchaseOrder}
                                        receiverRuc={identification}
                                    />
                                </TabPanel>
                            }
                            <DialogActions>
                                {
                                    <Typography className="px-4" variant="caption" color="textSecondary">
                                        {requirePo && requireTaxPlan ? "Seleciona plan de impuestos y orden de compra para continuar" : requireTaxPlan ? "Selecciona un plan de impuestos para continuar" : requirePo ? "Selecciona una orden de compra para continuar" : ""}
                                    </Typography>
                                }
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    disabled={requirePo && requireTaxPlan ? !selectedPurchaseOrder && !selectedTaxPlan : requirePo ? !selectedPurchaseOrder?.serialNumber : requireTaxPlan ? !selectedTaxPlan : false}
                                    disableElevation
                                    onClick={() => {
                                        onApprove(selectedTaxPlan, selectedPurchaseOrder)
                                        onClose()
                                    }}
                                >
                                    {"Aprobar"}
                                </Button>
                            </DialogActions>
                        </React.Fragment>
                }
            </React.Fragment>
        </Dialog>
    )
}

export default ApproveDocumentDialog
