import axios, { AxiosError } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, EXCEPTION_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const getExceptionsService = async () => {
    try {
        const result = await intance.get(EXCEPTION_PATH + LIST_PATH)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createExceptionService = async (exception: any) => {
    try {
        const result = await intance.post(EXCEPTION_PATH, exception)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteExceptionService = async (id: any) => {
    try {
        const result = await intance.delete(EXCEPTION_PATH + `/${id}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editExceptionService = async (id: number, exception: any) => {
    try {
        const result = await intance.put(EXCEPTION_PATH + `/${id}`, exception)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}