import { Checkbox, Icon, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { FIELDS, REJECTED_STATES } from 'lib'
import React from 'react'
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { IRejectedReportParams } from 'types'

interface Props {
    params: IRejectedReportParams
    onChange: (key: keyof IRejectedReportParams, value: any) => void
    restartDates: () => void
}

const mediumInputStyle = { width: "49%" }
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const RejectedReportParams = (props: Props) => {
    const { params, onChange: handleChange, restartDates } = props

    const allStatesSelected = React.useCallback(() => {
        return params.states.length === REJECTED_STATES.length
    }, [params.states])

    return (
        <div className="flex flex-grow flex-col h-full mr-4 bg-transparent pr-10">
            <Typography className="pb-3 pt-4" color="textSecondary" variant="body2" style={{ fontWeight: 700, letterSpacing: 0.5 }}>
                {"Parámetros del reporte"}
            </Typography>
            <div className="w-full flex flex-wrap pt-4">
                <Alert style={{ marginBottom: 20, width: "100%" }} variant="standard" severity="warning" icon={<Icon>info</Icon>}>
                    Se incluiran <strong>todos</strong> los rechazados en caso de no modificar las fechas
                </Alert>
                <div
                    className="flex justify-between mb-2 flex items-start"
                    style={{ ...mediumInputStyle, width: "100%" }}
                >
                    <KeyboardDatePicker
                        value={params.rejectedStart}
                        helperText={FIELDS.rejectedDate.name}
                        FormHelperTextProps={{ style: { margin: 8 } }}
                        onChange={(date) => { handleChange("rejectedStart", date) }}
                        style={{ ...mediumInputStyle, marginTop: 0, marginRight: 10 }}
                        emptyLabel={FIELDS.emptyDate.name}
                        inputVariant="outlined"
                        fullWidth
                        invalidDateMessage={"Fecha inválida"}
                        size="small"
                        label={FIELDS.startDate.name}
                        variant="inline"
                        format="dd/MM/yyyy"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon fontSize="small" color="action">event</Icon>
                                </InputAdornment>
                            ),
                        }}
                        onBlur={() => {
                            if (!params.rejectedStart || isNaN(params.rejectedStart.getTime())) {
                                handleChange("rejectedStart", null)
                            }
                        }}
                    />
                    <KeyboardDatePicker
                        value={params.rejectedEnd}
                        onChange={(date) => { handleChange("rejectedEnd", date) }}
                        style={{ ...mediumInputStyle, marginTop: 0 }}
                        emptyLabel={FIELDS.emptyDate.name}
                        inputVariant="outlined"
                        fullWidth
                        invalidDateMessage={"Fecha inválida"}
                        size="small"
                        label={FIELDS.endDate.name}
                        variant="inline"
                        format="dd/MM/yyyy"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon fontSize="small" color="action">event</Icon>
                                </InputAdornment>
                            ),
                        }}
                        onBlur={() => {
                            if (!params.rejectedEnd || isNaN(params.rejectedEnd.getTime())) {
                                handleChange("rejectedEnd", null)
                            }
                        }}
                    />
                    <Tooltip arrow title={"Reiniciar fechas"}>
                        <IconButton
                            onClick={restartDates}
                        >
                            <Icon fontSize="small">{"clear_all"}</Icon>
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="w-full flex items-center mb-2">
                    <Autocomplete
                        multiple
                        options={REJECTED_STATES}
                        disableCloseOnSelect
                        getOptionLabel={(option) => (option.name)}
                        fullWidth
                        value={REJECTED_STATES.filter(r => params.states.includes(r.id))}
                        openOnFocus
                        classes={{ input: "autocomplete-p-s" }}
                        size="small"
                        style={{ ...mediumInputStyle, width: "100%" }}
                        ChipProps={{ size: "small", style: { maxWidth: "auto" } }}
                        onChange={(_, selectedValue) => {
                            handleChange(
                                'states',
                                selectedValue.map(s => s.id)
                            )
                        }}
                        renderOption={(option) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8, height: 35 }}
                                    checked={Boolean(params.states.includes(option.id))}
                                    size="small"
                                />
                                <Typography variant="body2">
                                    {option.name}
                                </Typography>
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                label={FIELDS.documentType.name}
                            />
                        )}
                    />
                    <Tooltip arrow title={allStatesSelected() ? "Quitar todos" : "Selecionar todos"}>
                        <IconButton
                            onClick={() => {
                                handleChange(
                                    'states',
                                    allStatesSelected() ? [] : REJECTED_STATES.map(r => r.id)
                                )
                            }}
                        >
                            <Icon fontSize="small">{allStatesSelected() ? "indeterminate_check_box" : "check_box_outline_blank"}</Icon>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default RejectedReportParams
