import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { REPORT_PATH, ATS_PATH, UPLOAD_PATH, FIELDS } from 'lib'
import { DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })
const formDataConfig: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export const uploadATSFileService = async (file: any) => {
    const formData = new FormData()
    formData.append(FIELDS.file.key, file)
    try {
        const result = await intance.post(REPORT_PATH + ATS_PATH + UPLOAD_PATH, formData, formDataConfig)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}