import { Icon, IconButton, Tooltip } from '@material-ui/core'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

interface Props {
    value: string
}

const CopyToClipboardIconButton = (props: Props) => {
    const { value } = props
    return (
        <div
            onClick={e => e.stopPropagation()}
            className="mx-2"
        >
                <Tooltip arrow title={`Copiar ${value}`}>
                <CopyToClipboard text={value}>
                    <IconButton size="small">
                        <Icon fontSize="small">
                            content_copy
                        </Icon>
                    </IconButton>
                </CopyToClipboard>
            </Tooltip>
        </div>
    )
}

export default CopyToClipboardIconButton
