import { Icon, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography, Button, LinearProgress, Badge, Snackbar, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ConfirmDialog, CopyToClipboardIconButton, FileUploadDialog, FixedLoadingIndicator, LabelDisplayedRows, NotificationMessageDialog, ReasonDialog, RejectedFilters, Search } from 'components'
import { AuthContext } from 'context'
import { useFilters, useGetLast, useTableModel, useViewDocument } from 'hooks'
import { ROOT_TITLE, REJECTED_TITLE, RETENTION_REJECTED_TABLE_COLUMNS, MEDIUM_ROWS_PER_PAGINATION_OPTIONS, formatCurrency, getRejectedService, INITIAL_REJECTED_FILTERS, COUPA_STATES, FIELDS, REJECTED_STATE_IDS, markRejectedService, DOCUMENT_PATH, DOCUMENT_VIEW_PATH, VOUCHER_MASK, PURCHASE_SETTLEMENT_REJECTED_TABLE_COLUMNS, DOCUMENT_TYPE_IDS, VOUCHER_SIZE, REJECTED_INITIAL_PAGINATION, resendEmailNotificationService, uploadRejectedFileService } from 'lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import { IRejected, IRejectedFilters, INotificationHistory, NotificationType, IMessageConfig } from 'types'
import moment from 'moment'
import 'moment/locale/es'
import { useHistory } from 'react-router-dom'
import { conformToMask } from 'react-text-mask'
moment.locale("es")

const useStyles = makeStyles({
    tooltip: {
      fontSize: 14,
    },
});

interface Props {
    initialType: number
}

const Rejected = (props: Props) => {
    const { initialType } = props
    const history = useHistory()
    const { user } = React.useContext(AuthContext)
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage } = useTableModel()
    const { getDocument, loading: loadingDocument } = useViewDocument()
    const [selectedNotifications, setSelectedNotifications] = React.useState<INotificationHistory[] | undefined>()
    const [selectedRejected, setSelectedRejected] = React.useState<IRejected | undefined>()
    const [type, setType] = React.useState<NotificationType | undefined>()
    const { filters, handleChangeFilters, restartFilters } = useFilters<IRejectedFilters>({ initialFilters: { ...INITIAL_REJECTED_FILTERS, type: initialType } })
    const [list, setList] = React.useState<IRejected[]>([])
    const [total, setTotal] = React.useState<number>(0)
    const [selectedId, setSelectedId] = React.useState<number>(-1)
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [openConfirm, setOpenConfirm] = React.useState(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const { getLast, LastAlert } = useGetLast()
    const classes = useStyles()
    const getInitialList = async () => {
        try {
            setLoading(true)
            const result = await getRejectedService(filters, REJECTED_INITIAL_PAGINATION.offset, REJECTED_INITIAL_PAGINATION.page)
            setList(result.rejected)
            setTotal(result.total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        getInitialList()
        getLast()
    }

    const handleReset = async () => {
        restartFilters()
        getLast()
        await getInitialList()
        handleChangePage({}, 0)
    }

    const refreshList = async () => {
        try {
            setLoading(true)
            getLast()
            const result = await getRejectedService(filters, list.length, 0)
            setList(result.rejected)
            setTotal(result.total)
            setLoading(false)
        } catch (error) {
            throw new Error()
        }
    }

    const handleMarkRejected = async (rejectedId: number, newState: number) => {
        try {
            setLoading(true)
            getLast()
            await markRejectedService(rejectedId, newState)
            await refreshList()
            setMessageConfig({ open: true, severity: "success", message: "Documento marcado" })
        } catch (error) {
            setLoading(false)
        }
    }

    const handleNewPage = async (pageNumber: number, offset: number) => {
        try {
            setLoading(true)
            getLast()
            const result = await getRejectedService({ ...INITIAL_REJECTED_FILTERS, type: initialType }, offset, pageNumber)
            setList(current => current.concat(result.rejected))
            setTotal(result.total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleSelect = async (documentId: number) => {
        try {
            const response = await getDocument(documentId)
            history.push({
                state: { document: response },
                pathname: `${DOCUMENT_PATH}${DOCUMENT_VIEW_PATH}/${documentId}`
            })
        } catch (error) {

        }
    }

    const handleUpload = async (file: File) => {
        try {
            setLoading(true)
            await uploadRejectedFileService(file)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const isRetentionReceipt = React.useCallback((): Boolean => { return initialType === DOCUMENT_TYPE_IDS.retentionReceipt }, [initialType])

    const handleReasonSuccess = () => {
        setMessageConfig({ open: true, message: "Motivo actualizado", severity: "success" })
        refreshList()
    }

    const handleResendEmail = async () => {
        try {
            setLoading(true)
            if (selectedRejected !== undefined) {
                await resendEmailNotificationService(selectedRejected.rejectedId, type === "notification" ? 1 : 2)
                setMessageConfig({ open: true, severity: "success", message: "Notificación enviada." })
                await refreshList()
            }
            setLoading(false)
            return true
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, severity: "error", message: "No se pudo enviar la notificación." })
            return false
        }
    }

    React.useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const result = await getRejectedService({ ...INITIAL_REJECTED_FILTERS, type: initialType }, REJECTED_INITIAL_PAGINATION.offset, REJECTED_INITIAL_PAGINATION.page)
                setList(result.rejected)
                setTotal(result.total)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        init()
    }, [initialType])

    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${REJECTED_TITLE}`}</title>
            </Helmet>
            <div className="flex items-center">
                <form className="flex w-full" onSubmit={handleSubmit}>
                    <div className="flex-grow">
                        <div className="flex items-center justify-between w-full flex-wrap">
                            <Search
                                onChange={(e) => handleChangeFilters("retentionDocumentNumber", e.target.value)}
                                query={filters.retentionDocumentNumber}
                                onClear={() => handleChangeFilters("retentionDocumentNumber", "")}
                                placeholer="Buscar por número de retención..."
                                width={"33%"}
                            />
                            <Search
                                onChange={(e) => handleChangeFilters("associatedDocumentNumber", e.target.value)}
                                query={filters.associatedDocumentNumber}
                                onClear={() => handleChangeFilters("associatedDocumentNumber", "")}
                                placeholer="Buscar por documento al que aplica..."
                                width={"33%"}
                            />
                            <Search
                                onChange={(e) => handleChangeFilters("rucOrName", e.target.value)}
                                query={filters.rucOrName}
                                onClear={() => handleChangeFilters("rucOrName", "")}
                                placeholer="Buscar por RUC o nombre..."
                                width={"33%"}
                            />
                            <RejectedFilters
                                filters={filters}
                                handleChangeFilters={handleChangeFilters}
                                widget={<LastAlert />}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            disableElevation
                            type="submit"
                            style={{ width: 120, marginTop: 10, marginLeft: 20 }}
                        >
                            {"Buscar"}
                        </Button>
                        <Button
                            startIcon={
                            <Icon fontSize="small">
                                restart_alt
                            </Icon>}
                            variant="contained"
                            size="small"
                            disableElevation
                            onClick={handleReset}
                            disabled={loading}
                            style={{ width: 120, marginTop: 10, marginLeft: 20 }}
                        >
                            {"Cancelar"}
                        </Button>
                    </div>
                </form>
            </div>
            {
                loading &&
                <LinearProgress />
            }
            <div className="flex h-full flex-col mt-4 overflow-hidden">
                <TableContainer className="h-full">
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                {(isRetentionReceipt() ? RETENTION_REJECTED_TABLE_COLUMNS : PURCHASE_SETTLEMENT_REJECTED_TABLE_COLUMNS).map((headCell, index) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={"left"}
                                        padding={"default"}
                                        // sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : "asc"}
                                            onClick={createSortHandler(headCell.id as any)}
                                            style={{ fontSize: "0.85em", width: index === 0 || index === 1 ? 180 : (headCell.id === FIELDS.authorizationNumber.key || headCell.id === FIELDS.supplier.key || headCell.id === FIELDS.reason.key) ? 300 : (headCell.id === FIELDS.value.key || headCell.id === FIELDS.supplierNotification.key || headCell.id === FIELDS.reminder.key) ? 80 : 150 }}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <span className="hidden">
                                                    {order === "desc"
                                                        ? "sorted descending"
                                                        : "sorted ascending"}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell padding={"default"} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (rows(list) as IRejected[]).map((row, index) => (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.documentId + index + "document"}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className="document-link cursor-pointer"
                                            onClick={() => handleSelect(row.retDocumentId)}
                                        >
                                            <div className="w-full flex items-center">
                                                {conformToMask(row.retSerialNumber, VOUCHER_MASK, { guide: false }).conformedValue}
                                                <CopyToClipboardIconButton value={conformToMask(row.retSerialNumber, VOUCHER_MASK, { guide: false }).conformedValue} />
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row" className="document-link cursor-pointer" onClick={() => handleSelect(row.documentId)}>
                                            <div className="w-full flex items-center">
                                                {row.serialNumber.length !== VOUCHER_SIZE ? row.serialNumber : conformToMask(row.serialNumber, VOUCHER_MASK, { guide: false }).conformedValue}
                                                <CopyToClipboardIconButton value={row.serialNumber.length !== VOUCHER_SIZE ? row.serialNumber : conformToMask(row.serialNumber, VOUCHER_MASK, { guide: false }).conformedValue} />
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div className="w-full flex items-center">
                                                {row.retAuthorization} <CopyToClipboardIconButton value={row.retAuthorization} />
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div className="w-full flex items-center">
                                                {moment(new Date(row.retDocumentDate).toISOString()).format('DD-MM-YYYY').toString()}
                                                <CopyToClipboardIconButton value={moment(new Date(row.retDocumentDate).toISOString()).format('DD-MM-YYYY').toString()} />
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div className="w-full flex items-center">
                                                {row.companyRuc}
                                                <CopyToClipboardIconButton value={row.companyRuc} />
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row">{row.companyName}</TableCell>
                                        <TableCell component="th" scope="row" align="right">{formatCurrency(row.total)}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {
                                                row.processStatus === COUPA_STATES.rejectedSat ?
                                                    "Rechazado SAT" : "Rechazo Interno"
                                            }
                                        </TableCell>
                                        {
                                            isRetentionReceipt() &&
                                            <React.Fragment>
                                                <TableCell component="th" scope="row" align="left">
                                                    { 
                                                        Boolean(row.notifications.length) ?
                                                            <Button
                                                                onClick={() => {
                                                                    setSelectedNotifications(row.notifications)
                                                                    setType("notification")
                                                                }}
                                                                style={{ textTransform: 'none' }}
                                                                size="small"
                                                            >
                                                                <Badge
                                                                    overlap="rectangular"
                                                                    badgeContent={row.notifications.length}
                                                                    classes={{ badge: 'info-notification' }}
                                                                >
                                                                    <Typography color="textSecondary" style={{ fontWeight: 600, letterSpacing: 0.75 }} variant="caption">
                                                                        {"Enviado"}
                                                                    </Typography>
                                                                </Badge>
                                                            </Button>
                                                            :
                                                            <Tooltip title="Enviar Notificación" >
                                                                <Button
                                                                    onClick={() => {
                                                                        setOpenConfirm(true)
                                                                        setSelectedRejected(row)
                                                                        setType("notification")
                                                                    }}
                                                                    style={{ textTransform: 'none' }}
                                                                    size="small"
                                                                >
                                                                    <Typography color="textSecondary" style={{ fontWeight: 600, letterSpacing: 0.75 }} variant="caption">
                                                                        {"Sin enviar"}
                                                                    </Typography>
                                                                </Button>
                                                            </Tooltip>
                                                    }
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {
                                                        Boolean(row.reminders.length) ?
                                                            <Button
                                                                onClick={() => {
                                                                    setSelectedNotifications(row.reminders)
                                                                    setType("reminder")
                                                                }}
                                                                style={{ textTransform: 'none' }}
                                                                size="small"
                                                            >
                                                                <Badge
                                                                    overlap="rectangular"
                                                                    badgeContent={row.reminders.length}
                                                                    classes={{ badge: 'info-notification' }}
                                                                >
                                                                    <Typography color="textSecondary" style={{ fontWeight: 600, letterSpacing: 0.75 }} variant="caption">
                                                                        {"Enviado"}
                                                                    </Typography>
                                                                </Badge>
                                                            </Button>
                                                            :
                                                            <Tooltip title="Enviar Notificación" >
                                                                <Button
                                                                    onClick={() => {
                                                                        setOpenConfirm(true)
                                                                        setSelectedRejected(row)
                                                                        setType("reminder")
                                                                    }}
                                                                    style={{ textTransform: 'none' }}
                                                                    size="small"
                                                                >
                                                                    <Typography color="textSecondary" style={{ fontWeight: 600, letterSpacing: 0.75 }} variant="caption">
                                                                        {"Sin enviar"}
                                                                    </Typography>
                                                                </Button>
                                                            </Tooltip>
                                                    }
                                                </TableCell>
                                            </React.Fragment>
                                        }
                                        <TableCell align="center" component="th" scope="row">
                                            {
                                                row.status === REJECTED_STATE_IDS.toBeCanceled ?
                                                    <div className="flex items-center">
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            fullWidth
                                                            disableElevation
                                                            disabled={loading || !Boolean(user?.canRequest)}
                                                            onClick={() => handleMarkRejected(row.rejectedId, REJECTED_STATE_IDS.requested)}
                                                        >
                                                            {"Marcar"}
                                                        </Button>
                                                    </div>
                                                    :
                                                    <React.Fragment>
                                                        {
                                                            row.status === REJECTED_STATE_IDS.requested || row.status === REJECTED_STATE_IDS.canceled ?
                                                                <div className="flex w-full justify-center">
                                                                    <Icon style={{ color: "#4caf50" }} color="action" fontSize="small">
                                                                        check_circle
                                                                    </Icon>
                                                                </div>
                                                                : undefined
                                                        }
                                                    </React.Fragment>
                                            }
                                        </TableCell>
                                        <TableCell align="center" component="th" scope="row">
                                            {
                                                row.status === REJECTED_STATE_IDS.requested || row.status === REJECTED_STATE_IDS.toBeCanceled ?
                                                    <div className="flex items-center">
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            fullWidth
                                                            disableElevation
                                                            disabled={loading || row.status === REJECTED_STATE_IDS.toBeCanceled || !Boolean(user?.canCancel)}
                                                            onClick={() => handleMarkRejected(row.rejectedId, REJECTED_STATE_IDS.canceled)}
                                                        >
                                                            {"Marcar"}
                                                        </Button>
                                                    </div>
                                                    :
                                                    <React.Fragment>
                                                        {
                                                            row.status === REJECTED_STATE_IDS.requested || row.status === REJECTED_STATE_IDS.canceled ?
                                                                <div className="flex w-full justify-center">
                                                                    <Icon style={{ color: "#4caf50" }} fontSize="small">
                                                                        check_circle
                                                                    </Icon>
                                                                </div>
                                                                : undefined
                                                        }
                                                    </React.Fragment>
                                            }
                                        </TableCell>
                                        <TableCell align="center" component="th" scope="row">
                                            {
                                                row.status === REJECTED_STATE_IDS.toBeCanceled ?
                                                    <div className="flex items-center">
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            fullWidth
                                                            disableElevation
                                                            disabled={loading || !Boolean(user?.canRequest)}
                                                            onClick={() => handleMarkRejected(row.rejectedId, REJECTED_STATE_IDS.na)}
                                                        >
                                                            {"Marcar"}
                                                        </Button>
                                                    </div>
                                                    :
                                                    <React.Fragment>
                                                        {
                                                            row.status === REJECTED_STATE_IDS.na ?
                                                                <div className="flex w-full justify-center">
                                                                    <Icon style={{ color: "#4caf50" }} color="action" fontSize="small">
                                                                        check_circle
                                                                    </Icon>
                                                                </div>
                                                                : undefined
                                                        }
                                                    </React.Fragment>
                                            }
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="left">
                                            {
                                                Boolean(row.reason) ?
                                                    <Tooltip title={row.reason} classes={{tooltip: classes.tooltip}}> 
                                                        <div>{row.reason.length > 30 ? row.reason.substring(0, 30) + "..." : row.reason}</div>
                                                    </Tooltip>
                                                    :
                                                    <Button
                                                        variant="contained"
                                                        disableElevation
                                                        size="small"
                                                        onClick={() => setSelectedId(row.rejectedId)}
                                                    >
                                                        {"Ingresar motivo"}
                                                        <Icon style={{ marginLeft: 10, fontSize: "1.2em" }} fontSize="small">
                                                            edit
                                                        </Icon>
                                                    </Button>
                                            }
                                        </TableCell>
                                        {
                                            isRetentionReceipt() &&
                                            <TableCell component="th" scope="row" align="right">
                                                {
                                                    row.notificationEmail ?
                                                        <div className="w-full flex items-center">
                                                            {row.notificationEmail}
                                                            {
                                                                isRetentionReceipt() &&
                                                                <CopyToClipboardIconButton value={row.notificationEmail} />
                                                            }
                                                        </div>
                                                        : ""
                                                }
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={MEDIUM_ROWS_PER_PAGINATION_OPTIONS}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={async (event, pageNumber) => {
                        if (pageNumber > page && ((pageNumber + 1) * rowsPerPage) > list.length && list.length < total) {
                            await handleNewPage(pageNumber, rowsPerPage)
                        }
                        handleChangePage(event, pageNumber)
                    }}
                    onRowsPerPageChange={async (event) => {
                        if (parseInt(event.target.value, 10) > rowsPerPage && parseInt(event.target.value, 10) > list.length && list.length < total) {
                            await handleNewPage(page, parseInt(event.target.value, 10))
                        }
                        handleChangeRowsPerPage(event as any)
                    }}
                    labelRowsPerPage="Documentos rechazados por página"
                    labelDisplayedRows={LabelDisplayedRows}
                />
            </div>
            {
                selectedNotifications &&
                <NotificationMessageDialog
                    open={Boolean(selectedNotifications)}
                    onClose={() => setSelectedNotifications(undefined)}
                    notifications={selectedNotifications}
                    type={type}
                    onSuccess={async () => {
                        setMessageConfig({ open: true, severity: "success", message: "Mensaje enviado" })
                        await refreshList()
                    }}
                    onError={() => setMessageConfig({ open: true, severity: "error", message: "No se pudo enviar el mensaje" })}
                />
            }
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loadingDocument} />
            <FileUploadDialog
                open={open}
                onClose={() => setOpen(false)}
                onAccept={(files) => { handleUpload(files[0].file) }}
                singleFile
                hideDescription
            />
            <ReasonDialog
                onClose={() => setSelectedId(-1)}
                rejectedId={selectedId}
                open={Boolean(selectedId !== -1)}
                onSuccess={handleReasonSuccess}
                onError={() => setMessageConfig({ open: true, message: "No se pudo actulizar el motivo...", severity: "error" })}
            />
            <ConfirmDialog
                open={openConfirm}
                onCancel={() => {
                    setOpenConfirm(false)
                    setSelectedRejected(undefined)
                }}
                onConfirm={handleResendEmail}
                title={`¿Esta seguro que desea enviar la notificación por correo electrónico al proveedor?`}
            />
        </Paper>
    )
}

export default Rejected
