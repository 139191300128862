import axios, { AxiosError } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, REJECTION_CAUSE_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const getRejectionCauseService = async () => {
    try {
        const result = await intance.get(REJECTION_CAUSE_PATH + LIST_PATH)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createRejectionCauseService = async (rejectionCause: any) => {
    try {
        const result = await intance.post(REJECTION_CAUSE_PATH, rejectionCause)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteRejectionCauseService = async (id: any) => {
    try {
        const result = await intance.delete(REJECTION_CAUSE_PATH + `/${id}`)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editRejectionCauseService = async (id: number, rejectionCause: any) => {
    try {
        const result = await intance.put(REJECTION_CAUSE_PATH + `/${id}`, rejectionCause)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}