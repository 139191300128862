import { getSupplierByIdService } from 'lib'
import React from 'react'
import { ISupplier } from 'types'

interface Props {
    ruc: string
}

interface Hook {
    loading: boolean
    supplier: ISupplier | undefined
}

const useGetSupplier = (props: Props): Hook => {
    const { ruc } = props
    const [loading, setLoading] = React.useState(false)
    const [supplier, setSupplier] = React.useState(undefined)

    React.useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const result = await getSupplierByIdService(ruc)
                setLoading(false)
                setSupplier(result)
            } catch (error) {
                // console.log(error)
                setLoading(false)
            }
        }
        init()
    }, [ruc])

    return {
        supplier,
        loading
    }
}

export default useGetSupplier
