import React from 'react'
import { Divider, Paper, TextField, Typography, List, Checkbox, ListItem, ListItemIcon, ListItemText, Avatar, IconButton, Icon, Popover, MenuItem, Button, Snackbar, Tooltip } from '@material-ui/core'
import { ROOT_TITLE, MODULE_VIEW_TITLE, getModuleViewsByModuleIdService, reorder, createModuleViewsByModuleIdService } from 'lib'
import { Helmet } from 'react-helmet'
import { ManagementContext } from 'context'
import { Alert, Autocomplete, Skeleton } from '@material-ui/lab'
import { FixedLoadingIndicator, Search, ServerErrorHandler } from 'components'
import Lottie from 'react-lottie'
import { IMessageConfig, IModule, IModuleView, IView } from 'types'
import view from 'assets/animations/view.json'
import { useInputValue } from 'hooks'
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd"
import { useLocation } from 'react-router'

const ModuleView = () => {
    const location = useLocation()
    const { modules, refreshModules, views, refreshViews } = React.useContext(ManagementContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [updating, setUpdating] = React.useState<boolean>(false)
    const { value: query, clearValue: handleClear, onChange: handleChange } = useInputValue()
    const [error, setError] = React.useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const [selectedModule, setSelectedModule] = React.useState<IModule | null>(null)
    const [moduleViews, setModuleViews] = React.useState<IModuleView[] | undefined>(undefined)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const handleSelectModule = async (module: IModule | null) => {
        setSelectedModule(module)
        if (Boolean(module) && module) {
            setLoading(true)
            setModuleViews(await getModuleViewsByModuleIdService(module.moduleId))
            setLoading(false)
        } else {
            setModuleViews(undefined)
        }
    }
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return
        const items = reorder(
            moduleViews ?? [],
            result.source.index,
            result.destination.index
        )
        setModuleViews(items)
    }
    const handleMove = (start: number, end: number) => {
        const items = reorder(
            moduleViews ?? [],
            start,
            end
        )
        setModuleViews(items)
    }
    const toggleView = (view: IView) => {
        const selected = Boolean(moduleViews?.find(m => m.viewViewId === view.viewId))
        if (selected) {
            setModuleViews(current => current?.filter(m => m.viewViewId !== view.viewId))
        } else {
            if (selectedModule) {
                setModuleViews(current => { if (current) { return [...current, { moduleModuleId: selectedModule.moduleId, viewViewId: view.viewId }] } })
            }
        }
    }
    const handleDeleteModuleView = (moduleView: IModuleView) => {
        setModuleViews(current => current ? current.filter(c => c !== moduleView) : undefined)
    }
    const handleSave = async () => {
        try {
            setUpdating(true)
            if (selectedModule && moduleViews) {
                const selectedModuleViews: IModuleView[] = moduleViews.map((m, i) => ({ moduleModuleId: selectedModule.moduleId, viewViewId: m.viewViewId, index: i }))
                await createModuleViewsByModuleIdService(selectedModule.moduleId, { moduleViews: selectedModuleViews })
                setMessageConfig({ open: true, message: "Vistas actualizadas!", severity: "success" })
            }
            setUpdating(false)
        } catch (error) {
            setUpdating(false)
            setMessageConfig({ open: true, message: "No se pudo actualizar las vistas...", severity: "error" })
        }
    }
    const getViewsModel = React.useCallback(() => {
        const src = [...views]
        if (Boolean(query)) {
            return src.filter((s: any) => {
                return s.name.toUpperCase().includes(query.toUpperCase())
            })
        }
        return src
    }, [query, views])
    React.useEffect(() => {
        const initScreen = async () => {
            try {
                setLoading(true)
                await refreshModules()
                await refreshViews()
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setError(true)
            }
        }
        initScreen()
    }, [refreshModules, refreshViews])
    React.useEffect(() => {
        if ((location.state as any)?.module) {
            handleSelectModule((location.state as any).module)
        }
    }, [location.state])
    return (
        <div className="flex items-center flex h-full justify-center overflow-y-hidden py-1">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${MODULE_VIEW_TITLE}`}</title>
            </Helmet>
            <Paper className="flex flex-col h-full w-1/4 overflow-hidden">
                <div className="p-4 pb-0">
                    <Typography style={{ fontWeight: 600, letterSpacing: 0.75 }} variant="subtitle1" color="textSecondary" className="uppercase pb-2">
                        {"Módulos registrados"}
                    </Typography>
                    <Autocomplete
                        disabled={loading || updating}
                        options={modules}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        openOnFocus
                        value={selectedModule}
                        onChange={(_, newInputValue) => { handleSelectModule(newInputValue) }}
                        size="small"
                        className="autocomplete-small-p"
                        noOptionsText="No se encontraron módulos registrados"
                        classes={{ root: "search-text-field", noOptions: "text-xs" }}
                        renderInput={(params) => <TextField {...params} placeholder="Buscar módulo por nombre..." variant="outlined" />}
                    />
                </div>
                {
                    (Boolean(moduleViews) && moduleViews && !loading) &&
                    <div className="flex flex-grow flex-col p-4 overflow-y-hidden">
                        <Typography style={{ fontWeight: 600, letterSpacing: 0.75 }} variant="subtitle1" color="textSecondary" className="uppercase pb-2">
                            {"Vistas registradas"}
                        </Typography>
                        <Search
                            onChange={handleChange}
                            query={query}
                            onClear={handleClear}
                            placeholer={"Filtrar vistas por nombre..."}
                            width="100%"
                            disabled={updating || loading}
                        />
                        <div className="mt-4">
                            <Divider />
                        </div>
                        <div className="flex-grow overflow-y-auto w-full">
                            <List className="w-full" dense component="div" role="list">
                                {
                                    getViewsModel().map(view => (
                                        <Tooltip placement="left" arrow title={view.description}>
                                            <ListItem
                                                onClick={() => toggleView(view)}
                                                dense
                                                key={view.relativePath}
                                                role="listitem" button
                                                disabled={updating || loading}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={moduleViews.map(m => m.viewViewId).includes(view.viewId)}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        size="small"
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={view.name} primary={`${view.name}`} />
                                            </ListItem>
                                        </Tooltip>
                                    ))
                                }
                            </List>
                        </div>
                        <div>
                            <Divider />
                            <div className="w-full mt-4 flex justify-end items-center">
                                <Typography className="px-2 uppercase" color="textSecondary" style={{ fontSize: "0.75em" }}>
                                    {`${moduleViews.length}/${views.length} agregadas`}
                                </Typography>
                                <IconButton disabled={updating || loading} onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
                                    <Icon fontSize="small">more_vert</Icon>
                                </IconButton>
                                <Popover
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={() => { setAnchorEl(null); if (selectedModule) setModuleViews(views.map(v => { return { moduleModuleId: selectedModule.moduleId, viewViewId: v.viewId } })) }} dense>
                                        {"Agregar todas"}
                                    </MenuItem>
                                    <MenuItem onClick={() => { setAnchorEl(null); setModuleViews([]) }} dense>
                                        {"Eliminar todas"}
                                    </MenuItem>
                                </Popover>
                            </div>
                        </div>
                    </div>
                }
            </Paper>
            <Paper className="flex-flex-col h-full p-4 w-2/3 ml-6">
                {
                    (Boolean(selectedModule) && selectedModule && !loading) ?
                        <div className="w-full h-full flex flex-col">
                            <Typography style={{ fontWeight: 600, letterSpacing: 0.75 }} variant="subtitle1" color="textSecondary" className="uppercase pb-2">
                                {`Vistas agregadas a ${selectedModule.name}`}
                            </Typography>
                            <div className="flex-grow overflow-y-auto">
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            // style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {
                                                    moduleViews?.map((moduleView, index) => (
                                                        <Draggable isDragDisabled={updating || loading} key={"moduleView" + index} draggableId={"moduleView" + index} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className="flex w-full my-2"
                                                                >
                                                                    <Paper className="flex items-center justify-center px-4 mr-2">
                                                                        <Avatar style={{ width: 35, height: 35, fontSize: "0.9em" }}>
                                                                            {index + 1}
                                                                        </Avatar>
                                                                    </Paper>
                                                                    <Paper className="flex-grow p-4">
                                                                        {views.find(v => v.viewId === moduleView.viewViewId)?.name}
                                                                    </Paper>
                                                                    <Paper className="flex items-center justify-center px-4 ml-2">
                                                                        <IconButton onClick={() => handleMove(index, index - 1)} disabled={(index === 0) || updating || loading} size="small">
                                                                            <Icon>
                                                                                {"keyboard_arrow_up"}
                                                                            </Icon>
                                                                        </IconButton>
                                                                        <IconButton onClick={() => handleMove(index, index + 1)} disabled={(index + 1 === moduleViews.length) || updating || loading} size="small">
                                                                            <Icon>
                                                                                {"keyboard_arrow_down"}
                                                                            </Icon>
                                                                        </IconButton>
                                                                    </Paper>
                                                                    <div className="mx-2 flex items-center justify-center">
                                                                        <IconButton disabled={updating || loading} onClick={() => handleDeleteModuleView(moduleView)} color="primary">
                                                                            <Icon fontSize="small">
                                                                                {"close"}
                                                                            </Icon>
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))
                                                }
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <div className="px-4 flex items-center justify-end mt-4">
                                <Button
                                    disableElevation
                                    disabled={moduleViews?.length === 0 || updating}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSave}
                                >
                                    {"Guardar cambios"}
                                </Button>
                            </div>
                        </div>
                        :
                        <div className="w-full h-full flex flex-col items-center justify-center p-8">
                            {
                                loading ?
                                    <Skeleton width="100%" height="50%" variant="text" animation="wave" />
                                    :
                                    <React.Fragment>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: view,
                                                rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                                            }}
                                            height={300}
                                            width={300}
                                        />
                                        <Typography color="textSecondary" variant="h6" align="center">
                                            {"Seleciona un módulo para poder administrar sus vistas"}
                                        </Typography>
                                    </React.Fragment>
                            }
                        </div>
                }
            </Paper>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading || updating} />
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={async () => { try { await refreshModules(); await refreshViews() } catch (e) { throw new Error("") } }}
            />
        </div>
    )
}

export default ModuleView
