import { Icon, IconButton, Snackbar, Typography } from '@material-ui/core'
import { EmailList, RejectedReportParams } from 'components'
import { useRejectedReportParams } from 'hooks'
import { ROOT_TITLE, sendRejectedReportByEmailService, SEND_REPORT_TITLE, transformRejectedReportParams } from 'lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import Lottie from 'react-lottie'
import mailSend from 'assets/animations/mail-send.json'
import { Alert } from '@material-ui/lab'

const SendReports = () => {
    const { handleChangeParams, params, restartDates } = useRejectedReportParams()
    const [open, setOpen] = React.useState(false)
    const onSubmit = async (emails: string[]) => {
        try {
            setOpen(true)
            await sendRejectedReportByEmailService(transformRejectedReportParams(emails, params.states, params.rejectedStart, params.rejectedEnd))
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="w-full flex flex-col h-full overflow-hidden">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${SEND_REPORT_TITLE}`}</title>
            </Helmet>
            <div className="flex flex-grow overflow-hidden">
                <RejectedReportParams
                    params={params}
                    onChange={handleChangeParams}
                    restartDates={restartDates}
                />
                <EmailList
                    reportTypeSelected
                    onSubmit={onSubmit}
                />
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                action={
                    <IconButton style={{ marginRight: 20 }} aria-label="close" color="inherit" onClick={() => setOpen(false)}>
                        <Icon>close</Icon>
                    </IconButton>
                }
            >
                <Alert variant="filled" onClose={() => setOpen(false)} severity="info" style={{ paddingRight: 30 }}>
                    <div className="flex items-center">
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: mailSend,
                                rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                            }}
                            height={120}
                            width={120}
                        />
                        <Typography className="px-4">{"Sus reportes se estan enviando..."}</Typography>
                    </div>
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SendReports
