import axios, { AxiosError } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, IDENTIFICATION_TYPE_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const getIdentificationTypesService = async () => {
    try {
        const result = await intance.get(IDENTIFICATION_TYPE_PATH + LIST_PATH)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createIdentificationTypeService = async (identificationType: any) => {
    try {
        const result = await intance.post(IDENTIFICATION_TYPE_PATH, identificationType)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteIdentificationTypeService = async (id: any) => {
    try {
        const result = await intance.delete(IDENTIFICATION_TYPE_PATH + `/${id}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editIdentificationTypeService = async (id: number, identificationType: any) => {
    try {
        const result = await intance.put(IDENTIFICATION_TYPE_PATH + `/${id}`, identificationType)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}