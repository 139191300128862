import axios, { AxiosError } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, CLOSING_DATE_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const getClosingDatesService = async () => {
    try {
        const result = await intance.get(CLOSING_DATE_PATH + LIST_PATH)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getClosingDateByMonthAndYearService = async (month: number, year: number) => {
    try {
        const result = await intance.get(`${CLOSING_DATE_PATH}/${year}/${month}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const saveClosingDateService = async (closingDate: any) => {
    try {
        const result = await intance.post(CLOSING_DATE_PATH, closingDate)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}