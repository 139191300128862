import React from 'react'
import { Icon, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core'
import { FixedLoadingIndicator, LabelDisplayedRows, Search, ServerErrorHandler } from 'components'
import { useInputValue, useTableModel } from 'hooks'
import { ROOT_TITLE, SMALL_ROWS_PER_PAGINATION_OPTIONS, VIEW_TABLE_COLUMNS, VIEW_TITLE } from 'lib'
import { IMessageConfig, IView } from 'types'
import { ManagementContext } from 'context'
import { Alert } from '@material-ui/lab'
import { Helmet } from 'react-helmet'

const Views = () => {
    const { views, refreshViews } = React.useContext(ManagementContext)
    const { value: query, clearValue: handleClear, onChange: handleChange } = useInputValue()
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage } = useTableModel()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const getModel = React.useCallback((): any[] => {
        const src = [...views]
        if (Boolean(query)) {
            return src.filter((s: any) => {
                return s.name.toUpperCase().includes(query.toUpperCase())
            })
        }
        return src
    }, [views, query])

    React.useEffect(() => {
        const initScreen = async () => {
            try {
                setLoading(true)
                await refreshViews()
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setError(true)
            }
        }
        initScreen()
    }, [refreshViews])
    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${VIEW_TITLE}`}</title>
            </Helmet>
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <Search
                        onChange={handleChange}
                        query={query}
                        onClear={handleClear}
                        placeholer="Buscar por nombre"
                    />
                </div>
            </div>
            <div className="flex h-full flex-col mt-4 overflow-hidden">
                <div className="flex flex-grow overflow-auto">
                    <TableContainer>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {VIEW_TABLE_COLUMNS.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={'left'}
                                            padding={"none"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id as any)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(getModel()) as IView[]).map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.viewId}
                                        >
                                            <TableCell component="th" scope="row" padding="none">{row.name}</TableCell>
                                            <TableCell padding="none" align="left">{row.description}</TableCell>
                                            <TableCell padding="none" align="left">{row.relativePath}</TableCell>
                                            <TableCell padding="none" align="left"><Icon style={{ color: "#a1a1a1" }}>{row.icon}</Icon></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <TablePagination
                        rowsPerPageOptions={SMALL_ROWS_PER_PAGINATION_OPTIONS}
                        component="div"
                        count={getModel().length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Vistas por página"
                        labelDisplayedRows={LabelDisplayedRows}
                    />
                </div>
            </div>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={refreshViews}
            />
            <FixedLoadingIndicator loading={loading} />
        </Paper>
    )
}


export default Views