import axios, { AxiosError } from 'axios'
import { AUTHENTICATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { MODULE_PATH, MODULE_VIEW_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: AUTHENTICATION_API_SERVICE_DOMAIN })


export const getModuleViewsByModuleIdService = async (id: number) => {
    try {
        const result = await intance.get(MODULE_PATH + "/" + id + MODULE_VIEW_PATH)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createModuleViewsByModuleIdService = async (id: number, moduleViews: any) => {
    try {
        const result = await intance.post(MODULE_PATH + "/" + id + MODULE_VIEW_PATH, moduleViews)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}