import { FIELDS } from "lib"

export const OFFICE_TABLE_COLUMNS = [
    { id: FIELDS.officeCode.key, label: FIELDS.officeCode.name },
    { id: FIELDS.description.key, label: FIELDS.description.name },
    { id: FIELDS.active.key, label: FIELDS.state.name },
]

export const TAX_TABLE_COLUMNS = [
    { id: FIELDS.taxCode.key, label: FIELDS.taxCode.name },
    { id: FIELDS.description.key, label: FIELDS.description.name },
    { id: FIELDS.percentage.key, label: FIELDS.percentage.name },
    { id: FIELDS.jbaCode.key, label: FIELDS.jbaCode.name },
    { id: FIELDS.taxTypeId.key, label: FIELDS.taxTypeId.name },
]