import { Accordion, AccordionSummary, Icon, Typography, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Radio, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useTableModel } from 'hooks'
import { DOCUMENT_LIST_TABLE_COLUMNS } from 'lib'
import React from 'react'
import { IDetail, ITax, ITaxType } from 'types'

interface Props {
    taxes: ITax[]
    taxTypes: ITaxType[]
    onTaxSelected: (detail: IDetail[]) => void
}

const documents = [
    {
        invoiceNumber: 123465789,
        voucher: "Detalle de liquidación de compras exterior",
        emissionDate: new Date(),
        subtotalNoTaxes: 356,
        discount: 0,
        tip: 5,
        taxesValue: 45,
        total: 401
    },
    {
        invoiceNumber: 7548745,
        voucher: "Detalle de liquidación de compras exterior",
        emissionDate: new Date(),
        subtotalNoTaxes: 64,
        discount: 0,
        tip: 5,
        taxesValue: 3,
        total: 67
    },
    {
        invoiceNumber: 885,
        voucher: "Detalle de liquidación de compras exterior",
        emissionDate: new Date(),
        subtotalNoTaxes: 64,
        discount: 0,
        tip: 5,
        taxesValue: 3,
        total: 67
    },
]

const DocumentList = (props: Props) => {
    const { taxTypes, taxes, onTaxSelected } = props
    const [expanded, setExpanded] = React.useState<boolean>(true)
    const [open, setOpen] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const [selectedDocument, setSelectedDocument] = React.useState<any>(undefined)
    const [selectedTax, setSelectedTax] = React.useState<ITax | null>(null)
    const { createSortHandler, order, orderBy, rows } = useTableModel()
    const handleClick = (d: any) => {
        setOpen(true)
        setSelectedDocument(d)
    }
    const handleClose = () => {
        setOpen(false)
        setSelectedDocument(undefined)
    }
    const handleSelectTax = () => {
        if (!selectedTax) {
            setError(true)
            return
        }
        setError(false)
        setOpen(false)
        onTaxSelected([{ description: "Seleccion 1", discount: 0, quantity: 2, unitPrice: 5, tax: selectedTax ?? undefined }])
    }
    const getNoRetentionTaxes = React.useCallback((): ITax[] => {
        const noRetentionTaxTypes = taxTypes.filter(t => !t.forRetention).map(t => t.taxId)
        return taxes.filter(t => noRetentionTaxTypes.includes(t.taxTypeId))
    }, [taxes, taxTypes])
    return (
        <React.Fragment>
            <Accordion expanded={expanded} onChange={() => setExpanded(current => !current)}>
                <AccordionSummary
                    style={{ marginRight: 10 }}
                    expandIcon={<Icon>expand_more</Icon>}
                >
                    <div className="flex items-center">
                        <Icon color="action">list</Icon>
                        <Typography className="pl-2" style={{ fontWeight: 600, letterSpacing: 0.5 }} color="textSecondary" variant="body2">{"Listado de documentos"}</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="w-full overflow-y-auto" style={{ maxHeight: 100 }}>
                        <TableContainer>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align={'left'}
                                            padding={"none"}
                                            style={{ width: 60 }}
                                        />
                                        {DOCUMENT_LIST_TABLE_COLUMNS.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align={'left'}
                                                padding={"none"}
                                                sortDirection={orderBy === headCell.id ? order : false}
                                                size="small"
                                            >
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={createSortHandler(headCell.id as any)}
                                                    style={{ fontSize: "0.85em" }}
                                                >
                                                    {headCell.label}
                                                    {orderBy === headCell.id ? (
                                                        <span className="hidden">
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                        <TableCell
                                            padding={"default"}
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rows(documents).map((d, index) => (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={index.toString()}
                                                className="cursor-pointer"
                                                selected={selectedDocument === d}
                                                onClick={(_) => handleClick(d)}
                                            >
                                                <TableCell padding="none" align="left">
                                                    <Radio
                                                        checked={selectedDocument === d}
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell padding="none" align="left">{d.invoiceNumber}</TableCell>
                                                <TableCell className="truncate" style={{ maxWidth: 200, paddingRight: 10 }} padding="none" align="left">{d.voucher}</TableCell>
                                                <TableCell padding="none" align="left">{d.emissionDate.toDateString()}</TableCell>
                                                <TableCell padding="none" align="left">{d.subtotalNoTaxes}</TableCell>
                                                <TableCell padding="none" align="left">{d.discount}</TableCell>
                                                <TableCell padding="none" align="left">{d.tip}</TableCell>
                                                <TableCell padding="none" align="left">{d.taxesValue}</TableCell>
                                                <TableCell padding="none" align="left">{d.total}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Dialog maxWidth="xs" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{"Selecciona el impuesto a aplicar"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"Los detalles del documento serán agregados en base al impuesto que selecciones."}
                    </DialogContentText>
                    <Autocomplete
                        options={getNoRetentionTaxes()}
                        loadingText="Cargando..."
                        noOptionsText="No existen impuestos registrados"
                        getOptionLabel={(tax) => tax.description}
                        value={selectedTax}
                        openOnFocus
                        size="small"
                        style={{ marginTop: 35, marginBottom: 30 }}
                        onChange={(_, value) => setSelectedTax(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                error={error}
                                helperText={error && "Selecciona un impuesto para continuar"}
                                variant="outlined"
                                fullWidth
                                label={"Impuestos"}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {"Cancelar"}
                    </Button>
                    <Button onClick={handleSelectTax} color="primary">
                        {"Aceptar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default DocumentList
