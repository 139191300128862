import { Divider, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { IPurchaseOrderHeader } from 'types'
import moment from 'moment'
import 'moment/locale/es'
import { ManagementContext, DocumentContext } from 'context'

moment.locale("es")

interface Props {
    header: IPurchaseOrderHeader
    serialNumber: string
}

const textStyle = { fontWeight: 600, fontSize: "0.8em" }

const RequestData = (props: Props) => {
    const { header, serialNumber } = props
    const { accountTypes } = React.useContext(ManagementContext)
    const { costCenters } = React.useContext(DocumentContext)
    return (
        <Paper variant="outlined" className="p-4 px-6" elevation={1}>
            <div className="flex my-2 flex-col">
                <div className="flex w-full justify-between pb-2">
                    <div>
                        {
                            Boolean(serialNumber) &&
                            <Typography style={{ ...textStyle, paddingBottom: 10 }} variant="subtitle2">
                                {`Orden de compra número: ${serialNumber}`}
                            </Typography>
                        }
                        <Typography style={{ fontWeight: 600 }} variant="subtitle2">
                            {`${header.subject}`}
                        </Typography>
                        <Typography style={{ ...textStyle }} variant="subtitle2">
                            {`Num. Solicitud: ${header.poRequestNumber ?? ""}`}
                        </Typography>
                        <Typography className="pb-1" style={{ ...textStyle }} variant="subtitle2">
                            {`Creada el: ${moment(new Date(header.createDate).toISOString()).format('DD-MM-YYYY').toString()}`}
                        </Typography>
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {`Dirección: ${header.deliveryAddress}`}
                        </Typography>
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {`Tipo de cuenta: ${accountTypes.find(a => a.accountTypeId === header.accountTypeId)?.accountCode} - ${accountTypes.find(a => a.accountTypeId === header.accountTypeId)?.description}`}
                        </Typography>
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {`Centro de costos: ${costCenters.find(c => c.costCenterId === header.costCenterId)?.costCenterCode} - ${costCenters.find(c => c.costCenterId === header.costCenterId)?.description}`}
                        </Typography>
                    </div>
                    <div>
                        {
                            Boolean(header.supplier) &&
                            <React.Fragment>
                                <Typography color="primary" align="right" className="pb-1" style={{ ...textStyle }} variant="subtitle2">
                                    {`Proveedor selecionado`.toUpperCase()}
                                </Typography>
                                <Typography align="right" style={textStyle} variant="subtitle2">
                                    {`${header.supplier?.companyName}`}
                                </Typography>
                                <Typography align="right" style={textStyle} variant="subtitle2" className="pb-4">
                                    {`${header.supplier?.identification}`}
                                </Typography>
                            </React.Fragment>
                        }
                    </div>
                </div>
                {
                    (header.specialInstruction || header.comment) &&
                    <React.Fragment>
                        <Divider />
                        {
                            header.specialInstruction &&
                            <React.Fragment>
                                <div className="flex items-center pt-4">
                                    <Typography style={{ letterSpacing: 1 }} align="justify" variant="caption">
                                        {`Instrucción especial`.toUpperCase()}
                                    </Typography>
                                </div>
                                <Typography align="justify" variant="caption">
                                    {`${header.specialInstruction}`}
                                </Typography>
                            </React.Fragment>
                        }
                        {
                            header.comment &&
                            <React.Fragment>
                                <div className="flex items-center pt-4">
                                    <Typography style={{ letterSpacing: 1 }} align="justify" variant="caption">
                                        {`Comentario`.toUpperCase()}
                                    </Typography>
                                </div>
                                <Typography align="justify" variant="caption">
                                    {`${header.comment}`}
                                </Typography>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>
        </Paper>
    )
}

export default RequestData
