import { TableCell, Icon, Menu, MenuItem, CircularProgress } from '@material-ui/core'
import { getDocumentByIdService, getDocumentElectronicInfo } from 'lib'
import React from 'react'
import { IRefund } from 'types'

interface Props {
    documentId: number
}

const RefundsCell = (props: Props) => {
    const { documentId } = props
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [loading, setLoading] = React.useState(false)
    const [refunds, setRefunds] = React.useState<IRefund[]>([])
    const handleRefunds = async () => {
        try {
            setLoading(true)
            const response = await getDocumentByIdService(documentId)
            const document = getDocumentElectronicInfo(response, response?.electronicData, [], [], [])
            setRefunds(document.refunds ?? [])
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    return (
        <React.Fragment>
            <TableCell
                onClick={(e) => {
                    e.stopPropagation()
                    setAnchorEl(e.currentTarget)
                    handleRefunds()
                }}
                className="document-link" padding="default" align="left"
            >
                <div className="flex items-center">
                    {"Ver sustentos"}
                    <Icon className="ml-2" fontSize="small">
                        expand_more
                    </Icon>
                </div>
            </TableCell>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                BackdropProps={{ style: { backgroundColor: "#00000025" }, onClick: (e) => { e.preventDefault(); e.stopPropagation(); setAnchorEl(null) } }}
            >
                <div style={{ width: 250, maxHeight: 300 }}>
                    {
                        loading ?
                            <div className="flex flex-row items-center justify-center w-full h-full" style={{ height: 80 }}>
                                <CircularProgress thickness={12} size={30} color="primary" />
                            </div>
                            :
                            <React.Fragment>
                                {
                                    refunds.map(refund => (
                                        <MenuItem
                                            className="document-link"
                                            dense
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                            }}
                                        >
                                            {refund.sequential}
                                        </MenuItem>
                                    ))
                                }
                            </React.Fragment>
                    }
                </div>
            </Menu>
        </React.Fragment>
    )
}

export default RefundsCell
