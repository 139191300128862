import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { FIELDS } from 'lib'
import { DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { IRejectedFilters } from 'types'
import { AND_PARAM, RETENTION_DOC_NUM_PARAM, PROCESS_STATUS_PARAM, ASSOCIATED_DOC_NUM_PARAM, STATES_PARAM, REJECTED_PATH, OFFSET_PARAM, PAGE_SIZE_PARAM, SEARCH_PARAM, RUC_OR_NAME_PARAM, SEND_NOTIFICATION_EMAIL_PATH, STATE_PATH, REJECTED_HISTORY_PATH, LAST_PATH, TYPE_PARAM, UPLOAD_PATH, FILE_FROM_EMAIL_PATH, MARK_PATH, REASON_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

const formDataConfig: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export const getRejectedService = async (filters: IRejectedFilters, pageSize: number, offset: number) => {
    try {
        const result = await intance.get(
            REJECTED_PATH + SEARCH_PARAM
            + RUC_OR_NAME_PARAM + filters.rucOrName + AND_PARAM
            + RETENTION_DOC_NUM_PARAM + filters.retentionDocumentNumber + AND_PARAM
            + ASSOCIATED_DOC_NUM_PARAM + filters.associatedDocumentNumber + AND_PARAM
            + PROCESS_STATUS_PARAM + filters.processStatus.join(",") + AND_PARAM
            + STATES_PARAM + filters.states.join(",") + AND_PARAM
            + TYPE_PARAM + filters.type + AND_PARAM
            + PAGE_SIZE_PARAM + pageSize + AND_PARAM
            + OFFSET_PARAM + offset
        )
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getRejectedHistoryService = async (pageSize: number, offset: number) => {
    try {
        const result = await intance.get(
            REJECTED_HISTORY_PATH + SEARCH_PARAM
            + PAGE_SIZE_PARAM + pageSize + AND_PARAM
            + OFFSET_PARAM + offset
        )
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getLastRejectedHistoryService = async () => {
    try {
        const result = await intance.get(REJECTED_HISTORY_PATH + LAST_PATH)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const resendEmailNotificationService = async (rejectedId: number, type: number) => {
    try {
        const result = await intance.post(REJECTED_PATH + SEND_NOTIFICATION_EMAIL_PATH, null, { params: { rejectedId, type } })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        console.log(JSON.stringify(e))
        throw new Error(e.response?.data)
    }
}

export const markRejectedService = async (rejectedId: number, newState: number) => {
    try {
        const result = await intance.put(REJECTED_PATH + `/${rejectedId}` + STATE_PATH + `/${newState}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        console.log(JSON.stringify(e))
        throw new Error(e.response?.data)
    }
}

export const uploadRejectedFileService = async (file: any) => {
    const formData = new FormData()
    formData.append(FIELDS.file.key, file)
    try {
        const result = await intance.post(REJECTED_PATH + UPLOAD_PATH, formData, formDataConfig)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const uploadFileAndMarkRejectedService = async (file: any) => {
    const formData = new FormData()
    formData.append(FIELDS.file.key, file)
    try {
        const result = await intance.post(REJECTED_PATH + UPLOAD_PATH + MARK_PATH, formData, formDataConfig)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const sendRejectedFileFromEmailService = async () => {
    try {
        const result = await intance.post(REJECTED_PATH + FILE_FROM_EMAIL_PATH)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const updateRejectedReasonService = async (rejectedId: any, reason: string) => {
    try {
        const result = await intance.put(`${REJECTED_PATH}${REASON_PATH}/${rejectedId}`, { reason })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}