import axios, { AxiosError } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { LIST_PATH, TAX_PLAN_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })

export const getTaxPlansService = async () => {
    try {
        const result = await intance.get(TAX_PLAN_PATH + LIST_PATH)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createTaxPlanService = async (taxPlan: any) => {
    try {
        const result = await intance.post(TAX_PLAN_PATH, taxPlan)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteTaxPlanService = async (id: any) => {
    try {
        const result = await intance.delete(TAX_PLAN_PATH + `/${id}`)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editTaxPlanService = async (id: number, taxPlan: any) => {
    try {
        const result = await intance.put(TAX_PLAN_PATH + `/${id}`, taxPlan)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}