import { Button, Card, CardActionArea, Divider, Icon, InputAdornment, List, ListItem, ListItemText, MenuItem, Paper, Snackbar, TablePagination, TextField, Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { CurrencyInput, FixedLoadingIndicator, LabelDisplayedRows, Search, ServerErrorHandler } from 'components'
import { ManagementContext } from 'context'
import { ROOT_TITLE, APPROVAL_LEVEL_TITLE, formatCurrency, FIELDS, deleteUserApprovalByUserIdService, NOT_ASSIGNED, createUserApprovalService, editApprovalLevelsService } from 'lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import { IApprovalLevel, IMessageConfig, IUser } from 'types'
import { useInputValue, usePagination } from 'hooks'
import { Alert } from '@material-ui/lab'

const ApprovalLevel = () => {
    const { approvalLevels, users, userApprovals, refreshApprovalLevels, refreshUsers, refreshUserApprovals } = React.useContext(ManagementContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const { value: usersQuery, clearValue: handleClearUsersSearch, onChange: handleChangeUsersSearch } = useInputValue()
    const { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } = usePagination()
    const [selectedLevel, setSelectedLevel] = React.useState<IApprovalLevel | undefined>(undefined)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const handleUpdateUser = async (user: IUser, newValue: number) => {
        const userApproval = userApprovals.find(u => u.userId === user.userId)
        try {
            setLoading(true)
            if (userApproval) {
                await deleteUserApprovalByUserIdService(user.userId)
            }
            if (newValue !== NOT_ASSIGNED.value) {
                await createUserApprovalService({ approvalLevelId: newValue, userId: user.userId })
            }
            await refreshUserApprovals()
            setLoading(false)
            setMessageConfig({ open: true, message: newValue !== NOT_ASSIGNED.value ? "Nivel de aprovación asignado!" : "Nivel deasignación removido!", severity: "success" })
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo crear el nivel de aprovación...", severity: "error" })
        }
    }
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            if (selectedLevel) {
                setLoading(true)
                await editApprovalLevelsService(selectedLevel, selectedLevel.approvalLevelId)
                await refreshApprovalLevels()
                setMessageConfig({ open: true, message: "Monto actualizado!", severity: "success" })
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo editar el nivel de aprovación...", severity: "error" })
        }
    }
    React.useEffect(() => {
        const initScreen = async () => {
            try {
                setLoading(true)
                await refreshApprovalLevels()
                await refreshUserApprovals()
                await refreshUsers()
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setError(false)
            }
        }
        initScreen()
    }, [refreshApprovalLevels, refreshUsers, refreshUserApprovals])
    const getUsersModel = React.useCallback(() => {
        const filteredUserApprovals = selectedLevel ? userApprovals.filter(u => u.approvalLevelId === selectedLevel.approvalLevelId) : []
        const filteredUsers = filteredUserApprovals.map(u => u.userId)
        const src = filteredUsers.length > 0 ? [...users].filter(u => filteredUsers.includes(u.userId)) : selectedLevel ? [] : [...users]
        if (Boolean(usersQuery)) {
            return src.filter((s: any) => {
                return s.name.toUpperCase().includes(usersQuery.toUpperCase()) || s.email.toUpperCase().includes(usersQuery.toUpperCase())
            })
        }
        return src
    }, [usersQuery, users, selectedLevel, userApprovals])
    const getUsers = React.useCallback((): number[] => {
        return userApprovals.map(u => u.userId)
    }, [userApprovals])
    return (
        <div className="flex flex-col h-full overflow-hidden py-1">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${APPROVAL_LEVEL_TITLE}`}</title>
            </Helmet>
            <div className="flex h-full justify-center">
                <Paper className="h-full py-4 px-8 h-full flex flex-col w-1/4">
                    <Typography style={{ fontWeight: 600, letterSpacing: 0.75 }} variant="subtitle1" color="textSecondary" className="uppercase">
                        {"Niveles de aprovación"}
                    </Typography>
                    <Card className="my-3">
                        <CardActionArea
                            disabled={loading}
                            onClick={() => setSelectedLevel(undefined)}
                            className="h-full"
                            style={{ borderColor: selectedLevel === undefined ? red[900] : "transparent", transition: "0.5s", borderLeftWidth: 15, borderStyle: "solid", paddingRight: 15 }}
                        >
                            <div className="h-full w-full p-4 flex items-center flex-col justify-center">
                                <Typography variant="h6" className="pb-1">
                                    {`Todos`}
                                </Typography>
                            </div>
                        </CardActionArea>
                    </Card>
                    {
                        approvalLevels.map(approvalLevel => (
                            <Card className="flex-grow my-3">
                                <CardActionArea
                                    disabled={loading}
                                    onClick={() => setSelectedLevel(approvalLevel)}
                                    className="h-full"
                                    style={{ borderColor: selectedLevel?.approvalLevelId === approvalLevel.approvalLevelId ? red[(approvalLevel.index + 1) * 200] : "transparent", transition: "0.5s", borderLeftWidth: 15, borderStyle: "solid", paddingRight: 15 }}
                                >
                                    <div className="h-full w-full p-2 flex items-center flex-col justify-center">
                                        <Typography gutterBottom variant="h5" component="h2" style={{ color: red[(approvalLevel.index + 1) * 200] }} >                                        
                                            {`Nivel ${approvalLevel.index}`}
                                        </Typography>
                                        {
                                            approvalLevel.index !== 0 && 
                                            <Typography className="truncate" color="textSecondary" gutterBottom variant="overline" component="h2">
                                                {`Monto máximo ${formatCurrency(approvalLevel.maxAmount)}`}
                                            </Typography>
                                        }
                                    </div>
                                </CardActionArea>
                            </Card>
                        ))
                    }
                </Paper>
                <Paper className="flex w-2/3 ml-6">
                    <div className="w-full h-full flex flex-col items-center justify-center px-8 py-4">
                        <div className="w-full h-full flex flex-col">
                            <div className="flex items-center w-full">
                                <Typography style={{ fontWeight: 600, letterSpacing: 0.75, width: "80%" }} variant="subtitle1" color="textSecondary" className="uppercase pb-2">
                                    {`Lista de usuarios ${selectedLevel ? "nivel \t" + selectedLevel.index : ""}`}
                                </Typography>
                                {
                                    selectedLevel && selectedLevel.index !== 0 &&
                                    <form
                                        className="w-full flex items-center justify-between ml-2"
                                        onSubmit={handleSubmit}
                                    >
                                        <TextField
                                            size="small"
                                            label={FIELDS.maxAmount.name}
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Icon fontSize="small" color="action">attach_money</Icon>
                                                    </InputAdornment>
                                                ),
                                                inputComponent: CurrencyInput as any,
                                            }}
                                            required
                                            value={selectedLevel.maxAmount}
                                            onChange={(e) => setSelectedLevel({ ...selectedLevel, maxAmount: parseFloat(e.target.value) })}
                                        />
                                        <Button
                                            size="small"
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            disableElevation
                                            style={{ marginLeft: 15 }}
                                        >
                                            {"Actualizar monto"}
                                        </Button>
                                    </form>
                                }
                            </div>
                            <div className="w-full flex items-center">
                                {
                                    !selectedLevel &&
                                    <Search
                                        onChange={handleChangeUsersSearch}
                                        query={usersQuery}
                                        onClear={handleClearUsersSearch}
                                        placeholer={"Filtrar usuarios por nombre o correo electrónico..."}
                                        width="50%"
                                        disabled={loading}
                                    />
                                }
                            </div>
                            <div className="mt-4">
                                <Divider />
                            </div>
                            <div className="flex-grow overflow-y-auto w-full mb-4">
                                <List className="w-full">
                                    {
                                        (rowsPerPage > -1 ? getUsersModel().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : getUsersModel()).map((user) => {
                                            return (
                                                <div className="flex w-full my-2 pr-4 items-center">
                                                    <ListItem
                                                        classes={{ container: "w-full", button: "button-p-0" }}
                                                        disabled={loading}
                                                        key={user.userId}
                                                        role="listitem"
                                                    >
                                                        <ListItemText primary={user.name} secondary={user.email} />
                                                    </ListItem>
                                                    <TextField
                                                        label={FIELDS.approvalLevel.name}
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ width: 220 }}
                                                        onChange={(e) => handleUpdateUser(user, parseInt(e.target.value))}
                                                        select
                                                        disabled={loading}
                                                        value={getUsers().includes(user.userId) ? userApprovals.find(u => u.userId === user.userId)?.approvalLevelId : -1}
                                                    >
                                                        {
                                                            approvalLevels.map(level => (
                                                                <MenuItem key={level.approvalLevelId} value={level.approvalLevelId}>{`Nivel ${level.index}`}</MenuItem>
                                                            ))
                                                        }
                                                        <MenuItem value={NOT_ASSIGNED.value}>{NOT_ASSIGNED.label}</MenuItem>
                                                    </TextField>
                                                </div>
                                            )
                                        })
                                    }
                                </List>
                            </div>
                            <div className="px-4 flex items-center justify-between">
                                <div>
                                    <Divider />
                                    <TablePagination
                                        rowsPerPageOptions={[10, 15, 25, { label: 'Todos', value: -1 }]}
                                        colSpan={3}
                                        count={getUsersModel().length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        labelRowsPerPage="Usuarios por página"
                                        labelDisplayedRows={LabelDisplayedRows}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        classes={{ toolbar: "w-full" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading} />
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={async () => {
                    await refreshApprovalLevels()
                    await refreshUsers()
                    await refreshUserApprovals()
                }}
            />
        </div>
    )
}

export default ApprovalLevel
