import { FIELDS } from "lib"

export const DETAIL_TABLE_COLUMNS = [
    { id: FIELDS.description.key, label: FIELDS.description.name },
    { id: FIELDS.quantity.key, label: FIELDS.quantity.name },
    { id: FIELDS.unitPrice.key, label: FIELDS.unitPrice.name },
    { id: FIELDS.taxes.key, label: FIELDS.taxes.name },
    { id: FIELDS.discount.key, label: FIELDS.discount.name },
    { id: FIELDS.subtotalNoTaxes.key, label: FIELDS.subtotalNoTaxes.name },
    { id: FIELDS.taxesValue.key, label: FIELDS.taxesValue.name },
    { id: FIELDS.total.key, label: FIELDS.total.name },
]

export const DETAIL_DESCRIPTION_ONLY_TABLE_COLUMNS = [
    { id: FIELDS.description.key, label: FIELDS.description.name },
    { id: FIELDS.subtotalNoTaxes.key, label: FIELDS.subtotalNoTaxes.name },
    { id: FIELDS.taxesValue.key, label: FIELDS.taxesValue.name },
    { id: FIELDS.total.key, label: FIELDS.total.name },
]

export const DETAIL_DESCRIPTION_PURCHASE_ONLY_TABLE_COLUMNS = [
    { id: FIELDS.purchaseCode.key, label: FIELDS.purchaseCode.name },
    { id: FIELDS.description.key, label: FIELDS.description.name },
    { id: FIELDS.quantity.key, label: FIELDS.quantity.name },    
]

export const PURCHASE_ORDER_DETAIL_TABLE_COLUMNS = [
    { id: FIELDS.purchaseCode.key, label: FIELDS.purchaseCode.name },
    { id: FIELDS.description.key, label: FIELDS.description.name },
    { id: FIELDS.quantity.key, label: FIELDS.quantity.name },    
    { id: FIELDS.unitPrice.key, label: FIELDS.unitPrice.name },    
]

export const INITIAL_DETAIL = {
    [FIELDS.unitPrice.key]: 0.00,
    [FIELDS.quantity.key]: 1,
    [FIELDS.discount.key]: 0,
}