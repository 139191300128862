import { VOUCHER_MASK } from 'lib'
import MaskedInput from 'react-text-mask'

interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void
}

export default function InvoiceInput(props: TextMaskCustomProps) {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            mask={VOUCHER_MASK}
            ref={(ref: any) => { inputRef(ref ? ref.inputElement : null) }}
            placeholder="001 - 001 - XXXXXXXXX"
        />
    )
}