import { Avatar, Box, Divider, Icon, Paper, Tooltip, Typography } from '@material-ui/core'
import { AuthContext, ManagementContext } from 'context'
import FileSaver from 'file-saver'
import { downloadDocumentFileByIdService } from 'lib'
import React from 'react'
import { IDocumentFile, IFlowData } from 'types'

interface Props {
    approvalLevel: number
    flowData: IFlowData[]
    rejected?: boolean
    approvalMessage?: IDocumentFile
    onDownloadStarted: () => void
}

const ApprovalData = (props: Props) => {
    const { approvalLevels, users } = React.useContext(ManagementContext)
    const { userApproval } = React.useContext(AuthContext)
    const { approvalLevel, flowData, rejected, approvalMessage, onDownloadStarted } = props
    const getApprovalLevel = React.useCallback(() => {
        const level = approvalLevels.find(a => a.approvalLevelId === approvalLevel)
        return level ? `Nivel de aprobación ${level.index}` : "Sin nivel de aprobación"
    }, [approvalLevels, approvalLevel])
    const showLowerLevelMessage = React.useCallback(() => {
        const documentLevel = approvalLevels.find(a => a.approvalLevelId === approvalLevel)
        const userLevel = approvalLevels.find(a => a.approvalLevelId === userApproval?.approvalLevelId)
        if (documentLevel && userLevel) { return documentLevel.index > 1 && (flowData.length + 1 < documentLevel.index) && (userLevel.index > flowData.length + 1) }
        return false
    }, [approvalLevels, approvalLevel, flowData.length, userApproval])
    const showCanApproveLevelMessage = React.useCallback(() => {
        const userLevel = approvalLevels.find(a => a.approvalLevelId === userApproval?.approvalLevelId)
        if (userLevel) { return userLevel.index === flowData.length + 1 }
        return false
    }, [approvalLevels, flowData.length, userApproval])
    const handleDownload = async (documentFile: IDocumentFile) => {
        const result = await downloadDocumentFileByIdService(documentFile.documentFileId)
        FileSaver.saveAs(result, documentFile.fileName ?? documentFile?.documentFileId?.toString())
        onDownloadStarted()
    }
    return (
        <Paper variant="outlined" className="p-4 px-6" elevation={1}>
            <div className="w-full flex flex-col">
                <div className="flex justify-between items-center">
                    <Typography style={{ fontWeight: 600, letterSpacing: 0.5, paddingBottom: 5 }} variant="caption">
                        {getApprovalLevel()}
                    </Typography>
                    {
                        showLowerLevelMessage() &&
                        <Typography style={{ fontWeight: 600, letterSpacing: 0.5, paddingBottom: 5, display: "flex", alignItems: "center" }} color="error" variant="caption">
                            <Icon fontSize="small" color="error" style={{ marginRight: 5 }}>info</Icon> {"\tNecesita de aprobación de niveles inferiores para continuar"}
                        </Typography>
                    }
                    {
                        showCanApproveLevelMessage() &&
                        <Box color="success.main">
                            <Typography style={{ fontWeight: 600, letterSpacing: 0.5, paddingBottom: 5, display: "flex", alignItems: "center" }} variant="caption">
                                <Icon fontSize="small" style={{ marginRight: 5 }}>check_circle</Icon> {"\tTu usuario puede aprobar esta solicitud"}
                            </Typography>
                        </Box>
                    }
                </div>
                {
                    flowData.length === 0 ?
                        <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                            {"Esta solicitud no ha sido aprobada por ningun usuario hasta el momento."}
                        </Typography>
                        :
                        <React.Fragment>
                            <Typography color={rejected ? "error" : "textSecondary"} style={{ fontWeight: 600, letterSpacing: 0.5 }} variant="caption">
                                {rejected ? "Rechazada" : "Aprobada por"}
                            </Typography>
                            {
                                flowData.map((f, index) => {
                                    const user = users.find(u => u.userId === f.userId)
                                    return (
                                        <div className="mt-3 w-full">
                                            <div className="flex items-center">
                                                <Avatar style={{ transform: "scale(0.65)", opacity: (index + 1) * 0.30 }}>
                                                    {index + 1}
                                                </Avatar>
                                                <div className="flex flex-col ml-2">
                                                    <Typography variant="caption">
                                                        {user?.name}
                                                    </Typography>
                                                    <Typography style={{ fontSize: "0.65em" }} color="textSecondary">
                                                        {user?.email}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <Typography className="quote" color="textSecondary">
                                                {f.message}
                                            </Typography>
                                            <Divider style={{ marginTop: 10 }} />
                                        </div>
                                    )
                                })
                            }
                        </React.Fragment>
                }
            </div>
            {
                approvalMessage &&
                <Tooltip arrow title={"Descargar mensaje de aprobación"}>
                    <Typography onClick={() => handleDownload(approvalMessage)} className='py-2 document-link flex cursor-pointer items-center flex-grow-0' style={{ fontSize: "0.9em", letterSpacing: 0.5, width: 'fit-content' }}>
                        <Icon style={{ marginRight: 5 }} fontSize="small">download</Icon>
                        {approvalMessage.fileName}
                    </Typography>
                </Tooltip>
            }
        </Paper>
    )
}

export default ApprovalData
