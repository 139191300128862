import { ITax } from "types"
import { FIELDS } from "./fields"

export const TAX_TABLE_COLUMNS = [
    { id: FIELDS.taxCode.key, label: FIELDS.taxCode.name },
    { id: FIELDS.description.key, label: FIELDS.description.name },
    { id: FIELDS.percentage.key, label: FIELDS.percentage.name },
    { id: FIELDS.jbaCode.key, label: FIELDS.jbaCode.name },
    { id: FIELDS.taxTypeId.key, label: FIELDS.taxTypeId.name },
]

export const DEFAULT_TAX: ITax = {
    description: "Impuesto por defecto",
    jbaCode: "0",
    percentage: 0,
    taxCode: 0,
    taxId: 0,
    taxTypeId: 0
}

export const SRI_SUBTOTAL_BY_TAXES_GUIDE = {
    iva0: { code: "2", percentageCode: "0" },
    iva12: { code: "2", percentageCode: "2" },
    iva15: { code: "2", percentageCode: "4" },
    noIvaObject: { code: "2", percentageCode: "6" },
}

export const RETENTION_TAX_COLUMNS = [
    { id: FIELDS.code.key, label: FIELDS.code.name },
    { id: FIELDS.type.key, label: FIELDS.type.name }, 
    { id: FIELDS.description.key, label: FIELDS.description.name },
    { id: FIELDS.percentage.key, label: FIELDS.percentage.name }, 
    { id: FIELDS.taxBase.key, label: FIELDS.taxBase.name },
    { id: FIELDS.total.key, label: FIELDS.total.name }, 
]