import { CircularProgress } from '@material-ui/core'
import React from 'react'

interface Props {
    loading: boolean
}

const FixedLoadingIndicator = (props: Props) => {
    return (
        <React.Fragment>
            {
                props.loading &&
                <div className="fixed bottom-0 right-0 mb-4 mr-4">
                    <CircularProgress thickness={12} size={30} />
                </div>
            }
        </React.Fragment>
    )
}

export default FixedLoadingIndicator
