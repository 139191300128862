import axios, { AxiosError } from 'axios'
import { DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { HISTORY_TYPE_PATH, LIST_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

export const getHistoryTypesService = async () => {
    try {
        const result = await intance.get(HISTORY_TYPE_PATH + LIST_PATH);
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}