export const PAYMENT_TYPES = [
  {
    value: "01",
    name: "LOCAL",
  },
  {
    value: "02",
    name: "EXTERIOR",
  },
]
