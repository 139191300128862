import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
  Checkbox,
  Icon,
  IconButton
} from "@material-ui/core"
import { useValues } from "hooks"
import {
  createTaxPlanService,
  editTaxPlanService,
  EDIT_PATH,
  FIELDS,
} from "lib"
import React from "react"
import { useLocation, useHistory } from "react-router"
import { ITax, ITaxPlan } from "types"
import { ManagementContext } from "context"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import Autocomplete from "@material-ui/lab/Autocomplete"

interface Props {
  forCustomers: boolean
  onSuccess: () => void
  onError: () => void
  onClose: () => void
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const TaxPlanForm = (props: Props) => {
  const { taxes, taxTypes } = React.useContext(ManagementContext)
  const location = useLocation()
  const history = useHistory()
  const { forCustomers, onError, onSuccess, onClose } = props
  const initialInput = React.useRef<any>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [selectedTaxes, setSelectedTaxes] = React.useState<ITax[]>([])
  const {
    values: taxPlan,
    updateValue: handleChange,
    updateValues,
  } = useValues()

  const handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setLoading(true)
      taxPlan[FIELDS.taxCodes.key] = selectedTaxes
      if (!(location.state as any)?.edit) {
        await createTaxPlanService({ ...taxPlan, active: true, forCustomers })
      } else {
        const edited = { ...taxPlan, forCustomers }
        delete edited[FIELDS.taxPlanId.key]
        await editTaxPlanService(taxPlan[FIELDS.taxPlanId.key], { ...edited })
      }
      setLoading(false)
      if (!(location.state as any)?.edit) {
        updateValues({
          taxPlanId: 0,
          name: "",
          description: "",
          taxCodes: [],
          active: true,
          forCustomers: forCustomers,
          generateRetention: true,
          onlyIva: false,
        } as ITaxPlan)
        initialInput.current?.focus()
      }
      onSuccess()
    } catch (error) {
      setLoading(false)
      onError()
    }
  }
  React.useEffect(() => {

    if ((location.state as any)?.edit) {
      if ((location.state as any)?.values) {
        const v = (location.state as any)?.values
        const taxIds = v.taxCodes
          .map((row, index) => {
            return row.taxId + ''
          });
        updateValues(v)
        setSelectedTaxes(taxes.filter(t => taxIds.includes(t.taxId + '')))
      }
    } else {
      history.replace(location.pathname.replace(EDIT_PATH, ""))
    }
  }, [location.state, updateValues, history, location.pathname, taxes])

  return (
    <div className="p-4">
      <div className="flex items-center justify-between">
        <Typography
          variant="body2"
          style={{ fontWeight: 600, letterSpacing: 0.5 }}
        >
          {(location.state as any)?.edit
            ? "Editar Plan de Impuestos"
            : "Nuevo Plan de Impuestos"}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <Icon fontSize="small">close</Icon>
        </IconButton>
      </div>
      <form
        onSubmit={handleSumbit}
        className="pt-8 flex flex-col"
        style={{ width: 450 }}
      >
        <TextField
          inputRef={initialInput}
          autoFocus
          size="small"
          label={FIELDS.name.name}
          variant="outlined"
          fullWidth
          required
          value={taxPlan[FIELDS.name.key]}
          onChange={(e) => handleChange(FIELDS.name.key, e.target.value)}
        />
        <TextField
          size="small"
          label={FIELDS.description.name}
          variant="outlined"
          fullWidth
          style={{ marginTop: 10 }}
          multiline
          onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault() } }}
          rows={3}
          required
          value={taxPlan[FIELDS.description.key]}
          onChange={(e) => handleChange(FIELDS.description.key, e.target.value)}
        />
        <FormGroup className="p-2" row>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                size="small"
                checked={Boolean(taxPlan['generateRetention'])}
                onChange={(e) =>
                  handleChange('generateRetention', e.target.checked)
                }
                name={'Emitir Retenciones'}
              />
            }
            label={'Emitir Retenciones'}
          />
        </FormGroup>
        <FormGroup className="p-2" row>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                size="small"
                checked={Boolean(taxPlan['onlyIva'])}
                onChange={(e) =>
                  handleChange('onlyIva', e.target.checked)
                }
                name={'Solo IVA'}
              />
            }
            label={'Solo IVA'}
          />
        </FormGroup>
        {(location.state as any)?.edit && (
          <FormGroup className="p-2" row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={Boolean(taxPlan[FIELDS.active.key])}
                  onChange={(e) =>
                    handleChange(FIELDS.active.key, e.target.checked)
                  }
                  name={FIELDS.active.name}
                />
              }
              label={FIELDS.active.name}
            />
          </FormGroup>
        )}
        <Autocomplete
          multiple
          options={taxes.filter(t => {
            const type = taxTypes.find(type => type.taxId === t.taxTypeId)
            return type?.forRetention
          })}
          disableCloseOnSelect
          getOptionLabel={(tax) => {
            const taxType = taxTypes.find(taxType => taxType.taxId === tax.taxTypeId)
            return `${taxType?.name ?? ""} ${taxType?.forRetention ? tax.taxCode : tax.jbaCode}`
          }}
          limitTags={2}
          fullWidth
          value={selectedTaxes}
          openOnFocus
          size="small"
          style={{ marginTop: 10 }}
          ChipProps={{ size: "small", style: { maxWidth: 90 } }}
          onChange={(e, selectedValue) => setSelectedTaxes(selectedValue)}
          renderOption={(tax, { selected }) => {
            const taxType = taxTypes.find(taxType => taxType.taxId === tax.taxTypeId)
            return (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8, height: 50 }}
                  checked={selected}
                />

                {`${taxType?.name ?? ""} ${taxType?.forRetention ? tax.taxCode : tax.jbaCode} - ${tax.percentage.toFixed(2)}%`}
              </React.Fragment>
            )
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              label={"Impuestos"}
            />
          )}
        />
        <div className="mt-8">
          <Divider />
        </div>
        <div className="flex justify-end pt-3">
          <Button
            size="small"
            color="primary"
            variant="contained"
            disableElevation
            disabled={loading}
            type="submit"
          >
            {loading ? (
              <CircularProgress
                style={{ color: "white" }}
                size={16}
                className="my-1"
                thickness={10}
              />
            ) : (location.state as any)?.edit ? (
              "Guardar"
            ) : (
              "Registrar"
            )}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default TaxPlanForm
