import { InputAdornment, TextField, Icon, IconButton } from '@material-ui/core'
import React from 'react'

interface Props {
    query: string
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    onClear: () => void
    placeholer?: string
    width?: number | string
    disabled?: boolean
}

const Search = (props: Props) => {
    const { onChange, query, onClear, placeholer, width, disabled } = props
    const inputRef = React.useRef<any>(null)
    const handleClear = () => {
        onClear()
        inputRef.current?.focus()
    }
    return (
        <TextField
            size="small"
            inputRef={inputRef}
            value={query ?? ""}
            onChange={onChange}
            variant="outlined"
            style={{ width: width ?? 360 }}
            placeholder={placeholer ?? "Buscar..."}
            classes={{ root: "search-text-field" }}
            InputProps={{
                startAdornment: <InputAdornment position="start"><Icon style={{ fontSize: "0.75em" }}>search</Icon></InputAdornment>,
                endAdornment: query && <InputAdornment position="end"><IconButton onClick={handleClear} size="small"><Icon style={{ fontSize: "0.75em" }}>close</Icon></IconButton></InputAdornment>
            }}
            disabled={disabled}
        />
    )
}


export default Search
