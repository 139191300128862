import { Box, Drawer, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Slide, Typography } from '@material-ui/core'
import { DOCUMENT_PATH, DOCUMENT_TYPES, NEW_DOCUMENT_PATH, REIMBURSEMENT_PATH } from 'lib'
import React from 'react'
import documents from 'assets/img/documents.svg'
import { useHistory } from 'react-router'
import { IDocumentTypeInputs } from 'types'
import { useLocation } from 'react-router-dom'

interface Props {
    open: boolean
    onClose: () => void
}

const DocumentPicker = (props: Props) => {
    const history = useHistory()
    const { open, onClose } = props
    const location = useLocation()
    const handleSelect = (selected: IDocumentTypeInputs) => {
        history.push({
            pathname: `${DOCUMENT_PATH}${NEW_DOCUMENT_PATH}/${selected.type}`,
            state: { type: selected }
        })
    }
    return (
        <React.Fragment>
            <Drawer onClose={onClose} anchor="right" open={open}>
                <Box className="h-full flex flex-col relative overflow-hidden" style={{ width: 350 }} bgcolor="primary.main">
                    <div className="flex flex-wrap items-center p-4 justify-center mb-2">
                        <IconButton onClick={onClose}>
                            <Icon className="text-white">
                                {"chevron_left"}
                            </Icon>
                        </IconButton>
                        <Typography className="text-white uppercase" variant="h6" align="center" style={{ fontWeight: 600, letterSpacing: 0.25 }}>
                            {"Selecciona el tipo de documento"}
                        </Typography>
                    </div>
                    <Slide timeout={{ enter: 400, exit: 300 }} direction="up" in={open}>
                        <Paper style={{ borderRadius: 30, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} className="flex-grow p-4 overflow-x-hidden overflow-y-auto">
                            <img style={{ bottom: 10, objectFit: "contain", width: 220, height: 220, right: -10, transition: "0.75s", pointerEvents: "none" }} className="z-10 opacity-25 absolute" src={documents} alt="Documents" />
                            <List>
                                {
                                    (location.pathname === `${DOCUMENT_PATH}${REIMBURSEMENT_PATH}` 
                                    ? DOCUMENT_TYPES.filter(d => d.type === "cash-reimbursement" || d.type === "employee-reimbursement") 
                                    : DOCUMENT_TYPES).map(d => (
                                        <ListItem
                                            button
                                            onClick={() => handleSelect(d)}
                                        >
                                            <ListItemAvatar>
                                                <Icon style={{ color: "#a1a1a1" }}>
                                                    description
                                                </Icon>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={d.name}
                                            />
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Paper>
                    </Slide>
                </Box>
            </Drawer>
        </React.Fragment>
    )
}

export default DocumentPicker
